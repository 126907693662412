@if (dialogType === 'SESSION_EXPIRED') {
  <h2 mat-dialog-title>Session Expired</h2>
}

<mat-dialog-content>
  <span style="color: black;">Please enter your credentials to continue</span>

  <form class="login-register-form" [formGroup]="loginForm">
    <mat-form-field class="form-field">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email">

      @if (loginForm.controls.email.hasError('required')) {
        <mat-error>Email is required.</mat-error>
      }

      @if (loginForm.controls.email.hasError('email')) {
        <mat-error>Invalid email.</mat-error>
      }
    </mat-form-field>

    <mat-form-field class="form-field">
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password">

      @if (loginForm.controls.password.hasError('required')) {
        <mat-error>Password is required.</mat-error>
      }
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button [disabled]="isLoading" mat-raised-button (click)="logout()">{{dialogType === 'SESSION_EXPIRED' ? 'Logout' : 'Cancel'}}</button>

  <button [disabled]="isLoading" mat-raised-button color="primary" (click)="login()">Log In</button>
</mat-dialog-actions>
