<div class="job-benefits-container">
  <div class="header-container">
    <div class="header">
      <span style="font-family: 'Manrope Light', sans-serif; color: #082645;"> JOB&nbsp;</span>
      <span
        style="font-family: 'Wulkan Display SemiBold Italic',sans-serif;color: #1BA9B5;">BENEFITS</span>
    </div>
    <div class="line-green">
    </div>
  </div>

  <div class="benefits-list">
    <ul>
      <li>Personalized Compensation Packages</li>
      <li>401K Plan with Weekly Company Match</li>
      <li>Health Insurance Available Your First Week</li>
      <li>Private, Furnished, Company Paid Housing</li>
      <li>Weekly Pay with Available Direct Deposit</li>
      <li>Attractive Bonus Programs</li>
    </ul>
  </div>
</div>
