<div class="flex-column">
  <mat-card class="dialog-card">

    <form [formGroup]="educationForm" style="margin: 10px; display: flex; flex-direction: column; gap: 10px;">

      <div class="form-row">


        <div style="margin-top: 10px; flex: 1; width:100%">
          <div class="form-array-title">
            @if(educationFormArray.controls.length === 0) {
              <div class="portal-section-empty"><p>No education added, you can add a reference by hitting
                the Add Education button in the right.</p></div>
            }
            <div class="flex-expand"></div>
            <button class="mat-icon-button-sm"  mat-icon-button (click)="addEducation()"
                    matTooltip="Add Education">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ng-container *ngTemplateOutlet="educationList"></ng-container>
        </div>
      </div>


      <ng-template #educationList>
        <!--        <div class="form-row">-->
        <div formArrayName="education" class="form-array-margin flex-column">
          @for (item of educationFormArray.controls; track item.value) {
            <div [formGroupName]="$index" class="form-array">
              <button [disabled]="disablePage" mat-icon-button (click)="removeEducationItem($index)"
                      class="remove-button">
                <mat-icon>close</mat-icon>
              </button>
              <div class="form-array-wrap">
                <mat-form-field>
                  <mat-label>School</mat-label>
                  <input matInput formControlName="schoolName">
                  @if (educationFormArray.at($index).get('schoolName')?.hasError('required')) {
                    <mat-error>School Name is required</mat-error>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>City</mat-label>
                  <input matInput formControlName="city">
                  @if (educationFormArray.at($index).get('city')?.hasError('required')) {
                    <mat-error>City is required</mat-error>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>State</mat-label>
                  <mat-select formControlName="state">
                    @for (state of states; track state.code) {
                      <mat-option [value]="state.code">{{ state.name }}</mat-option>
                    }
                  </mat-select>
                  @if (educationFormArray.at($index).get('state')?.hasError('required')) {
                    <mat-error>State is required</mat-error>
                  }
                </mat-form-field>


                <div class="custom-date-picker">
                  <app-custom-date-month formControlName="graduationDate"
                                         [placeHolder]="'Graduation Date'"
                                         [required]="true"></app-custom-date-month>

                </div>

                <mat-form-field>
                  <mat-label>Degree</mat-label>
                  <input matInput formControlName="degree">
                  @if (educationFormArray.at($index).get('degree')?.hasError('required')) {
                    <mat-error>Degree is required</mat-error>
                  }
                </mat-form-field>
              </div>
            </div>
          }
        </div>
        <!--        </div>-->
      </ng-template>
    </form>
  </mat-card>
</div>

