import {Component, OnInit, ViewChild} from '@angular/core';

import {MatCard} from "@angular/material/card";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {
  FormGroupDirective,
  NgForm,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";

import {fieldMatchValidator} from "../../shared/validators/fieldMatchValidator";
import {Subscription} from "rxjs";
import {MatDialogActions} from "@angular/material/dialog";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {PortalUserService} from "../../shared/services/portal-user.service";
import {
  MultiLineSnackBarService
} from "../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {MatCheckbox} from "@angular/material/checkbox";
import { requiredIfTouched } from '../../../shared/validators/RequiredIfTouched';

export class PasswordValidatorErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    // @ts-ignore
    return control.dirty && (!control.valid || (form.hasError('fieldsMatch')) || form.hasError('fieldsDoNotMatch'));
  }
}


@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [

    MatCard,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatSuffix,
    MatDialogActions,
    MatIconButton,
    MatIcon,
    MatButton,
    MatCheckbox,

  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent implements OnInit{
  changePasswordForm: UntypedFormGroup;
  communicationConsentForm: UntypedFormGroup;
  formMatcher = new PasswordValidatorErrorStateMatcher();

  showCurrentPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;
  @ViewChild('updateButton') private updateButton: MatButton;

  private subscriptions: Subscription[] = [];

  constructor(private portalUserService : PortalUserService,
              private snackBar: MultiLineSnackBarService,

              ) {
  }

  ngOnInit() {
    this.setChangePasswordForm();

  }

  private setChangePasswordForm(): void {

    this.changePasswordForm = new UntypedFormGroup({
      currPass: new UntypedFormControl(null, [requiredIfTouched]),
      newPass: new UntypedFormControl(null, [Validators.minLength(6), Validators.maxLength(20), Validators.required]),
      confirmPass: new UntypedFormControl(null, [Validators.minLength(6), Validators.maxLength(20), Validators.required]),
    }, [fieldMatchValidator(true, 'newPass', 'confirmPass'),
      fieldMatchValidator(false, 'currPass', 'newPass')]);
  }





  changePassword(): void {
    const currPass = this.changePasswordForm.get('currPass');
    const newPass = this.changePasswordForm.get('newPass');

    // @ts-ignore
    const oldPassword: string = currPass.value;
    // @ts-ignore
    const newPassword: string = newPass.value;

const activeUser = this.portalUserService.activeUser;

    this.portalUserService.updateLoggedInUserPassword(oldPassword,newPassword)
        .subscribe(result => {
          const message: string[] = result.isSuccess
            ? ['Password has been changed']
            : result.errorList;
          this.snackBar.displayMessages(message);

          if (result.isSuccess) {
            this.clearForm();
            this.updateButton.focus();
          }
        })

  }

  clearForm(): void {
    this.changePasswordForm.reset();
    this.showNewPassword = false;
    this.showConfirmPassword = false;
    this.showCurrentPassword = false;
  }


}
