import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-apply-today',
  standalone: true,
  imports: [],
  templateUrl: './apply-today.component.html',
  styleUrl: './apply-today.component.css'
})
export class ApplyTodayComponent {

}
