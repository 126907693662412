<div class="mobile-dialog-header">
  Add your resume file
</div>
<mat-dialog-content>
  <div [formGroup]="fileForm" class="file-form" >

    <div class="form-row">
      <input type="file" #fileInput style="display: none;" (input)="uploadFile($event)">
      <mat-form-field class="mat-mdc-form-field-bottom-align">
        <mat-label>File</mat-label>
        <input matInput formControlName="fileName">
        <button mat-icon-button matIconSuffix (click)="fileInput.click()">
          <mat-icon>attach_file</mat-icon>
        </button>
        @if (fileForm.controls.fileName.hasError('required')) {
          <mat-error>File is required.</mat-error>
        }
      </mat-form-field>

<!--      <button (click)="fileInput.click()" mat-raised-button color="primary">-->
<!--        Load Resume-->

<!--      </button>-->


    </div>



    @if (isLoadingFile) {

        <mat-progress-bar mode="indeterminate"></mat-progress-bar>

    }


  </div>
<!--  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>-->
  </mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
<!--  <button mat-raised-button color="primary" (click)="fileInput.click()" > Load Resume</button>-->
</mat-dialog-actions>

