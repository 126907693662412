<div class="table-row-m">
  <div class="table-row-inner">
    <div class="column-header-m header-font-m">State</div>
    <div class="column-value state-font-m">{{ state }}</div>
  </div>
  <div class="table-row-inner">
    <div class="column-header-m header-font-m">Phone</div>
    <div class="column-value content-font-m">{{phone |phone }}</div>
  </div>
  <div class="table-row-inner">
    <div class="column-header-m header-font-m">WalkThru</div>
    <div class="column-value content-font-m">{{convertWalkThrough()}}</div>
  </div>
  <div class="table-row-inner">
    <div class="column-header-m header-font-m">Compact</div>
    <div class="column-value content-font-m">{{convertCompact()}}</div>
  </div>
  <div class="table-row-inner">
    <div class="column-header-m header-font-m">Nursys</div>
    <div class="column-value content-font-m">{{convertNursys()}}</div>
  </div>
  <div class="table-row-inner">
    <div class="column-header-m header-font-m">License Info</div>
    <div class="column-value content-font-m">
      <ul class="list-style">
        @for(item of info ; track item; let index = $index)
        {
          <li>{{item}}</li>
        }
      </ul>
    </div>
  </div>
</div>
