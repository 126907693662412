<div class="home-container">
  <div class="home-column">
    <app-portal-section-completeness
      [completeness]="personalInfoCompleteness()"
      [label]="'Profile Completeness'"
      (click)="onPersonalInfoClick($event)"
    ></app-portal-section-completeness>
  </div>

  <div class="home-column">
    <app-portal-home-job-applications></app-portal-home-job-applications>
    <app-recruiter-contact></app-recruiter-contact>
  </div>
</div>
