<div class="desktop">
  <div class="housing-card">
    @if (housingContent.ImagePosition == "right") {
      <div class="housing-card-content left-radius ">
        <div class="card-icon">
          <img class="icon" ngSrc="assets/svg/{{housingContent.IconFileName}}" alt="" width="1" height="1">
        </div>
        <div class="card-content">
          <h4 class="card-title manrope-bold">
            {{ housingContent.Title }}
          </h4>
          <p class="card-description  manrope-regular" [innerHTML]="housingContent.Description  | safeHtml">
          </p>
        </div>
      </div>
      <div class="housing-card-img   right-radius">
        <img ngSrc="assets/img/{{housingContent.ImageFileName}}" alt="" width="1"
             height="1" class="img-right">
      </div>

    } @else {

      <div class="housing-card-img   left-radius">
        <img ngSrc="assets/img/{{housingContent.ImageFileName}}" alt="" width="1"
             height="1" class="img-left">
      </div>

      <div class="housing-card-content right-radius ">
        <div class="card-icon">
          <img class="icon" ngSrc="assets/svg/{{housingContent.IconFileName}}" alt="" width="1" height="1">
        </div>
        <div class="card-content">
          <h4 class="card-title manrope-bold">
            {{ housingContent.Title }}
          </h4>
          <p class="card-description  manrope-regular" [innerHTML]="housingContent.Description  | safeHtml">
          </p>
        </div>
      </div>
    }
  </div>
</div>


<div class="tablet-mobile">
  <div class="housing-card">
    <div class="housing-card-img   left-radius">
      <img ngSrc="assets/img/{{housingContent.ImageFileName}}" alt="" width="1"
           height="1" class="img-left">
    </div>
    <div class="housing-card-content right-radius ">
      <div class="card-icon">
        <img class="icon" ngSrc="assets/svg/{{housingContent.IconFileName}}" alt="" width="1" height="1">
      </div>
      <div class="card-content">
        <h4 class="card-title manrope-bold">
          {{ housingContent.Title }}
        </h4>
        <p class="card-description  manrope-regular" [innerHTML]="housingContent.Description  | safeHtml">
        </p>
      </div>
    </div>
  </div>
</div>

