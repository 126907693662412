import {Component, Input} from '@angular/core';
import {PhonePipe} from "../../../../../shared/pipes/phone.pipe";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-licensure-table-item-mobile',
  standalone: true,
  imports: [
    PhonePipe,
  ],
  templateUrl: './licensure-table-item-mobile.component.html',
  styleUrl: './licensure-table-item-mobile.component.css'
})
export class LicensureTableItemMobileComponent {
  @Input() state: string;
  @Input() phone: string;
  @Input() walkThrough: boolean;
  @Input() compact: boolean;
  @Input() nursys: boolean;
  @Input() info: string[];

  // pipe: PhonePipe = new PhonePipe();

  convertWalkThrough(): string {
    return this.walkThrough ? 'walk-thru' : '';
  }
  convertNursys(): string {
    return this.nursys ? 'nursys' : '';
  }

  convertCompact(): string {
    return this.compact ? 'compact' : '';
  }
}
