import {Component, Input} from '@angular/core';
import {Assignment, AssignmentStatus} from "../../shared/types";
import {MatCard} from "@angular/material/card";
import {DatePipe, NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {TimesheetComponent} from "../timesheets/timesheet.component";
import {PhonePipe} from "../../../shared/pipes/phone.pipe";

@Component({
  selector: 'app-user-assignment',
  standalone: true,
  imports: [
    MatCard,
    DatePipe,
    NgIf,
    MatButton,
    PhonePipe,

    TimesheetComponent
  ],
  templateUrl: './user-assignment.component.html',
  styleUrl: './user-assignment.component.css'
})
export class UserAssignmentComponent {
  @Input() assignment: Assignment;
  protected readonly AssignmentStatus = AssignmentStatus;

  constructor( ) {
  }




}
