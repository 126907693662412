import {Component, Inject, QueryList, ViewChildren} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Education} from '../../../shared/types';
import {CatalogsService} from '../../../../shared/services/catalogs.service';
import {
  MultiLineSnackBarService
} from '../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {ProfileService} from '../../services/profile.service';
import {PortalUserService} from '../../../shared/services/portal-user.service';
import {CustomDateMonthComponent} from "../../../../shared/components/custom-date-month/custom-date-month.component";

@Component({
  selector: 'app-education-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    CustomDateMonthComponent
  ],
  templateUrl: './education-dialog.component.html',
  styleUrl: './education-dialog.component.css'
})
export class EducationDialogComponent {
  disablePage = false;
  states = this.catalogsService.getStates();

  educationForm = this.formBuilder.group({
    schoolName: [this.data?.schoolName ?? '', [Validators.required]],
    city: [this.data?.city ?? '', [Validators.required]],
    state: [this.data?.state ?? '', [Validators.required]],
    graduationDate: [this.data?.graduationDate, [Validators.required]],
    degree: [this.data?.degree ?? '', [Validators.required]],
  });
  @ViewChildren(CustomDateMonthComponent) customDateComponents: QueryList<CustomDateMonthComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Education | null,
    private formBuilder: FormBuilder,
    private catalogsService: CatalogsService,
    private snackBar: MultiLineSnackBarService,
    private profileService: ProfileService,
    private userService: PortalUserService,
    private matDialogRef: MatDialogRef<EducationDialogComponent>,
  ) {
  }

  saveEducation(): void {
    this.markAllAsTouched();
    if (!this.educationForm.valid) {
      this.snackBar.displayMessages(['Please fix all errors before saving.']);
      return;
    }

    // disable form and buttons
    this.disablePage = true;
    this.educationForm.disable();
    // send to API for saving
    const newEducation = this.educationForm.value as Education;
    newEducation.id = this.data?.id ?? 0;
    this.profileService.saveEducation(newEducation)
      .subscribe(result => {
        if (result.isSuccess) {
          const education = this.userService.activeUser()?.education ?? [];
          const newEducation = result.value as Education;
          if (education.find(wh => wh.id === newEducation.id)) {
            education.splice(education.findIndex(wh => wh.id === newEducation.id), 1, newEducation);
          } else {
            education.push(newEducation);
          }
          this.userService.setEducation(education);
          this.matDialogRef.close();
        } else {
          this.snackBar.displayMessages(result.errorList);
        }
        this.disablePage = false;
        this.educationForm.enable();
      });
  }
  markAllAsTouched() {
    this.educationForm.markAllAsTouched();
    this.customDateComponents.forEach(customDateComponent => customDateComponent.markAllAsTouched());
  }
}
