import {Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about/about.component';
import {JobSearchComponent} from './job-search/job-search.component';
import {PortalComponent} from './portal/portal.component';
import {ContactComponent} from './about/contact/contact.component';
import {RapidAppComponent} from './rapid-app/rapid-app.component';
import {FaqComponent} from './resources/faq/faq.component';
import {HousingInformationComponent} from './benefits/housing-information/housing-information.component';
import {LicensureGuideComponent} from './benefits/licensure-guide/licensure-guide.component';
import {ReferralsComponent} from './benefits/referrals/referrals.component';
import {CompanyPoliciesComponent} from './resources/company-policies/company-policies.component';
import {BenefitsComponent} from './benefits/benefits/benefits.component';
import {IndividualBlogPostComponent} from "./blog/individual-blog-post/individual-blog-post.component";
import {JobDetailsComponent} from "./job-search/job-details/job-details.component";
import {SuccessfulSubmitComponent} from './shared/components/successful-submit/successful-submit.component';
import {PortalNewPasswordComponent} from "./portal/portal-new-password/portal-new-password.component";
import {VerifyAccountComponent} from './portal/account-verification/verify-account/verify-account.component';

const mainRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'apply',
    component: RapidAppComponent,
  },
  {
    path: 'benefits',
    component: BenefitsComponent,
  },
  // {
  //   path: 'blog',
  //   component: BlogComponent,
  // },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'housing',
    component: HousingInformationComponent,
  },
  {
    path: 'individual-blog',
    component: IndividualBlogPostComponent,
  },
  {
    path: 'job-search',
    component: JobSearchComponent,
  },
  {
    path: 'job-details',
    component: JobDetailsComponent,
  },
  {
    path: 'licensure',
    component: LicensureGuideComponent,
  },
  {
    path: 'new-password/:meta',
    component: PortalNewPasswordComponent,
  },
  {
    path: 'policies',
    component: CompanyPoliciesComponent,
  },
  {
    path: 'portal',
    component: PortalComponent,
  },
  {
    path: 'referrals',
    component: ReferralsComponent,
  },
  {
    path: 'thank-you',
    component: SuccessfulSubmitComponent,
  },
  {
    path: 'verify-account',
    component: VerifyAccountComponent
  }
];

const redirectedRoutes: Routes = [
  {
    path: 'states/florida-nursing-jobs',
    component: JobSearchComponent,
  },
  {
    path: 'states/georgia-nursing-jobs',
    component: JobSearchComponent,
  },
  {
    path: 'specialties/psych-tech-allied-jobs',
    component: JobSearchComponent,
  },
  {
    path: 'specialties',
    component: JobSearchComponent,
  },
  {
    path: 'states',
    component: JobSearchComponent,
  },
];

export const routes: Routes = [...mainRoutes, ...redirectedRoutes];
