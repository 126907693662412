<div class="housing-container">

  <app-housing-header style="width: 100%"></app-housing-header>

  <div class="housing-content-container flex-column ">
    <div class="search-container flex-column">
      <h3>
        <span class="manrope-light searching-header"> SEARCHING FOR </span>
        <span class="wulkandisplay-semibolditalic searching-header-italic">HOUSING?</span>
      </h3>
      <div class="paragraf-big search-description">
        Whether you want furnished housing provided for you or a housing stipend to find your own place instead, the
        choice is yours! You may find that Nightingale-offered housing is best for one assignment while the housing
        stipend works better for another. It’s okay to switch between housing options from assignment to assignment.
      </div>
    </div>
    <div class="line-green">
    </div>
  </div>

  <div class="flex-column" style="padding-bottom: 60px;">
    @for (housingCard of housingContentCards; track housingCard.Title) {
      <div class="housing-content-row">
        <app-housing-card [housingContent]="housingCard"></app-housing-card>
      </div>
    }
  </div>

  <app-footer></app-footer>

</div>
