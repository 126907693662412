<div class="faq-item">
  <div>
    <div class="number">08</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>Does Nightingale offer part time or per diem assignments?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>Yes. While Nightingale is primarily a clinical travel firm, representing and
          working with full-time staff, long-term, part time or as needed assignment are
          easily worked out based on the determination of the client facility’s staff deployment plan.</p>


      </div>
    }

  </div>

</div>

