import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {
  TableFooterComponent
} from "../../../../shared/components/table-footer/table-footer.component";

import {PolicyMockDataService} from "../../services/policy-mock-data-service";
import {SelectOption} from "../../../../shared/model/types";
import {map, Observable} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-policy-list',
  standalone: true,
  imports: [
    AsyncPipe,

    NgIf,
    NgOptimizedImage,
    TableFooterComponent,

  ],
  templateUrl: './policy-list.component.html',
  styleUrl: './policy-list.component.css'
})
export class PolicyListComponent implements OnInit {
  constructor(
    private policyService: PolicyMockDataService
  ) {
  }

  policies$ = this.policyService.getPolicies();
  resultSize: number;
  newPage = 1;
  reload: boolean;
  policiesToShow$: Observable<SelectOption[]>;
  firstItem: number;
  showError: boolean;
  itemsPerPage = 10;



  ngOnInit() {

    this.setNewPage(1, true);

  }

  onFooterClick(page: number) {
    this.setNewPage(page, false);
  }

  setNewPage(page: number, reload: boolean) {
    this.newPage = page;
    this.reload = reload;
    this.policiesToShow$ = this.policies$.pipe(map(policies => {
        this.resultSize = policies.length;

        if (policies.length < this.itemsPerPage) {
          this.firstItem = 1;
          return policies;
        } else {
          this.firstItem = (this.newPage - 1) * this.itemsPerPage + 1;
          return policies.slice((this.newPage - 1) * this.itemsPerPage, (this.newPage) * this.itemsPerPage)

        }

      }
    ));


    // this.firstItem=(this.newPage-1)*10+1;
  }


  openPdf(pdfUrl: string): void {
    window.open(pdfUrl, '_blank');
  }

}


