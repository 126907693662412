import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, map, Observable, of} from 'rxjs';
import {createFailedResult, Result} from '../../../shared/model/result';
import {
  ChecklistTemplate,
  Education, FileInfo, PortalUserProfile,
  ProfilePersonalInfo,
  ProfileProfessionalInfo,
  Reference,
  SkillsChecklist,
  WorkHistory
} from '../../shared/types';
import {PortalUserFileFull} from "../portal-user-files/shared/types";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  resumeData: PortalUserProfile;

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  savePersonalInfo(personalInfo: ProfilePersonalInfo): Observable<Result> {
    return this.httpClient.post<Result>('profile/personal-info', personalInfo)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }

  saveProfessionalInfo(professionalInfo: ProfileProfessionalInfo): Observable<Result> {
    return this.httpClient.post<Result>('profile/professional-info', professionalInfo)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }

  saveWorkHistory(workHistory: WorkHistory): Observable<Result<WorkHistory>> {
    const request = (workHistory.id ?? 0) > 0
      ? this.httpClient.put<Result<WorkHistory>>(`profile/work-history/${workHistory.id}`, workHistory)
      : this.httpClient.post<Result<WorkHistory>>('profile/work-history', workHistory);

    return request
      .pipe(
        catchError(e => of(createFailedResult<WorkHistory>(['An error occurred, please try again.'])))
      );
  }

  deleteWorkHistory(workHistoryId: number): Observable<Result> {
    return this.httpClient.delete<Result>(`profile/work-history/${workHistoryId}`)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }

  saveEducation(education: Education): Observable<Result<Education | null>> {
    const request = (education.id ?? 0) > 0
      ? this.httpClient.put<Result<Education>>(`profile/education/${education.id}`, education)
      : this.httpClient.post<Result<Education>>('profile/education', education);

    return request
      .pipe(
        catchError(e => of({
          isSuccess: false,
          value: null,
          errorList: ['An error occurred, please try again.'],
          warningList: [],
          infoList: [],
        }))
      );
  }

  deleteEducation(educationId: number): Observable<Result> {
    return this.httpClient.delete<Result>(`profile/education/${educationId}`)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }

  saveReference(reference: Reference): Observable<Result<Reference>> {
    const request = (reference.id ?? 0) > 0
      ? this.httpClient.put<Result<Reference>>(`profile/reference/${reference.id}`, reference)
      : this.httpClient.post<Result<Reference>>('profile/reference', reference);

    return request
      .pipe(
        catchError(e => of(createFailedResult<Reference>(['An error occurred, please try again.'])))
      );
  }

  deleteReference(referenceId: number): Observable<Result> {
    return this.httpClient.delete<Result>(`profile/reference/${referenceId}`)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }

  getChecklistTemplates(): Observable<Result<ChecklistTemplate[]>> {
    return this.httpClient.get<Result<ChecklistTemplate[]>>('skills-checklist/templates')
      .pipe(
        catchError(e => of(createFailedResult<ChecklistTemplate[]>(['An error occurred, please try again.'])))
      );
  }

  saveSkillsChecklist(skillsChecklist: SkillsChecklist): Observable<Result<SkillsChecklist | null>> {
    return this.httpClient.post<Result<SkillsChecklist>>('profile/skills-checklist', skillsChecklist)
      .pipe(
        catchError(e => of(createFailedResult<SkillsChecklist>(['An error occurred, please try again.'])))
      );
  }

  deleteSkillsChecklist(skillsChecklistId: number): Observable<Result> {
    return this.httpClient.delete<Result>(`profile/skills-checklist/${skillsChecklistId}`)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }


  getSmList(): Observable<{ id: number; name: string }[]> {
    return this.httpClient.get<{ id: number; name: string }[]>('profile/sm-users');
  }


  public loadResume(newFile: FileInfo): Observable<Result<PortalUserProfile>> {
    return this.httpClient.post<Result<PortalUserProfile>>('profile/load-resume', newFile)
      .pipe(
        catchError(e => {
          return of(createFailedResult<PortalUserProfile>(['An error occurred, please ensure your file is less than 20MB and try again.']));
        })
      );
  }

  // uploadResume(file: File): Observable<Result> {}


  saveResumeData(resumeData: PortalUserProfile) {
    this.resumeData = resumeData;
  }

  getResumeData() {
    return this.resumeData;
  }
  clearResumeData() {
    this.resumeData = {} as PortalUserProfile;
  }

  saveProfile(profileInfo: PortalUserProfile): Observable<Result<PortalUserProfile>> {
    return this.httpClient.post<Result<PortalUserProfile>>('profile', profileInfo)
      .pipe(
        catchError(e => {

          return of(createFailedResult<PortalUserProfile>(['An error occurred, please try again.']));
        })
      );
  }
  saveResumeProfile(profileInfo: PortalUserProfile): Observable<Result<PortalUserProfile>> {
    return this.httpClient.post<Result<PortalUserProfile>>('profile/save-resume', profileInfo)
      .pipe(
        catchError(e => {

          return of(createFailedResult<PortalUserProfile>(['An error occurred, please try again.']));
        })
      );
  }

}
