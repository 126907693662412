<div class="contact-header-container">

  <app-header></app-header>

  <div class="contact-header  flex-column">

    <h2>
      <span class="manrope-extralight"> Get In</span>
      <span class="wulkandisplay-regularitalic"> Touch</span>
    </h2>

    <div class="contact-description">
      <span> Anytime, anywhere. Nightingale Travel Nursing Specialists</span>
      <span> are always on-call for you. Contact us today.</span>
    </div>
    <div class="line">
    </div>


    <div class="connect-online-container">
      <div class="desktop">
        <app-connect-online></app-connect-online>
      </div>
    </div>

  </div>


</div>
