import { Component } from '@angular/core';
import {FaqItemComponent} from "./faq-item/faq-item.component";
import {HousingCardComponent} from '../../../../benefits/housing-information/housing-card/housing-card.component';
import {FaqItem1Component} from "./faq-item-1/faq-item-1.component";
import {FaqItem2Component} from "./faq-item-2/faq-item-2.component";
import {FaqItem12Component} from "./faq-item-12/faq-item-12.component";
import {FaqItem11Component} from "./faq-item-11/faq-item-11.component";
import {FaqItem10Component} from "./faq-item-10/faq-item-10.component";
import {FaqItem9Component} from "./faq-item-9/faq-item-9.component";
import {FaqItem8Component} from "./faq-item-8/faq-item-8.component";
import {FaqItem7Component} from "./faq-item-7/faq-item-7.component";
import {FaqItem6Component} from "./faq-item-6/faq-item-6.component";
import {FaqItem5Component} from "./faq-item-5/faq-item-5.component";
import {FaqItem4Component} from "./faq-item-4/faq-item-4.component";
import {FaqItem3Component} from "./faq-item-3/faq-item-3.component";

@Component({
  selector: 'app-faq-list',
  standalone: true,
  imports: [
    FaqItemComponent,
    HousingCardComponent,
    FaqItem1Component,
    FaqItem2Component,
    FaqItem12Component,
    FaqItem11Component,
    FaqItem10Component,
    FaqItem9Component,
    FaqItem8Component,
    FaqItem7Component,
    FaqItem6Component,
    FaqItem5Component,
    FaqItem4Component,
    FaqItem3Component
  ],
  templateUrl: './faq-list.component.html',
  styleUrl: './faq-list.component.css'
})
export class FaqListComponent {


}
