<div class="portal-profile-section-container">
  <mat-card class="portal-profile-section-card">
    <form [formGroup]="profileForm" style="margin: 10px; display: flex; flex-direction: column; gap: 10px;">
      <div class="form-row">
        <div class="flex-column" style="flex: 1;">
          <mat-form-field>
            <mat-label>Has your license ever been investigated, suspended, or revoked?</mat-label>
            <mat-select formControlName="hadLicenseSuspended"
                        (valueChange)="toggleFormFieldRequired($event, profileForm.controls.suspensionExplanation)">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>If your license was suspended, please provide an explanation:</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
                      formControlName="suspensionExplanation"></textarea>
            @if (profileForm.controls.suspensionExplanation.hasError('required')) {
              <mat-error>Suspension Explanation is required</mat-error>
            }
          </mat-form-field>
        </div>
        <div class="flex-column" style="flex: 1;">
          <mat-form-field>
            <mat-label>Have you ever been convicted of a crime other than a minor traffic violation (including any
              dispositions of adjudication withheld)?
            </mat-label>
            <mat-select formControlName="wasConvictedOfCrime"
                        (valueChange)="toggleFormFieldRequired($event, profileForm.controls.convictionExplanation)">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>If you were convicted of a crime, please provide an explanation:</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
                      formControlName="convictionExplanation"></textarea>
            @if (profileForm.controls.convictionExplanation.hasError('required')) {
              <mat-error>Conviction Explanation is required</mat-error>
            }
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <mat-form-field style="flex: 1;">
          <mat-label>Can you submit verification of legal right to work in the U.S. if requested?</mat-label>
          <mat-select formControlName="legalToWork">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="flex: 2;">
          <mat-label>Charting/EMR Experience</mat-label>
          <mat-select formControlName="chartingEmrExperience" multiple>
            @for(option of chartingEmrOptions; track option.value) {
              <mat-option [value]="option.value">{{option.label}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field style="flex: 1;">
          <mat-label>Primary Specialty</mat-label>
          <mat-select formControlName="specialty">
            @for (specialty of specialties; track specialty) {
              <mat-option [value]="specialty">{{ specialty }}</mat-option>
            }
          </mat-select>
          @if (profileForm.controls.specialty.hasError('required')) {
            <mat-error>Specialty is required</mat-error>
          }
        </mat-form-field>
        <mat-form-field style="flex: 2;">
          <mat-label>Additional Specialties</mat-label>
          <mat-select multiple formControlName="secondarySpecialties">
            @for (specialty of specialties; track specialty) {
              <mat-option [value]="specialty">{{ specialty }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-checkbox color="primary" formControlName="hasCompactLicense">Check here if you have a compact license
        </mat-checkbox>
      </div>

      <div class="form-row hide-mobile">
        <div style="margin-top: 10px; flex: 1;">
          <div class="form-array-title">
            <div>State Licenses</div>
            <div class="flex-expand"></div>
            <button class="mat-icon-button-sm" [disabled]="disablePage" mat-icon-button (click)="addLicense()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ng-container *ngTemplateOutlet="licensesList"></ng-container>
        </div>

        <mat-divider vertical></mat-divider>

        <div style="margin-top: 10px; flex: 1;">
          <div class="form-array-title">
            <div>Certifications</div>
            <div class="flex-expand"></div>
            <button class="mat-icon-button-sm" [disabled]="disablePage" mat-icon-button (click)="addCertification()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ng-container *ngTemplateOutlet="certificatesList"></ng-container>
        </div>
      </div>

      <div class="show-mobile flex-row">
        <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start" style="flex: 1;">
          <mat-tab label="Licenses">
            <ng-container *ngTemplateOutlet="licensesList"></ng-container>
          </mat-tab>
          <mat-tab label="Certifications">
            <ng-container *ngTemplateOutlet="certificatesList"></ng-container>
          </mat-tab>
        </mat-tab-group>
        <button class="mat-icon-button-sm add-active-button" [disabled]="disablePage" mat-icon-button
                (click)="addActive(tabGroup.selectedIndex)">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <ng-template #licensesList>
        <div formArrayName="statesLicensed" class="form-array-margin flex-column">
          @for (stateLicense of licensesFormArray.controls; track stateLicense.value) {
            <div [formGroupName]="$index" class="form-array">
              <button [disabled]="disablePage" mat-icon-button (click)="removeLicense($index)" class="remove-button">
                <mat-icon>close</mat-icon>
              </button>
              <mat-form-field>
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                  @for (state of states; track state.code) {
                    <mat-option [value]="state.code">{{ state.name }}</mat-option>
                  }
                </mat-select>
                @if (licensesFormArray.at($index).get('state')?.hasError('required')) {
                  <mat-error>State is required</mat-error>
                }
              </mat-form-field>
              <mat-form-field>
                <mat-label>Expiration Date</mat-label>
                <input matInput formControlName="expirationDate" [matDatepicker]="stateLicenseExpirationDatePicker">
                <mat-datepicker-toggle matSuffix [for]="stateLicenseExpirationDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #stateLicenseExpirationDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          }
        </div>
      </ng-template>

      <ng-template #certificatesList>
        <div formArrayName="certifications" class="form-array-margin flex-column">
          @for (certification of certificationsFormArray.controls; track certification.value) {
            <div [formGroupName]="$index" class="form-array">
              <button [disabled]="disablePage" mat-icon-button (click)="removeCertification($index)"
                      class="remove-button">
                <mat-icon>close</mat-icon>
              </button>
              <mat-form-field>
                <mat-label>Certification</mat-label>
                <mat-select formControlName="name">
                  @for (certification of certifications; track certification) {
                    <mat-option [value]="certification">{{ certification }}</mat-option>
                  }
                </mat-select>
                @if (certificationsFormArray.at($index).get('name')?.hasError('required')) {
                  <mat-error>Certification is required</mat-error>
                }
              </mat-form-field>
              <mat-form-field>
                <mat-label>Expiration Date</mat-label>
                <input matInput formControlName="expirationDate" [matDatepicker]="certificationExpirationDatePicker">
                <mat-datepicker-toggle matSuffix [for]="certificationExpirationDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #certificationExpirationDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          }
        </div>
      </ng-template>
    </form>
  </mat-card>
</div>

<div class="portal-profile-section-bottom-action-bar">
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="saveProfile()">
    @if (disablePage) {
      <mat-spinner diameter="25"></mat-spinner>
    } @else {
      Save
    }
  </button>
</div>
