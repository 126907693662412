import {Component} from '@angular/core';
import {JobOrderService} from '../../../shared/services/job-order.service';
import {toObservable} from '@angular/core/rxjs-interop';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {map, switchMap, take, tap} from 'rxjs';
import {AsyncPipe, DatePipe} from '@angular/common';
import {application} from 'express';
import {JobApplication} from '../../../shared/model/types';
import {Router} from '@angular/router';
import {MatCard} from '@angular/material/card';

@Component({
  selector: 'app-portal-home-job-applications',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatCard
  ],
  templateUrl: './portal-home-job-applications.component.html',
  styleUrl: './portal-home-job-applications.component.css'
})
export class PortalHomeJobApplicationsComponent {

  applicationComparer = (a: JobApplication, b: JobApplication) =>
    a.createdDttm === b.createdDttm
      ? 0
      : a.createdDttm > b.createdDttm
        ? -1
        : 1;

  lastJobApplications$ =
    toObservable(this.userService.activeUser)
      .pipe(
        switchMap(user => this.jobOrderService.getJobApplications(user?.candidateId, 3)),
        map(applications => applications.sort(this.applicationComparer).slice())
      );


  constructor(
    private userService: PortalUserService,
    private jobOrderService: JobOrderService,
    private router: Router,
  ) {
  }

  navigateToJobSearch(): void {
    this.router.navigate(['/job-search']).then();
  }

}
