import {Component, TemplateRef, ViewChild} from '@angular/core';
import {ContactService} from '../../../shared/services/contact.service';
import {AsyncPipe} from '@angular/common';
import {PhonePipe} from '../../../shared/pipes/phone.pipe';
import {Observable} from 'rxjs';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatCard} from '@angular/material/card';

@Component({
  selector: 'app-recruiter-contact',
  standalone: true,
  imports: [
    AsyncPipe,
    PhonePipe,
    MatCard
  ],
  templateUrl: './recruiter-contact.component.html',
  styleUrl: './recruiter-contact.component.css'
})
export class RecruiterContactComponent {
  recruiterDetails$ = this.contactService.getRecruiterContactDetails();
  @ViewChild('template') template: TemplateRef<any>;

  constructor(
    private contactService: ContactService,
    private bottomSheet: MatBottomSheet,
  ) {
  }

  requestRecruiterContact(): void {
    this.contactService.requestRecruiterContact()
      .subscribe((result) => {
        if (result) {
          this.openBottomSheet();
        }
      });
  }

  openBottomSheet(): void {
    this.bottomSheet.open( this.template);
  }
}
