<div class="menu-container unselectable">
  <!--Header-->
  <div class="menu-header">
    <div class="flex-expand"></div>
    <div class="menu-close-button">
      <img ngSrc="./assets/svg/close-menu-tablet.svg" alt="" fill (click)="close()">
    </div>
  </div>

  <!--HOME-->
  <a href="/" class="menu-item menu-item-closed">HOME</a>

  <!--JOB SEARCH-->
  <a href="/job-search" class="menu-item menu-item-closed">JOB SEARCH</a>


  <!--BENEFITS-->
  <div class="menu-item" [ngClass]="isBenefitsDropdownOpen ? '' : 'menu-item-closed'"
       (click)="toggleBenefitsDropdown()">
    <div>BENEFITS</div>
    <div class="flex-expand"></div>

    @if (isBenefitsDropdownOpen) {
      <div class="menu-toggle-button">
        <img ngSrc="./assets/svg/dropdown-menu-close.svg" fill alt="">
      </div>
    } @else {
      <div class="menu-toggle-button">
        <img ngSrc="./assets/svg/dropdown-menu-open.svg" fill alt="">
      </div>
    }
  </div>

  @if (isBenefitsDropdownOpen) {
    <div class="menu-sub-item-container">
      <a href="/benefits" class="menu-sub-item">BENEFITS</a>
      <a href="/housing" class="menu-sub-item">HOUSING</a>
      <a href="/licensure" class="menu-sub-item">LICENSURE</a>
      <a href="/referrals" class="menu-sub-item">REFERRALS PROGRAM</a>
    </div>
    <div class="menu-item-closed menu-margin"></div>
  }

  <!--RESOURCES-->
  <div class="menu-item" [ngClass]="isResourcesDropdownOpen ? '' : 'menu-item-closed'"
       (click)="toggleResourcesDropdown()">
    <div>RESOURCES</div>
    <div class="flex-expand"></div>

    @if (isResourcesDropdownOpen) {
      <div class="menu-toggle-button">
        <img ngSrc="./assets/svg/dropdown-menu-close.svg" fill alt="">
      </div>
    } @else {
      <div class="menu-toggle-button">
        <img ngSrc="./assets/svg/dropdown-menu-open.svg" fill alt="">
      </div>
    }
  </div>

  @if (isResourcesDropdownOpen) {
    <div class="menu-sub-item-container">
      <a href="/policies" class="menu-sub-item">COMPANY POLICIES</a>
      <a href="/faq" class="menu-sub-item">FAQ</a>
    </div>
    <div class="menu-item-closed menu-margin"></div>
  }


  <!--ABOUT US-->
  <div class="menu-item" [ngClass]="isAboutDropdownOpen ? '' : 'menu-item-closed'"
       (click)="toggleAboutDropdown()">
    <div>ABOUT US</div>
    <div class="flex-expand"></div>

    @if (isAboutDropdownOpen) {
      <div class="menu-toggle-button">
        <img ngSrc="./assets/svg/dropdown-menu-close.svg" fill alt="">
      </div>
    } @else {
      <div class="menu-toggle-button">
        <img ngSrc="./assets/svg/dropdown-menu-open.svg" fill alt="">
      </div>
    }
  </div>

  @if (isAboutDropdownOpen) {
    <div class="menu-sub-item-container">
      <a href="/about" class="menu-sub-item">ABOUT NIGHTINGALE</a>
      <a href="/contact" class="menu-sub-item">CONTACT</a>
    </div>
    <div class="menu-item-closed menu-margin"></div>
  }

  <!--BLOG-->
  <!-- //TODO: Add Blogs-->
<!--    <a href="/blog" class="menu-item">BLOG</a>-->

  <!--BUTTONS-->
  <div class="buttons-line">
    <a href="/portal" class="header-menu-button-mobile header-menu-button-outline-mobile">
      <img ngSrc="./assets/svg/icon_user-dark.svg" alt="a" height="20" width="20">
      <div style="width: 5px"></div>
      <div>MY ACCOUNT</div>
    </a>

    <div style="width: 5%"></div>

    <a href="/apply" class="header-menu-button-mobile header-menu-button-filled-mobile">APPLY
    </a>
  </div>
</div>
