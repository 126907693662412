import {Component} from '@angular/core';
import {JobOrderService} from '../../shared/services/job-order.service';
import {JobsListComponent} from './jobs-list/jobs-list.component';
import {NgOptimizedImage} from '@angular/common';
import {Router} from '@angular/router';
import {JobOrder} from '../../shared/model/types';
import {Observable} from 'rxjs';
import {PortalUserService} from '../../portal/shared/services/portal-user.service';

@Component({
  selector: 'app-jobs',
  standalone: true,
  imports: [
    JobsListComponent,
    NgOptimizedImage
  ],
  templateUrl: './jobs.component.html',
  styleUrl: './jobs.component.css'
})
export class JobsComponent {
  jobOrders$: Observable<JobOrder[]>;

  constructor(
    private portalUserService: PortalUserService,
    private jobOrderService: JobOrderService
  ) {
    const candidateId = this.portalUserService.activeUser()?.candidateId;
    this.jobOrders$ =
      this.jobOrderService.getJobOrders(
        candidateId,
        '',
        '',
        'WeeklyPay',
        'Desc',
        true
      );
  }

}
