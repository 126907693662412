import {Component} from '@angular/core';
import {HeaderComponent} from '../../shared/components/header/header.component';
import {BrowseJobsComponent} from "../../home/hero/browse-jobs/browse-jobs.component";
import {ActivatedRoute} from "@angular/router";
import {FooterComponent} from "../../shared/components/footer/footer/footer.component";
import {NgOptimizedImage} from "@angular/common";
import {PledgeComponent} from "./pledge/pledge.component";
import {JoinNightingaleComponent} from "./join-nightingale/join-nightingale.component";
import {ExpectMoreComponent} from "./expect-more/expect-more.component";
import {TravelMissionComponent} from "./travel-mission/travel-mission.component";
import {SeoUtilsService} from '../../shared/services/seo-utils.service';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [
    HeaderComponent,
    BrowseJobsComponent,
    FooterComponent,
    NgOptimizedImage,
    PledgeComponent,
    JoinNightingaleComponent,
    ExpectMoreComponent,
    TravelMissionComponent
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.css'
})
export class AboutComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private seoUtilsService: SeoUtilsService,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'About Nightingale Travel Nursing',
      'We\'re your partner in your travel nursing career. Connecting you with opportunities, benefits and housing.',
      'about-nightingale'
    );
  }
}
