[
  {
    "comment": "Not only do you get to travel and see new places, but you earn more doing it. A lot more. Put it this way. You earn enough that you don’t have to work all year round.",
    "imagePath": "./assets/img/nurse1.png",
    "name": "Jennifer A.",
    "starCount": 5
  },
  {
    "comment": "I know it sounds funny, but I didn’t go into Travel Nursing to travel. I just wanted to build my career.",
    "imagePath": "./assets/img/nurse2.png",
    "name": "Nicole K.",
    "starCount": 5
  },
  {
    "comment": "I would NEVER Travel with anyone but Nightingale.",
    "imagePath": "./assets/img/nurse3.png",
    "name": "Anderson J.",
    "starCount": 5
  },
  {
    "comment": "They REALLY are there to help you be the best nurse you can be. And who doesn’t want to be the best? Plus you get the best benefits and pay rates in the industry. It’s kind of a no-brainer.",
    "imagePath": "./assets/img/nurse4.png",
    "name": "Alicia J.",
    "starCount": 5
  },
  {
    "comment": "They REALLY are there to help you be the best nurse you can be. And who doesn’t want to be the best? Plus you get the best benefits and pay rates in the industry. It’s kind of a no-brainer.",
    "imagePath": "./assets/img/nurse4.png",
    "name": "Alicia J.",
    "starCount": 5
  },
  {
    "comment": "I know it sounds funny, but I didn’t go into Travel Nursing to travel. I just wanted to build my career.",
    "imagePath": "./assets/img/nurse2.png",
    "name": "Nicole K.",
    "starCount": 5
  }
]
