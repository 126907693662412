<div class="apply-today-section">

  <div class="apply-today-content">
    <h3>
      <span class="apply-today-title">  APPLY</span>
      <span class=" apply-today-italic"> TODAY </span>
    </h3>
    <div class="while-line">
    </div>

    <div class="apply-today-description paragraf-big">
      <div>Top pay, benefits and unprecedented adventure await. Discover the Travel</div>
      <div> Nursing lifestyle with Nightingale.</div>
    </div>

    <a href="/apply" class="apply-today-btn-outline">
      <span class="apply-today-btn button-text">APPLY NOW</span>
    </a>

  </div>
</div>
