import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatCard, MatCardActions, MatCardTitle} from '@angular/material/card';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatDivider} from '@angular/material/divider';
import {MatError, MatFormField, MatLabel, MatPrefix, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatSelect} from '@angular/material/select';
import {CatalogsService} from '../../../shared/services/catalogs.service';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {ProfileService} from '../services/profile.service';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {PortalUserSourceType, ProfileEmail, ProfilePersonalInfo, ProfilePhone} from '../../shared/types';
import {NgTemplateOutlet} from '@angular/common';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {CdkTextareaAutosize} from "@angular/cdk/text-field";

@Component({
  selector: 'app-portal-user-personal-info',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatCard,
    MatCardActions,
    MatCheckbox,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDivider,
    MatError,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatProgressSpinner,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    MatCardTitle,
    MatPrefix,
    NgTemplateOutlet,
    MatTab,
    MatTabGroup,
    CdkTextareaAutosize
  ],
  templateUrl: './portal-user-personal-info.component.html',
  styleUrl: './portal-user-personal-info.component.css'
})
export class PortalUserPersonalInfoComponent implements OnInit {
  states = this.catalogService.getStates();
  portalUserSourceTypeOptions = PortalUserSourceType.portalUserSourceTypeOptions;
  user = this.userService.activeUser()?.personalInfo;
  smList: { id: number; name: string }[] = [];


  disablePage = false;

  emailsFormArray = this.formBuilder.array([]) as FormArray;
  phonesFormArray = this.formBuilder.array([]) as FormArray;
  profileForm = this.formBuilder.group({
    firstName: [this.user?.firstName, [Validators.required]],
    middleName: [this.user?.middleName],
    lastName: [this.user?.lastName, [Validators.required]],
    dateOfBirth: [this.user?.dateOfBirth],
    gender: [this.user?.gender],
    ssn: [this.user?.ssn],
    emails: this.emailsFormArray,
    phones: this.phonesFormArray,
    address1: [this.user?.address1],
    address2: [this.user?.address2],
    city: [this.user?.city],
    state: [this.user?.state],
    zip: [this.user?.zip],
    emergencyContactName: [this.user?.emergencyContactName],
    emergencyContactPhone: [this.user?.emergencyContactPhone],
    emergencyContactRelationship: [this.user?.emergencyContactRelationship],
    emergencyContactAddress: [this.user?.emergencyContactAddress],
    hasLatexSensitivity: [this.user?.hasLatexSensitivity],
    portalUserSource: [this.user?.portalUserSource],
    referredByName: [this.user?.referredByName],
    referredByPhone: [this.user?.referredByPhone],
    referredByEmail: [this.user?.referredByEmail],
    portalUserSourceDescription: [this.user?.portalUserSourceDescription],
    referredToSmId: [this.user?.referredToSmId],
  });

  constructor(
    private catalogService: CatalogsService,
    private userService: PortalUserService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private multiLineSnackBar: MultiLineSnackBarService,
  ) {
    this.user?.emails?.forEach(email => {
      this.addEmail(email);
    });
    this.user?.phones?.forEach(phone => {
      this.addPhone(phone);
    });
  }

  ngOnInit() {
    this.loadSmList();
    this.loadProfile();
  }

  loadSmList(): void {
    // Subscribe to the Observable and assign the response to smList
    this.profileService.getSmList().subscribe({
      next: (response) => {
        this.smList = response;
      },
      error: (error) => {
        console.error('Failed to load SM List', error);
        // Handle error scenario, maybe set smList to an empty array or show an error message
      }
    });
  }

  loadProfile(): void {

    this.portalUserSourceSelectionChange(this.profileForm.get('portalUserSource')?.value);
  }

  saveProfile(): void {
    // validate fields
    this.profileForm.markAllAsTouched();
    if (this.profileForm.invalid) {
      this.multiLineSnackBar.displayMessages(['Please fix all errors before saving.']);
      return;
    }

    this.disablePage = true;
    this.profileForm.disable();
    const personalInfo = this.profileForm.value as ProfilePersonalInfo;
    this.profileService.savePersonalInfo(personalInfo)
      .subscribe(result => {
        if (result.isSuccess) {
          this.userService.setPersonalInfo(personalInfo);

          this.multiLineSnackBar.displayMessages(['Profile update successful.']);
        } else {
          this.multiLineSnackBar.displayMessages(result.errorList);
        }
        this.disablePage = false;
        this.profileForm.enable();
        this.loadProfile();
      });
  }

  addEmail(email?: ProfileEmail): void {
    const emailForm = this.formBuilder.group({
      id: [email?.id],
      email: [email?.email, [Validators.required, Validators.email]],
      isPrimary: [email?.isPrimary ?? false]
    });
    this.emailsFormArray.insert(0, emailForm, {emitEvent: false});
  }

  removeEmail(index: number): void {
    this.emailsFormArray.removeAt(index);
  }

  addPhone(phone?: ProfilePhone): void {
    const phoneForm = this.formBuilder.group({
      id: [phone?.id],
      phoneType: [phone?.phoneType ?? 'cell'],
      countryCode: [phone?.countryCode ?? '1'],
      phoneNumber: [phone?.phoneNumber, [Validators.required]],
      isPrimary: [phone?.isPrimary ?? false]
    });
    this.phonesFormArray.insert(0, phoneForm, {emitEvent: false});
  }

  removePhone(index: number): void {
    this.phonesFormArray.removeAt(index);
  }

  addActive(selectedIndex: number | null) {
    switch (selectedIndex) {
      case 0:
        this.addEmail();
        break;
      case 1:
        this.addPhone();
        break;
    }
  }


  portalUserSourceSelectionChange(value: any): void {
    const isReferral = value === 'referral';
    if (isReferral) {
      this.profileForm.controls.referredByName.setValidators(Validators.required);
      this.profileForm.controls.portalUserSourceDescription.reset();
      this.profileForm.controls.portalUserSourceDescription.clearValidators();
    } else {
      this.profileForm.controls.referredByName.reset();
      this.profileForm.controls.referredByName.clearValidators();
      this.profileForm.controls.referredByPhone.reset();
      this.profileForm.controls.referredByEmail.reset();
      if (value === 'other') {
        this.profileForm.controls.portalUserSourceDescription.setValidators(Validators.required);
      } else {
        this.profileForm.controls.portalUserSourceDescription.reset();
        this.profileForm.controls.portalUserSourceDescription.clearValidators();
      }
    }
    this.profileForm.controls.referredByName.updateValueAndValidity();
    this.profileForm.controls.portalUserSourceDescription.updateValueAndValidity();
    this.profileForm.updateValueAndValidity();
  }
}
