<mat-card class="assignment-container">
  <div class="assignment-row">
    <div class="assignment-item">Facility: {{ assignment.facility }}</div>
    <div class="assignment-item">City/State: {{ assignment.city }}/{{ assignment.state }}</div>
  </div>

  <div class="assignment-row">

    <div class="assignment-item">Specialty: {{ assignment.specialty }}</div>
    <div class="assignment-item">Start-End: {{ assignment.startDate | date : 'MM/dd/yyyy' }}
      - {{ assignment.actualEndDate | date : 'MM/dd/yyyy' }}
    </div>

  </div>

  @if (assignment.status == AssignmentStatus.Working || assignment.status == AssignmentStatus.EndedNormally || assignment.status == AssignmentStatus.EarlyTermination) {
    <div class="assignment-row">
      <div class="assignment-item">Worked Hours: {{ assignment.workedHours }}</div>
    </div>

    @if (assignment.deficitHours < 0) {
      <div class="assignment-row">
        <div class="assignment-item">Deficit Hours: {{ assignment.deficitHours }}
        </div>
      </div>
    }
  }

  @if (assignment.status == AssignmentStatus.PendingNew || assignment.status == AssignmentStatus.Locked || assignment.status == AssignmentStatus.PendingExtension) {
    <div class="assignment-row " style="justify-content: space-between; align-content: space-between">
      <div class="portal-home-section-title">My Credentialer</div>
      <div style="align-self: center;">
        <a class="mat-primary" style="text-decoration: none" href="mailto:credentialscompliance@nightingalenurses.net">credentialscompliance&#64;nightingalenurses.net</a>
      </div>
    </div>

    @if (assignment.credentialerFirstName) {
      <div class="assignment-row">

        <div style="display: flex; flex-direction: column;">
          <div class="portal-home-item-title">
            Name: {{ assignment.credentialerFirstName }} {{ assignment.credentialerLastName }}
          </div>

          <div class="portal-home-item-title">Phone:
            <a class="mat-primary" style="text-decoration: none"
               href="tel:{{assignment.credentialerPhone | phone}}"> {{ assignment.credentialerPhone | phone }}</a>
          </div>

          <div class="portal-home-item-title">
            Email:<a class="mat-primary" style="text-decoration: none"
                     href="mailto:{{assignment.credentialerEmail}}"> {{ assignment.credentialerEmail }}</a>
          </div>
        </div>
      </div>
    }
  }

  @if (assignment.status == AssignmentStatus.Working || assignment.status == AssignmentStatus.EndedNormally || assignment.status == AssignmentStatus.EarlyTermination) {
    <app-timesheets [assignment]="assignment"
                    [assignmentPayrollId]="assignment.assignmentPayrollId"></app-timesheets>
  }

</mat-card>
