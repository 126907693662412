<div style="width: 100%">
  <div class="refer-rn-now-container flex-column">
    <div  class="refer-title" >
      <h3>
        <span class="refer-rn-now-header-italic">REFER</span>
        <span class="refer-rn-now-header">&nbsp;AN RN NOW</span>
      </h3>
    </div>
  <div class="desktop-tablet">
      <div class="refer-now-outliner">
        <div class="btn-outline" (click)="navigateToPortal()">
          <div class="btn button-text"> START EARNING
          </div>
        </div>
      </div>
  </div>


    <div class="mobile">
      <div class="btn-outline" (click)="navigateToPortal()">
        <div class="btn button-text"> START EARNING
        </div>
      </div>
    </div>
  </div>
</div>
