<div class="about-us-container">

  <div class="about-us-header-container">

    <app-header></app-header>

    <div class="about-us-header flex-column">
      <div class="header-contaianer">

        <h2>
          <span class="manrope-extralight"> About&nbsp;</span>
          <span class="wulkandisplay-regularitalic">Us&nbsp;</span>
        </h2>

        <div class="line-green">
        </div>
      </div>
    </div>
  </div>

  <img ngSrc="assets/img/Safety_Platform_nurse_running.jpg" alt="about-us-background" class="about-us-background"
       width="1" height="1">

  <div class="helping-nurses">

    <div class="helping-nurses-header">
      <h3>
        <span class="manrope-regular"> OVER 20 YEARS OF </span>
        <span class="wulkandisplay-semibolditalic" style="color: #1BA9B5;">HELPING NURSES&nbsp;</span>
        <span class="manrope-regular">GET AHEAD</span>
      </h3>

      <div class="line-green">
      </div>

    </div>

    <div class="helping-nurses-content">

      <h4 class="helping-nurses-content-text1">
        Nightingale is a family-owned travel nursing
        business founded upon the simple idea that integrity and
        compassion
        are always rewarded. Like our namesake, Florence Nightingale — we are driven by a passion for helping others.
      </h4>

      <div>
        <p class="helping-nurses-content-text2">
          Our corporate mission statement consists of just two words: “Do Good”. That two-word phrase has been our
          guiding principle ever since we opened in 2003.
        </p>

        <p class="helping-nurses-content-text2" style="margin-top: 30px;">
          We believe that the best way we can do good in the world is by hiring the best travel nurses we can and by
          supporting them to the best of our abilities. By delivering the very best RNs, we have access to the
          country’s
          best hospitals in the most appealing locales. It’s a win-win scenario for travel nurses and hospitals alike.
        </p>
      </div>


      <a class="apply-now-outline" style="text-decoration: none;" href="/apply">
        <span class="apply-now-btn button-text">APPLY NOW</span>
      </a>

    </div>

  </div>

  <app-expect-more></app-expect-more>


  <div class="travel-mission-background">

    <app-travel-mission></app-travel-mission>

    <div class="desktop">
      <app-pledge></app-pledge>
    </div>

  </div>

  <div class="tablet-mobile">
    <app-pledge></app-pledge>
  </div>

  <app-join-nightingale class="overlap"></app-join-nightingale>

  <app-footer style="width: 100%"></app-footer>

</div>
