<div class="main-individual-blog-container" *ngIf="blog$ | async as blog">
  <app-ptb-layout-individual-blog
    [title]=blog.title
    [tags]="blog.tags"></app-ptb-layout-individual-blog>
  <app-body-individual-blog
    [blog]="blog"></app-body-individual-blog>
  <app-related-post
    [blog]=blog></app-related-post>

  <app-footer></app-footer>
</div>


