import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of} from 'rxjs';
import {createFailedResult, Result} from '../../../../shared/model/result';

@Injectable({
  providedIn: 'root'
})
export class PortalVerificationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  verifyAccount(meta: string): Observable<Result> {
    const encodedMeta = encodeURIComponent(meta);
    return this.httpClient.post<Result>(`verify-account?meta=${encodedMeta}`, {})
      .pipe(
        catchError(() => of(createFailedResult(['An error occurred while checking account status, please try again.'])))
      );
  }

  checkAccountVerified(): Observable<Result<boolean>> {
    return this.httpClient.post<Result<boolean>>('check-account-verified', {})
      .pipe(
        catchError(() => of(createFailedResult<boolean>(['An error occurred while checking account status, please try again.'])))
      );
  }

  resendVerificationEmail(): Observable<Result> {
    return this.httpClient.post<Result>('resend-verification-email', {})
      .pipe(
        catchError(() => of(createFailedResult(['An error occurred while resending the email, please try again.'])))
      );
  }
}
