import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-faq-item-7',
  standalone: true,
    imports: [
        NgOptimizedImage
    ],
  templateUrl: './faq-item-7.component.html',
  styleUrl: './faq-item-7.component.css'
})
export class FaqItem7Component {
  expandAnswer:boolean=false;
  toggleAnswer() {
    this.expandAnswer = !this.expandAnswer;
  }
}
