import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Observable, tap} from 'rxjs';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {FileViewData} from './types/types';
import {FileMimeTypes} from './types/mime-types';

@Component({
  selector: 'app-file-viewer-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatProgressSpinner,
    AsyncPipe,
    NgOptimizedImage
  ],
  templateUrl: './file-viewer-dialog.component.html',
  styleUrl: './file-viewer-dialog.component.css'
})
export class FileViewerDialogComponent {
  isLoading = true;

  fileName = '';
  contentType: string;
  iframeSource?: SafeResourceUrl = undefined;
  base64Url: string;
  fileData: Blob;

  fileViewer$: Observable<FileViewData> = this.data.fileData$.pipe(
    tap((file) => {
      const fileExt = file.fileExtension.startsWith('.') ? file.fileExtension : `.${file.fileExtension}`;
      this.contentType = FileMimeTypes.mimetypes.get(fileExt) ?? 'application/octet-stream';
      this.fileName = file.fileName;
      const fileBytes = this.base64ToArrayBuffer(file.base64FileContents);
      this.fileData = new Blob([fileBytes], {type: this.contentType});
      if (this.contentType.startsWith('image/')) {
        this.base64Url = `data:${file.fileExtension};base64,${file.base64FileContents}`;
      } else {
        const fileUrl = URL.createObjectURL(this.fileData);
        this.iframeSource = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
        setTimeout(() => {
          window.URL.revokeObjectURL(fileUrl);
        }, 1500);
      }
      this.isLoading = false;
    }),
  )

  constructor(private dialog: MatDialogRef<FileViewerDialogComponent>,
              private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: {
                fileData$: Observable<FileViewData>
              }) {
  }

  base64ToArrayBuffer(base64: string): any {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const resultBytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      resultBytes[i] = binaryString.charCodeAt(i);
    }
    return resultBytes.buffer;
  }

  moveToNewTab(): void {
    const fileUrl = URL.createObjectURL(this.fileData);
    const newTab = window.open(fileUrl);
    newTab!.onload = () => {
      this.dialog.close();
    };
  }
}
