@if (groupedRecivedFiles.length > 0) {
  <div class="row" style="justify-content: space-between;align-content: center;align-items: center">
    <div class="portal-home-section-title" style="font-size: 20px">Timesheets</div>
  </div>

  <div class="timesheet-container">
    @for (group of groupedRecivedFiles; track group.weekEnding) {

      <div (drop)="dropFile($event, group.weekEnding)"
           (dragover)="$event.preventDefault()"
           (dragenter)="onDragEnter($event, group.weekEnding)"
           (dragleave)="onDragLeave($event)" class="timesheet-card"
           [class.highlighted]="isDraggingFile && currentDragGroup === group.weekEnding">

        <div class="row week-ending">

          <span class="weekly-title">Week Ending - {{ group.weekEnding | date: 'MM/dd/yyyy' }}</span>
          <input type="file" #fileInput style="display: none;" (input)="uploadFile($event, group.weekEnding)">

          <div class="file-attachment" style="margin-left: 20px">
            <div style="cursor: pointer;display: flex" (click)="fileInput.click()" matTooltip="Upload Timesheet">
              <mat-icon style="font-size: 18px" matTooltip="Upload Timesheet">attach_file</mat-icon>
            </div>
          </div>

        </div>

        @for (file of group.files; track file.id) {
          <div class="row">
            <div class="file">
              @if (file.id) {
                <a href="" (click)="viewFile(file.id)"> {{ file.fileName }}</a>
              } @else {
                <a style="color: red"> {{ file.fileName }}</a>
              }
            </div>
          </div>
        }

      </div>
    }

  </div>
}
