import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {JobOrder} from '../../../shared/model/types';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {JobComponent} from '../../../shared/components/job/job.component';
import {CarouselScroll, CarouselType} from '../../shared/model/types';

@Component({
  selector: 'app-jobs-list',
  standalone: true,
  imports: [
    AsyncPipe,
    JobComponent,
    NgOptimizedImage
  ],
  templateUrl: './jobs-list.component.html',
  styleUrl: './jobs-list.component.css'
})
export class JobsListComponent implements OnInit, OnDestroy {
  @Input() jobOrders$: Observable<JobOrder[]>;

  subscriptions: Subscription[] = [];
  jobOrders: JobOrder[];
  startIndex = 0;
  visibleOrders: JobOrder[];

  ngOnInit(): void {
    this.subscriptions.push(
      this.jobOrders$.subscribe(orders => {
        this.jobOrders = orders;
        this.visibleOrders = this.jobOrders.slice(this.startIndex, this.startIndex + 6);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  scroll(carouselScroll: CarouselScroll): void {
    if (carouselScroll === CarouselScroll.Left) {
      this.scrollLeft();
    } else {
      this.scrollRight();
    }
  }

  scrollLeft(): void {
    // Move the visible orders to the left
    if (this.startIndex > 0) {
      this.startIndex--;
      const y = [this.visibleOrders[5]];
      let x= this.visibleOrders.slice(0, 5);
      this.visibleOrders = y.concat(x);


    }
  }

  scrollRight(): void {
    // Move the visible orders to the right
    if (this.startIndex + 3) {
      this.startIndex++;
      const x = this.visibleOrders[0];
      this.visibleOrders = this.visibleOrders.slice(1, 6);
      this.visibleOrders.push(x);
    }
  }

  protected readonly CarouselType = CarouselType;
}
