<div class="centered-spaced flex-row">
  <div style="margin-right: 20px;">
    @for (line of data.messages; track line) {
      <span>{{line}}</span><br>
    }
  </div>

  <div style="flex: 1 1 auto;"></div>

  <div style="max-width: 50px; flex: 1 1 auto;">
    @if(!action) {
      <button mat-button color="accent" (click)="close()">
        Close
      </button>
    } @else {
      <button mat-button color="accent" (click)="actionClicked()">
        {{action}}
      </button>
    }
  </div>
</div>

