<div class="license-table" *ngIf="licencesToShow$ | async as licences">

  <div *ngIf="showError" class="failure"><p>No records found</p></div>

  <app-licensure-table-header
    (searchClick)="searchClicked($event)"></app-licensure-table-header>

  @for (item of licences; track item; let index = $index) {
    <div class="desktop-tablet">
      <app-licensure-table-item
        [state]="item.state"
        [phone]="item.phone"
        [walkThrough]="item.walkThrough"
        [compact]="item.compact"
        [nursys]="item.nursys"
        [info]="item.info">
      </app-licensure-table-item>
    </div>
    <div class="mobile">
      <app-licensure-table-item-mobile
        [state]="item.state"
        [phone]="item.phone"
        [walkThrough]="item.walkThrough"
        [compact]="item.compact"
        [nursys]="item.nursys"
        [info]="item.info">
      </app-licensure-table-item-mobile>
    </div>

  }
  <div class="desktop-tablet">
    <div class="table-last-row">
      <app-table-footer
        [itemsPerPage]=itemsPerPage
        [totalItems]=resultSize
        [firstItem]="firstItem"
        [reload]="reload"
        [windowSize]="4"
        (requestedPageEvent)="onFooterClick($event)">

      </app-table-footer>
    </div>
  </div>
  <div class="mobile">
    <div class="table-last-row">
      <app-table-footer
        [itemsPerPage]=itemsPerPage
        [totalItems]=resultSize
        [firstItem]="firstItem"
        [reload]="reload"
        [windowSize]="2"
        (requestedPageEvent)="onFooterClick($event)">

      </app-table-footer>
    </div>
  </div>
</div>
