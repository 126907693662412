import {Component, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {NgClass, NgOptimizedImage} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {MobileMenuDialogComponent} from './mobile-header-dialog/mobile-menu-dialog.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgClass
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class HeaderComponent {
  @ViewChildren('menu') dropdownMenuList!: QueryList<any>;

  isBenefitsDropdownOpen = false;
  isResourcesDropdownOpen = false;
  isAboutDropdownOpen = false;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  onClick(event: MouseEvent) {
    const closeMenu =
      this.dropdownMenuList.toArray().some(menu => menu.nativeElement.contains(event.target));

    if (!closeMenu) {
      this.isBenefitsDropdownOpen = false;
      this.isResourcesDropdownOpen = false;
      this.isAboutDropdownOpen = false;
    }
  }

  toggleBenefitsDropdown(): void {
    this.isBenefitsDropdownOpen = !this.isBenefitsDropdownOpen;
    this.isResourcesDropdownOpen = false;
    this.isAboutDropdownOpen = false;
  }

  toggleResourcesDropdown(): void {
    this.isBenefitsDropdownOpen = false;
    this.isResourcesDropdownOpen = !this.isResourcesDropdownOpen;
    this.isAboutDropdownOpen = false;
  }

  toggleAboutDropdown(): void {
    this.isBenefitsDropdownOpen = false;
    this.isResourcesDropdownOpen = false;
    this.isAboutDropdownOpen = !this.isAboutDropdownOpen;
  }

  mobileMenuToggle(device: 'tablet' | 'phone') {
    this.dialog.open(MobileMenuDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '100%',
      height: '100%',
      data: device,
      autoFocus: false,
    });
  }

}
