import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-legends',
  standalone: true,
  imports: [],
  templateUrl: './legends.component.html',
  styleUrl: './legends.component.css'
})
export class LegendsComponent {


  constructor() {
  }
}
