import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'shorten',
  standalone: true
})
export class ShortenPipe implements PipeTransform {
  transform(value: any, length: number): any {
    if (value) {

      const stringValue = value as string;

      if (length && stringValue.length > length + 3) {
        return (stringValue).substring(0, length - 2) + '...';
      } else {
        return stringValue;
      }
    } else {
      return '';
    }
  }

}
