import { Component } from '@angular/core';
import {HeaderComponent} from "../../../shared/components/header/header.component";

@Component({
  selector: 'app-referral-header',
  standalone: true,
  imports: [
    HeaderComponent
  ],
  templateUrl: './referral-header.component.html',
  styleUrl: './referral-header.component.css'
})
export class ReferralHeaderComponent {

}
