import {Component} from '@angular/core';
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {ConnectOnlineComponent} from "../connect-online/connect-online.component";


@Component({
  selector: 'app-contact-header',
  standalone: true,
  imports: [
    HeaderComponent,
    ConnectOnlineComponent
  ],
  templateUrl: './contact-header.component.html',
  styleUrl: './contact-header.component.css'
})
export class ContactHeaderComponent {

}
