<div class="referral-container">

  <app-referral-header style="width: 100%"></app-referral-header>


  <div class="referral-benefit">

    <app-referral-benefit></app-referral-benefit>

    <div class="referral-scope-container">

      <div class="referral-point">
        <div class="referral-scope"> Open to all current or former Nightingale clinical employees!</div>
        <div class="referral-flow"></div>
      </div>

      <div class="referral-point">
        <div class="referral-scope"> Refer your qualified friends and associates to Nightingale and earn a substantial
          bonus!
        </div>
        <div class="referral-flow"></div>
      </div>

      <div class="referral-point">
        <div class="referral-scope"> To be qualified, your referral must not be an existing Nightingale clinician and
          execute an assignment agreement within six months of being referred.
        </div>
        <div class="referral-flow"></div>
      </div>

      <div class="referral-point">
        <div class="referral-completion-bonus flex-column">
          <div class="referral-completion-bonus-text">

            If your referral fully completes at least one thirteen-week assignment, you earn $1,500
          </div>
          <div class="or button-text">
            OR
          </div>
          <div class="referral-completion-bonus-text">
            If your referral fully completes at least one eight-week assignment, you earn $500 It’s Automatic!
          </div>
        </div>
      </div>

      <div class="referral-point">
        <div class="referral-scope">To make a referral click the Start Earning button below and log in.
        </div>
        <div class="referral-flow"></div>
      </div>

      <div class="referral-point">
        <div class="referral-scope">Fill out the Refer a Friend form and get your friend started!
        </div>
        <div class="referral-flow"></div>
      </div>

      <app-refer-rn-now style="width: 100%"></app-refer-rn-now>

    </div>

  </div>

  <app-referral-review></app-referral-review>

  <app-footer></app-footer>


</div>
