<div class="pledge-container"  >

  <div class="pledge-container-content" >
    <h3  class="pledge-container-header" >
      <span class="manrope-extralight"> THE FLORENCE </span>
      <span class="wulkandisplay-mediumitalic">NIGHTINGALE</span>
      <span class="manrope-extralight"> PLEDGE </span>
    </h3>
    <div class="line-white">
    </div>
  </div>

  <div>
    <p  class="pledge-container-content-text">
      I solemnly pledge myself before God and in the presence of this assembly, to pass my life in purity and to
      practice my profession faithfully. I will abstain from whatever is deleterious and mischievous, and will not
      take or knowingly administer any harmful drug. I will do all in my power to maintain and elevate the standard
      of my profession and will hold in confidence all personal matters committed to my keeping and all family
      affairs coming to my knowledge in the practice of my calling. With loyalty will I endeavor to aid the
      physician in his work, and devote myself to the welfare of those committed to my care.
    </p>
  </div>

</div>
