<div class="flex-column">
  <mat-card class="dialog-card">

    <form [formGroup]="profileForm" style="margin: 10px; display: flex; flex-direction: column; gap: 10px;">

      <div class="form-row">
        <mat-form-field style="flex: 1;">
          <mat-label>Primary Specialty</mat-label>
          <mat-select formControlName="specialty">
            @for (specialty of specialties; track specialty) {
              <mat-option [value]="specialty">{{ specialty }}</mat-option>
            }
          </mat-select>
          @if (profileForm.controls.specialty.hasError('required')) {
            <mat-error>Specialty is required</mat-error>
          }
        </mat-form-field>
        <mat-form-field style="flex: 2;">
          <mat-label>Additional Specialties</mat-label>
          <mat-select multiple formControlName="secondarySpecialties">
            @for (specialty of specialties; track specialty) {
              <mat-option [value]="specialty">{{ specialty }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-checkbox color="primary" formControlName="hasCompactLicense">Check here if you have a compact license
        </mat-checkbox>
      </div>

      <div class="form-row">


        <div style="margin-top: 10px; flex: 1; width:100%">
          <div class="form-array-title">
            <div>Certifications</div>
            <div class="flex-expand"></div>
            <button class="mat-icon-button-sm" [disabled]="disablePage" mat-icon-button (click)="addCertification()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ng-container *ngTemplateOutlet="certificatesList"></ng-container>
        </div>
      </div>


      <ng-template #certificatesList>
        <div formArrayName="certifications" class="form-array-margin flex-column">
          @for (certification of certificationsFormArray.controls; track certification.value) {
            <div [formGroupName]="$index" class="form-array">
              <button [disabled]="disablePage" mat-icon-button (click)="removeCertification($index)"
                      class="remove-button">
                <mat-icon>close</mat-icon>
              </button>
              <mat-form-field>
                <mat-label>Certification</mat-label>
                <mat-select formControlName="name">
                  @for (certification of certifications; track certification) {
                    <mat-option [value]="certification">{{ certification }}</mat-option>
                  }
                </mat-select>
                @if (certificationsFormArray.at($index).get('name')?.hasError('required')) {
                  <mat-error>Certification is required</mat-error>
                }
              </mat-form-field>
              <mat-form-field>
                <mat-label>Expiration Date</mat-label>
                <input matInput formControlName="expirationDate" [matDatepicker]="certificationExpirationDatePicker">
                <mat-datepicker-toggle matSuffix [for]="certificationExpirationDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #certificationExpirationDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          }
        </div>
      </ng-template>
    </form>
  </mat-card>
</div>
