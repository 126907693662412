import {Component} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {FooterComponent} from "../../shared/components/footer/footer/footer.component";

import {HousingCardComponent} from "./housing-card/housing-card.component";
import {HousingHeaderComponent} from "./housing-header/housing-header.component";
import {HousingContent} from "../../shared/model/types";
import {ActivatedRoute} from '@angular/router';
import {SeoUtilsService} from '../../shared/services/seo-utils.service';


@Component({
  selector: 'app-housing-information',
  standalone: true,
  imports: [
    NgOptimizedImage,
    HousingCardComponent,
    FooterComponent,
    HousingHeaderComponent
  ],
  templateUrl: './housing-information.component.html',
  styleUrl: './housing-information.component.css'
})
export class HousingInformationComponent {
  housingContentCards: HousingContent[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private seoUtilsService: SeoUtilsService,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'Housing For Travel Nurses - Nightingale',
      'We can find and provide housing for travel nurses, or assist you with your stipend to find the RIGHT accommodations for you and even your pets.',
      'travel-nurse-housing'
    );

    this.housingContentCards.push({
      ImagePosition: "left",
      ImageFileName: 'housing-image-01.webp',
      IconFileName: 'icon_housing-01.svg',
      Title: 'Choose Nightingale housing and we’ll find you the perfect place',
      Description: 'If you’d rather have one less thing to worry about, this is the option for you. You’ll get a nice place to' +
        ' live without having to lift a finger yourself. You may even live near other traveling nurses – and' +
        ' everyone knows that travel nurses are the best company!'
    });

    this.housingContentCards.push({
      ImagePosition: "right",
      ImageFileName: 'housing-image-02.webp',
      IconFileName: 'icon_housing-02.svg',
      Title: 'Take the housing stipend instead and do it yourself',
      Description: 'Some travel nurses are experts at finding housing online. Some prefer living in an RV so they can bring their housing with them wherever they go. ' +
        'Others may want to choose a location that is between work and somewhere else they want to be, like the beach, ski slopes or the big city.'
    });

    this.housingContentCards.push({
      ImagePosition: "left",
      ImageFileName: 'housing-image-03.webp',
      IconFileName: 'icon_housing-03.svg',
      Title: 'Traveling with a pet? No problem!',
      Description: "Just let your recruiter know so our housing team can ensure you and your furry friends find the perfect spot."
    });
  }

}
