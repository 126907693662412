[
  {
    "label": "Earning And Using Sick Leave for Arizona Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveforArizonaEmployees.pdf"
  },
  {
    "label": "Earning And Using Sick Leave for Arizona Employees (Spanish)",
    "value": "assets/files/policies/EarningAndUsingSickLeaveforArizonaEmployees_Spanish.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for California Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveforCaliforniaEmployees.pdf"
  },
  {
    "label": "California Senate Bill 184 regarding New Health Care Worker Retention Payment Program",
    "value": "https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=202120220SB184"
  },
  {
    "label": "Earning and Using Sick Leave for Colorado Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveForColoradoEmployees.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for Illinois Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveForIllinoisEmployees.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for Maryland Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveforMarylandEmployees.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for Massachusetts Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveforMassachusettsEmployees.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for Minnesota Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveForMinnesotaEmployees.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for New Jersey Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveforNewJerseyEmployees.pdf"
  },
  {
    "label": "New Jersey Family Leave Act (NJFLA) 12.31.22",
    "value": "assets/files/policies/NewJerseyFamilyLeaveAct(NJFLA)12.31.22.pdf"
  },
  {
    "label": "New Jersey Know Your Rights 12.31.22",
    "value": "assets/files/policies/NewJerseyKnowYourRights12.31.22.pdf"
  },
  {
    "label": "New Jersey Law Against Discrimination (NJLAD) 12.31.22",
    "value": "assets/files/policies/NewJerseyLawAgainstDiscrimination(NJLAD)12.31.22.pdf"
  },
  {
    "label": "New Jersey Minimum Wage Increase effective 1.1.23",
    "value": "assets/files/policies/NewJerseyMinimumWageIncreaseeffective1.1.23.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for New Mexico Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveforNewMexicoEmployees.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for New York Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveforNewYorkEmployees.pdf"
  },
  {
    "label": "New York City Notice of Employee Rights for Safe and Sick Leave effective 1.1.21",
    "value": "assets/files/policies/NewYorkCityNoticeofEmployeeRightsSickLeave1.1.21.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for Oregon Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveForOregonEmployees.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for Washington Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveForWashingtonEmployees.pdf"
  },
  {
    "label": "Earning and Using Sick Leave for Seattle, WA Employees",
    "value": "assets/files/policies/EarningAndUsingSickLeaveForSeattleWAEmployees.pdf"
  }
]
