import {Component, QueryList, ViewChildren} from '@angular/core';
import {MatCard} from "@angular/material/card";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgTemplateOutlet} from "@angular/common";
import {FormArray, FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {CatalogsService} from "../../../../../shared/services/catalogs.service";
import {PortalUserService} from "../../../../shared/services/portal-user.service";
import {ProfileService} from "../../../../portal-user-profile/services/profile.service";
import {
  MultiLineSnackBarService
} from "../../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {Education, WorkHistory} from "../../../../shared/types";
import {Observable, of} from "rxjs";
import {MatTooltip} from "@angular/material/tooltip";
import {CustomDateMonthComponent} from "../../../../../shared/components/custom-date-month/custom-date-month.component";

@Component({
  selector: 'app-work-history-editor',
  standalone: true,
  imports: [
    MatCard,
    MatCheckbox,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatError,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    NgTemplateOutlet,
    ReactiveFormsModule,
    MatTooltip,
    CustomDateMonthComponent
  ],
  templateUrl: './work-history-editor.component.html',
  styleUrl: './work-history-editor.component.css'
})
export class WorkHistoryEditorComponent {
  states = this.catalogService.getStates();
  @ViewChildren(CustomDateMonthComponent) customDateComponents: QueryList<CustomDateMonthComponent>;

  workHistories = this.profileService.getResumeData()?.workHistory?.sort((a, b) => {
    if (a.toDate === null) return -1; // a comes first if its date is null
    if (b.toDate === null) return 1;  // b comes first if its date is null
    const dateA = new Date(a.toDate).getTime();
    const dateB = new Date(b.toDate).getTime();
    return dateB - dateA; // Sort by toDate in descending order
  }) ?? [];


  disablePage = false;

  workHistoryFormArray = this.formBuilder.array([]) as FormArray;
  workHistoryForm = this.formBuilder.group({

    workHistory: this.workHistoryFormArray});

  constructor(
    private catalogService: CatalogsService,
    private userService: PortalUserService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private multiLineSnackBar: MultiLineSnackBarService,
  ) {

    this.workHistories?.forEach(work => {
      this.addWorkHistory(work);
    });
  }


  addWorkHistory(work: WorkHistory | null = null): void {
    const workHistoryItem = this.formBuilder.group({
      hospitalName: [work?.hospitalName ?? '', [Validators.required]],
      city: [work?.city ?? ''],
      state: [work?.state ?? ''],
      specialty: [work?.specialty ?? ''],
      fromDate: [work?.fromDate],
      toDate: [work?.toDate],
      responsibilities: [work?.responsibilities ?? ''],
    });



    this.workHistoryFormArray.push(workHistoryItem, {emitEvent: false});
  }

  removeWorkItem(index: number): void {
    this.workHistoryFormArray.removeAt(index);
  }

  save(): Observable<boolean> {
    this.markAllAsTouched();

    if (this.workHistoryForm.invalid) {
      this.multiLineSnackBar.displayMessages(['Please correct the errors and try again.']);
      return of(false);
    }
    this.disablePage = true;

    const resumeData = this.profileService.getResumeData();
    resumeData.workHistory = this.workHistoryForm?.value?.workHistory;

    resumeData.workHistory.forEach((work: WorkHistory) => {
      if (work.fromDate) {
        const fromDate = new Date(work.fromDate);
        fromDate.setDate(1); // Set day to the first day of the month
        work.fromDate = fromDate;
      }
      if (work.toDate) {
        const toDate = new Date(work.toDate);
        toDate.setDate(1); // Set day to the first day of the month
        work.toDate = toDate;
      }
    });

    this.profileService.saveResumeData(resumeData);
    return of(true);
  }

  markAllAsTouched() {
    this.workHistoryForm.markAllAsTouched();
    this.customDateComponents.forEach(customDateComponent => customDateComponent.markAllAsTouched());
  }


}
