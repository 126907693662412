[
	{
		"state": "AL",
		"phone": "8006565318",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 48 hours",
			"Permanent License Time-Frame: No set time frame - processed when materials are received",
			"Cost: $100 application fee, $50 temporary license fee, $3.50 transaction fee",
			"Application Requirements: Proof of citizenship, official transcripts, license verification",
			"Livescan Fingerprinting: No"
		]
	},
	{
		"state": "AK",
		"phone": "9072698161",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 10 business days",
			"Permanent License Time-Frame: 10 business days",
			"Cost: $375 (permanent license only) or $475 (including temporary permit)",
			"Application Requirements:  Proof of citizenship, official transcripts, license verification",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "AZ",
		"phone": "6027717800",
		"walkThrough": true,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 48 hours walk-in OR 2 weeks mail",
			"Permanent License Time-Frame: 1-2 months",
			"Cost: $50 temporary, $300 application fee, $50 fingerprints",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "AR",
		"phone": "5016862700",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 3-5 business days",
			"Permanent License Time-Frame: 3-5 business days",
			"Cost: $30 (temporary license), $125 (permanent license)",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "CA",
		"phone": "9163223350",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 8-10 weeks",
			"Permanent License Time-Frame: 10-12 weeks",
			"Cost: $100 (temporary license), $300 (permanent license), $49 fingerprints",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "CO",
		"phone": "3038942430",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 4-5 weeks",
			"Permanent License Time-Frame: 6 weeks",
			"Cost: $88 (nurse grads), $43 for licensure by endorsement,  $164 renewal fee",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: N/A"
		]
	},
	{
		"state": "CT",
		"phone": "8605097603",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 2-3 weeks",
			"Permanent License Time-Frame: 5-7 business days - once all documents received",
			"Cost: $180 (includes the cost of a temporary license)",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: No"
		]
	},
	{
		"state": "DE",
		"phone": "3027444517",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 4-6 weeks",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $40 (temporary license), $170 (permanent license)",
			"Application Requirements: Proof of citizenship, official transcripts",
			"Livescan Fingerprinting:  N/A"
		]
	},
	{
		"state": "FL",
		"phone": "8502454125",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 30 days",
			"Permanent License Time-Frame: 30 days",
			"Cost: $130",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "GA",
		"phone": "4782072440",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 15 business days",
			"Cost: $75 and a $10 processing fee",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "HI",
		"phone": "8085863000",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 15-20 business days",
			"Cost: $146-$202",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: No"
		]
	},
	{
		"state": "ID",
		"phone": "2083343110",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: No set timeline - issued once all documents received",
			"Permanent License Time-Frame: No set timeline - issued once all documents received",
			"Cost: $25 (temporary license), $118.25 (permanent license)",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "IL",
		"phone": "8008233110",
		"walkThrough": true,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 2 weeks",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $25 (temporary license), $50 (permanent license), $50 application fee",
			"Application Requirements:  Proof of citizenship, official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "IN",
		"phone": "3172342043",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 2 weeks",
			"Permanent License Time-Frame: 2-4 weeks",
			"Cost: $50 (temporary license), $200 (permanent license)",
			"Application Requirements:  Proof of citizenship, official transcriptson Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "IA",
		"phone": "5152813264",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 5-10 business days",
			"Permanent License Time-Frame: 5-10 business days but up to 8 weeks during high volume times",
			"Cost: $143.00 (Includes the $93 application fee and the $50 fee for the criminal history background check.)",
			"Application Requirements:  Proof of citizenship, official transcripts Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "KS",
		"phone": "7852964929",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 7-10 business days",
			"Permanent License Time-Frame: Varies",
			"Cost: $125",
			"Application Requirements:  Proof of citizenship, official transcriptslication Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "KY",
		"phone": "5024293300",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: No set timeline - issued once all documents received",
			"Permanent License Time-Frame: 2 weeks",
			"Cost: $165 (temporary license), $125 (permanent license), $12 fingerprint fee",
			"Application Requirements:  Proof of citizenship, official transcriptscation Requirements:",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "LA",
		"phone": "2257633570",
		"walkThrough": true,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: No set timeline - issued once all documents received",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $239.25 (application for full licensure with a 90-day temporary endorsement permit), $139.25 (permanent license including $100.00 application fee and $39.25 CBC processing fee)",
			"Application Requirements:  Proof of citizenship, official transcriptation Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "ME",
		"phone": "2072871133",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 1-2 weeks",
			"Cost: $75 (temporary license), $75 (permanent license)",
			"Application Requirements:  Proof of citizenship, official transcripts, passport-style photo",
			"Livescan Fingerprinting: N/A"
		]
	},
	{
		"state": "MD",
		"phone": "4105851900",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 48-72 hours",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $40 (temporary license), $100 (permanent license)",
			"Application Requirements:  Transcripts, Social Security number, verification of  no outstanding child support payments",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "MA",
		"phone": "6179730800",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $230",
			"Application Requirements:  N/A",
			"Livescan Fingerprinting: N/A"
		]
	},
	{
		"state": "MI",
		"phone": "5173350918",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 4-6 weeks - Only applies to individuals licensed as a registered nurse in Canada",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $64.25 plus an application fee of $80.30",
			"Application Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "MN",
		"phone": "6126172270",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 1 business day",
			"Permanent License Time-Frame: Varies",
			"Cost: $138.25",
			"Application Requirements:  Fingerprinting, Proof of graduation",
			"Livescan Fingerprinting: No"
		]
	},
	{
		"state": "MS",
		"phone": "6019874189",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 7-15 business days",
			"Cost: $100",
			"Application Requirements:  Social Security number, proof of graduation, transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "MO",
		"phone": "5737510681",
		"walkThrough": true,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: In person - Same day, Mail - 2 weeks",
			"Permanent License Time-Frame: 10-12 business days",
			"Cost: $90 (background check costs just under $45, and the application costs $45)",
			"Application Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "MT",
		"phone": "4068412340",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 30-60 days",
			"Cost: $200 , $27.25 background check fee",
			"Application Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "NE",
		"phone": "4024712133",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: No set timeline - issued once all documents received",
			"Permanent License Time-Frame: 4-5 weeks",
			"Cost: $123 Application Fee. $200 Examination Fee. $45.25 Criminal Background Check.",
			"Application Requirements:  Proof of citizenship, transcripts",
			"Livescan Fingerprinting: No"
		]
	},
	{
		"state": "NV",
		"phone": "8885906726",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 7-10 business days",
			"Permanent License Time-Frame: 2-4 months",
			"Cost: $105 (includes $5 fee for national database check)",
			"Application Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "NH",
		"phone": "6032712323",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: No set timeline - issued once all documents received",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $20 (temporary license), $148 (permanent license)",
			"Application Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "NJ",
		"phone": "9735046430",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: No set timeline - issued once all documents received",
			"Cost: $120 (temporary license), $320 (permanent license)",
			"Application Requirements:  The board approves only schools that achieve NCLEX passing rates of 75% or above.",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "NM",
		"phone": "5058418340",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 2-3 weeks",
			"Permanent License Time-Frame: No set timeline - issued once all documents received",
			"Cost: $50 (temporary license), $110 (permanent license), $44 (fingerprinting card)",
			"Application Requirements:  Graduation verification, official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "NY",
		"phone": "5184743817",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 6-8 weeks",
			"Permanent License Time-Frame: No set timeline - issued once all documents received",
			"Cost: $35 (temporary license), $143 (permanent license)",
			"Application Requirements:  Demonstrate good moral character, graduate from an approved program, and submit a passing NCLEX score",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "NC",
		"phone": "9197823211",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 1-2 weeks",
			"Permanent License Time-Frame: 6-8 weeks",
			"Cost: $75 application fee, $200 examination fee",
			"Application Requirements:  Transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "ND",
		"phone": "7013289777",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 3-5 business days",
			"Permanent License Time-Frame: 7-10 business days",
			"Cost: $170",
			"Application Requirements:  N/A",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "OH",
		"phone": "6144663947",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 4-6 weeks",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $75",
			"Application Requirements:  Criminal background check, fingerprints, school officials must send letters to the state board verifying each candidate's degree",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "OK",
		"phone": "4059621800",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Varies",
			"Permanent License Time-Frame: Varies",
			"Cost: $95 (temporary license), $85 (permanent license)",
			"Application Requirements:  Answer questions about fitness to serve, Background check, Fingerprints",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "OR",
		"phone": "5037314745",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 3-6 weeks",
			"Cost: $195 (endorsement), $160 (examination)",
			"Application Requirements:  Background check",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "PA",
		"phone": "7177837142",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 10-14 business days",
			"Permanent License Time-Frame: 8-10 weeks",
			"Cost: $70 (temporary license), $120 (permanent license without examination), $145 (permanent license with examination)",
			"Application Requirements:  Proof of graduation, Criminal and personal history questionnaire",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "RI",
		"phone": "4012225700",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 1 week",
			"Cost: $135",
			"Application Requirements: Official transcripts and degree verification to the state health department",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "SC",
		"phone": "8038964550",
		"walkThrough": true,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 1 day",
			"Permanent License Time-Frame: 10-14 business days",
			"Cost: $90 (permanent license), $100 (permanent license with temporary license)",
			"Application Requirements: Passport-style photograph, Notarized affidavit declaring sound moral character, Proof of either citizenship or legal immigration.",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "SD",
		"phone": "6053622760",
		"walkThrough": true,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 2-3 business days",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $25 (temporary license), $115 (permanent license), $43.25 background check fee",
			"Application Requirements:  Official transcripts",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "TN",
		"phone": "6155323202",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 6 weeks",
			"Cost: $115",
			"Application Requirements:  Proof of residency, criminal background check, passport-style photograph, confirm United States citizenship",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "TX",
		"phone": "5123057400",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 2 weeks",
			"Permanent License Time-Frame: 2 weeks",
			"Cost: $186",
			"Application Requirements:  Fingerprinting, FBI-run background check",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "UT",
		"phone": "8015306628",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 2-4 weeks",
			"Cost: $100",
			"Application Requirements:  Official transcripts, Fingerprints for background checks.",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "VT",
		"phone": "8028282396",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 3-5 business days",
			"Cost: $150 (examination fee), $60 (application fee)",
			"Application Requirements:  Social Security number, official transcripts",
			"Livescan Fingerprinting: No"
		]
	},
	{
		"state": "VA",
		"phone": "8046629909",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: Unavailable",
			"Permanent License Time-Frame: 4-6 weeks",
			"Cost: $190",
			"Application Requirements:  Criminal background check",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "WA",
		"phone": "3602364700",
		"walkThrough": false,
		"compact": false,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 3 weeks",
			"Permanent License Time-Frame: 3 weeks",
			"Cost: $120",
			"Application Requirements:  Graduation verification",
			"Livescan Fingerprinting: Yes"
		]
	},
	{
		"state": "WV",
		"phone": "6082662811",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 7-10 business days",
			"Permanent License Time-Frame: 7-10 business days",
			"Cost: $25 (temporary license), $70 (permanent license)",
			"Application Requirements:  Official transcripts",
			"Livescan Fingerprinting: New graduates only"
		]
	},
	{
		"state": "WI",
		"phone": "8776171565",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 10-15 business days",
			"Permanent License Time-Frame: 10-15 business days",
			"Cost: $10 (temporary license), $75 (new graduates), $82 (out of state nurses)",
			"Application Requirements:  N/A",
			"Livescan Fingerprinting: No"
		]
	},
	{
		"state": "WY",
		"phone": "3077777601",
		"walkThrough": false,
		"compact": true,
		"nursys": true,
		"info": [
			"Temporary License Time-Frame: 10-14 business days",
			"Permanent License Time-Frame: Up to 90 days",
			"Cost: $195",
			"Application Requirements:  Official transcripts, Fingerprint cards",
			"Livescan Fingerprinting: Yes"
		]
	}
]
