[
  {
    "question": "Where are these positions?",
    "answer": "Nightingale provides service in mostly all states, with access and reimbursement for re-licensure in the majority. If the job seeker lives in a state that is party to the Nursing Compact (currently forty of the fifty states), he/she can take advantage of multi-state privileges and freely work within those states by the uncomplicated process of obtaining a Compact License. Nightingale can guide and assist the candidate in obtaining a Compact License."
  },
  {
    "question": "How does the clinical travel process work?",
    "answer": "The process begins with an interaction with one of our trained and experienced Staffing Managers (SM). In that first interaction, candidates are preliminarily screened and vetted by the SM as to both interests and plans, as well as how they match up to the myriad opportunities that are available. If both parties are satisfied to move to the next step, the candidate completes an employment application and a detailed checklist of their experience and comfort within their area of specialization. Simultaneously, the SM is matching the candidate to both open positions, and those that are highly likely to open in the near term. It is at this time that the candidate’s background, education, experience, and desires regarding a potential job are matched in detail. The SM will discuss and offer assorted opportunities for consideration by the candidate and move on to the next stage, which is the development of an economic package. Once the parties agree on all details, the SM electronically submits the candidate’s profile information to the end-user client facility for their consideration. Most clients will then want to have a cell phone interaction with the candidate to further explore the opportunity and to reconfirm the match. If all goes well, the client will propose an offer of employment, confirming all details and electronic agreements are prepared for the review and execution of the parties. An expected start date is set, and on-boarding begins."

  },
  {
    "question": "How much experience and qualification do I need to work with Nightingale?",
    "answer": "All Nightingale clinicians must be legally able to work in the United States to begin his/her assignment and possess at least one state license and a degree in their particular field.  Each assignment specifies the required experience, certifications and special knowledge needed to qualify for consideration. Typically, applicants have worked recently for two years or more as a clinician with patients in the US, and at least half of that time working within a particular specialty. All assignment clinicians become W-2 employees of the firm upon the commencement of the assignment and remain so through all assignments, unless expressly terminated from their employment by the Company."
  },
  {
    "question": "What elements are considered by the parties during the matching process?",
    "answer": "Nightingale is a market maker of professional clinical labor. Since the national market is always in a state of disequilibrium (i.e., supply and demand never match perfectly), Nightingale intervention serves to make the job market work for both the client and the candidate. The clinician is typically concerned with the location, duration, duties, shift, patient ratios, scheduling, lodging, meals, and salary.  Some clinicians will request that their schedule is “blocked” together, so they get several days off in a row per work week.  With many jobs available, we can find one that works with the clinician’s scheduling needs.  The client’s staffing concerns typically involve licensure, certification, education, employment history, background, and references. Nightingale acts as the ultimate intermediary, for both the client and candidate, ensuring that both parties’ needs and concerns are met. Our goal in providing service is that everyone is satisfied."
  },
  {
    "question": "Does Nightingale pay for temporary relocation?",
    "answer": "Nightingale provides tax free travel reimbursement based on the candidate’s permanent tax home address and is paid per driving mile (currently 65 cents per mile).  We handle all mileage calculations during the matching process."
  },
  {
    "question": "Does Nightingale provide Paid Time Off (PTO)?",
    "answer": "Based on which state the assignment is located, Nightingale provides accrued time off pursuant to regulations.  Our PTO system calculates your PTO balances. The clinician may ask your staffing manager for your paid leave balance and usage rules at any time, again, determined in which state he/she is assigned."
  },
  {
    "question": "Does Nightingale reimburse for license and certification expense?",
    "answer": "Yes. As part of the assignment, untaxed reimbursement for expenses incurred by the clinician to obtain a new license, certification or required CEU. All such reimbursements are included in the clinician’s compensation package."
  },
  {
    "question": "Does Nightingale offer part time or per diem assignments?",
    "answer": "Yes. While Nightingale is primarily a clinical travel firm, representing and working with full-time staff, long-term, part time or as needed assignment are easily worked out based on the determination of the client facility’s staff deployment plan."
  },
  {
    "question": "How do Per Diem Advances/ Reimbursements Work?",
    "answer": "The purpose of Per Diem payments is to provide untaxed funds for qualifying workers that are on business trips on behalf of their employer, to be used for lodging, meals and incidentals incurred during their trip.  To qualify, the employee’s assignment must require an overnight stay because daily commutation from his/her Permanent Tax Home (PTH) would be unreasonable. The employee must establish a PTH through required declarations and documentation of the principal locus of their economic activity. Once qualified, the employee may receive advances and/or reimbursements that are not reported as taxable income. The MAXIMUM amounts an employee may receive at any time are set forth twice per year by the Federal General Services Administration as well as IRS Publication 1542. The rates vary as to the location in which the employee is residing during his/her business trip.  A look up link is provided here:  https://www.gsa.gov/travel/plan-book/per-diem-rates."
  },
  {
    "question": "Am I eligible for heath insurance in connection with my employment?",
    "answer": "Yes. Nightingale provides a free minimal essential coverage plan to all clinical employees within a week from the start of employment. Employees may “buy-up” dependent coverage of this plan on a pre-tax basis. Nightingale also provides group minimum value plan coverage as defined under the Affordable Care Act (ACA) subject to the Look Back Safe Harbor as defined under US Department of Treasury rules adopted alongside the ACA."
  },
  {
    "question": "What type of pre-employment orientation should I expect to receive?",
    "answer": "The working clinician is subject to the terms of the the Nightingale Employee Handbook, which provides a blueprint for working with Nightingale, regardless of which client facility you are assigned to.  The “clinical” orientation is performed by the client facility, pursuant to their rules, policies, and procedures. This orientation occurs the first time a clinician begins his/her first assignment at that facility. Each client facility has its own method of orientation. Members of Nightingale’s Clinical and Credentialing Department are available to all clinicians to resolve any questions or issues experienced at the client facility."
  },
  {
    "question": "What happens at the conclusion of my assignment?",
    "answer": "In a sizable percentage of our assignments, the clinician is requested by the client facility – at the clinician’s option - to extend. We stay in communication with the clinician throughout every assignment about their satisfaction with the position - and - if they are interested in considering an extension. If the clinician chooses not to extend, we immediately begin working to match the clinician with a new assignment. If the clinician seeks a break following the conclusion of the assignment before commencing a new one, Nightingale works to get the clinician a new assignment when they are ready to return."
  }
]
