<div class="portal-container">
  <div class="header-background">
    <app-header></app-header>
  </div>
  <div class="body-background">
    <div class="portal-body" [ngClass]="!!loggedInUser() ? '' : 'header-margin'">
      @if (loggedInUser()) {
        <div style="z-index: 200;">
          <app-portal-user-container
            [initialTab]="initialTab"
            [showTransition]="showTransition">
          </app-portal-user-container>
        </div>
      } @else {
        <div class="login-register-container">
          <mat-tab-group [dynamicHeight]="true">

            <!-- Login -->
            <mat-tab label="Login" [disabled]="isLoading">
              <div class="inner-container">

                <form class="login-register-form" [formGroup]="loginForm">
                  <mat-form-field class="form-field">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" formControlName="email">
                    @if (loginForm.controls.email.hasError('required')) {
                      <mat-error>Email is required.</mat-error>
                    }
                    @if (loginForm.controls.email.hasError('email')) {
                      <mat-error>Invalid email.</mat-error>
                    }
                  </mat-form-field>
                  <mat-form-field class="form-field">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" (keyup)="passwordKeyPressed($event)">
                    @if (loginForm.controls.password.hasError('required')) {
                      <mat-error>Password is required.</mat-error>
                    }
                  </mat-form-field>
                </form>

                <div class="flex-expand"></div>

                <div style="padding: 15px 0;">
                  <span style="color: #000;">Forgot your password?&nbsp;</span>
                  <span class="reset-text" (click)="sendPasswordResetLink()">Click here to reset it.</span>
                </div>

                <div class="login-register-button-row">
                  <button class="login-register-button" mat-raised-button color="primary" (click)="login()"
                          [disabled]="isLoading">
                    @if (isLoading) {
                      <mat-spinner diameter="25"></mat-spinner>
                    } @else {
                      Login
                    }
                  </button>
                </div>
              </div>
            </mat-tab>

            <!-- Register -->
            <mat-tab label="Register" [disabled]="isLoading">
              <div class="inner-container">

                <form class="login-register-form" [formGroup]="registrationForm">
                  <mat-form-field class="form-field">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" formControlName="firstName">
                    @if (registrationForm.controls.firstName.hasError('required')) {
                      <mat-error>First Name is required.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="form-field">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" formControlName="lastName">
                    @if (registrationForm.controls.lastName.hasError('required')) {
                      <mat-error>Last Name is required.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="form-field">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" formControlName="email">
                    @if (registrationForm.controls.email.hasError('required')) {
                      <mat-error>Email is required.</mat-error>
                    }
                    @if (registrationForm.controls.email.hasError('email')) {
                      <mat-error>Invalid email.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="form-field">
                    <mat-label>Phone</mat-label>
                    <input matInput type="phone" formControlName="phone">
                    @if (registrationForm.controls.phone.hasError('required')) {
                      <mat-error>Phone is required.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="form-field">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    @if (registrationForm.controls.password.hasError('required')) {
                      <mat-error>Password is required.</mat-error>
                    }
                    @if (registrationForm.hasError('fieldsDoNotMatch')) {
                      <mat-error>Passwords do not match.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="form-field">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput type="password" formControlName="confirmPassword">
                    @if (registrationForm.controls.confirmPassword.hasError('required')) {
                      <mat-error>Password confirmation is required.</mat-error>
                    }
                    @if (registrationForm.hasError('fieldsDoNotMatch')) {
                      <mat-error>Passwords do not match.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="form-field">
                    <mat-label>Specialty</mat-label>
                    <mat-select formControlName="specialty">
                      @for (specialty of specialties; track specialty) {
                        <mat-option [value]="specialty">{{ specialty }}</mat-option>
                      }
                    </mat-select>
                    @if (registrationForm.controls.specialty.hasError('required')) {
                      <mat-error>Specialty is required.</mat-error>
                    }
                  </mat-form-field>
                </form>

                <div class="login-register-button-row">
                  <button class="login-register-button" mat-raised-button color="primary" (click)="register()"
                          [disabled]="isLoading">
                    @if (isLoading) {
                      <mat-spinner diameter="25"></mat-spinner>
                    } @else {
                      Register
                    }
                  </button>
                </div>

              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      }
    </div>
  </div>
</div>

