import { Component } from '@angular/core';

@Component({
  selector: 'app-wings',
  standalone: true,
  imports: [],
  templateUrl: './wings.component.html',
  styleUrl: './wings.component.css'
})
export class WingsComponent {

}
