import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContainer,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';
import {PortalUserService} from '../shared/services/portal-user.service';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {
  MultiLineSnackBarService
} from '../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';

@Component({
  selector: 'app-portal-login-dialog',
  standalone: true,
  imports: [
    MatDialogContainer,
    MatDialogActions,
    MatButton,
    MatDialogTitle,
    MatDialogContent,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule
  ],
  templateUrl: './portal-login-dialog.component.html',
  styleUrl: './portal-login-dialog.component.css'
})
export class PortalLoginDialogComponent {
  isLoading = false;
  loginForm = this.formBuilder.nonNullable.group({
    email: ['', {
      validators: [
        Validators.required,
        Validators.email,
      ]
    }],
    password: ['', {
      validators: [
        Validators.required
      ]
    }]
  });

  constructor(
    private formBuilder: FormBuilder,
    private portalUserService: PortalUserService,
    private matDialogRef: MatDialogRef<PortalLoginDialogComponent>,
    private matDialog: MatDialog,
    private multiLineSnackBar: MultiLineSnackBarService,
    @Inject(MAT_DIALOG_DATA) public dialogType: 'SESSION_EXPIRED' | 'LOGIN_REQUIRED',
  ) {
  }

  logout(): void {
    this.portalUserService.clearUserData();
    this.matDialog.closeAll();
  }

  login(): void {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    }

    const email = this.loginForm.get('email')?.value ?? '';
    const password = this.loginForm.get('password')?.value ?? '';
    this.loginForm.disable();
    this.isLoading = true;
    this.portalUserService.login(email, password).subscribe(result => {
      if (result.isSuccess) {
        this.portalUserService.setActiveUser(result.value!);
        this.loginForm.reset();

        if (this.dialogType === 'SESSION_EXPIRED') {
          this.multiLineSnackBar.displayMessages(['Authentication successful, please retry the action you were taking.']);
        }

        this.matDialogRef.close(result.value!);
      } else {
        this.multiLineSnackBar.displayMessages(result.errorList);
      }
      this.isLoading = false;
      this.loginForm.enable();
    });
  }
}
