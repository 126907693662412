import {Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {SelectOption} from "../../model/types";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgClass, NgForOf, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {ShortenPipe} from "../../pipes/shorten.pipe";

@Component({
  selector: 'app-select-chip',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgStyle,
    NgClass,
    NgIf,
    NgForOf,
    ShortenPipe
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectChipComponent),
      multi: true
    }
  ],
  templateUrl: './select-chip.component.html',
  styleUrl: './select-chip.component.css'
})
export class SelectChipComponent implements ControlValueAccessor, OnInit {

  @ViewChild('div', {read: ElementRef}) public div: ElementRef<any>;

  constructor() {
  }

  @Input() options: SelectOption[];
  @Input() divId: string;
  @Input() singleSelection: boolean;
  @Input() placeholder: string;

  visibleOptions: SelectOption[];
  selectedOptions: SelectOption[] = [];
  value: any[];
  isDisabled: boolean;
  isSelectionListShown: boolean = false;

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  addItem(item: SelectOption) {
    this.selectedOptions?.push(item)
    let valuesArray = this.selectedOptions.map(option => option.value);
    this.onChange(valuesArray);
    this.visibleOptions = this.options.filter(option => !this.selectedOptions.includes(option));
  }

  ngOnInit(): void {
    this.visibleOptions = this.options;
  }

  removeItem(item: SelectOption,event:Event) {
    event.stopPropagation();
    const index = this.selectedOptions.indexOf(item, 0);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    }
    this.visibleOptions = this.options.filter(option => !this.selectedOptions.includes(option));
    let valuesArray = this.selectedOptions.map(option => option.value);
    this.onChange(valuesArray);
  }


  showSelectList() {
    this.isSelectionListShown = !this.isSelectionListShown;

    if (!this.isSelectionListShown) {
      this.onTouched();
    }
  }

  writeValue(value: any[]): void {
    this.value = value;
    if (value) {
      this.selectedOptions = value
        .map(val => this.options.find(option => option.value === val))
        .filter((option): option is SelectOption => option !== undefined);
      this.visibleOptions = this.options.filter(option => !this.selectedOptions.map(selectedOption => selectedOption.value).includes(option.value));
      this.onTouched();
    }

    // let valuesArray = this.selectedOptions.map(option => option.value);
    // this.onChange(valuesArray);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (!this.div.nativeElement.contains(event.target) && !event.target.classList.contains('select-item')) {
      if (this.isSelectionListShown) {
        this.isSelectionListShown = false;
        this.onTouched();
      }
    }
  }

  @HostListener('focus')
  onFocus() {
    this.isSelectionListShown = true;
    // this.onTouched();
  }

  @HostListener('blur')
  onBlur() {
    this.isSelectionListShown = false;
    this.onTouched();
  }

}
