import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {Benefit, NurseComment} from "../../../shared/model/types";
import benefits from "../../../../assets/data/benefit.json";

@Injectable({
  providedIn: 'root'
})
export class BenefitsMockDataService {
  getBenefits(): Observable<Benefit[]> {
    return of(benefits);
  }
}
