import { Component } from '@angular/core';
import {TipFrameComponent} from "./tip-frame/tip-frame.component";

@Component({
  selector: 'app-tips',
  standalone: true,
  imports: [
    TipFrameComponent
  ],
  templateUrl: './tips.component.html',
  styleUrl: './tips.component.css'
})
export class TipsComponent {

}
