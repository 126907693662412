@if (candidateAssignmentList$ | async) {

  <div class="user-assignment-container">

    <div class="assignment-row">

      <mat-card class="mat-card">
        <div class="assignment-container unselectable" style="overflow: hidden">
          <div class="portal-home-section-title">Active Assignments</div>

          @if (currentAssignments.length) {
            <div class="job-application-list">
              @for (assignment of currentAssignments; track assignment) {
                <div style="padding-bottom: 20px;">
                  <app-user-assignment [assignment]="assignment"></app-user-assignment>
                </div>
              }
            </div>
          } @else {
            <div class="row-spacer portal-home-item-title">No assignments found.</div>
          }
        </div>

      </mat-card>

      <mat-card class="mat-card">
        <div class="assignment-container unselectable" style="overflow: hidden">
          <div class="portal-home-section-title">Future Assignments</div>

          @if (lokedPendingAssignments.length) {
            <div class="job-application-list">
              @for (assignment of lokedPendingAssignments; track assignment) {
                <div style="padding-bottom: 20px;">
                  <app-user-assignment [assignment]="assignment" style="padding: 0"></app-user-assignment>
                </div>
              }
            </div>
          } @else {
            <div class="row-spacer portal-home-item-title">No assignments found.</div>
          }
        </div>

        <div class="end-spacer"></div>
      </mat-card>
    </div>

  </div>
}
