import {Component} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatCard, MatCardActions} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {Reference} from '../../shared/types';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {MatDialog} from '@angular/material/dialog';
import {ProfileService} from '../services/profile.service';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogResult, ConfirmationDialogStyle} from '../../../shared/components/confirmation-dialog/types';
import {EducationDialogComponent} from '../portal-user-education/education-dialog/education-dialog.component';
import {ReferenceDialogComponent} from './reference-dialog/reference-dialog.component';
import {PhonePipe} from '../../../shared/pipes/phone.pipe';

@Component({
  selector: 'app-portal-user-references',
  standalone: true,
  imports: [
    DatePipe,
    MatButton,
    MatCard,
    MatCardActions,
    MatIcon,
    MatIconButton,
    MatProgressSpinner,
    PhonePipe
  ],
  templateUrl: './portal-user-references.component.html',
  styleUrl: './portal-user-references.component.css'
})
export class PortalUserReferencesComponent {
  user = this.userService.activeUser;

  disablePage = false;

  constructor(
    private userService: PortalUserService,
    private matDialog: MatDialog,
    private profileService: ProfileService,
    private snackBar: MultiLineSnackBarService,
  ) {
  }

  removeReference(referenceId?: number): void {
    if (!referenceId) {
      this.snackBar.displayMessages(['An error occurred, please reload the page and try again.']);
      return;
    }

    // show confirmation dialog
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Reference',
        message: 'Are you sure you want to delete this record? This action cannot be undone.',
        confirmationDialogStyle: ConfirmationDialogStyle.twoButtonDialog,
        positiveBtnText: 'Delete',
        negativeBtnText: 'Cancel',
      }
    }).afterClosed().subscribe(result => {
      if (result === ConfirmationDialogResult.positive) {
        // disable page
        this.disablePage = true;
        this.profileService.deleteReference(referenceId).subscribe(result => {
          if (result.isSuccess) {
            const references = this.userService.activeUser()?.references ?? [];
            if (references.find(r => r.id === referenceId)) {
              references.splice(references.findIndex(r => r.id === referenceId), 1);
            }
            this.userService.setReferences(references);
          } else {
            this.snackBar.displayMessages(result.errorList);
          }
          this.disablePage = false;
        });
      }
    });
  }

  editReference(reference: Reference): void {
    this.matDialog.open(ReferenceDialogComponent, {
      autoFocus: false,
      panelClass: ['full-screen-mobile-dialog'],
      data: reference,
    });
  }

  addReference(): void {
    this.matDialog.open(ReferenceDialogComponent, {
      autoFocus: false,
      panelClass: ['full-screen-mobile-dialog'],
      data: null,
    });
  }
}
