{
  "address": {
    "address1": "7800 Congress Ave",
    "address2": "Suite 200",
    "city": "Boca Raton",
    "state": "FL",
    "zip": "33487"
  },
  "phone": "561.314.0140",
  "supportPhone": "866.681.4357",
  "credentialsFax": "561.828.2236",
  "timesheetFax": "561.828.0671",
  "timesheetEmail": "timesheets@nightingalenurses.net",
  "employeeVerificationPhone": "561.314.1600"
}


