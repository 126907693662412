<div class="home-message-container">

  <div class="home-message-container-title">
    <div class="home-message-container-title-text1">LEGENDARY <br>
      <span class="home-message-container-title-text2">YOU.</span>
    </div>
  </div>

  <div class="home-message-container-message">
    <div class="home-message-container-message-title">Mountain or career. You don’t get to the top without
      climbing.
    </div>
    <div class="home-message-container-message-text">Sign up today to begin or continue your travel nursing journey with Nightingale. We offer the highest pay rates and best benefits in the industry.
    </div>
  </div>

  <div class="home-message-container-action">
    <a href="/apply" class="home-message-container-action-button button-text">JOIN OUR TEAM</a>
  </div>
</div>
