import {Component, EventEmitter, Output} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-licensure-table-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    FormsModule,
    MatTooltip
  ],
  templateUrl: './licensure-table-header.component.html',
  styleUrl: './licensure-table-header.component.css'
})
export class LicensureTableHeaderComponent {
@Output() searchClick = new EventEmitter<string>();
  search: string = '';

  onSearchClick() {
    this.searchClick.emit(this.search);
  }
}
