import {Component} from '@angular/core';
import {SelectOption} from "../../../shared/model/types";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {CatalogsService} from "../../../shared/services/catalogs.service";
import {Router} from "@angular/router";
import {SelectChipComponent} from "../../../shared/components/select-chip/select-chip.component";
import {SelectSingleComponent} from "../../../shared/components/select-single/select-single.component";

@Component({
  selector: 'app-browse-jobs',
  standalone: true,
  imports: [
    FormsModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    SelectChipComponent,
    AsyncPipe,
    NgIf,
    SelectSingleComponent
  ],
  templateUrl: './browse-jobs.component.html',
  styleUrl: './browse-jobs.component.css'
})
export class BrowseJobsComponent {
  specialties: SelectOption[];
  states: SelectOption[];

  searchForm: FormGroup<{
    specialty: FormControl<string[] | null>,
    state: FormControl<string[] | null>,
  }>;

  constructor(
    private catalogsService: CatalogsService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.specialties = this.catalogsService.getSpecialties().map(specialty => new SelectOption(specialty, specialty));
    this.states = this.catalogsService.getStates().map(state => new SelectOption(state.name, state.code));

    this.searchForm = this.formBuilder.group({
      specialty: new FormControl<string[] | null>([]),
      state: new FormControl<string[] | null>([]),
    });
  }

  jobSearch(): void {
    const selectedSpecialties = (this.searchForm.controls['specialty'].value ?? []).join(',');
    const selectedStates = (this.searchForm.controls['state'].value ?? []).join(',');

    this.router.navigate(['/job-search'], {
      queryParams: {
        specialties: selectedSpecialties,
        states: selectedStates,
        column: 'WeeklyPay',
        direction: 'Desc'
      }
    }).then();
  }
}
