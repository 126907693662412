export enum CarouselType {
  None,
  Left,
  Right
}

export enum CarouselScroll {
  Left,
  Right
}
