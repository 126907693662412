import {Component} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-join-nightingale',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './join-nightingale.component.html',
  styleUrl: './join-nightingale.component.css'
})
export class JoinNightingaleComponent {
  constructor() {
  }
}
