<div class="tip-item">
  <img ngSrc="{{imageIcon}}" alt="image2" class="tip-icon"
            width="1" height="1">
  <div class="tip-item-explanation">
    <div class="tip-title-frame">
      <img ngSrc="{{imageIcon}}" alt="" class="tip-icon-mobile"
           width="1" height="1">
      <div class="title"><h5>{{ title }}</h5></div>
    </div>
    <div class="description"><p>{{ text }}</p></div>
  </div>
</div>
