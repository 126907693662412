import {ValidationErrors, ValidatorFn} from "@angular/forms";

export function fieldMatchValidator(mustMatch: boolean, firstField: string, secondField: string): ValidatorFn {
  return ((control): ValidationErrors | null => {
    const firstValue = control.get(firstField)?.value;
    const secondValue = control.get(secondField)?.value;

    if (mustMatch) {
      if (firstValue && secondValue) {
        return firstValue === secondValue ? null : {fieldsDoNotMatch: true};
      }
    } else {
      if (firstValue && secondValue) {
        return firstValue === secondValue ? {fieldsMatch: true} : null;
      }
    }

    return null;
  });


}
