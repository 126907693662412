import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-multi-line-snack-bar',
  standalone: true,
  imports: [
    MatButton
  ],
  templateUrl: './multi-line-snack-bar.component.html',
  styleUrl: './multi-line-snack-bar.component.css'
})
export class MultiLineSnackBarComponent implements OnInit {
  action = '';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {
      messages: string[],
      action: string
    },
    private matSnackBarRef: MatSnackBarRef<MultiLineSnackBarComponent>
  ) {
  }

  ngOnInit(): void {
    this.action = this.data.action;
  }

  close(): void {
    this.matSnackBarRef.dismiss();
  }

  actionClicked(): void {
    this.matSnackBarRef.dismissWithAction();
  }

}
