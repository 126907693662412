<div class="body-legends">
  <div class="legends-explanation">
    <div class="legends-explanation-text">
      <div class="legends-typography"><h3>LEGENDS &nbsp;</h3></div>
      <div class="wanted-typography"><h3>WANTED</h3></div>

    </div>
    <div class="line-white"></div>
  </div>
  <div class="legends-apply">
    <div class="legends-apply-typography">As a qualified Registered Nurse, you are in demand. So demand more. <br>
      Become part of the best team in Travel Nursing.
    </div>
    <a href="/apply" class="legends-apply-button button-text btn-hover">
      APPLY NOW
    </a>
  </div>
</div>
