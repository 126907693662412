<div class="body-nurses-say">
  <div class="what-nurses-say">
    <div class="what-nurses-say-explanation">
      <div class="what"><h2>WHAT&nbsp;
        <span class="nurses-say">NURSES SAY<br></span></h2></div>
      <div class="what"><h2>ABOUT NIGHTINGALE</h2></div>
    </div>

    <div class="line-green"></div>
  </div>
  <app-nurse-comment-frame></app-nurse-comment-frame>

</div>

