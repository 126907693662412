[
  {
    "id": 0,
    "title": "How to Begin Your Adventure",
    "imagePath": "assets/img/related-post-01.webp",
    "author": "Alex Moreno",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 3,
    "tags": [
      "Travel Nursing Tips",
      "Nightingale"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  },
  {
    "id": 1,
    "title": "A Brief History of Travel Nursing",
    "imagePath": "assets/img/related-post-02.webp",
    "author": "Eric Wiest",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 2,
    "tags": [
      "About Travel Nursing",
      "Nightingale"
    ],
    "related": [
      {
        "id": 2,
        "title": "Passion: The Nurse's Essential Journey",
        "imagePath": "assets/img/blog-featured-image.webp",
        "author": "Bob Marello",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "It is passion that truly separates nurses from the rest of the vocations. A passion to do what needs to be done, to help those in need, and to be the very best at it. It is this Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 1,
        "tags": [
          "Travel Nursing Tips",
          "Nightingale"
        ]
      }
    ]
  },
  {
    "id": 2,
    "title": "Passion: The Nurse's Essential Journey",
    "imagePath": "assets/img/blog-featured-image.webp",
    "author": "Bob Marello",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "It is passion that truly separates nurses from the rest of the vocations. A passion to do what needs to be done, to help those in need, and to be the very best at it. It is this Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 1,
    "tags": [
      "Travel Nursing Tips",
      "Nightingale"
    ],
    "related": []
  },
  {
    "id": 3,
    "title": "How to Begin Your Adventure",
    "imagePath": "assets/img/related-post-01.webp",
    "author": "Alex Moreno",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 0,
    "tags": [
      "Travel Nursing Tips"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  },
  {
    "id": 4,
    "title": "A Brief History of Travel Nursing",
    "imagePath": "assets/img/related-post-02.webp",
    "author": "Eric Wiest",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 0,
    "tags": [
      "About Travel Nursing"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  },
  {
    "id": 5,
    "title": "Passion: The Nurse's Essential Journey",
    "imagePath": "assets/img/blog-featured-image.webp",
    "author": "Bob Marello",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "It is passion that truly separates nurses from the rest of the vocations. A passion to do what needs to be done, to help those in need, and to be the very best at it. It is this passion that drives nurses past expectation, beyond exhaustion, task after task, patient after patient, day in and day out. It is passion that makes nursing less a vocation and more of a calling, and it is passion that elevates a nurse from admirable to invaluable to legendary.\n\nGiven the opportunity to travel to new places, make new friends, see new sights, and hone your nursing skills at the same time is a great way to cultivate or rekindle one’s passion for nursing. Traveling also helps nurses explore and develop new passions, like swimming, hiking, skiing, or kayaking, broadening their world and reaffirming the important decision they once made to pursue nursing as a career.\n\nAnd since Travel Nurses typically earn as much as 20% more than staff nurses, without worrying about travel or housing costs, it’s also a great way to get recognized for exactly how vital you are to the medical community.",
    "featured": 0,
    "tags": [
      "Travel Nursing Tips",
      "Nightingale",
      "Travel",
      "Passion"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      },
      {
        "id": 9,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Nursing"
        ]
      }
    ]
  },
  {
    "id": 6,
    "title": "How to Begin Your Adventure",
    "imagePath": "assets/img/related-post-01.webp",
    "author": "Alex Moreno",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 0,
    "tags": [
      "Travel Nursing Tips",
      "Travel"
    ],
    "related": []
  },
  {
    "id": 7,
    "title": "A Brief History of Travel Nursing",
    "imagePath": "assets/img/related-post-02.webp",
    "author": "Eric Wiest",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 0,
    "tags": [
      "About Travel Nursing",
      "Nightingale"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  },
  {
    "id": 8,
    "title": "Passion: The Nurse's Essential Journey",
    "imagePath": "assets/img/blog-featured-image.webp",
    "author": "Bob Marello",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "It is passion that truly separates nurses from the rest of the vocations. A passion to do what needs to be done, to help those in need, and to be the very best at it. It is this Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 0,
    "tags": [
      "Travel Nursing Tips",
      "Nightingale"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  },
  {
    "id": 9,
    "title": "How to Begin Your Adventure",
    "imagePath": "assets/img/related-post-01.webp",
    "author": "Alex Moreno",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 0,
    "tags": [
      "Travel Nursing Tips",
      "Nursing"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  },
  {
    "id": 10,
    "title": "A Brief History of Travel Nursing",
    "imagePath": "assets/img/related-post-02.webp",
    "author": "Eric Wiest",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 0,
    "tags": [
      "About Travel Nursing",
      "Nightingale"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  },
  {
    "id": 11,
    "title": "Passion: The Nurse's Essential Journey",
    "imagePath": "assets/img/blog-featured-image.webp",
    "author": "Bob Marello",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "It is passion that truly separates nurses from the rest of the vocations. A passion to do what needs to be done, to help those in need, and to be the very best at it. It is this Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": 0,
    "tags": [
      "Travel Nursing Tips",
      "Nightingale"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  },
  {
    "id": 12,
    "title": "The Inspiring Legacy of Florence Nightingale",
    "imagePath": "assets/img/related-post-01.webp",
    "author": "",
    "date": "2019-07-04T13:33:03.969Z",
    "content": "The Inspiring Legacy of Florence Nightingale Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "featured": -1,
    "tags": [
      "Nursing With Nightingale"
    ],
    "related": [
      {
        "id": 1,
        "title": "A Brief History of Travel Nursing",
        "imagePath": "assets/img/related-post-02.webp",
        "author": "Eric Wiest",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "For qualified nurses who want to get more out of their careers, Travel Nursing has been nothing short of a revolution. Beyond the significant bump in pay it affords, the Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 2,
        "tags": [
          "About Travel Nursing",
          "Nightingale"
        ]
      },
      {
        "id": 6,
        "title": "How to Begin Your Adventure",
        "imagePath": "assets/img/related-post-01.webp",
        "author": "Alex Moreno",
        "date": "2019-07-04T13:33:03.969Z",
        "content": "So you've landed your first Travel Nursing assignment. Congratulations! Now what? If you're nursing with Nightingale, you'll have been assigned an experienced Travel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "featured": 0,
        "tags": [
          "Travel Nursing Tips",
          "Travel"
        ]
      }
    ]
  }
]
