<div class="mobile-dialog-header">
  {{ fileName }}
</div>
<mat-dialog-content class="file-view-container">
  @if (isLoading) {
    <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
      <mat-spinner color="primary"></mat-spinner>
    </div>
  }
  @if (fileViewer$ | async) {
    @if (contentType.startsWith('image/')) {
      <img class="pdf-container-shadow" [src]="base64Url" alt="">
    } @else {
      <div class="flex-expand pdf-container-shadow">
        <iframe class="flex-expand pdf-container" id="pdfViewer" [src]="iframeSource"></iframe>
      </div>
    }
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Close</button>
  <button mat-raised-button color="primary" (click)="moveToNewTab()">Move to New Tab</button>
</mat-dialog-actions>
