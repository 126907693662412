import { Component } from '@angular/core';
import {NgForOf} from "@angular/common";
import {LicensureTableComponent} from "./licensure-table/licensure-table.component";
import {FaqListComponent} from '../../../resources/faq/body-faq/faq-list/faq-list.component';

@Component({
  selector: 'app-body-licensure',
  standalone: true,
  imports: [
    FaqListComponent,
    NgForOf,
    LicensureTableComponent
  ],
  templateUrl: './body-licensure.component.html',
  styleUrl: './body-licensure.component.css'
})
export class BodyLicensureComponent {
  data: Array<any>;constructor(){
    this.data = [
      { firstName: 'John', lastName: 'Doe', age: '35' },
      { firstName: 'Michael', lastName: 'Smith', age: '39' },
      { firstName: 'Michael', lastName: 'Jordan', age: '45' },
      { firstName: 'Tanya', lastName: 'Blake', age: '47' }
    ];
  }

}
