[
  {
    "transparentImagePath": "assets/svg/icon_bg-01.svg",
    "imageIcon": "assets/svg/icon_flexible-career.svg",
    "title": "Flexible Career",
    "text": "Travel nursing is super flexible!  Assignments have agreed start and end dates, and you have the freedom to take time off between assignments.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-02.svg",
    "imageIcon": "assets/svg/icon_travel.svg",
    "title": "Travel",
    "text": "Life can be a sweet adventure, and travel nursing can make it a reality. In travel nursing, you can have work and leisure.  You can visit different parts of the country while you work.  Typical assignment schedules are three (12) hour shifts per week, allowing you four days off per week to experience the local activities, attractions, and culture.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-03.svg",
    "imageIcon": "assets/svg/icon_perdiem-expense.svg",
    "title": "Per Diem Expense Reimbursements",
    "text": "Being a traveling clinician offers a world of economic benefits.  One of the most important is in the area of tax-free cash advances in order to pay for lodging and meals while on assignment.  You can then choose where you want to stay based on personal preference.  Travelers can also often take advantage of our corporate rates at participating venues.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-04.svg",
    "imageIcon": "assets/svg/icon_relicensing.svg",
    "title": "Relicensing Services",
    "text": "Nightingale is connected to the Boards of Nursing around the country and is an expert in the rules and regulations for obtaining a new or compact license for a clinician to practice. The cost of obtaining the license can be reimbursed as part of the compensation package.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-05.svg",
    "imageIcon": "assets/svg/icon_travel-reimbuse.svg",
    "title": "Travel Reimbursements",
    "text": "Nightingale provides tax-free reimbursements for transportation from your home to the remote worksite and back at the conclusion of the assignment.  This benefit is also part of the compensation package.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-06.svg",
    "imageIcon": "assets/svg/icon_first-week.svg",
    "title": "First Week Health Insurance",
    "text": "Upon employment with Nightingale Nurses, each clinician is automatically eligible for a complimentary health insurance plan. During enrollment, clinicians will have the option to buy-up dependent coverage under the plan.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-07.svg",
    "imageIcon": "assets/svg/icon_certification.svg",
    "title": "Certification and Education",
    "text": "There are a wide range of certifications that may be required based on specific job specialty. As part of the employment contract, Nightingale may reimburse for a new or expiring certification. We are connected to a network of educational providers and our credentialing specialists can assist clinicians with these certifications.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-08.svg",
    "imageIcon": "assets/svg/icon_coverage.svg",
    "title": "Professional Liability Coverage",
    "text": "Working as a healthcare professional entails significant responsibilities and potential risks. Nightingale offers professional liability protection for all clinicians at no cost to them. Additionally, we provide legal counsel to represent you in any inquest or investigation related to professional services rendered during your assignment.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-09.svg",
    "imageIcon": "assets/svg/icon_loyalty-bonus.svg",
    "title": "Loyalty Bonus",
    "text": "Our clinicians have a world of choices.  Above all, we value loyalty from those who have chosen to travel with us repeatedly.  To reflect these values, we reward loyalty with a generous bonus earned for every 1800 hours worked by our clinicians.  These bonuses are paid in the amount of a week’s pay and can be used during much deserved vacation weeks or gaps between assignments.",
    "url": ""
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-10.svg",
    "imageIcon": "assets/svg/icon_referral-bonus.svg",
    "title": "Referral Bonus",
    "text": "Personal relationships are the engine of business.  Nightingale values these relationships as our most important assets.  Most of our clinicians have been referred to Nightingale by friends and relatives of other clinicians.  We provide a bonus of $1,500 for each referral made by our clinicians once both the referring and referred clinician have completed at least a thirteen (13) week assignment.",
    "url": "/referrals"
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-11.svg",
    "imageIcon": "assets/svg/icon_free-lodging.svg",
    "title": "Free Lodging",
    "text": "As an alternative to Per Diem expense reimbursements to pay for lodging, Nightingale can also provide a free extended stay type lodging option if desired. Nightingale provides multiple housing options for our travel nurses.",
    "url": "/housing"
  },
  {
    "transparentImagePath": "assets/svg/icon_bg-12.svg",
    "imageIcon": "assets/svg/icon_family-pets.svg",
    "title": "Families and Pets",
    "text": "No need to come by yourself!  Travelers may bring family members including pets to their travel lodging. (pet restrictions may apply based on your choice of lodging).",
    "url": "/housing"
  }
]
