import {Component, OnInit} from '@angular/core';
import {
  PortalHomeJobApplicationsComponent
} from "../portal-user-home/job-applications/portal-home-job-applications.component";
import {
  PortalSectionCompletenessComponent
} from "../portal-user-home/portal-section-completness/portal-section-completeness.component";
import {RecruiterContactComponent} from "../portal-user-home/recruiter-contact/recruiter-contact.component";
import {Observable, tap} from "rxjs";
import {Result} from "../../shared/model/result";
import {Assignment, AssignmentStatus} from "../shared/types";
import {PortalUserAssignmentService} from "../shared/services/portal-user-assignment.service";
import {
  MultiLineSnackBarService
} from "../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {AsyncPipe, DatePipe, NgIf} from "@angular/common";
import {UserAssignmentComponent} from "./user-assignment/user-assignment.component";
import {MatCard} from "@angular/material/card";
import {TimesheetComponent} from "./timesheets/timesheet.component";

@Component({
  selector: 'app-portal-user-assignment',
  standalone: true,
  imports: [
    PortalHomeJobApplicationsComponent,
    PortalSectionCompletenessComponent,
    RecruiterContactComponent,
    NgIf,
    UserAssignmentComponent,
    AsyncPipe,
    DatePipe,
    MatCard,
    TimesheetComponent
  ],
  templateUrl: './portal-user-assignments.component.html',
  styleUrl: './portal-user-assignments.component.css'
})
export class PortalUserAssignmentComponent implements OnInit {
  currentAssignments: Assignment[] = [];
  lokedPendingAssignments: Assignment[] = [];

  constructor(
    private portalUserAssignmentService: PortalUserAssignmentService,
    private multiLineSnackBarService: MultiLineSnackBarService
  ) {
  }

  ngOnInit(): void {

  }

  candidateAssignmentList$ = this.portalUserAssignmentService.getCandidateAssignments().pipe(
    tap(result => {
      if (result.isSuccess) {
        let asssignments = result.value as Assignment[];
        this.currentAssignments = asssignments.filter(assignment => assignment.status === AssignmentStatus.Working || assignment.status === AssignmentStatus.EndedNormally || assignment.status === AssignmentStatus.EarlyTermination);
        this.lokedPendingAssignments = asssignments.filter(assignment => assignment.status === AssignmentStatus.Locked || assignment.status === AssignmentStatus.PendingExtension || assignment.status === AssignmentStatus.PendingNew);
      } else {
        this.multiLineSnackBarService.displayMessages(result.errorList);
      }
    })
  );

  protected readonly AssignmentStatus = AssignmentStatus;
}


