<div
  class="frame-benefits-grid-item"
  [ngClass]="color()"
  [ngStyle]="{'cursor': !!redirectUrl() ? 'pointer' : 'default'}"
  [routerLink]="redirectUrl()"
>
  <img ngSrc="{{transparentImagePath()}}" alt="image2" class="frame-benefits-grid-item-icon-transparent"
       width="1" height="1">
  <div class="frame-benefits-grid-item-inner">
    <img ngSrc="{{imageIcon()}}" alt="image2" class="frame-benefits-grid-item-icon-small"
         width="1" height="1">

    <div><h5 class="frame-benefits-grid-item-title">{{ title() }}</h5></div>
    <div><p class="frame-benefits-grid-item-text">{{ text() }} </p></div>
  </div>
</div>
