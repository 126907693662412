import { Component } from '@angular/core';
import {HeaderComponent} from "../../shared/components/header/header.component";

@Component({
  selector: 'app-ptb-layout-job-search',
  standalone: true,
    imports: [
        HeaderComponent
    ],
  templateUrl: './ptb-layout-job-search.component.html',
  styleUrl: './ptb-layout-job-search.component.css'
})
export class PtbLayoutJobSearchComponent {

}
