<div class="mobile-dialog-header">
  Add File
</div>
<mat-dialog-content
  (drop)="dropFile($event)"
  (dragover)="$event.preventDefault()"
  (dragenter)="isDraggingFile = true"
  (dragleave)="onDragLeave($event)">
  <div [ngClass]="isDraggingFile ? 'drag-over' : ''">
    <span [style.display]="isDraggingFile ? 'flex' : 'none'" style="color: white;">Drop file to upload!</span>
  </div>

  <div [formGroup]="fileForm" class="flex-column">
    <mat-form-field>
      <mat-label>File Category</mat-label>
      <mat-select #requirementClass formControlName="requirementClass" (valueChange)="requirementClassChanged()">
        @for (requirementClass of data.fileTypes; track requirementClass.credentialRequirementClass) {
          <mat-option
            [value]="requirementClass">{{ CredentialRequirementClass.getLabel(requirementClass.credentialRequirementClass) }}
          </mat-option>
        }
      </mat-select>
      @if (fileForm.controls.requirementClass.hasError('required')) {
        <mat-error>File Category is required.</mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label>File Type</mat-label>
      <mat-select formControlName="credentialType" (valueChange)="credentialTypeChanged($event)">
        @for (credentialType of requirementClass.value?.credentialOptions ?? []; track credentialType.id) {
          <mat-option [value]="credentialType">{{ credentialType.name }}</mat-option>
        }
      </mat-select>
      @if (fileForm.controls.credentialType.hasError('required')) {
        <mat-error>Credential Type is required.</mat-error>
      }
    </mat-form-field>
    <input type="file" #fileInput style="display: none;" (input)="uploadFile($event)">
    <mat-form-field>
      <mat-label>File</mat-label>
      <input matInput formControlName="fileName">
      <button mat-icon-button matIconSuffix (click)="fileInput.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
      @if (fileForm.controls.fileName.hasError('required')) {
        <mat-error>File is required.</mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label>Expiration Date</mat-label>
      <input matInput formControlName="expirationDate" [matDatepicker]="expirationDatePicker">
      <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #expirationDatePicker></mat-datepicker>
      @if (fileForm.controls.expirationDate.hasError('required')) {
        <mat-error>Expiration Date is required.</mat-error>
      }
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [disabled]="disablePage" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="saveFile()">Save</button>
</mat-dialog-actions>
