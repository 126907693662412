import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {FileType, PortalUserFileFull, PortalUserFileList} from './types';
import {catchError, Observable, of} from 'rxjs';
import {createFailedResult, Result} from '../../../../shared/model/result';

@Injectable({
  providedIn: 'root'
})
export class PortalUserFileService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public getFileTypes(): Observable<Result<FileType[]>> {
    return this.httpClient.get<Result<FileType[]>>('file/types')
      .pipe(
        catchError(e => of(createFailedResult<FileType[]>(['An error occurred, please try again.'])))
      );
  }

  public getFile(fileId: number): Observable<Result<PortalUserFileFull>> {
    return this.httpClient.get<Result<PortalUserFileFull>>(`file/${fileId}`)
      .pipe(
        catchError(e => of(createFailedResult<PortalUserFileFull>(['An error occurred, please try again.'])))
      );
  }

  public getFiles(): Observable<Result<PortalUserFileList[]>> {
    return this.httpClient.get<Result<PortalUserFileList[]>>('files')
      .pipe(
        catchError(e => of(createFailedResult<PortalUserFileList[]>(['An error occurred, please try again.'])))
      );
  }

  public addFile(newFile: PortalUserFileFull): Observable<Result> {
    return this.httpClient.post<Result>('file', newFile)
      .pipe(
        catchError(e => {
          return of(createFailedResult(['An error occurred, please ensure your file is less than 20MB and try again.']));
        })
      );
  }

  public deleteFile(fileId: number): Observable<Result> {
    return this.httpClient.delete<Result>(`file/${fileId}`)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }
}
