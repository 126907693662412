import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {BlogMockDataService} from "../../../services/blog-mock-data-service";
import {map} from "rxjs";
import {Blog, RelatedBlog} from "../../../../shared/model/types";
import {Router} from "@angular/router";

@Component({
  selector: 'app-related-post-item',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './related-post-item.component.html',
  styleUrl: './related-post-item.component.css'
})
export class RelatedPostItemComponent {
  @Input() blog: RelatedBlog;

  constructor(private router: Router) {
  }

  navigateToIndividualBlog(id: number) {
    this.router.navigate(['/individual-blog'], {
      state: {
        blogId: id
      }
    }).then();
  }
}

