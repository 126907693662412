<div class="policy-list" *ngIf="policiesToShow$ | async as policies">

  @for (item of policies; track item; let index = $index) {

    <div class="block">
      <div class="surface">
        <div class="policy-title" (click)="openPdf(item.value)">
          <h4>{{ item.label }}</h4>
        </div>
        <div class="button-view" (click)="openPdf(item.value)">

          <img ngSrc="./assets/svg/view-file.svg" alt="" width="18" height="18" priority>
          <div class="button-text">View</div>
        </div>
      </div>
    </div>


  }
  <div class="desktop-tablet">
    <div class="table-last-row">
      <app-table-footer
        [itemsPerPage]=itemsPerPage
        [totalItems]=resultSize
        [firstItem]="firstItem"
        [reload]="reload"
        [windowSize]=4
        (requestedPageEvent)="onFooterClick($event)">

      </app-table-footer>
    </div>
  </div>
  <div class="mobile">
    <div class="table-last-row">
      <app-table-footer
        [itemsPerPage]=itemsPerPage
        [totalItems]=resultSize
        [firstItem]="firstItem"
        [reload]="reload"
        [windowSize]=2
        (requestedPageEvent)="onFooterClick($event)">

      </app-table-footer>
    </div>
  </div>
</div>

