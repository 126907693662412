<div class="job-search">

  <div class="job-search-text">
    <span class="job-search-text-1">SEARCH&nbsp;</span>
    <span class="job-search-text-2">JOBS</span>
  </div>

  <form [formGroup]="searchForm">
    <div class="job-search-form">

      <div class="job-search-form-field-container">
        <app-select-chip
          formControlName="specialty"
          [options]="specialties"
          [divId]="'specialty'"
          [placeholder]="'Specialty'" style="width: 100%"
        ></app-select-chip>
      </div>

      <div class="job-search-form-field-container">
        <app-select-chip
          formControlName="state"
          [options]="states"
          [divId]="'state'"
          [placeholder]="'State'" style="width: 100%"
        ></app-select-chip>
      </div>

      <div class="job-search-button button-text" (click)="searchJobs()">
        <img ngSrc="assets/svg/search.svg" alt="" width="1" height="1" class="img">
        <div class="button-text">Search</div>
      </div>

    </div>
  </form>

</div>

