<ng-container *ngIf="benefits$ | async as benefits">
  <div class="desktop">
    <div class="frame-benefits">
      <ng-container *ngFor="let item of benefits; let i = index">
        <div class="try-flex">
          <app-benefit-reasons
            [transparentImagePath]="item.transparentImagePath"
            [imageIcon]="item.imageIcon"
            [title]="item.title"
            [text]="item.text"
            [color]="getColor(i+1, 'desktop')"
            [url]="item.url"
          >
          </app-benefit-reasons>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="tablet">
    <div class="frame-benefits">
      <ng-container *ngFor="let item of benefits; let i = index">
        <div class="try-flex">
          <app-benefit-reasons
            [transparentImagePath]="item.transparentImagePath"
            [imageIcon]="item.imageIcon"
            [title]="item.title"
            [text]="item.text"
            [color]="getColor(i+1, 'tablet')">
            [url]="item.link"
          </app-benefit-reasons>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mobile">
    <div class="frame-benefits">
      <ng-container *ngFor="let item of benefits; let i = index">
        <div class="try-flex">
          <app-benefit-reasons
            [transparentImagePath]="item.transparentImagePath"
            [imageIcon]="item.imageIcon"
            [title]="item.title"
            [text]="item.text"
            [color]="getColor(i+1, 'mobile')">
            [url]="item.link"
          </app-benefit-reasons>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
