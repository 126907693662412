import { Component } from '@angular/core';
import {
  PortalHomeJobApplicationsComponent
} from "../portal-user-home/job-applications/portal-home-job-applications.component";
import {
  PortalSectionCompletenessComponent
} from "../portal-user-home/portal-section-completness/portal-section-completeness.component";
import {RecruiterContactComponent} from "../portal-user-home/recruiter-contact/recruiter-contact.component";
import {PortalUserService} from "../shared/services/portal-user.service";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import {CommunicationConsentComponent} from "./communication-consent/communication-consent.component";

@Component({
  selector: 'app-portal-user-settings',
  standalone: true,
  imports: [
    PortalHomeJobApplicationsComponent,
    PortalSectionCompletenessComponent,
    RecruiterContactComponent,
    ChangePasswordComponent,
    CommunicationConsentComponent
  ],
  templateUrl: './portal-user-settings.component.html',
  styleUrl: './portal-user-settings.component.css'
})
export class PortalUserSettingsComponent {
  activeUser = this.userService.activeUser;

  constructor(
    private userService: PortalUserService,
  ) {
  }


}
