import { Component } from '@angular/core';

@Component({
  selector: 'app-referral-benefit',
  standalone: true,
  imports: [],
  templateUrl: './referral-benefit.component.html',
  styleUrl: './referral-benefit.component.css'
})
export class ReferralBenefitComponent {

}
