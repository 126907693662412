import {Component, computed, Signal} from '@angular/core';
import {AsyncPipe, DatePipe} from '@angular/common';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {MatCard, MatCardActions} from '@angular/material/card';
import {MatButton, MatIconButton} from '@angular/material/button';
import {WorkHistory} from '../../shared/types';
import {MatIcon} from '@angular/material/icon';
import {MatDialog} from '@angular/material/dialog';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogResult, ConfirmationDialogStyle} from '../../../shared/components/confirmation-dialog/types';
import {WorkHistoryDialogComponent} from './work-history-dialog/work-history-dialog.component';
import {ProfileService} from '../services/profile.service';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {MonthYearPipe} from "../../../shared/pipes/month-year.pipe";

@Component({
  selector: 'app-portal-user-work-history',
  standalone: true,
  imports: [
    AsyncPipe,
    MatProgressSpinner,
    MatCard,
    DatePipe,
    MatCardActions,
    MatButton,
    MatIconButton,
    MatIcon,
    MonthYearPipe,
  ],
  templateUrl: './portal-user-work-history.component.html',
  styleUrl: './portal-user-work-history.component.css'
})
export class PortalUserWorkHistoryComponent {
  workHistory: Signal<WorkHistory[]> = computed(() => this.sortWorkHistory(this.userService.activeUser()!.workHistory ?? []));

  disablePage = false;

  constructor(
    private userService: PortalUserService,
    private matDialog: MatDialog,
    private profileService: ProfileService,
    private snackBar: MultiLineSnackBarService,
  ) {
  }

  removeWorkHistory(workHistoryId?: number): void {
    // show confirmation dialog
    if (!workHistoryId) {
      this.snackBar.displayMessages(['An error occurred, please reload the page and try again.']);
      return;
    }
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Work History',
        message: 'Are you sure you want to delete this record? This action cannot be undone.',
        confirmationDialogStyle: ConfirmationDialogStyle.twoButtonDialog,
        positiveBtnText: 'Delete',
        negativeBtnText: 'Cancel',
      }
    }).afterClosed().subscribe(result => {
      if (result === ConfirmationDialogResult.positive) {
        // disable page
        this.disablePage = true;
        this.profileService.deleteWorkHistory(workHistoryId).subscribe(result => {
          if (result.isSuccess){
            const workHistory = this.userService.activeUser()?.workHistory ?? [];
            if (workHistory.find(wh => wh.id === workHistoryId)) {
              workHistory.splice(workHistory.findIndex(wh => wh.id === workHistoryId), 1);
            }
            this.userService.setWorkHistory(workHistory);
          } else {
            this.snackBar.displayMessages(result.errorList);
          }
          this.disablePage = false;
        });
      }
    });
  }

  editWorkHistory(workHistory: WorkHistory): void {
    this.matDialog.open(WorkHistoryDialogComponent, {
      autoFocus: false,
      panelClass: ['full-screen-mobile-dialog'],
      data: workHistory,
    });
  }

  addWorkHistory(): void {
    this.matDialog.open(WorkHistoryDialogComponent, {
      autoFocus: false,
      panelClass: ['full-screen-mobile-dialog'],
      data: null,
    });
  }

  private sortWorkHistory(workHistory: WorkHistory[]): WorkHistory[] {
    return workHistory.sort((a, b) => {
      if (a.fromDate === null) return -1; // a comes first if its date is null
      if (b.fromDate === null) return 1;  // b comes first if its date is null
      // If neither date is null, sort in descending order
      return (b.fromDate?.getTime() ?? 0) - (a.fromDate?.getTime() ?? 0);
    });
  }



}
