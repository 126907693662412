<div class="comment">
  <div class="inside-comment-typography"><p>{{ comment }}</p></div>
  <div class="nurse-signature">
    <div class="nurse-name">
      <div class="nurse-name-typography">{{ name }}</div>
      <div class="star-frame">
        <div class="star" [ngStyle]="{'display': starCount>0? '' : 'none'}">
          <img ngSrc="../../../../../assets/svg/star.svg" alt="image2"
               width="14" height="14">
        </div>
        <div class="star" [ngStyle]="{'display': starCount>1? '' : 'none'}">
          <img ngSrc="../../../../../assets/svg/star.svg" alt="image2"
               width="14" height="14">
        </div>
        <div class="star" [ngStyle]="{'display': starCount>2? '' : 'none'}">
          <img ngSrc="../../../../../assets/svg/star.svg" alt="image2"
               width="14" height="14">
        </div>
        <div class="star" [ngStyle]="{'display': starCount>3? '' : 'none'}">
          <img ngSrc="../../../../../assets/svg/star.svg" alt="image2"
               width="14" height="14">
        </div>
        <div class="star" [ngStyle]="{'display': starCount>4? '' : 'none'}">
          <img ngSrc="../../../../../assets/svg/star.svg" alt="image2"
               width="14" height="14">
        </div>
      </div>
    </div>
    <div class="nurse-photo">
      <img ngSrc="{{imagePath}}" alt="image2"
           width="48" height="48">
    </div>

  </div>

</div>
