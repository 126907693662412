import {inject} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import {catchError, concatMap, map, Observable, of, switchMap, tap} from 'rxjs';
import {PortalUserService} from '../../portal/shared/services/portal-user.service';
import {environment} from '../../../environments/environment';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const portalUserService = inject(PortalUserService);

  const url = environment.apiUrl.endsWith('/')
    ? `${environment.apiUrl}${req.url}`
    : `${environment.apiUrl}/${req.url}`;

  const tokenData = portalUserService.activeToken;
  let updatedRequest: HttpRequest<any>;
  if (req.url.includes('assets')) {
    updatedRequest = req;
  } else {
    updatedRequest = req.clone({
      url,
      headers: req.headers.set('token', !!tokenData()?.token ? tokenData()!.token : '')
    });
    if (!url.includes('file/upload')) {
      updatedRequest.headers.set('Content-Type', 'application/json');
    }
  }


  return next(updatedRequest)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        // If the error status is 401 (Unauthorized), try to refresh the token
        if (err.status === 401 &&
          !updatedRequest.url.includes('login') &&
          !updatedRequest.url.includes('register') &&
          !updatedRequest.url.includes('logout')) {
          return portalUserService.tryRefreshToken()
            .pipe(
              concatMap(() => {
                // After refreshing the token, clone the original request and set the new token in the header
                const newTokenData = portalUserService.activeToken;
                const newRequest = req.clone({
                  url,
                  headers: req.headers.set('token', !!newTokenData()?.token ? newTokenData()!.token : '')
                });

                // Retry the request with the new token
                return next(newRequest);
              }),
            );
        }

        // If the error is not 401, or the URLs include 'login', 'register', or 'logout', rethrow error to be handled elsewhere
        throw err;
      })
    );
}
