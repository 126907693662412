<div class="housing-header-container">

  <app-header></app-header>

  <div class="housing-header flex-column">
    <h2>
      <div class="wulkandisplay-regularitalic"> Housing</div>
      <div class="manrope-extralight">Information</div>
    </h2>
    <div class="line-green">
    </div>
  </div>
</div>
