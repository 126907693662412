import {Component} from '@angular/core';
import {HeaderComponent} from '../../shared/components/header/header.component';
import {ContactHeaderComponent} from "./contact-header/contact-header.component";
import {ConnectOnlineComponent} from "./connect-online/connect-online.component";
import {FooterComponent} from "../../shared/components/footer/footer/footer.component";
import {ActivatedRoute} from "@angular/router";
import {ApplyTodayComponent} from "../../shared/components/apply-today/apply-today.component";
import {JointCommissionComponent} from './joint-commission/joint-commission.component';
import {SeoUtilsService} from '../../shared/services/seo-utils.service';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    HeaderComponent,
    ContactHeaderComponent,
    ConnectOnlineComponent,
    FooterComponent,
    ApplyTodayComponent,
    JointCommissionComponent
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private seoUtilsService: SeoUtilsService,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'Get In Touch With Nightingale',
      'Have questions about our travel nursing partnership? Get in touch with us today.',
      'contact-nightingale'
    );
  }
}
