<div class="share-the-love-container">

  <div class="share-the-love-content">
    <h3>
      <div class="share-the-love">
        Share the love and
      </div>
      <div class="reap-the-benefit">
        reap the benefit!
      </div>
    </h3>
    <div class="nursing-lifestyle ">
      You know how much you love Nightingale's Travel Nursing lifestyle. And, chances are, you probably know a few
      qualified nurses who would also enjoy it. So why not refer them to us? We’ll pay you a cool $1,500 for EACH RN
      you refer, as soon as they complete their first 13-week assignment or more. It’s that easy.
    </div>
  </div>

  <div class="blue-line">
  </div>

</div>

