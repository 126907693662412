export enum ConfirmationDialogStyle {
  twoButtonDialog,
  threeButtonDialog
}

export enum ConfirmationDialogResult {
  positive,
  negative,
  neutral
}
