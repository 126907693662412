import {Component, ElementRef, Inject, TemplateRef, ViewChild} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import {
  MultiLineSnackBarService
} from '../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {ProfileService} from '../../services/profile.service';
import {PortalUserService} from '../../../shared/services/portal-user.service';
import {AsyncPipe, NgClass, NgTemplateOutlet} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {MatCard, MatCardContent} from '@angular/material/card';
import {MatDivider} from '@angular/material/divider';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatOption} from '@angular/material/autocomplete';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatRipple} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {ChecklistCategory, ChecklistTemplate, SkillsChecklist} from '../../../shared/types';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {of} from 'rxjs';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatTooltip} from '@angular/material/tooltip';
import {
  ConfirmationDialogComponent
} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {
  ConfirmationDialogResult,
  ConfirmationDialogStyle
} from '../../../../shared/components/confirmation-dialog/types';

@Component({
  selector: 'app-skills-checklist-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButton,
    MatButtonToggle,
    MatButtonToggleGroup,
    MatCard,
    MatCardContent,
    MatDivider,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatLabel,
    MatOption,
    MatProgressSpinner,
    MatRipple,
    MatSelect,
    NgClass,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    MatTabGroup,
    MatTab,
    NgTemplateOutlet,
    MatTooltip
  ],
  templateUrl: './skills-checklist-dialog.component.html',
  styleUrl: './skills-checklist-dialog.component.css'
})
export class SkillsChecklistDialogComponent {
  @ViewChild('helpTemplate') helpTemplate: TemplateRef<any>;

  templatesResult$ = !!this.data
    ? of({isSuccess: true, value: [this.data], errorList: [], infoList: [], warningList: []})
    : this.profileService.getChecklistTemplates();

  specialtyForm = this.formBuilder.group({
    specialty: [{value: this.data?.specialty, disabled: !!this.data}],
  });
  disablePage = false;
  disableButtons = false;

  chosenSpecialty: ChecklistTemplate | null = this.data ?? null;
  chosenCategory: ChecklistCategory | null = this.chosenSpecialty?.categories[0] ?? null;
  categoryIndex?: number = !!this.chosenCategory ? 0 : undefined;
  activeTabIndex = 0;
  errors: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChecklistTemplate | null,
    private formBuilder: FormBuilder,
    private snackBar: MultiLineSnackBarService,
    private profileService: ProfileService,
    private userService: PortalUserService,
    private matDialogRef: MatDialogRef<SkillsChecklistDialogComponent>,
    private bottomSheet: MatBottomSheet,
    private matDialog: MatDialog,
  ) {
  }

  displayHelpBottomSheet(): void {
    this.bottomSheet.open(this.helpTemplate);
  }

  dismissHelpSheet(): void {
    this.bottomSheet.dismiss();
  }

  specialtyChanged(value: ChecklistTemplate): void {
    this.chosenSpecialty = JSON.parse(JSON.stringify(value));
    this.chosenCategory = this.chosenSpecialty?.categories[0] ?? null;
    this.categoryIndex = !!this.chosenCategory ? 0 : undefined;
    this.activeTabIndex = 1;
  }

  categoryChanged(index: number, value: ChecklistCategory): void {
    this.chosenCategory = value;
    this.categoryIndex = index;
    this.activeTabIndex = 1;
    setTimeout(() => {
      this.scrollSkillsContainerToTop();
    }, 100);
  }

  previousSection(): void {
    if (this.categoryIndex !== undefined && this.categoryIndex !== 0) {
      this.chosenCategory = this.chosenSpecialty!.categories[this.categoryIndex - 1];
      this.categoryIndex--;
      this.activeTabIndex = 1;
      setTimeout(() => {
        this.scrollSkillsContainerToTop();
      }, 100);
    }
  }

  nextSection(): void {
    if (this.categoryIndex !== undefined && this.categoryIndex !== this.chosenSpecialty!.categories.length - 1) {
      this.chosenCategory = this.chosenSpecialty!.categories[this.categoryIndex + 1];
      this.categoryIndex++;
      this.activeTabIndex = 1;
      setTimeout(() => {
        this.scrollSkillsContainerToTop();
      }, 100);
    }
  }

  close(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Close Skills Checklist',
        message: 'Are you sure you want to close? All changes will be lost.',
        confirmationDialogStyle: ConfirmationDialogStyle.twoButtonDialog,
        positiveBtnText: 'Stay',
        negativeBtnText: 'Close',
      }
    }).afterClosed().subscribe((result: ConfirmationDialogResult) => {
      if (result === ConfirmationDialogResult.negative) {
        this.matDialogRef.close();
      }
    });
  }

  saveSkillsChecklist(): void {
    const errorList: string[] = [];
    for (const category of this.chosenSpecialty!.categories) {
      for (const skill of category.skills) {
        if (skill.skillLevel === undefined || skill.skillLevel === null) {
          errorList.push(`${category.categoryName} - ${skill.skillName} is required.`);
        }
      }
    }
    if (errorList.length > 0) {
      this.snackBar.displayMessages(
        [
          'Please fill out all skills before saving.',
          'Check the Categories list for sections that are incomplete.'
        ]
      );
      return;
    }

    // disable form and buttons
    this.disablePage = true;
    // send to API for saving
    const skillsChecklist = {
      id: undefined,
      createdDttm: new Date(),
      checklistTemplate: this.chosenSpecialty
    } as SkillsChecklist;
    this.profileService.saveSkillsChecklist(skillsChecklist)
      .subscribe(result => {
        if (result.isSuccess) {
          const skillsChecklists = this.userService.activeUser()?.skillsChecklists ?? [];
          const newChecklist = result.value as SkillsChecklist;
          if (skillsChecklists.find(sc => sc.checklistTemplate.specialty === newChecklist.checklistTemplate.specialty)) {
            skillsChecklists.splice(skillsChecklists.findIndex(sc => sc.checklistTemplate.specialty === newChecklist.checklistTemplate.specialty), 1, newChecklist);
          } else {
            skillsChecklists.push(newChecklist);
          }
          this.userService.setSkillsChecklists(skillsChecklists);
          this.matDialogRef.close();
        } else {
          this.snackBar.displayMessages(result.errorList);
        }
        this.disablePage = false;
      });
  }

  hasCompletedAllSkills(category: ChecklistCategory): boolean {
    const missingIndex = category.skills.findIndex(skill => skill.skillLevel === undefined || skill.skillLevel === null);
    return missingIndex === -1;
  }

  private scrollSkillsContainerToTop(): void {
    document.querySelectorAll('.skills-list-container')
      .forEach(e => e.scrollTo({top: 0, behavior: 'smooth'}));
  }
}
