import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MatDialogClose} from "@angular/material/dialog";
import {
  Assignment,
  CandidatePortalReceivedTimesheet,
  CandidatePortalReceivedTimesheetFile,
  CandidatePortalReceivedTimesheetFilesByWeekEnding
} from "../../shared/types";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatError, MatFormField, MatSuffix} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInput, MatLabel} from "@angular/material/input";
import {
  MultiLineSnackBarService
} from "../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {FileService} from "../../shared/services/file.service";
import {catchError, EMPTY, map, Observable, Subscription} from "rxjs";
import {PortalUserAssignmentService} from "../../shared/services/portal-user-assignment.service";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FileViewData} from "../../shared/components/file-viewer-dialog/types/types";
import {FileMimeTypes} from "../../shared/components/file-viewer-dialog/types/mime-types";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-timesheets',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatDatepickerInput,
    MatInput,
    MatIcon,
    MatDatepickerToggle,
    DatePipe,
    MatButton,
    MatIconButton,
    MatDatepicker,
    NgClass,
    NgIf,
    MatDialogClose,
    MatLabel,
    MatError,
    NgForOf,
    MatSuffix,
    MatTooltip,
  ],
  templateUrl: './timesheet.component.html',
  styleUrl: './timesheet.component.css'
})
export class TimesheetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() assignment: Assignment;
  @Input() assignmentPayrollId: number;
  disablePage = false;
  isLoading = true;
  isDraggingFile = false;
  currentDragGroup: string | null = null;

  allAssignmentWeekends: Date[] = [];
  groupedRecivedFiles: CandidatePortalReceivedTimesheetFilesByWeekEnding  [] = [];
  candidatePortalReceivedTimesheetFiles: CandidatePortalReceivedTimesheetFile[] = [];
  getCandidatePortalReceivedTimesheetFilesSubscription: Subscription

  constructor(private snackBar: MultiLineSnackBarService,
              private portalUserAssignmentService: PortalUserAssignmentService,
              private multiLineSnackBarService: MultiLineSnackBarService,
              private fileService: FileService) {
  }

  ngOnInit(): void {
    this.loadTimesheetFiles();
    this.allAssignmentWeekends = this.portalUserAssignmentService.getAssignmentWeekEnds(this.assignment.startDate, this.assignment.actualEndDate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData']) {
      this.assignment = changes['inputData'].currentValue;
    }
  }

  ngOnDestroy(): void {
    this.getCandidatePortalReceivedTimesheetFilesSubscription?.unsubscribe();
  }

  loadTimesheetFiles(): void {
    this.getCandidatePortalReceivedTimesheetFilesSubscription = this.portalUserAssignmentService.getCandidatePortalReceivedTimesheetFiles(this.assignmentPayrollId).subscribe(result => {
      if (result.isSuccess) {
        this.candidatePortalReceivedTimesheetFiles = result.value as CandidatePortalReceivedTimesheetFile[];
        let files = [...this.candidatePortalReceivedTimesheetFiles];
        this.allAssignmentWeekends.forEach(week => {
          if (!files.some(file => new Date(file.weekEnding).toISOString() === week.toISOString())) {
            this.candidatePortalReceivedTimesheetFiles.push({
              candidateId: this.assignment.candidateId,
              assignmentPayrollId: this.assignment.assignmentPayrollId,
              weekEnding: week,
              fileName: 'No File Uploaded',
              filePath: '',
              id: 0,
              createdDttm: new Date()
            });
          }
        });
        this.groupedRecivedFiles = this.SortAndGroupReceivedTimesheetFiles(this.candidatePortalReceivedTimesheetFiles);
        this.groupedRecivedFiles.sort((a, b) => new Date(b.weekEnding).getTime() - new Date(a.weekEnding).getTime());
      } else {
        this.multiLineSnackBarService.displayMessages(result.errorList);
      }
      this.isLoading = false;

    });
  }

  uploadFile(changeEvent: Event, weekEnding: string): void {
    const changeEventTarget = changeEvent.target as HTMLInputElement;
    const file = changeEventTarget?.files?.item(0);
    if (!file) {
      return;
    }

    this.handleUploadedFile(file, weekEnding);
  }

  private handleUploadedFile(file: File, weekEnding: string): void {
    if (file.size > 20971520) {
      this.snackBar.displayMessages(['File size must be less than 20MB.']);
      return;
    }

    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const name = fileName.split('.').slice(0, -1).join('.');
    const reader = new FileReader();

    reader.onload = () => {
      const readerResult = reader.result as string;
      const base64 = readerResult.split(',')[1];
      const weekEndingDate = weekEnding;
      const newFile: CandidatePortalReceivedTimesheet = {
        candidateId: this.assignment.candidateId,
        assignmentPayrollId: this.assignment.assignmentPayrollId,
        weekEnding: weekEndingDate ? new Date(weekEndingDate) : undefined,
        fileName: name,
        fileExtension: fileExtension!,
        fileContent: base64,
      };
      this.fileService.addFile(newFile).subscribe(result => {
        if (result.isSuccess) {
          this.snackBar.displayMessages(['File uploaded successfully.']);
          this.loadTimesheetFiles();
        } else {
          this.snackBar.displayMessages(result.errorList);
          this.disablePage = false;
        }
      });
    };

    reader.readAsDataURL(file);
  }


  dropFile(event: DragEvent, weekEnding: string): void {
    if (this.isLoading) {
      return;
    }

    event.preventDefault();
    this.isDraggingFile = false;

    if (event.dataTransfer?.files.length !== 1) {
      this.snackBar.displayMessages(['Please drop only one file at a time.']);
      return;
    }

    const file = event.dataTransfer?.files[0];
    this.handleUploadedFile(file, weekEnding);
  }

  onDragLeave(event: DragEvent) {
    if ((event.currentTarget! as Node).contains(event.relatedTarget as Node)) {
      return;
    }
    this.isDraggingFile = false;
    this.currentDragGroup = null;
  }

  onDragEnter(event: DragEvent, weekEnding: string): void {
    this.isDraggingFile = true;
    this.currentDragGroup = weekEnding;
  }

  viewFile(fileId: number) {
    if (!fileId) {
      return;
    }

    const fileData$: Observable<FileViewData> = this.fileService.getReceivedtimesheetFile(fileId)
      .pipe(
        map((fileResult) => {
          return {
            fileName: fileResult.value!.fileName,
            fileExtension: fileResult.value!.fileExtension,
            base64FileContents: fileResult.value!.fileContent
          };
        }),
        catchError((error) => {
          this.multiLineSnackBarService.displayMessages(['Unable to open the file.']);
          return EMPTY;
        })
      );

    fileData$.subscribe({
      next: (file) => {
        const fileExt = file.fileExtension.startsWith('.') ? file.fileExtension : `.${file.fileExtension}`;
        let contentType = FileMimeTypes.mimetypes.get(fileExt) ?? 'application/octet-stream';
        const fileBytes = this.fileService.base64ToArrayBuffer(file.base64FileContents);
        let fileData = new Blob([fileBytes], {type: contentType});
        const fileUrl = URL.createObjectURL(fileData);
        window.open(fileUrl);
      }
    });
    return false;
  }

  SortAndGroupReceivedTimesheetFiles(files: CandidatePortalReceivedTimesheetFile[]): CandidatePortalReceivedTimesheetFilesByWeekEnding[] {
    const grouped: { [key: string]: CandidatePortalReceivedTimesheetFile[] } = {};

    files.forEach(file => {
      let key = '';
      if (file.id === 0) {
        key = file.weekEnding.toISOString().split('T')[0];
      } else {
        key = file.weekEnding.toString().split('T')[0];
      }
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(file);
    });

    // Sort the array by weekEnding date
    return Object.keys(grouped).map(key => ({
      weekEnding: key,
      files: grouped[key]
    }));
  }

}
