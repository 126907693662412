import {Component} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatCard} from "@angular/material/card";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {PortalUserFileService} from "../../../portal-user-profile/portal-user-files/shared/portal-user-file.service";
import {
  MultiLineSnackBarService
} from "../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {ProfileService} from "../../../portal-user-profile/services/profile.service";
import {PortalUserService} from "../../../shared/services/portal-user.service";
import {FileInfo} from "../../../shared/types";
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-load-resume-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatCard,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatDialogTitle,
    MatProgressBar,
    MatDialogActions,
    MatDialogContent,
    MatDialogClose,
    MatIconButton,
    MatIcon,
    MatSuffix,



  ],
  templateUrl: './load-resume-dialog.component.html',
  styleUrl: './load-resume-dialog.component.css'
})
export class LoadResumeDialogComponent {
  fileForm = this.formBuilder.group({
    fileName: [{value: '', disabled: true}, [Validators.required]],
    fileExtension: ['', [Validators.required]],
    base64FileContents: ['', [Validators.required]],
    fileDate: [{value: undefined as Date | undefined, disabled: true}],
  });
  isLoadingFile = false;
  user = this.portalUserService.activeUser();

  constructor(
    private snackBar: MultiLineSnackBarService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private portalUserService: PortalUserService,
    private dialogRef: MatDialogRef<LoadResumeDialogComponent>,
  ) {
  }

  uploadFile(changeEvent: Event): void {
    const changeEventTarget = changeEvent.target as HTMLInputElement;
    const file = changeEventTarget?.files?.item(0);
    if (!file) {
      return;
    }

    this.handleUploadedFile(file);
    changeEventTarget.value = '';

  }

  private handleUploadedFile(file: File): void {
    if (file.size > 20971520) {
      this.snackBar.displayMessages(['File size must be less than 20MB.']);
      return;
    }

    this.isLoadingFile = true;
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const name = fileName.split('.').slice(0, -1).join('.');
    const lastModifiedDate = new Date(file.lastModified);
    const reader = new FileReader();

    reader.onload = () => {
      const readerResult = reader.result as string;
      const base64 = readerResult.split(',')[1];
      this.fileForm.controls.fileName.patchValue(name);
      this.fileForm.controls.fileExtension.patchValue(fileExtension!);
      this.fileForm.controls.base64FileContents.patchValue(base64);
      this.fileForm.controls.fileDate.patchValue(lastModifiedDate);
      const newFile: FileInfo = {
        fileName: name,
        fileExtension: fileExtension!,
        base64FileContents: base64,
        lastModifiedDate: lastModifiedDate,
      }

      this.profileService.loadResume(newFile).subscribe(result => {
        if (result.isSuccess && result.value) {
          let resumeData =  result.value;

          //not coming from resume but show in resume UI
          resumeData.professionalInfo.specialty = this.user?.professionalInfo?.specialty;
          this.profileService.saveResumeData(result.value);
          this.isLoadingFile = false;
          this.dialogRef.close(result);
        } else {
          this.snackBar.displayMessages(['Error parsing resume.']);
          this.isLoadingFile = false;
        }

      });


    }
    reader.readAsDataURL(file);
  }


}
