import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {PortalUserReferral} from '../types';
import {catchError, Observable, of} from 'rxjs';
import {createFailedResult, Result} from '../../../../shared/model/result';

@Injectable({
  providedIn: 'root'
})
export class PortalUserReferralService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public submitReferral(referral: PortalUserReferral): Observable<Result> {
    return this.httpClient.post<Result>('contact/referral', referral)
      .pipe(
        catchError(() => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }
}
