import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ContactInquiry} from "../../../../shared/model/types";
import {MatFormField, MatInput, MatLabel} from "@angular/material/input";
import {NgClass} from "@angular/common";
import {PhoneValidator} from "../../../../shared/validators/PhoneValidator";
import {ContactService} from "../../../../shared/services/contact.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-connect-online-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInput,
    MatLabel,
    MatFormField,
    NgClass
  ],
  templateUrl: './connect-online-form.component.html',
  styleUrl: './connect-online-form.component.css'
})
export class ConnectOnlineFormComponent {

  connectOnlineForm = new FormGroup({
    firstName: this.formBuilder.control('',Validators.required),
    lastName: this.formBuilder.control('',Validators.required),
    email: this.formBuilder.control('',[Validators.required,Validators.email]),
    phone: this.formBuilder.control('',[Validators.required,PhoneValidator]),
    inquiry: this.formBuilder.control('',Validators.required),
  });


  constructor(private formBuilder: FormBuilder,private contactService: ContactService, private router: Router) {
  }

  getClass(controlName: string) {
    // @ts-ignore
    return this.connectOnlineForm.controls[controlName].touched && !this.connectOnlineForm.controls[controlName].valid ? 'error-label' : 'no-error-label';
  }

  onFocusOutEvent(e: any) {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  }

  submitInquiry() {
    this.connectOnlineForm.markAllAsTouched();

    if (!this.connectOnlineForm.valid) {
      return;
    }

    const contactInquiry: ContactInquiry =
      ({
        firstName: this.connectOnlineForm.controls['firstName'].value as string,
        lastName: this.connectOnlineForm.controls['lastName'].value as string,
        email: this.connectOnlineForm.controls['email'].value as string,
        phone: this.connectOnlineForm.controls['phone'].value as string,
        inquiry: this.connectOnlineForm.controls['inquiry'].value as string,
      });

    this.contactService.submitContactForm(contactInquiry).subscribe(result => {
      this.router.navigate(['/thank-you'], {
        state: {
          source: 'contact',
        }
      }).then();
    });
    }
}
