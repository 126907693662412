[
  "ANTE",
  "CASE MGR",
  "CATH LAB",
  "CATH LAB TECH",
  "CCU",
  "Conscious Sedation",
  "CRNA",
  "CST",
  "CSTFA",
  "CT",
  "CVICU",
  "CVOR",
  "CVORT",
  "Cytotechnologist",
  "DIALYSIS",
  "DIALYSIS TECH",
  "DIETICIAN",
  "DOSIMETRIST",
  "ECHO TECH",
  "EEG",
  "ENDO",
  "ENDO TECH",
  "EP LAB",
  "EP Tech",
  "ER",
  "ER HOLD",
  "ER PEDS",
  "HISTO TECH",
  "HOSPICE",
  "ICU",
  "ICU FLOAT",
  "IMCU",
  "IR",
  "IR TECH",
  "L&D",
  "LPN",
  "LPN DIALYSIS",
  "LPN MS",
  "LTAC",
  "LTC",
  "MAMMO TECH",
  "MB",
  "MEDICAL LAB TECH",
  "MEDICAL TECHNOLOGIST",
  "MICU",
  "MLT",
  "MRI",
  "MS",
  "MS FLOAT",
  "MS TELE",
  "MS TELE FLOAT",
  "MULTI-MODALITY TECH",
  "NEURO",
  "NEURO ICU",
  "NEURO MS",
  "NEURO OR",
  "NEURO PCU",
  "NEURO TELE",
  "NICU",
  "NUC MED TECH",
  "NURSE PRACT",
  "ONCOLOGY",
  "OR",
  "OR MGR",
  "OR PEDS",
  "ORT",
  "OT",
  "PACU",
  "Pathology Assistant",
  "PATIENT OBSERVER",
  "PCT",
  "PCU",
  "PCVICU",
  "PEDS",
  "PEDS ONCOLOGY",
  "PEDS REHAB",
  "PEDS STEPDOWN",
  "PERFUSIONIST",
  "PHARMACIST",
  "Pharmacy Tech",
  "PHLEBOTOMIST",
  "Physicist",
  "PICU",
  "PSYCH",
  "PSYCH TECH",
  "PT",
  "RAD",
  "RAD TECH",
  "Radiation Therapist",
  "RADIOLOGY",
  "REHAB",
  "Respiratory Therapist",
  "RNFA",
  "SICU",
  "SOCIAL WORKER",
  "SPEC PROC TECH",
  "STEPDOWN",
  "STERILE TECH",
  "SUPERVISOR",
  "TELE",
  "ULTRASOUND TECH",
  "VACCINATOR",
  "WOUND CARE",
  "X-RAY TECH"
]
