<div class="join-container">
  <div class="join-header">
    <div>
      <div class="join-header-text1">Are you ready to shift your nursing</div>
      <div class="join-header-text2">career into high gear?</div>
      <div class="join-header-text1">Nightingale can take you there.</div>
    </div>

    <div class="line-green"></div>

    <div class="join-header-subtext">
      We’re the best partner an ambitious traveling clinician can have. Nightingale finds you the assignments where your skills
      and talents will be appreciated most.
    </div>
  </div>

  <div class="join-image-container"></div>


  <div class="join-background">

    <div class="join-steps">

      <div class="join-step">
        <img ngSrc="./assets/svg/join-step-1.svg" alt="" width="1" height="1" class="join-flow-img">
        <div class="join-step-text">
          <div class="join-step-text1">Build your profile</div>
          <div class="join-step-text2">Create a profile to showcase your skills, experience, and education.</div>
        </div>
      </div>

      <div class="join-step">
        <img ngSrc="./assets/svg/join-step-2.svg" alt="" width="1" height="1" class="join-flow-img">
        <div class="join-step-text">
          <div class="join-step-text1">Talk with recruiters</div>
          <div class="join-step-text2">Our recruiters are very responsive and will help you to navigate the job search process.
          </div>
        </div>
      </div>

      <div class="join-step">
        <img ngSrc="./assets/svg/join-step-3.svg" alt="" width="1" height="1" class="join-flow-img">
        <div class="join-step-text">
          <div class="join-step-text1">Get multiple offers</div>
          <div class="join-step-text2">We provide pay transparency so you can confidently pick the best offer.</div>
        </div>
      </div>
    </div>
  </div>

</div>
