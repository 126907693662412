<div class="faq-list">
  <app-faq-item-1></app-faq-item-1>
  <div class="faq-divider"></div>
  <app-faq-item-2></app-faq-item-2>
  <div class="faq-divider"></div>
  <app-faq-item-3></app-faq-item-3>
  <div class="faq-divider"></div>
  <app-faq-item-4></app-faq-item-4>
  <div class="faq-divider"></div>
  <app-faq-item-5></app-faq-item-5>
  <div class="faq-divider"></div>
  <app-faq-item-6></app-faq-item-6>
  <div class="faq-divider"></div>
  <app-faq-item-7></app-faq-item-7>
  <div class="faq-divider"></div>
  <app-faq-item-8></app-faq-item-8>
  <div class="faq-divider"></div>
  <app-faq-item-9></app-faq-item-9>
  <div class="faq-divider"></div>
  <app-faq-item-10></app-faq-item-10>
  <div class="faq-divider"></div>
  <app-faq-item-11></app-faq-item-11>
  <div class="faq-divider"></div>
  <app-faq-item-12></app-faq-item-12>

<!--  @for(item of questions; track item; let index = $index){-->

<!--    <app-faq-item-->
<!--      [question]="item.question"-->
<!--      [answer]="item.answer"-->
<!--      [order]= "index">-->
<!--    </app-faq-item>-->
<!--    <div class="faq-divider"></div>-->
<!--  }-->
</div>
