import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {SelectOption} from "../../../shared/model/types";
import policies from "../../../../assets/data/company-policies.json";



@Injectable({
  providedIn: 'root'
})
export class PolicyMockDataService {
  getPolicies(): Observable<SelectOption[]> {
    return of(policies);
  }

}
