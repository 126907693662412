<div class="frame-wings">
  <div class="frame-wings-explanation1">
    <div class="frame-wings-explanation1-typography">
      <h3>Nightingale doesn’t just connect you with the very best in travel nursing opportunities. Our WINGS help your career take flight.</h3>
    </div>
    <div class="line-green"></div>
  </div>
  <div class="frame-wings-explanation2-typography">
        <p>WINGS stands for “Wherever I Nurse, Guaranteed Support,” and it’s not just our promise, it’s also our package of premium benefits.<br>
Twelve excellent reasons to become a Nightingale Nurse.</p></div>
</div>
