import {Component, Input} from '@angular/core';
import {NgForOf, NgOptimizedImage} from "@angular/common";
import {Blog} from "../../../shared/model/types";

@Component({
  selector: 'app-body-individual-blog',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgForOf
  ],
  templateUrl: './body-individual-blog.component.html',
  styleUrl: './body-individual-blog.component.css'
})
export class BodyIndividualBlogComponent {
  @Input() blog: Blog;
  formatDate(date:Date)
  {
    return `${date.getDate()} ${date.toLocaleString('default', {month: 'short'})} ${date.getFullYear()}`;
  }
}
