import {Component, Inject} from '@angular/core';
import {MatCard, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatError, MatFormField, MatLabel, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {NgTemplateOutlet} from "@angular/common";
import {FormArray, FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {CatalogsService} from "../../../../../shared/services/catalogs.service";
import {PortalUserService} from "../../../../shared/services/portal-user.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {PortalUserProfile, ProfileEmail, ProfilePersonalInfo, ProfilePhone} from "../../../../shared/types";
import {ProfileService} from "../../../../portal-user-profile/services/profile.service";
import {
  MultiLineSnackBarService
} from "../../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-personal-info-editor',
  standalone: true,
    imports: [
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatCheckbox,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatError,
        MatFormField,
        MatIcon,
        MatIconButton,
        MatInput,
        MatLabel,
        MatOption,
        MatPrefix,
        MatSelect,
        MatSuffix,
        MatTab,
        MatTabGroup,
        NgTemplateOutlet,
        ReactiveFormsModule
    ],
  templateUrl: './personal-info-editor.component.html',
  styleUrl: './personal-info-editor.component.css'
})
export class PersonalInfoEditorComponent {
  states = this.catalogService.getStates();
  disablePage = false;
  user = this.userService.activeUser()?.personalInfo;
  emailsFormArray = this.formBuilder.array([]) as FormArray;
  phonesFormArray = this.formBuilder.array([]) as FormArray;
  personalInfo = this.profileService.getResumeData()?.personalInfo;


  profileForm = this.formBuilder.group({
    firstName: [this.personalInfo?.firstName, [Validators.required]],
    middleName: [this.personalInfo?.middleName],
    lastName: [this.personalInfo?.lastName, [Validators.required]],
    dateOfBirth: [this.personalInfo?.dateOfBirth],
    gender: [this.personalInfo?.gender],

    emails: this.emailsFormArray,
    phones: this.phonesFormArray,
    address1: [this.personalInfo?.address1],
    address2: [this.personalInfo?.address2],
    city: [this.personalInfo?.city],
    state: [this.personalInfo?.state],
    zip: [this.personalInfo?.zip],

  });
  constructor(
    private catalogService: CatalogsService,
    private userService: PortalUserService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:PortalUserProfile,
    private profileService: ProfileService,
    private multiLineSnackBar: MultiLineSnackBarService,
  ) {
    this.personalInfo?.emails?.forEach(email => {
      this.addEmail(email);
    });
    this.personalInfo?.phones?.forEach(phone => {
      this.addPhone(phone);
    });
  }




  addEmail(email?: ProfileEmail): void {
    const emailForm = this.formBuilder.group({
      id: [email?.id],
      email: [email?.email, [Validators.required, Validators.email]],
      isPrimary: [email?.isPrimary ?? false]
    });
    this.emailsFormArray.insert(0, emailForm, {emitEvent: false});
  }
  removeEmail(index: number): void {
    this.emailsFormArray.removeAt(index);
  }

  addPhone(phone?: ProfilePhone): void {
    const phoneForm = this.formBuilder.group({
      id: [phone?.id],
      phoneType: [phone?.phoneType ?? 'cell'],
      countryCode: [phone?.countryCode ?? '1'],
      phoneNumber: [phone?.phoneNumber, [Validators.required]],
      isPrimary: [phone?.isPrimary ?? false]
    });
    this.phonesFormArray.insert(0, phoneForm, {emitEvent: false});
  }

  removePhone(index: number): void {
    this.phonesFormArray.removeAt(index);
  }
  addActive(selectedIndex: number | null) {
    switch (selectedIndex) {
      case 0:
        this.addEmail();
        break;
      case 1:
        this.addPhone();
        break;
    }
  }



  save():Observable<boolean>{
    this.profileForm.markAllAsTouched();
    if (this.profileForm.invalid) {
      this.multiLineSnackBar.displayMessages(['Please correct the errors and try again.']);
      return of(false);
    }
    this.disablePage = true;
    const personalInfo = this.profileForm.value as ProfilePersonalInfo;
    const resumeData = this.profileService.getResumeData();
    resumeData.personalInfo.firstName = personalInfo.firstName;
    resumeData.personalInfo.middleName = personalInfo.middleName;
    resumeData.personalInfo.lastName = personalInfo.lastName;
    resumeData.personalInfo.dateOfBirth = personalInfo.dateOfBirth;
    resumeData.personalInfo.address1 = personalInfo.address1;
    resumeData.personalInfo.address2 = personalInfo.address2;
    resumeData.personalInfo.city = personalInfo.city;
    resumeData.personalInfo.state = personalInfo.state;
    resumeData.personalInfo.zip = personalInfo.zip;
    resumeData.personalInfo.emails = personalInfo.emails;
    resumeData.personalInfo.phones = personalInfo.phones;
    resumeData.personalInfo.gender = personalInfo.gender;
    resumeData.personalInfo.emergencyContactEmail = personalInfo.emergencyContactEmail;




    this.profileService.saveResumeData(resumeData);
    return of(true);

  }
}
