<div class="main-home-container">
  <app-hero ></app-hero>
  <app-career></app-career>
  <app-jobs></app-jobs>
  <app-join></app-join>
  <app-testimonials></app-testimonials>
  <app-home-message></app-home-message>
  <app-footer></app-footer>
</div>

