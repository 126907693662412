import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import blog from "../../../assets/data/blog.json";
import {Blog} from "../../shared/model/types";



@Injectable({
  providedIn: 'root'
})
export class BlogMockDataService {


  // getBlog(): Observable<Blog[]> {
  //   const blogs: Blog[] = blog.map(item => ({
  //     ...item,
  //     date: new Date(item.date)
  //
  //   }));
  //   return of(blogs);
  // }

  getBlog(): Observable<Blog[]> {
    const blogs: Blog[] = blog.map(item => ({
      ...item,
      date: new Date(item.date),
      related: item.related.map(relatedItem => ({
        ...relatedItem,
        date: new Date(relatedItem.date)
      }))
    }));
    return of(blogs);
  }

  // getRelatedBlog(id:number): Observable<Blog[]> {
  //   const blogs: Blog[] = blog.map(item => ({
  //     ...item,
  //     date: new Date(item.date),
  //     related: item.related.map(relatedItem => ({
  //       ...relatedItem,
  //       date: new Date(relatedItem.date)
  //     }))
  //   }));
  //   return of(blogs);
  // }



  // getIndividualBlog(id: number): Observable<Blog> {
  //   const blogItem = blog.find(item => item.id === id);
  //   if(!blogItem) return of({} as Blog);
  //   return of({
  //     ...blogItem,
  //     date: new Date(blogItem.date)
  //   });
  // }
  getIndividualBlog(id: number): Observable<Blog> {
    const blogItem = blog.find(item => item.id === id);
    if(!blogItem) return of({} as Blog);
    return of({
      ...blogItem,
      date: new Date(blogItem.date),
      related: blogItem.related.map(relatedItem => ({
        ...relatedItem,
        date: new Date(relatedItem.date)
      }))
    });
  }

}
