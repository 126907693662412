<div class="portal-profile-section-container">
  <mat-card class="portal-profile-section-card">
    <form [formGroup]="profileForm" style="margin: 10px; display: flex; flex-direction: column; gap: 10px;">
      <div class="form-row">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName">
          @if (profileForm.controls.firstName.hasError('required')) {
            <mat-error>First Name is required</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>Middle Name</mat-label>
          <input matInput formControlName="middleName">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName">
          @if (profileForm.controls.lastName.hasError('required')) {
            <mat-error>Last Name is required</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>Date of Birth</mat-label>
          <input matInput formControlName="dateOfBirth" [matDatepicker]="dateOfBirthPicker">
          <mat-datepicker-toggle matIconSuffix [for]="dateOfBirthPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option [value]="null"></mat-option>
            <mat-option value="Male">Male</mat-option>
            <mat-option value="Female">Female</mat-option>
            <mat-option value="preferNotToSay">Prefer not to say</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>SSN</mat-label>
          <input matInput formControlName="ssn">
        </mat-form-field>
      </div>

      <div style="color: #0fafbe; font-weight: 400;">Note: This address must match your permanent tax home address!
      </div>
      <div class="form-row">
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput formControlName="address1">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Address 2</mat-label>
          <input matInput formControlName="address2">
        </mat-form-field>
      </div>
        <div class="form-row">
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput formControlName="city">
        </mat-form-field>
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            @for (state of states; track state.code) {
              <mat-option [value]="state.code">{{ state.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Zip</mat-label>
          <input matInput formControlName="zip">
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field>
          <mat-label>Emergency Contact Name</mat-label>
          <input matInput formControlName="emergencyContactName">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Emergency Contact Phone</mat-label>
          <input matInput formControlName="emergencyContactPhone">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Emergency Contact Relationship</mat-label>
          <input matInput formControlName="emergencyContactRelationship">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Emergency Contact Address</mat-label>
          <input matInput formControlName="emergencyContactAddress">
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-checkbox formControlName="hasLatexSensitivity" color="primary">Check here if you have a latex
          sensitivity.
        </mat-checkbox>
      </div>

      <div class="form-row">
        <mat-form-field>
          <mat-label>How did you hear about us?</mat-label>
          <mat-select formControlName="portalUserSource"
                      (valueChange)="portalUserSourceSelectionChange($event)"
          >

            @for (sourceType of portalUserSourceTypeOptions; track sourceType.value) {
              <mat-option
                          [value]="sourceType.value">{{ sourceType.label }}
              </mat-option>
            }

          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Were you referred to a specific recruiter?</mat-label>
          <mat-select formControlName="referredToSmId">
            <mat-option [value]="null">None</mat-option>
            @for (sm of smList; track sm.id) {
              <mat-option [value]="sm.id">{{ sm.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @if (profileForm.get('portalUserSource')?.value === 'referral') {
        <div class="form-row">
          <mat-form-field>
            <mat-label>Referred By Name</mat-label>
            <input matInput formControlName="referredByName">
            @if (profileForm.controls.referredByName.hasError('required')) {
              <mat-error>Referred By Name is required</mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>Referred By Phone</mat-label>
            <input matInput formControlName="referredByPhone">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Referred By Email</mat-label>
            <input matInput formControlName="referredByEmail">
          </mat-form-field>
        </div>
      }

      @if (profileForm.get('portalUserSource')?.value === 'other') {
        <div class="form-row">
          <mat-form-field>
            <mat-label>Other Description:</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
                      formControlName="portalUserSourceDescription"></textarea>
            @if (profileForm.controls.portalUserSourceDescription.hasError('required')) {
              <mat-error>Other Description is required</mat-error>
            }
          </mat-form-field>
        </div>
      }
      <div class="form-row hide-mobile">
        <div class="email">
          <div class="form-array-title">
            <div>Emails</div>
            <div class="flex-expand"></div>
            <button class="mat-icon-button-sm" [disabled]="disablePage" mat-icon-button (click)="addEmail()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ng-container *ngTemplateOutlet="emailsList"></ng-container>
        </div>

        <mat-divider vertical></mat-divider>

        <div class="phone">
          <div class="form-array-title">
            <div>Phones</div>
            <div class="flex-expand"></div>
            <button class="mat-icon-button-sm" [disabled]="disablePage" mat-icon-button (click)="addPhone()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ng-container *ngTemplateOutlet="phonesList"></ng-container>
        </div>
      </div>

      <div class="flex-row show-mobile">
        <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start" style="flex: 1;">
          <mat-tab label="Emails">
            <ng-container *ngTemplateOutlet="emailsList"></ng-container>
          </mat-tab>
          <mat-tab label="Phones">
            <ng-container *ngTemplateOutlet="phonesList"></ng-container>
          </mat-tab>
        </mat-tab-group>
        <button class="mat-icon-button-sm add-active-button" [disabled]="disablePage" mat-icon-button
                (click)="addActive(tabGroup.selectedIndex)">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <ng-template #emailsList>
        <div formArrayName="emails" class="flex-column form-array-mobile-margin">
          @for (email of emailsFormArray.controls; track email.value) {
            <div [formGroupName]="$index" class="form-array">
              <button [disabled]="disablePage" mat-icon-button (click)="removeEmail($index)" class="remove-button">
                <mat-icon>close</mat-icon>
              </button>
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                @if (emailsFormArray.at($index).get('email')?.hasError('required')) {
                  <mat-error>Email is required</mat-error>
                }
              </mat-form-field>
              <mat-checkbox color="primary" formControlName="isPrimary">Primary</mat-checkbox>
            </div>
          }
        </div>
      </ng-template>

      <ng-template #phonesList>
        <div formArrayName="phones" class="flex-column form-array-mobile-margin">
          @for (phone of phonesFormArray.controls; track phone.value) {
            <div [formGroupName]="$index" class="form-array">
              <button [disabled]="disablePage" mat-icon-button (click)="removePhone($index)"
                      class="remove-button">
                <mat-icon>close</mat-icon>
              </button>
              <mat-form-field style="flex: 1;">
                <mat-label>Type</mat-label>
                <mat-select formControlName="phoneType">
                  <mat-option [value]="'cell'">Cell</mat-option>
                  <mat-option [value]="'landline'">Landline</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="flex: 1;">
                <mat-label>Country Code</mat-label>
                <input matInput formControlName="countryCode">
                <span matTextPrefix>+</span>
              </mat-form-field>
              <mat-form-field style="flex: 3;">
                <mat-label>Number</mat-label>
                <input matInput formControlName="phoneNumber">
              </mat-form-field>
              <mat-checkbox color="primary" formControlName="isPrimary">Primary</mat-checkbox>
            </div>
          }
        </div>
      </ng-template>

    </form>
  </mat-card>
</div>

<div class="portal-profile-section-bottom-action-bar">
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="saveProfile()">
    @if (disablePage) {
      <mat-spinner diameter="25"></mat-spinner>
    } @else {
      Save
    }
  </button>
</div>
