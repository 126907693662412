import {Component, Inject, QueryList, ViewChildren} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import {WorkHistory} from '../../../shared/types';
import {AbstractControl, FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {CatalogsService} from '../../../../shared/services/catalogs.service';
import {
  MultiLineSnackBarService
} from '../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {ProfileService} from '../../services/profile.service';
import {PortalUserService} from '../../../shared/services/portal-user.service';
import {CustomDateMonthComponent} from "../../../../shared/components/custom-date-month/custom-date-month.component";

@Component({
  selector: 'app-work-history-dialog',
  standalone: true,
    imports: [
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatError,
        MatFormField,
        MatIcon,
        MatIconButton,
        MatInput,
        MatLabel,
        MatOption,
        MatSelect,
        MatSuffix,
        ReactiveFormsModule,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        CustomDateMonthComponent
    ],
  templateUrl: './work-history-dialog.component.html',
  styleUrl: './work-history-dialog.component.css'
})
export class WorkHistoryDialogComponent {
  disablePage = false;
  states = this.catalogsService.getStates();
  workHistoryForm = this.formBuilder.group({
    hospitalName: [this.data?.hospitalName ?? '', [Validators.required]],
    city: [this.data?.city ?? ''],
    state: [this.data?.state ?? ''],
    specialty: [this.data?.specialty ?? ''],
    fromDate: [this.data?.fromDate],
    toDate: [this.data?.toDate],
    chartingExperience: [this.data?.chartingExperience ?? ''],
    salary: [this.data?.salary ?? ''],
    responsibilities: [this.data?.responsibilities ?? ''],
    numberOfBedsInUnit: [this.data?.numberOfBedsInUnit],
    numberOfBedsInHospital: [this.data?.numberOfBedsInHospital],
    patientRatio: [this.data?.patientRatio ?? ''],
    wasTravelAssignment: [this.data?.wasTravelAssignment ?? false],
    travelCompany: [{value: this.data?.travelCompany, disabled: !this.data?.wasTravelAssignment}],
    wasTeachingHospital: [this.data?.wasTeachingHospital],
    traumaHospitalLevel: [this.data?.traumaHospitalLevel],
    hasChargeExperience: [this.data?.hasChargeExperience],
    supervisor: [this.data?.supervisor ?? ''],
    supervisorPhone: [this.data?.supervisorPhone ?? ''],
    reasonForLeaving: [this.data?.reasonForLeaving ?? ''],
  });
  @ViewChildren(CustomDateMonthComponent) customDateComponents: QueryList<CustomDateMonthComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WorkHistory | null,
    private formBuilder: FormBuilder,
    private catalogsService: CatalogsService,
    private snackBar: MultiLineSnackBarService,
    private profileService: ProfileService,
    private userService: PortalUserService,
    private matDialogRef: MatDialogRef<WorkHistoryDialogComponent>,
  ) {
  }

  toggleFormFieldRequired(value: any, control: AbstractControl): void {
    if (value) {
      control.setValidators(Validators.required);
      control.enable();
    } else {
      control.clearValidators();
      control.reset();
      control.disable();
    }
    control.updateValueAndValidity();
    this.workHistoryForm.updateValueAndValidity();
  }

  saveWorkHistory(): void {
    this.workHistoryForm.markAllAsTouched();
    if (!this.workHistoryForm.valid) {
      this.snackBar.displayMessages(['Please fix all errors before saving.']);
      return;
    }

    // disable form and buttons
    this.disablePage = true;
    this.workHistoryForm.disable();
    // send to API for saving
    const newWorkHistory = this.workHistoryForm.value as WorkHistory;
    newWorkHistory.id = this.data?.id ?? 0;
    this.profileService.saveWorkHistory(newWorkHistory)
      .subscribe(result => {
        if (result.isSuccess) {
          const workHistory = this.userService.activeUser()?.workHistory ?? [];
          const newWorkHistory = result.value as WorkHistory;
          if (workHistory.find(wh => wh.id === newWorkHistory.id)) {
            workHistory.splice(workHistory.findIndex(wh => wh.id === newWorkHistory.id), 1, newWorkHistory);
          } else {
            workHistory.push(newWorkHistory);
          }
          this.userService.setWorkHistory(workHistory);
          this.matDialogRef.close();
        } else {
          this.snackBar.displayMessages(result.errorList);
        }
        this.disablePage = false;
        this.workHistoryForm.enable();
      });
  }
  markAllAsTouched() {
    this.workHistoryForm.markAllAsTouched();
    this.customDateComponents.forEach(customDateComponent => customDateComponent.markAllAsTouched());
  }
}
