import {Component} from '@angular/core';
import {HeaderComponent} from '../shared/components/header/header.component';
import {NgOptimizedImage} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {HeroComponent} from './hero/hero.component';
import {CareerComponent} from './career/career.component';
import {FooterComponent} from '../shared/components/footer/footer/footer.component';
import {JobsComponent} from './jobs/jobs.component';
import {JoinComponent} from './join/join.component';
import {HomeMessageComponent} from './home-message/home-message.component';
import {TestimonialsComponent} from '../shared/components/testimonials/testimonials.component';
import {SeoUtilsService} from '../shared/services/seo-utils.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent,
    NgOptimizedImage,
    ReactiveFormsModule,
    HeroComponent,
    CareerComponent,
    FooterComponent,
    JobsComponent,
    JoinComponent,
    HomeMessageComponent,
    TestimonialsComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  constructor(
    private seoUtilsService: SeoUtilsService,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      null,
      'Find Travel Nurse Job Opportunities Across America - Nightingale',
      'We\'re more than a travel nursing agency, we\'re your partner to unlock competitive pay, benefits, and exciting nursing assignments across the U.S.',
      'nightingale-travel-nurses'
    );
  }
}
