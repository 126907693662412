<div class="faq-item">
  <div>
    <div class="number">12</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>What type of pre-employment orientation should I expect to receive?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>The working clinician is subject to the terms of the the Nightingale Employee Handbook,
          which provides a blueprint for working with Nightingale, regardless of which client facility
          you are assigned to.  The “clinical” orientation is performed by the client facility,
          pursuant to their rules, policies, and procedures. This orientation occurs the first time
          a clinician begins his/her first assignment at that facility. Each client facility
          has its own method of orientation. Members of Nightingale’s Clinical and Credentialing
          Department are available
          to all clinicians to resolve any questions or issues experienced at the client facility.</p>


      </div>
    }

  </div>

</div>

