import {Component, Input} from '@angular/core';
import {NgOptimizedImage, NgStyle} from "@angular/common";

@Component({
  selector: 'app-nurse-comment',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgStyle
  ],
  templateUrl: './nurse-comment.component.html',
  styleUrl: './nurse-comment.component.css'
})
export class NurseCommentComponent {
  @Input() imagePath: string;
  @Input() name: string;
  @Input() comment: string;
  @Input() starCount: number;

}
