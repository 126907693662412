import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-message',
  standalone: true,
  imports: [],
  templateUrl: './home-message.component.html',
  styleUrl: './home-message.component.css'
})
export class HomeMessageComponent {

}
