<div class="footer-content">

  <div style="width: 100%">
    <div style="height: 40px; text-align: center">
      <img ngSrc="./assets/svg/logo-dark.svg" alt="" width="262" height="50" priority>
    </div>
  </div>

  <div class="certification-row">
    <div class="certification">
      <a class="flex-row"
         style="align-items: center; cursor: pointer; text-decoration: none;"
         target="_blank"
         href="https://www.natho.org/">
        <div style="padding-right: 10px;">
          <img ngSrc="assets/img/nightingale-natho-logo@2x.png" width="60" height="60" alt="">
        </div>
        <div class="flex-row" style="flex-wrap: wrap;">
          <p style="color: #CED4DA;">Proud Member of National Association of Travel Healthcare Organizations</p>
        </div>

      </a>

      <a class="flex-row" style="align-items: center; cursor: pointer; text-decoration: none;"
         target="_blank"
         href="https://www.jointcommission.org">
        <div style="padding-right: 10px;">
          <img ngSrc="assets/img/nightingale-jointcommission-gold.png" width="60" height="60" alt="">
        </div>
        <div class="flex-row" style="flex-wrap: wrap">
          <p style="color: #CED4DA;">Certified by The Joint Commission and has Earned its Gold Seal of Approval</p>
        </div>

      </a>
    </div>
  </div>

  <div class="menu-container">
    <!--HOME-->

    <div class="flex-row menu-container-row">

      <a class="menu-item" href="/">HOME</a>

      <a class="menu-item" href="/job-search">JOB SEARCH</a>

      <a class="menu-item" href="/contact">CONTACT</a>

      <a class="menu-item" href="/apply">APPLY</a>

      <!-- //TODO: Add Blogs-->
      <!-- <div class="menu-item MenuText" (click)="navigate('blog')">BLOG</div>-->
    </div>
  </div>

  <div class="footer-box">

    <div class="footer-contact-referral">

      <div class="footer-contact">
        <div class="footer-contact-col">
          <div class="contact-icon">
            <img ngSrc="assets/svg/footer_icon-email.svg" width="30" height="30" alt="Email">
          </div>
          <div class="contact paragraf-big">
            <a href="mailto:{{ companyContactInfo.timesheetEmail }}" style="color: white; text-decoration: none;">
              {{ companyContactInfo.timesheetEmail }}
            </a>
          </div>
        </div>

        <div class="footer-contact-col">
          <div class="contact-icon">
            <img ngSrc="assets/svg/footer_icon-phone.svg" width="30" height="30" alt="Phone">
          </div>
          <div class="contact paragraf-big">
            <a href="tel:{{ companyContactInfo.supportPhone }}" style="color: white; text-decoration: none;">
              {{ companyContactInfo.supportPhone }}
            </a>
          </div>

        </div>
      </div>

      <div class="footer-referral">
        <div class="footer-row-ref paragraf-big manrope-regular">
          Get up to
          <span class="footer-row-ref-highlight manrope-bold"> &nbsp;$1500</span>
          for
          <span class="footer-row-ref-highlight manrope-bold"> each new</span>
          traveler referral!
        </div>

        <div class="flex-row">

          <a href="/referrals" class="flex-row learn-more-btn-outline btn-hover" style="text-decoration: none;">
            <div class="learn-more-btn button-text manrope-bold">
              LEARN MORE
            </div>
            <span style=" display: flex;align-self: center">
              <img ngSrc="/assets/svg/icon_button-arrowright.svg" width="18" height="18" alt="Learn More"></span>
          </a>
        </div>
      </div>
    </div>

  </div>


  <div class="copy-write-row">

    <div class="copy-write manrope-regular">
      © COPYRIGHT 2017 - 2024 NIGHTINGALE NURSES LLC. ALL RIGHTS RESERVED.
    </div>

    <!-- //TODO: Add links to social networks-->
    <div class="flex-row" style=" justify-content: center; gap: 16px; display: none;">
      <img ngSrc="assets/svg/social_icon-in.svg" width="30" height="30" alt="linkedin">
      <img ngSrc="assets/svg/social_icon-ig.svg" width="30" height="30" alt="instagram">
      <img ngSrc="assets/svg/social_icon-fb.svg" width="30" height="30" alt="facebook">
      <img ngSrc="assets/svg/social_icon-x.svg" width="30" height="30" alt="x">
    </div>
  </div>

</div>

