<mat-card>
  @if ({details: recruiterDetails$ | async}; as contact) {
    <div class="recruiter-contact-container">
      <div class="portal-home-section-title unselectable">My Recruiter</div>

      @if (contact.details?.isSuccess) {
        <div
          class="portal-home-item-title row-spacer">{{ contact.details?.value?.firstName }} {{ contact.details?.value?.lastName }}
        </div>

        <div class="portal-home-item-title row-spacer">
          <a [href]="'mailto:' + contact.details?.value?.email">
            {{ contact.details?.value?.email }}
          </a>
        </div>

        <div class="portal-home-item-title row-spacer">
          <a [href]="'tel:' + contact.details?.value?.phone">
            {{ contact.details?.value?.phone ?? '' | phone }}
          </a>
        </div>
      } @else {
        <div class="portal-home-item-title unselectable row-spacer">No Recruiter Assigned</div>
        <div class="portal-home-item-title unselectable row-spacer portal-home-link"
             (click)="requestRecruiterContact()">
          Request to be contacted by a recruiter.
        </div>
      }
    </div>
  }

  <div class="end-spacer"></div>
</mat-card>


<ng-template #template>
  <div style="align-self: center">
    Your request was submitted! A recruiter will contact you soon.
  </div>
</ng-template>
