<div [ngClass]="isSelectionListShown?'select-chip':'select-chip-unselected'" #div [id]="divId" >

  <div class="grid-row " (click)="showSelectList()">
    @if (selectedOption) {
      <div class="selected-item">
        <div class="placeholder">
          {{ placeholder }}
        </div>
        <div class="selected-item-text">{{ selectedOption.label| shorten: 19 }}</div>
      </div>
    } @else {
      @if (!isSelectionListShown) {
        <div>
          {{ placeholder }}
        </div>
      }
    }
    <div class="grid-row-button" >
      <img ngSrc="assets/svg/select-open.svg" alt="" height="23" width="23">
    </div>
  </div>
  @if (isSelectionListShown) {
    <div class="selection-list">
      @for (item of visibleOptions; track item; let index = $index) {
        <div class="select-item" (click)="selectItem(item)">
          {{ item.label }}
        </div>
      }
    </div>
  }

  <!--  <div *ngIf="isSelectionListShown" class="selection-list">-->
  <!--    <div *ngFor="let item of visibleOptions" class="select-item" (click)="addItem(item)">-->
  <!--      {{ item.label }}-->
  <!--    </div>-->
  <!--  </div>-->

</div>
