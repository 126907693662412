<!--suppress JSUnusedGlobalSymbols -->
<div
  class="header"
>
  <a href="/" class="logo-desktop unselectable">
    <img ngSrc="./assets/svg/logo-dark.svg" alt="" width="262" height="50" style="cursor: pointer" priority>
  </a>

  <a href="/" class="logo-tablet unselectable">
    <img ngSrc="./assets/svg/logo-dark.svg" alt="" width="210" height="40" style="cursor: pointer" priority>
  </a>

  <a href="/" class="logo-phone unselectable">
    <img ngSrc="./assets/svg/logo-dark.svg" alt="" width="125.4" height="24" style="cursor: pointer" priority>
  </a>

  <div class="flex-expand"></div>

  <div class="mobile-menu-tablet">
    <img ngSrc="./assets/svg/menu-tablet.svg" alt="" width="36" height="36" (click)="mobileMenuToggle('tablet')">
  </div>

  <div class="mobile-menu-phone">
    <img ngSrc="./assets/svg/menu-tablet.svg" alt="" width="24" height="24" (click)="mobileMenuToggle('phone')">
  </div>

  <!--Menu-->
  <div class="menu-container unselectable">
    <!--HOME-->
    <a href="/" class="menu-item-container menu-item">HOME</a>

    <!--JOB SEARCH-->
    <a href="/job-search" class="menu-item-container menu-item">JOB SEARCH</a>

    <!--BENEFITS-->
    <div class="menu-item-container" (click)="toggleBenefitsDropdown()" #menu>
      <div class="menu-item">BENEFITS</div>
      <img ngSrc="./assets/svg/icon_menu-dropdown.svg" alt="" height="24" width="24">

      @if (isBenefitsDropdownOpen) {
        <div class="dropdown-menu">

          <a href="/benefits" class="dropdown-menu-item-container">
            <div class="dropdown-menu-item-text">BENEFITS</div>
          </a>

          <a href="/housing" class="dropdown-menu-item-container">
            <div class="dropdown-menu-item-text">HOUSING</div>
          </a>

          <a href="/licensure" class="dropdown-menu-item-container">
            <div class="dropdown-menu-item-text">LICENSURE</div>
          </a>

          <a href="/referrals" class="dropdown-menu-item-container">
            <div class="dropdown-menu-item-text">REFERRALS PROGRAM</div>
          </a>

        </div>
      }
    </div>

    <!--RESOURCES-->
    <div class="menu-item-container" (click)="toggleResourcesDropdown()" #menu>
      <div class="menu-item">RESOURCES</div>
      <img ngSrc="./assets/svg/icon_menu-dropdown.svg" alt="" height="24" width="24">

      @if (isResourcesDropdownOpen) {

        <div class="dropdown-menu">

          <a href="/policies" class="dropdown-menu-item-container">
            <div class="dropdown-menu-item-text">COMPANY POLICIES</div>
          </a>

          <a href="/faq" class="dropdown-menu-item-container">
            <div class="dropdown-menu-item-text">FAQ</div>
          </a>

        </div>
      }
    </div>

    <!--ABOUT US-->
    <div class="menu-item-container" (click)="toggleAboutDropdown()" #menu>
      <div class="menu-item">ABOUT US</div>
      <img ngSrc="./assets/svg/icon_menu-dropdown.svg" alt="" height="24" width="24">

      @if (isAboutDropdownOpen) {
        <div class="dropdown-menu">

          <a href="/about" class="dropdown-menu-item-container">
            <div class="dropdown-menu-item-text">ABOUT NIGHTINGALE</div>
          </a>

          <a href="/contact" class="dropdown-menu-item-container">
            <div class="dropdown-menu-item-text">CONTACT</div>
          </a>

        </div>
      }
    </div>


    <!-- //TODO: Add Blogs-->
<!--     <a href="/blog" class="menu-item-container menu-item">BLOG</a>-->

    <a href="/portal" class="menu-item-container">
      <img ngSrc="./assets/svg/icon_user-white.svg" alt="" height="20" width="20">
      <div style="width: 5px"></div>

      <div class="menu-item">MY ACCOUNT</div>
    </a>

    <a href="/apply" class="header-menu-button header-menu-button-filled">APPLY</a>
  </div>
</div>
