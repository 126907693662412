import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-faq-item-6',
  standalone: true,
    imports: [
        NgOptimizedImage
    ],
  templateUrl: './faq-item-6.component.html',
  styleUrl: './faq-item-6.component.css'
})
export class FaqItem6Component {
  expandAnswer:boolean=false;
  toggleAnswer() {
    this.expandAnswer = !this.expandAnswer;
  }
}
