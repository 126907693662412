<div mat-dialog-title>{{title}}</div>
<mat-dialog-content>
  {{message}}
</mat-dialog-content>
@if(confirmationDialogStyle === twoButtonDialog) {
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" (click)="exitDialog(negativeResult)">{{negativeBtnText}}</button>
    <button mat-raised-button color="primary" (click)="exitDialog(positiveResult)">{{positiveBtnText}}</button>
  </mat-dialog-actions>
} @else if (confirmationDialogStyle === threeButtonDialog) {
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" (click)="exitDialog(negativeResult)">{{negativeBtnText}}</button>
    <button mat-raised-button color="accent" (click)="exitDialog(neutralResult)">{{neutralBtnText}}</button>
    <button mat-raised-button color="primary" (click)="exitDialog(positiveResult)">{{positiveBtnText}}</button>
  </mat-dialog-actions>
}
