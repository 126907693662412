import {Component} from '@angular/core';
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {NurseComment} from "../../../shared/model/types";
import {NurseCommentsMockDataService} from "../../benefits/services/nurse-comments-mock-data-service";

@Component({
  selector: 'app-referral-review',
  standalone: true,
  imports: [
    NgOptimizedImage,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './referral-review.component.html',
  styleUrl: './referral-review.component.css'
})
export class ReferralReviewComponent {
  nurseComments: NurseComment[];
  currentNurseComment: NurseComment;

  nurseComments$ = this.nurseCommentsService.getNurseComments();

  constructor(private nurseCommentsService: NurseCommentsMockDataService) {

    this.nurseComments$.subscribe(nurseComments => {
      this.nurseComments = nurseComments;
      this.currentNurseComment = nurseComments[0];
    });

  }


  navigateRight() {
    const currentIndex = this.nurseComments.indexOf(this.currentNurseComment);
    if (currentIndex < this.nurseComments.length - 1) {
      this.currentNurseComment = this.nurseComments[currentIndex + 1];
    } else {
      this.currentNurseComment = this.nurseComments[0];
    }
  }

  navigateLeft() {
    const currentIndex = this.nurseComments.indexOf(this.currentNurseComment);
    if (currentIndex > 0) {
      this.currentNurseComment = this.nurseComments[currentIndex - 1];
    } else {
      this.currentNurseComment = this.nurseComments[this.nurseComments.length - 1];
    }
  }
}
