import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  standalone: true
})
export class PhonePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    const stringValue = value.replace(/\D/g, '');

    if (stringValue.length === 10) {
      return `(${stringValue.substring(0, 3)}) ${stringValue.substring(3, 6)}-${stringValue.substring(6)}`;
    } else {
      return value;
    }
  }

}



