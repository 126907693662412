<div class="quick-details-container">
  <div class="header-container">
    <div class="header">
      <span style="font-family: 'Manrope Light', sans-serif; font-weight: 300; color: #082645;">QUICK&nbsp;</span>
      <span
        style="font-family: 'Wulkan Display SemiBold Italic',sans-serif;font-weight: 600;color: #1BA9B5;">DETAILS</span>
    </div>
  </div>

  @if (!jobOrder.applied) {
    <div class="apply-today-btn-outline " (click)="applyToJobOrder()">
      <span class="apply-today-btn button-text">APPLY</span>
    </div>
  }
  @if (jobOrder.applied) {
    <div class="apply-today-btn-outline " style=" cursor:default" >
      <span class="apply-today-btn button-text">APPLIED</span>
    </div>
  }

  <div class="quick-details">
    <div class="quick-details-item">
      <div class="quick-details-item-label">SPECIALTY</div>
      <div class="quick-details-item-value">{{ jobOrder.specialty }}</div>
    </div>

    <div class="quick-details-item">
      <div class="quick-details-item-label">DURATION</div>
      <div class="quick-details-item-value">{{ jobOrder.duration }} weeks</div>
    </div>
    <div class="quick-details-item">
      <div class="quick-details-item-label">SHIFT</div>
      <div class="quick-details-item-value">{{ jobOrder.shift }}</div>
    </div>
    <div class="quick-details-item">
      <div class="quick-details-item-label">STATE</div>
      <div class="quick-details-item-value">{{ jobOrder.state }}</div>
    </div>
    <div class="quick-details-item">
      <div class="quick-details-item-label">CITY</div>
      <div class="quick-details-item-value">{{ jobOrder.city }}</div>
    </div>
    <div class="quick-details-item">
      <div class="quick-details-item-label">HOURS /WK</div>
      <div class="quick-details-item-value">{{ jobOrder.hoursPerWeek }}</div>
    </div>
  </div>


</div>
