import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import {MatButton, MatIconButton} from '@angular/material/button';
import {CredentialType, FileType, PortalUserFileFull} from '../shared/types';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {
  MultiLineSnackBarService
} from '../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {MatError, MatFormField, MatOption, MatSelect, MatSuffix} from '@angular/material/select';
import {MatInput, MatLabel} from '@angular/material/input';
import {CredentialRequirementClass} from '../../../../shared/model/enumTypes';
import {MatIcon} from '@angular/material/icon';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {NgClass} from '@angular/common';
import {PortalUserFileService} from '../shared/portal-user-file.service';
import {PortalUserService} from '../../../shared/services/portal-user.service';

@Component({
  selector: 'app-portal-user-file-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatSelect,
    MatFormField,
    MatLabel,
    MatOption,
    ReactiveFormsModule,
    MatInput,
    MatIconButton,
    MatIcon,
    MatSuffix,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatError,
    NgClass
  ],
  templateUrl: './portal-user-file-dialog.component.html',
  styleUrl: './portal-user-file-dialog.component.css'
})
export class PortalUserFileDialogComponent implements OnInit {
  disablePage = false;
  fileForm = this.formBuilder.group({
    requirementClass: [undefined as FileType | undefined, [Validators.required]],
    credentialType: [{value: undefined as CredentialType | undefined, disabled: true}, [Validators.required]],
    fileName: [{value: '', disabled: true}, [Validators.required]],
    fileExtension: ['', [Validators.required]],
    base64FileContents: ['', [Validators.required]],
    expirationDate: [{value: undefined as Date | undefined, disabled: true}],
  });
  isDraggingFile = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { fileTypes: FileType[], uploadedFile?: File },
    private formBuilder: FormBuilder,
    private snackBar: MultiLineSnackBarService,
    private fileService: PortalUserFileService,
    private matDialogRef: MatDialogRef<PortalUserFileDialogComponent>,
    private portalUserService: PortalUserService,
  ) {
  }

  ngOnInit(): void {
    if (!!this.data.uploadedFile) {
      this.handleUploadedFile(this.data.uploadedFile);
    }
  }

  onDragLeave(event: DragEvent) {
    // Check if the relatedTarget is a child of the drop zone
    if ((event.currentTarget! as Node).contains(event.relatedTarget as Node)) {
      // If it is, do not fire the dragleave event
      return;
    }

    this.isDraggingFile = false;
  }

  requirementClassChanged() {
    this.fileForm.controls.credentialType.enable();
    this.fileForm.controls.credentialType.patchValue(undefined);
    this.fileForm.controls.expirationDate.clearValidators();
    this.fileForm.controls.expirationDate.disable();
    this.fileForm.updateValueAndValidity();
  }

  credentialTypeChanged(value: CredentialType) {
    if (value.canExpire) {
      this.fileForm.controls.expirationDate.enable();
      this.fileForm.controls.expirationDate.setValidators(Validators.required);
    } else {
      this.fileForm.controls.expirationDate.clearValidators();
      this.fileForm.controls.expirationDate.disable();
    }
    this.fileForm.controls.expirationDate.updateValueAndValidity();
  }

  dropFile(event: DragEvent) {
    event.preventDefault();
    this.isDraggingFile = false;

    if (event.dataTransfer?.files.length !== 1) {
      this.snackBar.displayMessages(['Please drop only one file at a time.']);
      return;
    }

    const file = event.dataTransfer?.files[0];
    if (!!file) {
      this.handleUploadedFile(file);
    }
  }

  uploadFile(changeEvent: Event): void {
    const changeEventTarget = changeEvent.target as HTMLInputElement;
    const file = changeEventTarget?.files?.item(0);
    if (!file) {
      return;
    }

    this.handleUploadedFile(file);
  }

  saveFile(): void {
    this.fileForm.markAllAsTouched();

    if (this.fileForm.invalid) {
      this.snackBar.displayMessages(['Please fill out all required fields.']);
      return;
    }

    this.disablePage = true;
    this.fileForm.disable();
    const newFile: PortalUserFileFull = {
      requirementClass: this.fileForm.value.requirementClass!.credentialRequirementClass,
      credentialType: this.fileForm.value.credentialType!,
      fileName: this.fileForm.value.fileName!,
      fileExtension: this.fileForm.value.fileExtension!,
      base64FileContents: this.fileForm.value.base64FileContents!,
      expirationDate: this.fileForm.value.expirationDate ?? undefined,
    };
    this.fileService.addFile(newFile).subscribe(result => {
      if (result.isSuccess) {
        if (!!result.value) {
          this.portalUserService.addValidFile(result.value);
        }
        this.snackBar.displayMessages(['File uploaded successfully.']);
        this.matDialogRef.close(true);
      } else {
        this.snackBar.displayMessages(result.errorList);
        this.disablePage = false;
        this.fileForm.enable();
      }
    });
  }

  private handleUploadedFile(file: File): void {
    if (file.size > 20971520) {
      this.snackBar.displayMessages(['File size must be less than 20MB.']);
      return;
    }

    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const name = fileName.split('.').slice(0, -1).join('.');
    const reader = new FileReader();

    reader.onload = () => {
      const readerResult = reader.result as string;
      const base64 = readerResult.split(',')[1];
      this.fileForm.controls.fileName.patchValue(name);
      this.fileForm.controls.fileName.enable();
      this.fileForm.controls.fileExtension.patchValue(fileExtension!);
      this.fileForm.controls.base64FileContents.patchValue(base64);
    };

    reader.readAsDataURL(file);
  }

  protected readonly CredentialRequirementClass = CredentialRequirementClass;
}
