export interface Result<T = undefined> {
  isSuccess: boolean;
  value?: T;
  errorList: string[];
  warningList: string[];
  infoList: string[];
}

export function createFailedResult<T = undefined>(errorList: string[], warningList: string[] = [], infoList: string[] = []): Result<T> {
  return {
    isSuccess: false,
    value: undefined,
    errorList,
    warningList,
    infoList,
  };
}
