import {Component, Input} from '@angular/core';
import {HeaderComponent} from "../../../shared/components/header/header.component";

@Component({
  selector: 'app-ptb-layout-individual-blog',
  standalone: true,
  imports: [
    HeaderComponent
  ],
  templateUrl: './ptb-layout-individual-blog.component.html',
  styleUrl: './ptb-layout-individual-blog.component.css'
})
export class PtbLayoutIndividualBlogComponent {
  @Input() title: string;
  @Input() tags: string[];
}
