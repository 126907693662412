import {Component, OnInit} from '@angular/core';
import {HeaderComponent} from "../../shared/components/header/header.component";
import {MatButton} from "@angular/material/button";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {PortalUserService} from "../shared/services/portal-user.service";
import {
  MultiLineSnackBarService
} from "../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialogClose} from "@angular/material/dialog";
import {fieldMatchValidator} from "../shared/validators/fieldMatchValidator";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {Result} from "../../shared/model/result";
import {Observable} from "rxjs";


@Component({
  selector: 'app-portal-new-password',
  standalone: true,
  imports: [
    HeaderComponent,
    MatButton,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatProgressSpinner,
    ReactiveFormsModule,
    NgOptimizedImage,
    AsyncPipe,
    NgIf,
    MatDialogClose
  ],
  templateUrl: './portal-new-password.component.html',
  styleUrl: './portal-new-password.component.css'
})
export class PortalNewPasswordComponent implements OnInit {

  isLoading = false;
  token: string;
  newPasswordForm = this.formBuilder.nonNullable.group({
    password: ['', {
      validators: [
        Validators.required
      ]
    }],
    confirmPassword: ['', {
      validators: [
        Validators.required,
      ]
    }],
  }, {validators: fieldMatchValidator(true, 'password', 'confirmPassword')});
  public tokenValidation$: Observable<Result> | undefined;


  constructor(private portalUserService: PortalUserService,
              private formBuilder: FormBuilder,
              private multiLineSnackBar: MultiLineSnackBarService,
              private router: Router,
              private activatedRoute: ActivatedRoute,) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.token = params.get('meta') || '';
      if (!this.token) {
        this.router.navigate(['/portal']).then();
      }
      this.tokenValidation$ = this.portalUserService.validateResetToken(encodeURIComponent(this.token));
    });
  }


  submit(): void {
    this.newPasswordForm.markAllAsTouched();

    if (this.newPasswordForm.hasError('fieldsDoNotMatch')) {
      this.multiLineSnackBar.displayMessages(['Passwords do not match']);
      return;
    }
    if (this.newPasswordForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.portalUserService.savePassword(encodeURIComponent(this.token)!, this.newPasswordForm.value.password!).subscribe((result) => {
      if (result.isSuccess) {
        this.multiLineSnackBar.displayMessages(['Password updated successfully']);
        this.router.navigate(['/portal']).then();
      } else {
        this.multiLineSnackBar.displayMessages(result.errorList);
      }
      this.isLoading = false;
    });

  }
}
