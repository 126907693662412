import { Injectable } from '@angular/core';
import {MultiLineSnackBarComponent} from '../component/multi-line-snack-bar/multi-line-snack-bar.component';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MultiLineSnackBarService {

  constructor(private snackBar: MatSnackBar) {
  }

  displayMessages(messages: string[]): void {
    this.snackBar.openFromComponent<MultiLineSnackBarComponent>(
      MultiLineSnackBarComponent,
      {
        data: {messages: messages, action: ''},
        duration: 10000
      }
    );
  }

  displayMessagesWithAction(messages: string[], action: string): Observable<void> {
    return this.snackBar.openFromComponent(MultiLineSnackBarComponent, {
      data: {
        messages,
        action
      },
      duration: 10000
    }).onAction();
  }
}
