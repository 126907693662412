import {Component, computed, input, output, Signal} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {JobOrder} from "../../shared/model/types";
import {CarouselType} from "../../home/shared/model/types";
import {TableFooterComponent} from "../../shared/components/table-footer/table-footer.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {JobComponent} from "../../shared/components/job/job.component";
import {MatSuffix} from "@angular/material/form-field";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIconButton} from "@angular/material/button";
import {MatSelectChange} from '@angular/material/select';


@Component({
  selector: 'app-job-list',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    JobComponent,
    TableFooterComponent,
    FormsModule,
    ReactiveFormsModule,
    NgStyle,
    MatSuffix,
    MatTooltip,
    MatIconButton,
    NgOptimizedImage,
    NgForOf
  ],
  templateUrl: './job-list.component.html',
  styleUrl: './job-list.component.css'
})
export class JobListComponent {
  jobOrders = input<JobOrder[]>([]);
  jobOrdersCount = computed(() => this.jobOrders().length);
  sortChanged = output<{ column: 'WeeklyPay' | 'City' | 'Duration', direction: 'Asc' | 'Desc' }>();

  visibleJobOrders: Signal<JobOrder[]>;

  itemsPerPage = 12;
  currentPage = 1;
  firstItemIndex = 0;

  sortColumn: 'WeeklyPay' | 'City' | 'Duration' = 'WeeklyPay';
  sortDirection: 'Asc' | 'Desc' = 'Desc'

  sortOptions = [
    {label: "Weekly pay", value: "WeeklyPay"},
    {label: "City", value: "City"},
    {label: "Duration", value: "Duration"},
  ];

  protected readonly CarouselType = CarouselType;

  constructor() {
    this.setNewPage(1);
  }

  onFooterClick(page: number) {
    this.setNewPage(page);
  }

  onSortDirectionChanged() {
    this.sortDirection = this.sortDirection == 'Asc' ? 'Desc' : 'Asc';
    this.sortChanged.emit({
      column: this.sortColumn,
      direction: this.sortDirection
    });
  }

  onSortColumnChanged(event: any): void {
    this.sortChanged.emit({
      column: this.sortColumn,
      direction: this.sortDirection
    });
  }

  getIconClass() {
    return this.sortDirection === 'Desc' ? 'rotate-icon' : '';
  }

  setNewPage(page: number) {
    this.currentPage = page;

    this.visibleJobOrders = computed(() => {
      if (this.jobOrders().length < this.itemsPerPage) {
        this.firstItemIndex = 1;
        return this.jobOrders();
      } else {
        this.firstItemIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
        return this.jobOrders().slice((this.currentPage - 1) * this.itemsPerPage, (this.currentPage) * this.itemsPerPage)
      }
    });
  }

}


