import {Component} from '@angular/core';
import {HeaderComponent} from '../shared/components/header/header.component';
import {MatError, MatFormField, MatInput, MatLabel} from '@angular/material/input';
import {PortalUserService} from './shared/services/portal-user.service';
import {MatButton} from '@angular/material/button';
import {AsyncPipe, NgClass} from '@angular/common';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MultiLineSnackBarService} from '../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {LoggedInUser, PortalRegistrationData} from './shared/types';
import {MatOption, MatSelect} from '@angular/material/select';
import {CatalogsService} from '../shared/services/catalogs.service';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {PortalUserContainerComponent} from './portal-user-container/portal-user-container.component';
import {Result} from '../shared/model/result';
import {Router} from '@angular/router';
import {MatDialog} from "@angular/material/dialog";
import {
  PortalPasswordResetDialogComponent
} from "./portal-password-reset-dialog/portal-password-reset-dialog.component";
import {fieldMatchValidator} from "./shared/validators/fieldMatchValidator";


@Component({
  selector: 'app-portal',
  standalone: true,
  imports: [
    HeaderComponent,
    MatInput,
    MatLabel,
    MatFormField,
    MatButton,
    AsyncPipe,
    ReactiveFormsModule,
    MatError,
    MatProgressSpinner,
    MatSelect,
    MatOption,
    MatTabGroup,
    MatTab,
    PortalUserContainerComponent,
    NgClass,
  ],
  templateUrl: './portal.component.html',
  styleUrl: './portal.component.css'
})
export class PortalComponent {
  loggedInUser = this.portalUserService.activeUser;


  isLoading = false;
  specialties = this.catalogsService.getSpecialties();
  loginForm = this.formBuilder.nonNullable.group({
    email: ['', {
      validators: [
        Validators.required,
        Validators.email,
      ]
    }],
    password: ['', {
      validators: [
        Validators.required
      ]
    }]
  });

  showTransition = false;
  initialTab = '';
  registrationForm = this.formBuilder.nonNullable.group({
    email: ['', {
      validators: [
        Validators.required,
        Validators.email,
      ]
    }],
    phone: ['', {
      validators: [
        Validators.required
      ]
    }],
    password: ['', {
      validators: [
        Validators.required
      ]
    }],
    confirmPassword: ['', {
      validators: [
        Validators.required,
      ]
    }],
    firstName: ['', {
      validators: [
        Validators.required
      ]
    }],
    lastName: ['', {
      validators: [
        Validators.required
      ]
    }],
    specialty: ['', {
      validators: [
        Validators.required
      ]
    }], // this might have to be loaded from API
  }, {validators: fieldMatchValidator(true, 'password', 'confirmPassword')});

  constructor(
    private portalUserService: PortalUserService,
    private formBuilder: FormBuilder,
    private multiLineSnackBar: MultiLineSnackBarService,
    private catalogsService: CatalogsService,
    private router: Router,
    private matDialog: MatDialog,

  ) {
    this.initialTab = this.router.getCurrentNavigation()?.extras.state?.['initialTab'];
  }

  passwordKeyPressed(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  login(): void {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    }

    const email = this.loginForm.get('email')?.value ?? '';
    const password = this.loginForm.get('password')?.value ?? '';
    this.loginForm.disable();
    this.isLoading = true;
    this.portalUserService.login(email, password).subscribe((result: Result<LoggedInUser | null>) => {
      if (result.isSuccess) {
        this.showTransition = true;
        this.portalUserService.setActiveUser(result.value!);
        this.loginForm.reset();
        setTimeout(() => this.showTransition = false, 500);
      } else {
        this.multiLineSnackBar.displayMessages(result.errorList);
      }
      this.isLoading = false;
      this.loginForm.enable();
    });
  }

  register(): void {
    this.registrationForm.markAllAsTouched();
    if (this.registrationForm.invalid) {
      if (this.registrationForm.hasError('fieldsDoNotMatch')) {
        this.registrationForm.controls.confirmPassword.setErrors({fieldsDoNotMatch: true});
        this.registrationForm.controls.password.setErrors({fieldsDoNotMatch: true});
      }
      return;
    }

    const registrationData: PortalRegistrationData = {
      email: this.registrationForm.value.email ?? '',
      phone: this.registrationForm.value.phone ?? '',
      password: this.registrationForm.value.password ?? '',
      firstName: this.registrationForm.value.firstName ?? '',
      lastName: this.registrationForm.value.lastName ?? '',
      specialty: this.registrationForm.value.specialty ?? '',
    };
    this.registrationForm.disable();
    this.isLoading = true;
    this.portalUserService.registerUser(registrationData).subscribe((result: Result<LoggedInUser | null>) => {
      if (result.isSuccess) {
        this.showTransition = true;
        this.portalUserService.setActiveUser(result.value!);
        this.registrationForm.reset();
        setTimeout(() => this.showTransition = false, 500);
      } else {
        this.multiLineSnackBar.displayMessages(result.errorList);
      }
      this.isLoading = false;
      this.registrationForm.enable();
    });
  }


  sendPasswordResetLink() {
    // let width = '300px';
    // if (this.deviceType() == DeviceType.DESKTOP)
    //   width = '400px';

    this.matDialog.open(PortalPasswordResetDialogComponent, {
      disableClose: true,
      // width: width,
      data: {
        email: this.loginForm.get('email')?.value ?? ''

      },
    })
      .afterClosed();
  }
}


