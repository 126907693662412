<mat-form-field style="width: 100%">
  <mat-label>{{ placeHolder }}</mat-label>
  <input [formControl]="formControl" [matDatepicker]="picker" matInput (dateChange)="onDateChange($event)"
         style="display: none">
  <input [value]="formControl.value | monthYear" matInput (blur)="onBlur($event)" [disabled]="true">
  <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
  <mat-datepicker #picker (monthSelected)="chosenMonthHandler($event, picker)" panelClass="month-year-picker"
                  startView="multi-year"></mat-datepicker>
  @if (formControl.hasError('required') && (formControl.dirty || formControl.touched)) {
    <mat-error>{{ getErrorString() }}</mat-error>
  }

</mat-form-field>
