[
  {
    "imageIcon": "assets/svg/icon_travel-binder.svg",
    "title": "Travel Binder",
    "text": "Create a travel binder which holds/organizes all of your pertinent documents such a forms, licenses, immunization records, applications, etc."
  },
  {

    "imageIcon": "assets/svg/icon_permanent-home.svg",
    "title": "Permanent Home",
    "text": "Make arrangements for your permanent home such as mail, lawn maintenance, pet care (some jobs welcome pets so inquire with your recruiter), etc."
  },
  {

    "imageIcon": "assets/svg/icon_computer-devices.svg",
    "title": "Computers/Devices",
    "text": "If traveling with your laptop or tablet, make sure you pack all chargers."
  },
  {

    "imageIcon": "assets/svg/icon_packing.svg",
    "title": "Packing",
    "text": "Clothing, scrubs, shoes and anything else you may need to have versatility whether at work or play in your new destination."
  },
  {

    "imageIcon": "assets/svg/icon_toiletries.svg",
    "title": "Toiletries",
    "text": "These items can take up room in your suitcase so unless there is something you don’t think you can get in your new location, just purchase these after arrival."
  },
  {

    "imageIcon": "assets/svg/icon_shopping-list.svg",
    "title": "Shopping List",
    "text": "Make this prior to your arrival and keep it handy. This way, once you’re in your new residence, unpacked and settled, you can head out the door and accomplish your must-do shopping efficiently."
  },
  {

    "imageIcon": "assets/svg/icon_travel-camera.svg",
    "title": "Memories",
    "text": "This is an exciting opportunity to explore a new city not just in regard to your exceptional travel nurse job but also for venturing out. So take pics, keep a journal, dine on new cuisine, try adventurous activities and enjoy the journey!"
  }

]
