import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {LicensureTableItemComponent} from "./licensure-table-item/licensure-table-item.component";

import {LicensureMockDataService} from "../../services/licensure-mock-data-service";
import {LicensureTableHeaderComponent} from "./licensure-table-header/licensure-table-header.component";
import {TableFooterComponent} from "../../../../shared/components/table-footer/table-footer.component";
import {map, Observable} from "rxjs";
import {Licensure} from "../../../../shared/model/types";
import {LicensureTableItemMobileComponent} from "./licensure-table-item-mobile/licensure-table-item-mobile.component";


@Component({
  selector: 'app-licensure-table',
  standalone: true,
  imports: [
    AsyncPipe,
    LicensureTableItemComponent,
    NgIf,
    LicensureTableHeaderComponent,
    TableFooterComponent,
    LicensureTableItemMobileComponent
  ],
  templateUrl: './licensure-table.component.html',
  styleUrl: './licensure-table.component.css'
})
export class LicensureTableComponent implements OnInit {
  constructor(
    private licenceService: LicensureMockDataService

  ) {
  }

  licences$ = this.licenceService.getLicensure();
  filteredLicences$: Observable<Licensure[]>;
  resultSize: number;
  newPage = 1;
  reload: boolean;
  licencesToShow$: Observable<Licensure[]>;
  firstItem: number;
  showError: boolean;
  itemsPerPage = 10;


  ngOnInit() {
    this.filteredLicences$ = this.licences$;
    this.setNewPage(1, true);

  }


  searchClicked(search: string) {
    this.filteredLicences$ = this.licences$.pipe(map(licences => {
      return licences.filter(licence => licence.state.toLowerCase().startsWith(search.toLowerCase()));
    }));
    this.setNewPage(1, true);
  }

  onFooterClick(page: number) {
    this.setNewPage(page, false);
  }

  setNewPage(page: number, reload: boolean) {
    this.newPage = page;
    this.reload = reload;
    this.licencesToShow$ = this.filteredLicences$.pipe(map(licences => {
        this.resultSize = licences.length;
        this.showErrorMessage();
        if (licences.length < this.itemsPerPage) {
          this.firstItem = 1;
          return licences;
        } else {
          this.firstItem = (this.newPage - 1) * this.itemsPerPage + 1;
          return licences.slice((this.newPage - 1) * this.itemsPerPage, (this.newPage) * this.itemsPerPage)

        }

      }
    ));


    // this.firstItem=(this.newPage-1)*10+1;
  }

  showErrorMessage() {
    if (this.resultSize == 0) {
      this.showError = true;
      setTimeout(() => this.showError = false, 2000);
    }
  }


}
