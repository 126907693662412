<div class="faq-item">
  <div>
    <div class="number">01</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>What happens at the conclusion of my assignment?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>In a sizable percentage of our assignments, the clinician is requested by the client
          facility – at the clinician’s option - to extend. We stay in communication with the clinician
          throughout every assignment about their satisfaction with the position - and - if they are
          interested in considering an extension. If the clinician chooses not to extend, we immediately
          begin working to match the clinician with a new assignment. If the clinician seeks a break
          following the conclusion of the assignment before commencing a new one,
          Nightingale works to get the clinician a new assignment when they are ready to return.</p>


      </div>
    }

  </div>

</div>

