import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy, OnInit
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl, FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Subject} from "rxjs";
import {MatError, MatFormField, MatFormFieldControl, MatSuffix} from "@angular/material/form-field";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatInput, MatLabel} from "@angular/material/input";
import {MonthYearPipe} from "../../pipes/month-year.pipe";

@Component({
  selector: 'app-custom-date-month',
  templateUrl: './custom-date-month.component.html',
  styleUrl: './custom-date-month.component.css',
  standalone: true,
  imports: [
    MatFormField,
    MatDatepickerToggle,
    MatDatepicker,
    MatDatepickerInput,
    MatInput,
    MatLabel,
    MatSuffix,
    MatError,
    ReactiveFormsModule,
    MonthYearPipe,
    MatFormField

  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDateMonthComponent),
      multi: true
    },MonthYearPipe
  ],

})

export class CustomDateMonthComponent implements OnInit,ControlValueAccessor, OnDestroy, MatFormFieldControl<string | Date> {
  @Input() placeHolder: string = '';
  @Input() required: boolean;
  private _disabled = false;
  private _required = false;
  readonly stateChanges = new Subject<void>();

  static nextId = 0;
  formControl = new FormControl;

  @HostBinding('attr.aria-describedby') describedBy = '';
  @HostBinding() id = `app-custom-date-${CustomDateMonthComponent.nextId++}`;

  onChange: any = () => {
  };
  onTouched: any = () => {
  };


  ngOnInit() {
    this.formControl = new FormControl('', this.required ? Validators.required : null);
  }
  constructor(private cd: ChangeDetectorRef,private monthYearPipe:MonthYearPipe) {
  }


  value: string | Date;
  placeholder: string;
  ngControl: NgControl;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  writeValue(value: any): void {
    if (value) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        this.formControl.setValue(date );
      } else {
        this.formControl.setValue('');
      }
    } else {
      this.formControl.setValue('');
    }

    this.onChange(this.formControl.value);
    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onDateChange(event:any): void {
    this.writeValue(event.value);
  }

  chosenMonthHandler(value: any, datepicker: { close: () => void; }) {

    this.formControl.setValue(value);
    this.onChange(value);
    datepicker.close();
  }

  onBlur(event: Event): void {
    this.onChange(this.formControl.value);
  }

  markAllAsTouched() {
    this.formControl.markAsTouched();
  }

  getErrorString() {
    if (this.formControl.hasError('required')) {
      return this.placeHolder + ' is required';
    }
    return '';
  }


}
