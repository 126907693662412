<div class="faq-item">
  <div>
    <div class="number">09</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>How do Per Diem Advances/ Reimbursements Work?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>The purpose of Per Diem payments is to provide untaxed funds for qualifying workers that are on
          business trips on behalf of their employer, to be used for lodging, meals and incidentals incurred during
          their trip. To qualify, the employee’s assignment must require an overnight stay because daily
          commutation from his/her Permanent Tax Home (PTH) <i>would be unreasonable</i>.
        </p>
        <br>
        <p>
          The employee must establish a PTH through required declarations and documentation of the principal
          locus of their economic activity. <b><i>Once qualified, the employee may receive advances and/or
          reimbursements that are not reported as taxable income</i></b>.
        </p>
        <br>
        <p>
          The MAXIMUM amounts an employee may receive at any time are set forth twice per year by the Federal
          General Services Administration as well as IRS Publication 1542. The rates vary as to the location in
          which the employee is residing during his/her business trip. A look up link is provided here:
        </p>
        <p>
          <a href="https://www.gsa.gov/travel/plan-book/per-diem-rates" class="faq-link">https://www.gsa.gov/travel/plan-book/per-diem-rates.</a>

          </p>


      </div>
    }

  </div>

</div>

