import { Component } from '@angular/core';
import {FrameBenefitsComponent} from '../../shared/components/frame-benefits/frame-benefits.component';

@Component({
  selector: 'app-career',
  standalone: true,
  imports: [
    FrameBenefitsComponent
  ],
  templateUrl: './career.component.html',
  styleUrl: './career.component.css'
})
export class CareerComponent {

}
