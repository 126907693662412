import {Component} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {NurseCommentComponent} from "./nurse-comment/nurse-comment.component";

import {NurseCommentFrameComponent} from "./nurse-comment-frame/nurse-comment-frame.component";

@Component({
  selector: 'app-testimonials',
  standalone: true,
  imports: [
    AsyncPipe,
    NgForOf,
    NgIf,
    NurseCommentComponent,
    NurseCommentFrameComponent
  ],
  templateUrl: './testimonials.component.html',
  styleUrl: './testimonials.component.css'
})
export class TestimonialsComponent {


}
