import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ContactInquiry, RapidApp, RecruiterContactDetails, SelectOption, State} from '../model/types';
import {map, Observable, of} from 'rxjs';
import Specialty from '../../../assets/data/specialties.json';
import States from '../../../assets/data/state.json';
import {Result} from '../model/result';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getSpecialties(): Observable<SelectOption[]> {
    return of(Specialty);
  }

  getStates(): Observable<SelectOption[]> {
    return of(States).pipe(
      map(
        (array: State[]) => array.map((item: State) => ({
          label: item.name,
          value: item.code
        }))
      )
    );
  }


  submitContactForm(contactInquiry: ContactInquiry): Observable<boolean> {
    return this.httpClient.post<boolean>('contact', contactInquiry);
  }

  submitRapidApp(rapidApp: RapidApp): Observable<boolean> {
    return this.httpClient.post<boolean>('contact/rapid-app', rapidApp);
  }

  getRecruiterContactDetails(): Observable<Result<RecruiterContactDetails>> {
    return this.httpClient.get<Result<RecruiterContactDetails>>('contact/recruiter');
  }

  requestRecruiterContact(): Observable<boolean> {
    return this.httpClient.post<boolean>('contact/recruiter', {});
  }
}
