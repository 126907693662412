import {UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

// @ts-ignore
export const PhoneValidator: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
  const errors = {
    phoneNumberContainsLetters: false,
    phoneNumberTooShort: false,
    phoneNumberTooLong: false
  };
  let temp;
  if (control.disabled) {
    return null;
  }
  if (control.value) {
    temp = control.value.replace(/[^0-9a-zA-Z]/g, '');
    if (isNaN(Number(temp))) {
      errors.phoneNumberContainsLetters = true;
    }
    temp = control.value.replace(/[^0-9]/g, '');
    if (temp.length < 10) {
      errors.phoneNumberTooShort = true;
    } else if (temp.length > 10) {
      errors.phoneNumberTooLong = true;
    }
  }
  return errors.phoneNumberContainsLetters || errors.phoneNumberTooLong || errors.phoneNumberTooShort ? errors : null;
};
