<div class="joint-commission-container">

  <div class="joint-commission-background">
  </div>

  <div class="joint-commission-description">
    <div class="joint-commission-header">
      Contact the Joint Commission
    </div>

    <div class="line">
    </div>

    <div class="joint-commission-text1">
      Nightingale is a proud holder of the Gold Seal Certificate from the Joint Commission on the Accreditation of
      Healthcare Organizations.
      <div class="joint-commission-text2">
        We encourage all employees and other interested parties to notify the Joint Commission - without concern for
        disciplinary or punitive action - regarding any patient, safety or quality of care issue encountered. A link
        to
        the Joint Commission is provided below.
      </div>
    </div>

    <a
      href="https://www.jointcommission.org/resources/patient-safety-topics/report-a-patient-safety-concern-or-complaint"
      target="_blank"
      class="joint-commission-report-link">
      Click this link to file a report with the Joint Commission.
    </a>

  </div>

</div>



