import {Component, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatCard} from "@angular/material/card";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgTemplateOutlet} from "@angular/common";
import {CatalogsService} from "../../../../../shared/services/catalogs.service";
import {PortalUserService} from "../../../../shared/services/portal-user.service";
import {ProfileService} from "../../../../portal-user-profile/services/profile.service";
import {
  MultiLineSnackBarService
} from "../../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {Education} from "../../../../shared/types";
import {Observable, of} from "rxjs";
import {MatTooltip} from "@angular/material/tooltip";
import {CustomDateMonthComponent} from "../../../../../shared/components/custom-date-month/custom-date-month.component";

@Component({
  selector: 'app-education-info-editor',
  standalone: true,
  imports: [
    FormsModule,
    MatCard,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatError,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    NgTemplateOutlet,
    ReactiveFormsModule,
    MatTooltip,
    CustomDateMonthComponent
  ],
  templateUrl: './education-info-editor.component.html',
  styleUrl: './education-info-editor.component.css'
})
export class EducationInfoEditorComponent {
  states = this.catalogService.getStates();
  @ViewChildren(CustomDateMonthComponent) customDateComponents: QueryList<CustomDateMonthComponent>;
  educations = this.profileService.getResumeData()?.education?.sort((a, b) => {
    if (a.graduationDate === null) return -1; // a comes first if its date is null
    if (b.graduationDate === null) return 1;  // b comes first if its date is null
    const dateA = new Date(a.graduationDate).getTime();
    const dateB = new Date(b.graduationDate).getTime();
    return dateB - dateA; // Sort by toDate in descending order
  }) ?? [];

  disablePage = false;

  educationFormArray = this.formBuilder.array([]) as FormArray;
  educationForm = this.formBuilder.group({

    education: this.educationFormArray
  });

  constructor(
    private catalogService: CatalogsService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private multiLineSnackBar: MultiLineSnackBarService,
  ) {

    this.educations?.forEach(edu => {
      this.addEducation(edu);
    });
  }


  addEducation(education: Education | null = null): void {
    const educationItem = this.formBuilder.group({
      schoolName: [education?.schoolName ?? '', [Validators.required]],
      city: [education?.city ?? '', [Validators.required]],
      state: [education?.state ?? '', [Validators.required]],
      graduationDate: [education?.graduationDate, [Validators.required]],
      degree: [education?.degree ?? '', [Validators.required]],
    });


    this.educationFormArray.push(educationItem, {emitEvent: false});
  }

  removeEducationItem(index: number): void {
    this.educationFormArray.removeAt(index);
  }

  save(): Observable<boolean> {
    this.markAllAsTouched();

    if (this.educationForm.invalid) {
      this.multiLineSnackBar.displayMessages(['Please correct the errors and try again.']);
      return of(false);
    }
    this.disablePage = true;
    const resumeData = this.profileService.getResumeData();
    resumeData.education = this.educationForm?.value?.education;
    resumeData.education.forEach((education: Education) => {
        if (education.graduationDate) {
          const grdDate = new Date(education.graduationDate);
          grdDate.setDate(1); // Set day to the first day of the month
          education.graduationDate = grdDate;
        }
      }
    )
    this.profileService.saveResumeData(resumeData);
    return of(true);

  }

  markAllAsTouched() {
    this.educationForm.markAllAsTouched();
    this.customDateComponents.forEach(customDateComponent => customDateComponent.markAllAsTouched());
  }
}
