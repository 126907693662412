<mat-card>
  <div class="home-job-applications-container unselectable">
    <div class="portal-home-section-title">Last Job Applications</div>

    @if ({list: lastJobApplications$ | async}; as jobApplications) {
      @if (!!jobApplications.list?.length) {
        <div class="job-application-list">
          @for (jobApplication of jobApplications.list; track jobApplication) {
            <mat-card class="job-application">
              <div class="job-detail">
                <div class="job-detail-item">{{ jobApplication.createdDttm | date : 'MM/dd/yyyy' }}</div>
                <div class="job-detail-item">{{ jobApplication.duration }} weeks, {{ jobApplication.shift }}</div>
              </div>

              <div class="job-detail">
                <div class="portal-home-item-title">{{ jobApplication.specialty }}</div>
                <div class="portal-home-item-title">{{ jobApplication.city }}, {{ jobApplication.state }}</div>
              </div>
            </mat-card>
          }
        </div>
      } @else {
        <div class="row-spacer portal-home-item-title">No job applications found.</div>
        <div class="row-spacer portal-home-item-title portal-home-link" (click)="navigateToJobSearch()">
          Search Job Orders
        </div>
      }
    }
  </div>

  <div class="end-spacer"></div>
</mat-card>
