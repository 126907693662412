import {Component, Input, OnInit} from '@angular/core';
import {HeaderComponent} from "../../shared/components/header/header.component";
import {BlogMockDataService} from "../services/blog-mock-data-service";
import {BlogListComponent} from "../blog-list/blog-list.component";
import {FeaturedBlogsComponent} from "../featured-blogs/featured-blogs.component";
import {FooterComponent} from "../../shared/components/footer/footer/footer.component";
import {PtbLayoutBlogComponent} from "../ptb-layout-blog/ptb-layout-blog.component";
import {PtbLayoutIndividualBlogComponent} from "./ptb-layout-individual-blog/ptb-layout-individual-blog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {AsyncPipe, NgIf} from "@angular/common";
import {Observable} from "rxjs";
import {Blog} from "../../shared/model/types";
import {BodyIndividualBlogComponent} from "./body-individual-blog/body-individual-blog.component";
import {RelatedPostComponent} from "./related-post/related-post.component";

@Component({
  selector: 'app-individual-blog-post',
  standalone: true,
  imports: [
    HeaderComponent,
    BlogListComponent,
    FeaturedBlogsComponent,
    FooterComponent,
    PtbLayoutBlogComponent,
    PtbLayoutIndividualBlogComponent,
    AsyncPipe,
    NgIf,
    BodyIndividualBlogComponent,
    RelatedPostComponent
  ],
  templateUrl: './individual-blog-post.component.html',
  styleUrl: './individual-blog-post.component.css'
})
export class IndividualBlogPostComponent implements  OnInit{
  blogId: number;
  blog$ :Observable<Blog>;

  constructor(private blogService: BlogMockDataService,private route:ActivatedRoute,  private router: Router) {

    this.blogId = (<{blogId: number}>this.router.getCurrentNavigation()?.extras.state).blogId ?? '';

  }

  ngOnInit() {
    this.blog$ = this.blogService.getIndividualBlog(this.blogId);
  }

  formatDate(date: Date) {
    return `${date.getDate()} ${date.toLocaleString('default', {month: 'short'})} ${date.getFullYear()}`;
  }

}
