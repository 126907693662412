<div class="portal-profile-section-container">
  @if( skillsChecklists().length===0) {
    <div class="portal-section-empty paragraf-big">No skills checklists added, you can add a skills
      checklist by hitting the Add Checklist button in the bottom right.</div>
  }
  @for (checklistRecord of skillsChecklists(); track checklistRecord.id) {
    <mat-card class="portal-profile-section-card">
      <div class="checklist-row">
        <div class="checklist-row-action-buttons">
          <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage" (click)="removeChecklist(checklistRecord.id)">
            <mat-icon>delete</mat-icon>
          </button>
          <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage" (click)="editChecklist(checklistRecord.checklistTemplate)">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
        <div class="checklist-field">
          <div class="checklist-field-label">Specialty</div>
          <div>{{ checklistRecord.checklistTemplate.specialty }}</div>
        </div>
        <div class="checklist-field">
          <div class="checklist-field-label">Submitted</div>
          <div>{{ checklistRecord.createdDttm | date: 'MM/dd/yyyy' }}</div>
        </div>
      </div>
    </mat-card>
  }
</div>

<div class="portal-profile-section-bottom-action-bar">
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="addChecklist()">
    @if (disablePage) {
      <mat-spinner diameter="25"></mat-spinner>
    } @else {
      Add Checklist
    }
  </button>
</div>
