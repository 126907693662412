import { Component } from '@angular/core';
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatCard} from "@angular/material/card";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatDialogActions} from "@angular/material/dialog";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {PortalUserService} from "../../shared/services/portal-user.service";
import {
  MultiLineSnackBarService
} from "../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {fieldMatchValidator} from "../../shared/validators/fieldMatchValidator";
import {PasswordValidatorErrorStateMatcher} from "../change-password/change-password.component";
import {CommunicationConsent} from "../../shared/types";

@Component({
  selector: 'app-communication-consent',
  standalone: true,
    imports: [
        MatButton,
        MatCard,
        MatCheckbox,
        MatDialogActions,
        MatError,
        MatFormField,
        MatIcon,
        MatIconButton,
        MatInput,
        MatLabel,
        MatSuffix,
        NgIf,
        ReactiveFormsModule
    ],
  templateUrl: './communication-consent.component.html',
  styleUrl: './communication-consent.component.css'
})
export class CommunicationConsentComponent {

  communicationConsentForm: UntypedFormGroup;

  private subscriptions: Subscription[] = [];
  comm: CommunicationConsent;

  constructor(private portalUserService: PortalUserService,
              private snackBar: MultiLineSnackBarService,
  ) {
  }

  ngOnInit() {
    this.setCommunicationConsentForm();
    this.portalUserService.getCommunicationConsent().subscribe(result => {
      if (result.isSuccess && result.value) {
        this.comm = result.value;
      }
      this.loadCommunicationConsent();
    });
  }


  private setCommunicationConsentForm(): void {
    this.communicationConsentForm =
      new UntypedFormGroup({
        receiveMarketingEmails: new UntypedFormControl(false),
        receiveOperationalTextMessages: new UntypedFormControl(false),
        receiveMarketingTextMessages: new UntypedFormControl(false)

      });
  }

  private loadCommunicationConsent(): void {
    this.communicationConsentForm.patchValue({
      receiveMarketingEmails: this.comm?.receiveMarketingEmails,
      receiveOperationalTextMessages: this.comm?.receiveOperationalTextMessages,
      receiveMarketingTextMessages: this.comm?.receiveMarketingTextMessages
    });
  }


  submitCommunicationConsent(): void {


    const comm = {
      receiveMarketingEmails: this.communicationConsentForm.get('receiveMarketingEmails')?.value ?? false,
      receiveOperationalTextMessages: this.communicationConsentForm.get('receiveOperationalTextMessages')?.value ?? false,
      receiveMarketingTextMessages: this.communicationConsentForm.get('receiveMarketingTextMessages')?.value ?? false
    }
    this.portalUserService.saveCommunicationConsent(comm)
      .subscribe(result => {
        const message: string[] = result.isSuccess
          ? ['Communication consent has been updated']
          : result.errorList;
        this.snackBar.displayMessages(message);
        if (result.isSuccess) {
          this.comm = comm;
        } else {
          this.loadCommunicationConsent();
        }
      });
  }



}



