import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BlogMockDataService} from "../../services/blog-mock-data-service";
import {map} from "rxjs";
import {AsyncPipe, NgIf} from "@angular/common";
import {RelatedPostItemComponent} from "./related-post-item/related-post-item.component";
import {Blog, RelatedBlog} from "../../../shared/model/types";

@Component({
  selector: 'app-related-post',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    RelatedPostItemComponent
  ],
  templateUrl: './related-post.component.html',
  styleUrl: './related-post.component.css'
})
export class RelatedPostComponent implements OnChanges{
  @Input() blog: Blog;
  relatedBlogs: RelatedBlog[];
  constructor(
    private blogService: BlogMockDataService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['blog'] && changes['blog'].currentValue) {
      const relatedBlogs = changes['blog'].currentValue.related;
      this.relatedBlogs = relatedBlogs.length > 3 ? relatedBlogs.slice(0, 3) : relatedBlogs;

    }
  }
  //
  // relatedBlogs$ = this.blog.related.pipe(
  //   map(blogs => blogs
  //     .filter(blog => blog.featured !== 0 )  // Exclude items with 'featured' as -1
  //     .sort((a, b) => b.featured - a.featured)) // Order by 'featured' in descending order
  // );
}
