<div class="nurse-comments-frame" *ngIf="nurseCommentsToShow$ | async as nurseCommentToShow">
  <div #scrollableContent class="nurse-comments">
    @for (nurseComment of nurseCommentToShow; track nurseComment)
    {
      <div class="flex-n">
      <app-nurse-comment
        [imagePath]=nurseComment.imagePath
        [name]=nurseComment.name
        [comment]=nurseComment.comment
        [starCount]=nurseComment.starCount>
      </app-nurse-comment>
        </div>

    }
  </div>
  <div class="nurse-comments-footer">
    <div class="arrows">
      <div class="arrow btn-hover" (click)="moveLeft()">
        <img src="assets/svg/chevron.svg" alt="try1" width="24" height="24">
      </div>
      <div class="arrow btn-hover" (click)="moveRight()">
        <img src="/assets/svg/chevron.svg" alt="try" width="24" height="24" class="rotate">
      </div>
    </div>
  </div>
</div>
