<div class="body-cta">
  <div class="cta-title-frame">
    <div class="flex-row">
      <span class="cta-title"><h3>MORE&nbsp;</h3></span>
      <span class="cta-title-italic"><h3>QUESTIONS?</h3></span>
    </div>
    <div class="line-white"></div>
  </div>
  <div class="cta-middle-text">Simply contact us and one of our highly trained staff will assist you.</div>

  <a href="/contact" class="cta-call-us">

<!--      <img ngSrc="assets/svg/call.svg" alt="" height="18" width="18">-->

      <span class="cta-call-us-text button-text">CONTACT US</span>

  </a>
</div>
