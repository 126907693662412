import {Component, Input} from '@angular/core';
import {ConnectOnlineComponent} from "../../../about/contact/connect-online/connect-online.component";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {JobOrder} from "../../../shared/model/types";

@Component({
  selector: 'app-job-detail-header',
  standalone: true,
  imports: [
    ConnectOnlineComponent,
    HeaderComponent
  ],
  templateUrl: './job-detail-header.component.html',
  styleUrl: './job-detail-header.component.css'
})
export class JobDetailHeaderComponent {
  @Input() state: string;
  @Input() city: string;

}
