<div class="rapid-app-form">

  <div class="rapid-app-summary">

    <div class="rapid-app-summary-title">
      <div class="rapid-app-summary-title-text">Extraordinary Opportunities for Exceptional Nurses</div>
      <div class="line-green" style="margin-left:0"></div>
    </div>

    <div class="rapid-app-summary-content-text">
      <div><p>If you’re an experienced nurse and you love what you do — you’re in the right place. <a
        href="https://www.nightingalenurses.net/about">Nightingale is the
        travel nursing agency</a> for nurses who know their worth and want an agency that looks out for their best
        interests. We offer some of the best pay in the travel nursing industry, <a
          href="https://www.nightingalenurses.net/benefits">competitive benefits packages</a>, and
        outstanding 24/7 on-call support for you.
      </p>

      </div>
      <div><p>On this page, you will find all the resources and information you need to apply to work with Nightingale.
        If
        you would like to search for open travel nursing positions with our firm you can search by specialty or by
        state at <a href="/job-search" class="highlight">this link</a>.
      </div>
    </div>


  </div>

  <div class="rapid-app-form-inputs">
    <div class="rapid-app-form-inputs-title">
      <div class="flex-row-left"><h3>
        <span class="title2">YOUR </span>
        <span class="title2-italic">LEGEND </span>
        <span class="title2">AWAITS </span>
      </h3>
      </div>
      <div class="title2-paragraph">
        <p>Tell us a little about you.</p>
      </div>
    </div>

    <form [formGroup]="applyForm" class="rapid-app-form-frame" *ngIf="combined$ | async">
      <div class="form-input no-error-border">
        <label [ngClass]="getClass('firstName')">First Name<span style="color:red"> *</span></label>
        <input class="input-x" formControlName="firstName">
      </div>

      <div class="form-input no-error-border">
        <label [ngClass]="getClass('lastName')">Last Name<span style="color:red"> *</span></label>
        <input class="input-x" formControlName="lastName">
      </div>

      <div class="form-input no-error-border">
        <label [ngClass]="getClass('phone')">Phone<span style="color:red"> *</span></label>
        <input class="input-x"
               formControlName="phone"
               id="phone"
               placeholder="(___) ___-____"
               (focusout)="onFocusOutEvent($event)">
      </div>

      <div class="form-input no-error-border">
        <label [ngClass]="getClass('email')">Email<span style="color:red"> *</span></label>
        <input type="email" class="input-x" formControlName="email">
      </div>

      <div class="form-input no-error-border">
        <label [ngClass]="getClass('specialty')">Primary Specialty<span style="color:red"> *</span></label>
        <app-select-single [options]="specialties"
                           [divId]="'specialty'"
                           formControlName="specialty"
                           tabindex="0">
        </app-select-single>
      </div>

      <div class="form-input no-error-border">
        <label [ngClass]="getClass('secondarySpecialties')">Additional Specialties</label>

        <app-select-chip [options]="specialties"
                         [divId]="'secondarySpecialties'"
                         formControlName="secondarySpecialties"
                         tabindex="0"
        ></app-select-chip>
      </div>

      <div class="form-input no-error-border">
        <label [ngClass]="getClass('statesLicensed')">States Licensed<span style="color:red"> *</span></label>
        <app-select-chip [options]="states"
                         [divId]="'statesLicensed'"
                         formControlName="statesLicensed"
                         tabindex="0"></app-select-chip>
      </div>

      <div class="span2 compact-license">
        <div class="check-box-frame">
          <input type="checkbox" id="compactLicense" formControlName="hasCompactLicense" tabindex="0"/>
          <label for="compactLicense" class="check-box-text"><h6>Compact License</h6></label>
        </div>

        <div class="compact-license-text">
          <p> By providing your phone number, you agree to receive text messages from Nightingale Nurses. Messaging and
            data rates may apply, and messaging frequency varies. </p>
        </div>
      </div>
      <div class="form-button button-text span2" (click)="saveApplication()" tabindex="0">START&nbsp;ADVENTURE</div>
      <!--      </div>-->
    </form>


  </div>

</div>
