<div class="portal-profile-section-container">
  @if( educationHistory().length===0) {
    <div class="portal-section-empty paragraf-big">No education added, you can add an education by hitting
      the Add Education button in the bottom right.</div>
  }
  @for (educationRecord of educationHistory(); track educationRecord.id) {
    <mat-card class="portal-profile-section-card">
      <div class="education-row">
        <div class="education-row-action-buttons">
          <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage"
                  (click)="removeEducation(educationRecord.id)">
            <mat-icon>delete</mat-icon>
          </button>
          <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage"
                  (click)="editEducation(educationRecord)">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
        <div class="education-field">
          <div class="education-field-label">School</div>
          <div>{{ educationRecord.schoolName }}</div>
        </div>
        <div class="education-field">
          <div class="education-field-label">Degree</div>
          <div>{{ educationRecord.degree }}</div>
        </div>
        <div class="education-field">
          <div class="education-field-label">Graduation Date</div>
          <div>{{ educationRecord.graduationDate | monthYear }}</div>
        </div>
        <div class="education-field">
          <div class="education-field-label">City</div>
          <div>{{ educationRecord.city }}</div>
        </div>
        <div class="education-field">
          <div class="education-field-label">State</div>
          <div>{{ educationRecord.state }}</div>
        </div>
      </div>
    </mat-card>
  }
</div>

<div class="portal-profile-section-bottom-action-bar">
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="addEducation()">
    @if (disablePage) {
      <mat-spinner diameter="25"></mat-spinner>
    } @else {
      Add Education
    }
  </button>
</div>
