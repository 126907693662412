<div class="body-main-job-list-container">

  <div class="job-list-with-sort">
    <div class="sort-items-block">
      <div class="sort-with-select">
        <div class="sort-with">Sort by:</div>

        <select id="sortColumn" class="sort-items-select" [(ngModel)]="sortColumn" (change)="onSortColumnChanged($event)">
          <option *ngFor="let option of sortOptions" [value]="option.value">{{ option.label }}</option>
        </select>
      </div>

      <img
        ngSrc="/assets/svg/north.svg" alt="" width="18" height="18"
        class="icon-button {{getIconClass()}}"
        [matTooltip]="sortDirection === 'Desc' ? 'Descending' : 'Ascending'"
        (click)="onSortDirectionChanged()"
      >
    </div>

    <div class="jobs-list">
      @for (jobOrder of visibleJobOrders(); track jobOrder.id; let index = $index) {
        <div class="flex-r">
          <app-job
            [jobOrder]="jobOrder"
            [faded]="false"
            [carousel]=" CarouselType.None"
          ></app-job>
        </div>
      } @empty {
        <div class="no-jobs-found">
          <h4>No jobs found</h4>
        </div>
      }
    </div>
  </div>

  <div class="desktop-tablet">
    <div class="table-last-row">
      <app-table-footer
        [itemsPerPage]=itemsPerPage
        [totalItems]=jobOrdersCount()
        [firstItem]=firstItemIndex
        [reload]="true"
        [windowSize]=4
        (requestedPageEvent)="onFooterClick($event)"
      ></app-table-footer>
    </div>
  </div>

  <div class="mobile">
    <div class="table-last-row">
      <app-table-footer
        [itemsPerPage]=itemsPerPage
        [totalItems]=jobOrdersCount()
        [firstItem]=firstItemIndex
        [reload]="true"
        [windowSize]=2
        (requestedPageEvent)="onFooterClick($event)"
      ></app-table-footer>
    </div>
  </div>
</div>
