<div class="contact-card">

  <div class="contact">
    <div class="card-header">
      corporate headquarters
    </div>
    <div>
      <div class="card-text">
        {{ companyContactInfo.address.address1 }}
      </div>
      <div class="card-text">{{ companyContactInfo.address.address2 }}</div>
      <div class="card-text">{{ companyContactInfo.address.city }}
        , {{ companyContactInfo.address.state }} {{ companyContactInfo.address.zip }}
      </div>
    </div>
    <div class="phone" style="padding-top: 6px;">
      <a href="tel:{{ companyContactInfo.phone }}">{{ companyContactInfo.phone }}</a>
    </div>
  </div>

  <div class="contact">
    <div class="card-header">
      24HR Support
    </div>

    <div class="phone">
      <a href="tel:{{ companyContactInfo.supportPhone }}">{{ companyContactInfo.supportPhone }}</a>
    </div>
  </div>

  <div class="contact">
    <div class="card-header">
      Credentials / Contracts Fax
    </div>
    <div class="phone">
      <a href="tel:{{ companyContactInfo.credentialsFax }}">{{ companyContactInfo.credentialsFax }}</a>
    </div>
  </div>

  <div class="contact">
    <div class="card-header">
      Timesheets Fax
    </div>
    <div class="phone">
      <a href="tel:{{ companyContactInfo.timesheetFax }}">{{ companyContactInfo.timesheetFax }}</a>
    </div>

    <div class="email">
      <a href="mailto:{{ companyContactInfo.timesheetEmail }}">
        {{ companyContactInfo.timesheetEmail }}
      </a>
    </div>
  </div>

  <div class="contact">
    <div class="card-header">
      Verification of Employment
    </div>
    <div class="phone">
      <a href="tel:{{ companyContactInfo.employeeVerificationPhone }}">
        {{ companyContactInfo.employeeVerificationPhone }}
      </a>
    </div>
  </div>
</div>
