import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {NgOptimizedImage} from '@angular/common';
import {HeaderComponent} from '../../shared/components/header/header.component';
import {CatalogsService} from '../../shared/services/catalogs.service';
import {BrowseJobsComponent} from "./browse-jobs/browse-jobs.component";

@Component({
  selector: 'app-hero',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgOptimizedImage,
    HeaderComponent,
    BrowseJobsComponent
  ],
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.css'
})
export class HeroComponent {

  searchForm: FormGroup<{
    specialty: FormControl<string | null>,
    state: FormControl<string | null>,
  }>;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.searchForm = this.formBuilder.group({
      specialty: this.formBuilder.control('Specialty'),
      state: this.formBuilder.control('State'),
    });
  }

}
