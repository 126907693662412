import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {CompanyContactInfo} from "../model/types";
import companyContact from "../../../assets/data/companyContact.json";

@Injectable({
  providedIn: 'root'
})
export class ContactDetailsService {

  getContactInfo(): Observable<CompanyContactInfo> {
    return of(companyContact);
  }
}
