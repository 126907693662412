import {Component} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-faq-item-1',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './faq-item-1.component.html',
  styleUrl: './faq-item-1.component.css'
})
export class FaqItem1Component {
  expandAnswer:boolean=false;
  toggleAnswer(){
    this.expandAnswer=!this.expandAnswer;
  }
}
