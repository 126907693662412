import {Component} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {Router} from "@angular/router";
import {CompanyContactInfo} from "../../../model/types";
import {ContactDetailsService} from "../../../services/contact-details.service";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {

  companyContactInfo: CompanyContactInfo;

  constructor(contactMockDataServiceService: ContactDetailsService) {
    contactMockDataServiceService.getContactInfo().subscribe(contactInfo => {
      this.companyContactInfo = contactInfo;
    });
  }
}
