<div class="faq-item">
  <div>
    <div class="number">07</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>Does Nightingale reimburse for license and certification expense?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>Yes. As part of the assignment, untaxed reimbursement for expenses incurred by the clinician
          to obtain a new license, certification or required CEU.
          All such reimbursements are included in the clinician’s compensation package.</p>

      </div>
    }

  </div>

</div>

