import {computed, Injectable, signal} from '@angular/core';
import {catchError, map, Observable, of, tap,} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {createFailedResult, Result} from '../../../shared/model/result';
import {
  CommunicationConsent,
  Education,
  LoggedInUser,
  PortalRegistrationData,
  PortalToken,
  PortalUserFileMin,
  PortalUserProfile,
  ProfilePersonalInfo,
  ProfileProfessionalInfo,
  Reference,
  SkillsChecklist,
  WorkHistory
} from '../types';
import {MatDialog} from '@angular/material/dialog';
import {PortalLoginDialogComponent} from '../../portal-login-dialog/portal-login-dialog.component';
import {PortalSectionCompleteness} from '../../../shared/model/types';

@Injectable({
  providedIn: 'root',
})
export class PortalUserService {

  constructor(
    private httpClient: HttpClient,
    private matDialog: MatDialog,
  ) {
  }

  private user = signal(this.getActiveUser());
  activeUser = this.user.asReadonly();

  private token = signal(this.getActiveToken());
  activeToken = this.token.asReadonly();

  profileCompleteness = computed(() => {
      return [
        this.getPersonalInfoCompleteness(this.activeUser()?.personalInfo),
        this.getProfessionalInfoCompleteness(this.activeUser()?.professionalInfo),
        this.getWorkHistoryCompleteness(this.activeUser()?.workHistory),
        this.getEducationCompleteness(this.activeUser()?.education),
        this.getReferencesCompleteness(this.activeUser()?.references),
        this.getSkillsChecklistsCompleteness(this.activeUser()?.skillsChecklists),
        this.getMyFilesCompleteness(this.activeUser()?.files),
      ];
    }
  );

  selectedTab = signal('Home');

  login(email: string, password: string,): Observable<Result<LoggedInUser | null>> {
    return this.httpClient.post<Result<LoggedInUser | null>>('login', {
      email,
      password
    })
      .pipe(
        catchError(() => of({
          isSuccess: false,
          value: null,
          errorList: ['An error occurred, please try again.'],
          warningList: [],
          infoList: [],
        }))
      );
  }

  registerUser(registrationData: PortalRegistrationData): Observable<Result<LoggedInUser | null>> {
    return this.httpClient.post<Result<LoggedInUser | null>>('register', registrationData)
      .pipe(
        catchError(() => of({
          isSuccess: false,
          value: null,
          errorList: ['An error occurred, please try again.'],
          warningList: [],
          infoList: [],
        }))
      );
  }

  clearUserData(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.user.set(null);
    this.token.set(null);
  }

  setActiveUser(user: LoggedInUser): void {
    if (this.user()?.userEmail !== user.userProfile.userEmail) {
      this.user.set(null);
    }

    // this is needed due to the login dialog
    // if the user logs in with a different account then was previously logged in, the timeout gives the application
    // the necessary time to clear the user data before setting the new user data
    setTimeout(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.setItem('user', JSON.stringify(user.userProfile));
      localStorage.setItem('token', JSON.stringify(user.token));

      this.user.set(this.getActiveUser());
      this.token.set(user.token);
    }, 250);
  }

  tryRefreshToken(): Observable<Result> {
    const refreshToken = encodeURIComponent(this.activeToken()?.refreshToken ?? '');
    return this.httpClient.post<Result<PortalToken>>(`refresh-token?refreshToken=${refreshToken}`, {})
      .pipe(
        tap((result: Result<PortalToken>) => {
          if (result.isSuccess) {
            const token = result.value;
            localStorage.removeItem('token');
            localStorage.setItem('token', JSON.stringify(token));
            this.token.set(token!);
          } else {
            this.matDialog.open(PortalLoginDialogComponent, {
              disableClose: true,
              width: '300px',
              data: 'SESSION_EXPIRED',
            });
          }
        }),
        map(result => ({
          isSuccess: result.isSuccess,
          value: undefined,
          errorList: result.errorList,
          warningList: result.warningList,
          infoList: result.infoList
        })),
      );
  }

  setPersonalInfo(personalInfo: ProfilePersonalInfo): void {
    const user = this.getActiveUser();
    if (user) {
      user.personalInfo = personalInfo;
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  setProfessionalInfo(professionalInfo: ProfileProfessionalInfo): void {
    const user = this.getActiveUser();
    if (user) {
      user.professionalInfo = professionalInfo;
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  setWorkHistory(workHistory: WorkHistory[]): void {
    const user = this.getActiveUser();
    if (user) {
      if(workHistory!=null) {
        workHistory = this.fixWorkHistoryDates(workHistory);
      }
      user.workHistory = workHistory;
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  setUserResumeInfo(resumeInfo: PortalUserProfile): void {

    this.setPersonalInfo(resumeInfo.personalInfo);
    this.setProfessionalInfo(resumeInfo.professionalInfo);
    this.setEducation(resumeInfo.education);

    this.setReferences(resumeInfo.references);
    this.setWorkHistory(resumeInfo.workHistory);
  }


  setEducation(education: Education[]): void {
    const user = this.getActiveUser();
    if (user) {
     if(education!=null) {
       education = this.fixEducationDates(education);
     }
      user.education = education;
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  setReferences(references: Reference[]): void {
    const user = this.getActiveUser();
    if (user) {
      if(references!=null) {
        references = this.fixReferenceDates(references);
      }
      user.references = references;
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  setSkillsChecklists(checklists: SkillsChecklist[]): void {

    const user = this.getActiveUser();
    if (user) {
      if(checklists!=null) {
        checklists = this.fixChecklistDates(checklists);
      }
      user.skillsChecklists = checklists;
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  addValidFile(file: PortalUserFileMin): void {
    const user = this.getActiveUser();
    if (user) {
      user.files.push(file);
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  tryRemoveFile(fileId: number): void {
    const user = this.getActiveUser();
    if (user) {
      user.files = user.files.filter(f => f.id !== fileId);
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  setUserStatus(status: string): void {
    const user = this.getActiveUser();
    if (user) {
      user.userStatus = status;
      this.user.set(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  private getActiveUser(): PortalUserProfile | null {
    if (typeof window === 'undefined') {
      return null;
    }

    const userJson = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    // if the token or user is empty clear any data
    if (!token || !userJson) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      return null;
    }

    const tokenData = JSON.parse(token) as PortalToken;
    // if the token is expired, clear any data
    if (new Date(tokenData.tokenExpirationDttm) < new Date()) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      return null;
    }

    const portalUser = JSON.parse(userJson) as PortalUserProfile;
    if (!portalUser) {
      return null;
    }

    portalUser.workHistory = this.fixWorkHistoryDates(portalUser.workHistory ?? []);
    portalUser.education = this.fixEducationDates(portalUser.education ?? []);
    portalUser.references = this.fixReferenceDates(portalUser.references ?? []);
    portalUser.skillsChecklists = this.fixChecklistDates(portalUser.skillsChecklists ?? []);
    return portalUser;
  }

  private getActiveToken(): PortalToken | null {
    let tokenJson = null;
    if (typeof window !== 'undefined') {
      tokenJson = localStorage.getItem('token');
      const token = localStorage.getItem('token');
      // if the token is empty or expired, clear the user data
      if (!token) {
        // this.clearUserData();
        return null;
      }

      const tokenData = JSON.parse(token) as PortalToken;
      if (tokenData.tokenExpirationDttm < new Date()) {
        // this.clearUserData();
        return null;
      }
    }

    return tokenJson ? JSON.parse(tokenJson) as PortalToken : null;
  }

  private fixWorkHistoryDates(workHistory: WorkHistory[]): WorkHistory[] {
    for (let history of workHistory) {
      history.fromDate = !!history.fromDate ? new Date(history.fromDate) : history.fromDate;
      history.toDate = !!history.toDate ? new Date(history.toDate) : history.toDate;
    }
    return workHistory;
  }

  private fixEducationDates(education: Education[]): Education[] {
    for (let item of education) {
      item.graduationDate = !!item.graduationDate ? new Date(item.graduationDate) : item.graduationDate;
    }
    return education;
  }

  private fixReferenceDates(references: Reference[]): Reference[] {
    for (let reference of references) {
      reference.startDate = !!reference.startDate ? new Date(reference.startDate) : reference.startDate;
      reference.endDate = !!reference.endDate ? new Date(reference.endDate) : reference.endDate;
    }
    return references;
  }

  private fixChecklistDates(checklists: SkillsChecklist[]): SkillsChecklist[] {
    for (let checklist of checklists) {
      checklist.createdDttm = !!checklist.createdDttm ? new Date(checklist.createdDttm) : checklist.createdDttm;
    }
    return checklists;
  }

  private getPersonalInfoCompleteness(personalInfo: ProfilePersonalInfo | undefined | null): PortalSectionCompleteness {
    if (!personalInfo) {
      return new PortalSectionCompleteness('Personal Info', 0, 0)
    }

    let counter = 0;

    if (!!personalInfo.firstName) {
      counter++;
    }

    if (!!personalInfo.lastName) {
      counter++;
    }

    if (!!personalInfo.dateOfBirth) {
      counter++;
    }

    if (!!personalInfo.gender) {
      counter++;
    }

    if (!!personalInfo.ssn) {
      counter++;
    }

    if (!!personalInfo.address1) {
      counter++;
    }

    if (!!personalInfo.city) {
      counter++;
    }

    if (!!personalInfo.state) {
      counter++;
    }

    if (!!personalInfo.zip) {
      counter++;
    }

    if (!!personalInfo.emergencyContactName) {
      counter++;
    }

    if (!!personalInfo.emergencyContactPhone) {
      counter++;
    }

    if (!!personalInfo.emergencyContactRelationship) {
      counter++;
    }

    if (!!personalInfo.emergencyContactAddress) {
      counter++;
    }

    if (!!personalInfo.emails.length) {
      counter++;

    }

    if (!!personalInfo.phones.length) {
      counter++;

    }

    return new PortalSectionCompleteness('Personal Info', counter, 15);
  }

  private getProfessionalInfoCompleteness(professionalInfo: ProfileProfessionalInfo | undefined | null): PortalSectionCompleteness {
    if (!professionalInfo) {
      return new PortalSectionCompleteness('Professional Info', 0, 0)
    }

    let counter = 0;

    if (professionalInfo.hadLicenseSuspended !== null && professionalInfo.hadLicenseSuspended !== undefined) {
      counter++;
    }

    if (professionalInfo.wasConvictedOfCrime !== null && professionalInfo.wasConvictedOfCrime !== undefined) {
      counter++;
    }

    if (professionalInfo.legalToWork !== null && professionalInfo.legalToWork !== undefined) {
      counter++;
    }

    if (!!professionalInfo.specialty) {
      counter++;
    }

    if (!!professionalInfo.statesLicensed?.length) {
      counter++;
    }

    return new PortalSectionCompleteness('Professional Info', counter, 5);
  }

  private getWorkHistoryCompleteness(workHistory: WorkHistory[] | undefined | null): PortalSectionCompleteness {
    if (!workHistory) {
      return new PortalSectionCompleteness('Work History', 0, 0);
    }

    return new PortalSectionCompleteness('Work History', workHistory.length > 0 ? 1 : 0, 1);
  }

  private getEducationCompleteness(education: Education[] | undefined | null): PortalSectionCompleteness {
    if (!education) {
      return new PortalSectionCompleteness('Education', 0, 0);
    }

    return new PortalSectionCompleteness('Education', education.length > 0 ? 1 : 0, 1);
  }

  private getReferencesCompleteness(references: Reference[] | undefined | null): PortalSectionCompleteness {
    if (!references) {
      return new PortalSectionCompleteness('References', 0, 0);
    }

    switch (references.length) {
      case 0 :
        return new PortalSectionCompleteness('References', 0, 2);
      case 1 :
        return new PortalSectionCompleteness('References', 1, 2);
      default :
        return new PortalSectionCompleteness('References', 2, 2);
    }
  }

  private getSkillsChecklistsCompleteness(skillsChecklists: SkillsChecklist[] | undefined | null): PortalSectionCompleteness {
    if (!skillsChecklists) {
      return new PortalSectionCompleteness('Skills Checklists', 0, 0);
    }

    return new PortalSectionCompleteness('Skills Checklists', skillsChecklists.length > 0 ? 1 : 0, 1);
  }

  private getMyFilesCompleteness(files: PortalUserFileMin[] | undefined | null): PortalSectionCompleteness {
    if (!files) {
      return new PortalSectionCompleteness('My Files', 0, 0)
    }

    let counter = files.filter(i => i.requirementClass === 'candidateIdentification').length > 0 ? 1 : 0;

    if (files.filter(i => i.requirementClass === 'candidateLicense').length > 0) {
      counter++;
    }

    return new PortalSectionCompleteness('My Files', counter, 2);
  }

  sendPasswordResetLink(email: string): Observable<Result> {
    return this.httpClient.post<Result>(`send-password-reset-link?email=${email}`, {});
  }

  validateResetToken(token: string | null): Observable<Result> {
    return this.httpClient.post<Result>(`validate-password-reset-token?token=${token}`, {});

  }

  savePassword(token: string, password: string): Observable<Result> {
    return this.httpClient.post<Result>(`update-portal-password?token=${token}&password=${password}`, {});
    // .pipe(
    //   catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
    // );
  }
  updateLoggedInUserPassword(oldPassword:string, newPassword:string ) :  Observable<Result> {

    return this.httpClient.post<Result>(`change-password?oldPassword=${oldPassword}&newPassword=${newPassword}`, {})
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }
  getCommunicationConsent() :  Observable<Result<CommunicationConsent | null>> {
    // @ts-ignore
    return this.httpClient.get<Result<CommunicationConsent | null>>(`profile/communication-consent`)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }

  saveCommunicationConsent(consent: CommunicationConsent) :  Observable<Result> {
    return this.httpClient.post<Result>(`profile/communication-consent`, consent)
      .pipe(
        catchError(e => of(createFailedResult(['An error occurred, please try again.'])))
      );
  }

}
