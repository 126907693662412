<div class="table-row">
  <div class="column-state state-font"><p>{{ state }}</p></div>
  <div class="column-phone content-font"><p>{{phone |phone }}</p></div>
<!--  <div class="column-phone content-font">{{convertPhone() }}</div>-->
  <div class="column-other-data content-font"><p>{{convertWalkThrough()}}</p></div>
  <div class="column-other-data content-font"><p>{{convertCompact()}}</p></div>
  <div class="column-other-data content-font"><p>{{convertNursys()}}</p></div>
  <div class="column-end list-font">
    <ul class="list-style">
      @for(item of info ; track item; let index = $index)
      {
         <li><p>{{item}}<p></li>
      }
    </ul>
  </div>

</div>
