<div class="ptb-layout-faq">
  <app-header style="width: 100%"></app-header>
  <div class="ptb-main-frame">
    <div class="ptb-explanation-frame">
      <div class="ptb-title-frame">
        <div class="ptb-faq-title"><h2>Travel Nurse <br>
          <span class="ptb-faq-title-italic">Tips & Questions</span>
        </h2></div>
      </div>
      <div class="ptb-faq-title-explanation">Some of our most popular inquiries. If you have additional questions,
        <br>
        call and speak to a Nightingale Recruiter today.
      </div>
    </div>
    <div class="line-green"></div>
  </div>
</div>

