import {Component} from '@angular/core';
import {HeaderComponent} from '../../shared/components/header/header.component';
import {BodyRapidAppComponent} from "../../rapid-app/body-rapid-app/body-rapid-app.component";
import {FooterComponent} from "../../shared/components/footer/footer/footer.component";
import {PtbLayoutRapidAppComponent} from "../../rapid-app/ptb-layout-rapid-app/ptb-layout-rapid-app.component";
import {CallToActionComponent} from "../../shared/components/call-to-action/call-to-action.component";
import {PtbLayoutPoliciesComponent} from "./ptb-layout-policies/ptb-layout-policies.component";
import {BodyPoliciesComponent} from "./body-policies/body-policies.component";
import {ActivatedRoute} from '@angular/router';
import {SeoUtilsService} from '../../shared/services/seo-utils.service';

@Component({
  selector: 'app-company-policies',
  standalone: true,
  imports: [
    HeaderComponent,
    BodyRapidAppComponent,
    FooterComponent,
    PtbLayoutRapidAppComponent,
    CallToActionComponent,
    PtbLayoutPoliciesComponent,
    BodyPoliciesComponent
  ],
  templateUrl: './company-policies.component.html',
  styleUrl: './company-policies.component.css'
})
export class CompanyPoliciesComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private seoUtilsService: SeoUtilsService,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'State Specific Travel Nursing Policy & Laws - Nightingale',
      'Every state has different and sometime unique work or leave policies, here\'s our resources to help keep travel nurses informed.',
      'policy-travel-nurse'
    );
  }
}
