<div class="table-row-h">
  <div class="column-state-h header-font-h"><h6>State</h6></div>
  <div class="column-phone-h header-font-h"><h6>Phone</h6></div>
  <div class="column-other-data-h header-font-h"><h6 matTooltip="Offers an expedited temporary license">Walk Through State</h6></div>
  <div class="column-other-data-h header-font-h"><h6 matTooltip="Accepts the common NLC license">Compact State</h6></div>
  <div class="column-other-data-h header-font-h"><h6>Nursys</h6></div>
  <div class="column-licence header-font-h"><h6>License Info</h6></div>
  <div class="search">
    <img ngSrc="./assets/svg/icon_search-dark.svg" alt="" height="18" width="18" (click)="onSearchClick()"  >
    <input class="input-style search-text" type="text"  placeholder="Search" [(ngModel)]="search"
           (keyup.enter)="onSearchClick()">
  </div>
</div>
