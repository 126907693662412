<div class="body-individual-blog-main-frame">
<!--  <div class="inner-frame">-->
    <img ngSrc="{{blog.imagePath}}" alt="image2" class="item-frame-image"
         width="1" height="1">
  <div class= "inner-frame">
  <div class="item-explanation-frame">
      <div class="blog-title"><h3>{{ blog.title }}</h3></div>
      <div class="sender-text"> {{ blog.author }} <span class="dark-text">• {{ formatDate(blog.date) }}</span></div>
    </div>
    <div class="blog-text">
      <p *ngFor="let paragraph of blog.content.split('\n\n')">{{ paragraph }}</p>
    </div>


    <div class="tags-media">
      <div class="tags">
        <div class="tag-text">Tags</div>

        <div class="tag-frame">
          <div class="button-layout" *ngFor="let tag of blog.tags">{{ tag }}</div>
        </div>
      </div>

      <div class="tags" style="display: none">
        <div class="tag-text">Share</div>

        <div class="media-frame">
          <img ngSrc="/assets/svg/icon_share_facebook.svg" alt="image2" class="media-icon" width="1" height="1">
          <img ngSrc="/assets/svg/icon_twitter.svg" alt="image2" class="media-icon" width="1" height="1">
          <img ngSrc="/assets/svg/icon_share_linkedin.svg" class="media-icon" alt="image2" width="1" height="1">
          <img ngSrc="/assets/svg/icon_share_telegram.svg" class="media-icon" alt="image2" width="1" height="1">
          <img ngSrc="/assets/svg/icon_share_whatsapp.svg" class="media-icon" alt="image2" width="1" height="1">
        </div>
      </div>
    </div>
  </div>
  <!--  </div>-->
</div>
