<div class="mobile-dialog-header">
  {{!!data ? 'Edit' : 'Add'}} Education
</div>
<mat-dialog-content>
  <div [formGroup]="educationForm" class="form-row">
    <mat-form-field>
      <mat-label>School</mat-label>
      <input matInput formControlName="schoolName">
      @if (educationForm.controls.schoolName.hasError('required')) {
        <mat-error>School Name is required</mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label>City</mat-label>
      <input matInput formControlName="city">
      @if (educationForm.controls.city.hasError('required')) {
        <mat-error>City is required</mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label>State</mat-label>
      <mat-select formControlName="state">
        @for (state of states; track state.code) {
          <mat-option [value]="state.code">{{ state.name }}</mat-option>
        }
      </mat-select>
      @if (educationForm.controls.state.hasError('required')) {
        <mat-error>State is required</mat-error>
      }

    </mat-form-field>
    <div class="custom-date-picker">
      <app-custom-date-month formControlName="graduationDate" [placeHolder]="'Graduation Date'"
                             [required]="true"></app-custom-date-month>
    </div>

    <mat-form-field>
      <mat-label>Degree</mat-label>
      <input matInput formControlName="degree">
      @if (educationForm.controls.degree.hasError('required')) {
        <mat-error>Degree is required</mat-error>
      }
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [disabled]="disablePage" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="saveEducation()">Save</button>
</mat-dialog-actions>
