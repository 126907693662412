<div class="referral-header-container">

  <app-header style="width: 100%"></app-header>


  <div class="referral-legend">
    <div class="referral-header  flex-column">
      <h2>
        <span class="referral-title">Refer a </span>
        <span class="referral-title-italic">Legend </span>
      </h2>
      <div class="referral-title-description">
        Start your journey to earning big bucks today! Tell us about the person you're referring as well as yourself.
        There is no limit to the number of bonuses you can earn; extra cash is a referral away!
      </div>
    </div>

    <div class="line-green">
    </div>
  </div>
</div>
