<div class="flex-column portal-profile-section-container">
  @if( user()?.references?.length === 0) {
    <div class="portal-section-empty paragraf-big">No references added, you can add a
      reference by hitting the Add Reference button in the bottom right.</div>
  }
  @for (reference of user()?.references ?? []; track reference.id) {
    <mat-card class="portal-profile-section-card">
      <div class="reference-row">
        <div class="reference-row-action-buttons">
          <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage" (click)="removeReference(reference.id)">
            <mat-icon>delete</mat-icon>
          </button>
          <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage" (click)="editReference(reference)">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
        <div class="reference-field">
          <div class="reference-field-label">Name</div>
          <div>{{ reference.firstName }} {{reference.lastName}}</div>
        </div>
        <div class="reference-field">
          <div class="reference-field-label">Phone</div>
          <div>{{ reference.phone | phone }}</div>
        </div>
        <div class="reference-field">
          <div class="reference-field-label">Email</div>
          <div>{{ reference.email }}</div>
        </div>
        <div class="reference-field">
          <div class="reference-field-label">My Position</div>
          <div>{{ reference.myPosition }}</div>
        </div>
        <div class="reference-field">
          <div class="reference-field-label">Reference Position</div>
          <div>{{ reference.referencePosition }}</div>
        </div>
        <div class="reference-field">
          <div class="reference-field-label">Hospital</div>
          <div>{{ reference.hospital }}</div>
        </div>
        <div class="reference-field">
          <div class="reference-field-label">Start - End</div>
          <div>{{ reference.startDate | date: 'MM/dd/yyyy' }} - {{reference.endDate | date: 'MM/dd/yyyy'}}</div>
        </div>
      </div>
    </mat-card>
  }
</div>

<div class="portal-profile-section-bottom-action-bar">
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="addReference()">
    @if (disablePage) {
      <mat-spinner diameter="25"></mat-spinner>
    } @else {
      Add Reference
    }
  </button>
</div>
