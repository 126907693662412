import {Component, Inject} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Education, Reference} from '../../../shared/types';
import {CatalogsService} from '../../../../shared/services/catalogs.service';
import {
  MultiLineSnackBarService
} from '../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {ProfileService} from '../../services/profile.service';
import {PortalUserService} from '../../../shared/services/portal-user.service';

@Component({
  selector: 'app-reference-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule
  ],
  templateUrl: './reference-dialog.component.html',
  styleUrl: './reference-dialog.component.css'
})
export class ReferenceDialogComponent {
  disablePage = false;
  states = this.catalogsService.getStates();
  referenceForm = this.formBuilder.group({
    firstName: [this.data?.firstName ?? '', [Validators.required]],
    lastName: [this.data?.lastName ?? '', [Validators.required]],
    phone: [this.data?.phone ?? '', [Validators.required]],
    email: [this.data?.email ?? '', [Validators.email]],
    myPosition: [this.data?.myPosition ?? ''],
    referencePosition: [this.data?.referencePosition ?? ''],
    hospital: [this.data?.hospital ?? ''],
    startDate: [this.data?.startDate],
    endDate: [this.data?.endDate],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Reference | null,
    private formBuilder: FormBuilder,
    private catalogsService: CatalogsService,
    private snackBar: MultiLineSnackBarService,
    private profileService: ProfileService,
    private userService: PortalUserService,
    private matDialogRef: MatDialogRef<ReferenceDialogComponent>,
  ) {
  }

  saveReference(): void {
    this.referenceForm.markAllAsTouched();
    if (!this.referenceForm.valid) {
      this.snackBar.displayMessages(['Please fix all errors before saving.']);
      return;
    }

    // disable form and buttons
    this.disablePage = true;
    this.referenceForm.disable();
    // send to API for saving
    const newReference = this.referenceForm.value as Reference;
    newReference.id = this.data?.id ?? 0;
    this.profileService.saveReference(newReference)
      .subscribe(result => {
        if (result.isSuccess) {
          const references = this.userService.activeUser()?.references ?? [];
          const newReference = result.value as Reference;
          if (references.find(wh => wh.id === newReference.id)) {
            references.splice(references.findIndex(wh => wh.id === newReference.id), 1, newReference);
          } else {
            references.push(newReference);
          }
          this.userService.setReferences(references);
          this.matDialogRef.close();
        } else {
          this.snackBar.displayMessages(result.errorList);
        }
        this.disablePage = false;
        this.referenceForm.enable();
      });
  }
}
