import {Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {SelectOption} from "../../model/types";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgClass, NgForOf, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {ShortenPipe} from "../../pipes/shorten.pipe";

@Component({
  selector: 'app-select-single',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgStyle,
    NgClass,
    NgIf,
    NgForOf,
    ShortenPipe
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSingleComponent),
      multi: true
    }
  ],
  templateUrl: './select-single.component.html',
  styleUrl: './select-single.component.css'
})
export class SelectSingleComponent implements ControlValueAccessor, OnInit {
  @ViewChild('div', {read: ElementRef}) public div: ElementRef<any>;

  @Input() options: SelectOption[];
  @Input() divId: string;
  @Input() placeholder: string;

  visibleOptions: SelectOption[];
  value : any;
  selectedOption: SelectOption;
  isDisabled: boolean;
  isSelectionListShown: boolean = false;

  constructor() {
  }

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  selectItem(item: SelectOption) {
    this.selectedOption = item;
    this.isSelectionListShown = false;
    this.onChange(this.selectedOption.value);
  }

  ngOnInit(): void {
    this.visibleOptions = this.options;
  }

  showSelectList() {
    this.isSelectionListShown = !this.isSelectionListShown;

    if (!this.isSelectionListShown) {
      this.onTouched();
    }
  }

  writeValue(value: any): void {
    this.value = value;
    const matchingOption = this.options.find(option => option.value === value);
    if (matchingOption) {
      this.selectedOption = matchingOption;
    } else {
      // Handle the case where no matching option is found
      // For example, you can assign a default value to selectedOption
      this.selectedOption = {label: '', value: ''};
    }
    // this.onChange(this.selectedOption.value);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (!this.div.nativeElement.contains(event.target) && !event.target.classList.contains('select-item')) {
      if (this.isSelectionListShown) {
        this.isSelectionListShown = false;
        this.onTouched();
      }
    }
  }

  @HostListener('focus')
  onFocus() {
    this.isSelectionListShown = true;
    // this.onTouched();
  }

  @HostListener('blur')
  onBlur() {
    this.isSelectionListShown = false;
    this.onTouched();
  }

}


