<div class="referral-review-container" *ngIf="currentNurseComment">
  <div class="desktop-tablet">
    <div style="cursor: pointer" (click)="navigateRight()">
      <img class="btn-hover" style="border-radius: 50%;" ngSrc="assets/svg/carousel-arrow-white-left.svg" alt="legend" width="56" height="56">
    </div>
  </div>
  <div class="referral-review">
    <div class="review-header">
      Thank you for everything!!
    </div>
    <div class="line-blue">
    </div>

    <div class="review-text">
      {{ currentNurseComment.comment }}
    </div>
    <div class="review-user">
      <h5>{{ currentNurseComment.name }}</h5>
    </div>
  </div>

  <div class="desktop-tablet">
    <div style="cursor: pointer" (click)="navigateLeft()">
      <img class="btn-hover" style="border-radius: 50%;" ngSrc="assets/svg/carousel-arrow-white-right.svg" alt="legend" width="56" height="56">
    </div>
  </div>

</div>
