import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-tip-item',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './tip-item.component.html',
  styleUrl: './tip-item.component.css'
})
export class TipItemComponent {

  @Input() imageIcon: string;
  @Input() title: string;
  @Input() text: string;

}
