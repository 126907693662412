import {Component} from '@angular/core';
import {HeaderComponent} from '../../shared/components/header/header.component';
import {NgOptimizedImage} from "@angular/common";
import {FooterComponent} from "../../shared/components/footer/footer/footer.component";
import {ReferralReviewComponent} from "./referral-review/referral-review.component";
import {ReferRnNowComponent} from "./refer-rn-now/refer-rn-now.component";
import {ReferralBenefitComponent} from "./referral-benefit/referral-benefit.component";
import {ReferralHeaderComponent} from "./referral-header/referral-header.component";
import {ActivatedRoute} from '@angular/router';
import {SeoUtilsService} from '../../shared/services/seo-utils.service';

@Component({
  selector: 'app-referrals',
  standalone: true,
  imports: [
    HeaderComponent,
    NgOptimizedImage,
    FooterComponent,
    ReferralReviewComponent,
    ReferRnNowComponent,
    ReferralBenefitComponent,
    ReferralHeaderComponent
  ],
  templateUrl: './referrals.component.html',
  styleUrl: './referrals.component.css'
})
export class ReferralsComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private seoUtilsService: SeoUtilsService,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'Refer A Fellow Travel Nurse To Nightingale',
      'We\'re happy to pay our happy travel nurses to refer us to qualified RNs who also can benefit from our agency.',
      'refer-a-travel-nurse'
    );
  }
}
