<div class="faq-item">
  <div>
    <div class="number">03</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>How much experience and qualification do I need to work with Nightingale?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>All Nightingale clinicians must be legally able to work in the United States to begin
          his/her assignment and possess at least one state license and a degree in their particular
          field.  Each assignment specifies the required experience, certifications and special
          knowledge needed to qualify for consideration. Typically, applicants have worked
          recently for two years or more as a clinician with patients in the US,
          and at least half of that time working within a particular specialty. All assignment
          clinicians become W-2 employees of the firm upon the commencement of the assignment and
          remain so through all assignments, unless expressly terminated from their employment by
          the Company.</p>
      </div>
    }

  </div>

</div>

