import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Faq, Tip} from "../../../shared/model/types";
import questions from "../../../../assets/data/faq.json";
import tips from "../../../../assets/data/tip.json";


@Injectable({
  providedIn: 'root'
})
export class FaqMockDataService {
  getFaq(): Observable<Faq[]> {
    return of(questions);
  }
  getTips(): Observable<Tip[]> {
    return of(tips);
  }
}
