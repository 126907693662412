import {Input} from "@angular/core";

export class DeviceType {
  static readonly PHONE = 'phone';
  static readonly TABLET = 'tablet';
  static readonly DESKTOP = 'desktop';
}

export class JobOrder {
  city: string;
  duration: string;
  id: number;
  shift: string;
  specialty: string;
  state: string;
  type: string;
  hoursPerWeek: number;
  applied: boolean;
  weeklyPay: number;
}

export class JobApplication {
  createdDttm: Date;
  specialty: string;
  state: string;
  city: string;
  duration: number;
  shift: string;
}

export class JobSearchParams {
  specialties: string;
  states: string;
  column: string;
  direction: string;
  candidateId: number | null | undefined;
}


export class SelectOption {
  public label: string;
  public value: any;

  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

export class RapidApp {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  specialty: string;
  secondarySpecialties: string;
  statesLicensed: string;
  hasCompactLicense: boolean;
}


export class State {

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }

  name: string;
  code: string;
}


export class NurseComment {
  imagePath: string;
  name: string;
  comment: string;
  starCount: number;
}

export class Faq {
  question: string;
  answer: string;
}


export class Benefit {
  transparentImagePath: string;
  imageIcon: string;
  title: string;
  text: string;
  url: string;
}

export class Tip {
  imageIcon: string;
  title: string;
  text: string;
}

export class Licensure {
  state: string;
  phone: string;
  walkThrough: boolean;
  compact: boolean;
  nursys: boolean;
  info: string[];

}

export class RelatedBlog {
  id: number;
  title: string;
  imagePath: string;
  author: string;
  date: Date;
  content: string;
  featured: number;
  tags: string[];
}


export class Blog {
  id: number;
  title: string;
  imagePath: string;
  author: string;
  date: Date;
  content: string;
  featured: number;
  tags: string[];
  related: RelatedBlog[];
}

export class HousingContent {
  ImageFileName: string;
  ImagePosition: string;
  IconFileName: string;
  Title: string;
  Description: string;

  constructor(ImageFileName: string, ImagePosition: string, IconFileName: string, Title: string, Description: string) {
    this.ImageFileName = ImageFileName;
    this.IconFileName = IconFileName;
    this.Title = Title;
    this.Description = Description;
    this.ImagePosition = ImagePosition;
  }
}

export class CompanyContactInfo {
  address: Address;
  phone: string;
  supportPhone: string;
  credentialsFax: string;
  timesheetFax: string;
  timesheetEmail: string;
  employeeVerificationPhone: string;
}

export class Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export class ReferralReview {
  reviewTitle: string;
  reviewContent: string;
  reviewerName: string;
}

export class ContactInquiry {
  firstName : string;
  lastName : string;
  phone : string;
  email : string;
  inquiry : string;
}
export class PortalSectionCompleteness {
  constructor(public section: string, public completed: number, public total: number) {
  }

  percentageColor: string = `hsl(${this.getCompletionPercentage() * 100}, 100%, 50%)`;

  public getCompletionPercentage(): number {
    if (
      !this.section ||
      !this.completed ||
      !this.total ||
      this.completed <= 0 ||
      this.total <= 0
    ) return 0;

    return this.completed / this.total;
  }
}

export class RecruiterContactDetails {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}
