import {Component, Input} from '@angular/core';
import {JobOrder} from "../../../shared/model/types";
import {delay, of, switchMap} from "rxjs";
import {PortalLoginDialogComponent} from "../../../portal/portal-login-dialog/portal-login-dialog.component";
import {PortalUserService} from "../../../portal/shared/services/portal-user.service";
import {MatDialog} from "@angular/material/dialog";
import {JobOrderService} from "../../../shared/services/job-order.service";


@Component({
  selector: 'app-quick-details',
  standalone: true,
  imports: [],
  templateUrl: './quick-details.component.html',
  styleUrl: './quick-details.component.css'
})
export class QuickDetailsComponent {
  @Input() jobOrder: JobOrder;

  constructor(
    private portalUserService: PortalUserService,
    private matDialog: MatDialog,
    private jobOrderService: JobOrderService,
  ) {
  }

  applyToJobOrder(): void {
    let candidateId = this.portalUserService.activeUser()?.candidateId;

    const candidateId$ = !!candidateId
      ? of(candidateId)
      : this.matDialog.open(PortalLoginDialogComponent, {
        disableClose: true,
        width: '300px',
        data: 'LOGIN_REQUIRED',
      })
        .afterClosed();

    candidateId$
      .pipe(
        delay(200),
        switchMap(id => {
          candidateId = candidateId === undefined ? id?.userProfile.candidateId : candidateId;

          return this.jobOrderService.applyToJobOrder(candidateId, this.jobOrder);
        })
      )
      .subscribe(result => {
        this.jobOrder.applied = result;
      });
  }

}
