import {Injectable} from '@angular/core';
import {JobApplication, JobOrder} from '../model/types';
import {Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobOrderService {
  constructor(
    private http: HttpClient
  ) {
  }

  getJobOrders(
    candidateId: number | null | undefined,
    states: string,
    specialties: string,
    sortColumn: string,
    sortDirection: string,
    featuredOnly: boolean
  ): Observable<JobOrder[]> {
    const candidateSegment = `candidateId=${candidateId}`;
    const statesSegment = `&states=${!!states ? states : ''}`;
    const specialtiesSegment = `&specialties=${!!specialties ? specialties : ''}`;
    const sortColumnSegment = `&sortColumn=${sortColumn}`;
    const sortDirectionSegment = `&sortDirection=${sortDirection}`;

    const url = `job-order?${candidateSegment}${statesSegment}${specialtiesSegment}${sortColumnSegment}${sortDirectionSegment}&featuredOnly=${featuredOnly}`;
    return this.http.get<JobOrder[]>(url);
  }

  applyToJobOrder(candidateId: number | null | undefined, jobOrder: JobOrder): Observable<boolean> {
    return !candidateId
      ? of(false)
      : this.http.post<boolean>(`job-order/application?candidateId=${candidateId}`, jobOrder);
  }

  getJobApplications(candidateId: number | null | undefined, maxCount: number = 0): Observable<JobApplication[]> {
    let countSegment = '';
    if (maxCount > 0) {
      countSegment = `&maxCount=${maxCount}`;
    }
    return !candidateId
      ? of([])
      : this.http.get<JobApplication[]>(`job-order/application?candidateId=${candidateId}${countSegment}`);
  }
}
