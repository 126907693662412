import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HeaderComponent} from '../header/header.component';
import {FooterComponent} from '../footer/footer/footer.component';

@Component({
  selector: 'app-successful-submit',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent
  ],
  templateUrl: './successful-submit.component.html',
  styleUrl: './successful-submit.component.css'
})
export class SuccessfulSubmitComponent {
  source: string = '';

  constructor(
    private router: Router,
  ) {
    if (!!this.router.getCurrentNavigation()?.extras.state) {
      this.source = (<{
        source: string
      }>this.router.getCurrentNavigation()?.extras.state).source ?? '';

      this.source = (<{ source: string }>this.router.getCurrentNavigation()?.extras.state).source ?? '';
    }

    if (!this.source) {
      this.navigateToHome();
    }
  }


  navigateToHome() {
    this.router.navigate(['/']).then();
  }
}
