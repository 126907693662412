import {Component, computed, Signal} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatCard, MatCardActions} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {Education, WorkHistory} from '../../shared/types';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {MatDialog} from '@angular/material/dialog';
import {ProfileService} from '../services/profile.service';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogResult, ConfirmationDialogStyle} from '../../../shared/components/confirmation-dialog/types';
import {
  WorkHistoryDialogComponent
} from '../portal-user-work-history/work-history-dialog/work-history-dialog.component';
import {EducationDialogComponent} from './education-dialog/education-dialog.component';
import {MonthYearPipe} from "../../../shared/pipes/month-year.pipe";

@Component({
  selector: 'app-portal-user-education',
  standalone: true,
  imports: [
    DatePipe,
    MatButton,
    MatCard,
    MatCardActions,
    MatIcon,
    MatIconButton,
    MatProgressSpinner,
    MonthYearPipe,
  ],
  templateUrl: './portal-user-education.component.html',
  styleUrl: './portal-user-education.component.css'
})
export class PortalUserEducationComponent {
  educationHistory: Signal<Education[]> = computed(() => this.sortEducation(this.userService.activeUser()!.education ?? []));

  disablePage = false;

  constructor(
    private userService: PortalUserService,
    private matDialog: MatDialog,
    private profileService: ProfileService,
    private snackBar: MultiLineSnackBarService,
  ) {
  }

  removeEducation(educationId?: number): void {
    if (!educationId) {
      this.snackBar.displayMessages(['An error occurred, please reload the page and try again.']);
      return;
    }

    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Education',
        message: 'Are you sure you want to delete this record? This action cannot be undone.',
        confirmationDialogStyle: ConfirmationDialogStyle.twoButtonDialog,
        positiveBtnText: 'Delete',
        negativeBtnText: 'Cancel',
      }
    }).afterClosed().subscribe(result => {
      if (result === ConfirmationDialogResult.positive) {
        this.disablePage = true;
        this.profileService.deleteEducation(educationId).subscribe(result => {
          if (result.isSuccess){
            const education = this.userService.activeUser()?.education ?? [];
            if (education.find(e => e.id === educationId)) {
              education.splice(education.findIndex(e => e.id === educationId), 1);
            }
            this.userService.setEducation(education);
          } else {
            this.snackBar.displayMessages(result.errorList);
          }
          this.disablePage = false;
        });
      }
    });
  }

  editEducation(education: Education): void {
    this.matDialog.open(EducationDialogComponent, {
      autoFocus: false,
      panelClass: ['full-screen-mobile-dialog'],
      data: education,
    });
  }

  addEducation(): void {
    this.matDialog.open(EducationDialogComponent, {
      autoFocus: false,
      panelClass: ['full-screen-mobile-dialog'],
      data: null,
    });
  }


  private sortEducation(education: Education[]): Education[] {
      return education.sort((a, b) => {
        if (a.graduationDate === null) return -1; // a comes first if its date is null
        if (b.graduationDate === null) return 1;  // b comes first if its date is null
        // If neither date is null, sort in descending order
        return (b.graduationDate?.getTime() ?? 0) - (a.graduationDate?.getTime() ?? 0);
      });
    }

}
