<div class="career-container">
  <div class="career-header">
    <div class="career-header-text">
      <h2> WHERE
        <span class="career-header-text-highlighted"> TRAVEL</span>
        <span class="career-header-text-highlighted"> NURSES </span>
        THRIVE </h2>
    </div>

    <div class="line-green"></div>

    <div class="career-header-subtext">Nightingale is more than just a travel nursing agency.<br>
      We're a partner who helps travel nurses thrive in their careers.<br>
      Explore the <a href="/benefits">benefits we provide to our travel nurses</a>.
    </div>
  </div>
  <app-frame-benefits></app-frame-benefits>
</div>
