<div class="settings-container">
  <div class="settings-column">
<app-change-password></app-change-password>

  </div>

  <div class="settings-column">
<app-communication-consent></app-communication-consent>
  </div>
</div>

