import {Component, OnInit} from '@angular/core';
import {HeaderComponent} from '../shared/components/header/header.component';
import {FooterComponent} from "../shared/components/footer/footer/footer.component";
import {ApplyTodayComponent} from "../shared/components/apply-today/apply-today.component";
import {PtbLayoutJobSearchComponent} from "./ptb-layout-job-search/ptb-layout-job-search.component";
import {BrowseJobComponent} from './browse-job/browse-job.component';
import {JobListComponent} from './job-list/job-list.component';
import {AsyncPipe, NgIf} from '@angular/common';
import {JobOrder, JobSearchParams} from '../shared/model/types';
import {JobOrderService} from '../shared/services/job-order.service';
import {BehaviorSubject, combineAll, combineLatest, Observable, switchMap} from 'rxjs';
import {ActivatedRoute, Params, Router, UrlSegment} from '@angular/router';
import {PortalUserService} from '../portal/shared/services/portal-user.service';
import {SeoUtilsService} from '../shared/services/seo-utils.service';

@Component({
  selector: 'app-job-search',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    ApplyTodayComponent,
    PtbLayoutJobSearchComponent,
    BrowseJobComponent,
    JobListComponent,
    NgIf,
    AsyncPipe
  ],
  templateUrl: './job-search.component.html',
  styleUrl: './job-search.component.css'
})
export class JobSearchComponent implements OnInit {
  jobSearchParams: JobSearchParams = {
    specialties: '',
    states: '',
    column: 'WeeklyPay',
    direction: 'Desc',
    candidateId: null
  };

  private jobSearchParams$ = new BehaviorSubject<JobSearchParams>({
    specialties: '',
    states: '',
    column: 'WeeklyPay',
    direction: 'Desc',
    candidateId: null
  });
  jobOrders$: Observable<JobOrder[]>;

  constructor(
    private jobOrderService: JobOrderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private portalUserService: PortalUserService,
    private seoUtilsService: SeoUtilsService,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'Travel Nursing Job Search - Nightingale',
      'Looking right now for a travel nursing opportunity? We\'ve got an updated database of hiring opportunities for travel nurses in hospitals across the U.S. for you to explore.',
      'find-travel-nurse-job'
    );

    this.jobSearchParams.candidateId = this.portalUserService.activeUser()?.userId;

    const path$ = combineLatest([this.activatedRoute.url, this.activatedRoute.queryParams]);
    path$.subscribe(data => {
      this.jobSearchParams = JobSearchComponent.getJobSearchParams(data[0], data[1], this.jobSearchParams.candidateId);

      this.jobSearchParams$.next(this.jobSearchParams);
    });
  }

  private static getJobSearchParams(
    url: UrlSegment[],
    params: Params,
    candidateId: number | null | undefined
  ): JobSearchParams {
    const result: JobSearchParams = {
      specialties: '',
      states: '',
      column: 'WeeklyPay',
      direction: 'Desc',
      candidateId: candidateId
    };

    const path = url.join('/');

    switch (path) {
      case 'states/florida-nursing-jobs':
        result.states = 'FL';
        break;
      case 'states/georgia-nursing-jobs':
        result.states = 'GA';
        break;
      case 'specialties/psych-tech-allied-jobs':
        result.specialties = 'PSYCH TECH';
        break;
      case 'specialties':
        break;
      case 'states':
        break;
      default:
        result.specialties = params['specialties'] ?? '';
        result.states = params['states'] ?? '';
        result.column = params['column'] ?? 'WeeklyPay';
        result.direction = params['direction'] ?? 'Desc';
    }

    return result;
  }

  ngOnInit(): void {
    this.jobOrders$ = this.jobSearchParams$
      .pipe(
        switchMap(params => this.jobOrderService.getJobOrders(
          params.candidateId,
          params.states,
          params.specialties,
          params.column,
          params.direction,
          false
        )),
      );
  }

  updateRoute(): void {
    this.router.navigate(['/job-search'], {
      queryParams: {
        specialties: this.jobSearchParams.specialties,
        states: this.jobSearchParams.states,
        column: this.jobSearchParams.column,
        direction: this.jobSearchParams.direction
      }
    }).then();
  }

  selectionChanged(event: { specialties: string[], states: string[] }): void {
    this.jobSearchParams.specialties = event.specialties.join(',');
    this.jobSearchParams.states = event.states.join(',');

    this.updateRoute();
  }

  sortChanged(event: { column: string, direction: string }): void {
    this.jobSearchParams.column = event.column;
    this.jobSearchParams.direction = event.direction;

    this.updateRoute();
  }

}
