<div class="faq-item">
  <div>
    <div class="number">06</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>Does Nightingale provide Paid Time Off (PTO)?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>Based on which state the assignment is located, Nightingale <a href="https://www.nightingalenurses.net/policies" class="faq-link">provides accrued time off pursuant to
          regulations</a>. Our PTO system calculates your PTO balances.
        </p>
        <br>
        <p>
          The clinician may ask your staffing manager for your paid leave balance and usage rules at any time,
          again, determined in which state he/she is assigned.
        </p>


      </div>
    }

  </div>

</div>

