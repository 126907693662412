import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";

// @ts-ignore
@Component({
  selector: 'app-table-footer',
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ],
  templateUrl: './table-footer.component.html',
  styleUrl: './table-footer.component.css'
})
export class TableFooterComponent implements OnInit, OnChanges {
  @Input() itemsPerPage: number;
  @Input() totalItems: number;
  @Input() firstItem: number;
  @Input() reload: boolean;
  @Input() windowSize: number;


  @Output() requestedPageEvent = new EventEmitter<number>();


  pageNumbers: number[] = [];
  pageNumbersToShow: number[] = [];
  currentPage: number;
  totalPages: number;
  dotsEnd: boolean = false;
  dotsStart: boolean = false;
  enablePrevious: boolean = false;
  enableNext: boolean = false;
  realWindowSize: number = 0;


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['firstItem'] !== undefined && changes['firstItem'].currentValue !== changes['firstItem']
      || changes['itemsPerPage'] !== undefined && changes['itemsPerPage'].currentValue !== changes['itemsPerPage']
      || changes['totalItems'] !== undefined && changes['totalItems'].currentValue !== changes['totalItems']
      || changes['reload'] !== undefined && changes['reload'].currentValue !== undefined && changes['reload']
      || changes['windowSize'] !== undefined && changes['windowSize'].currentValue !== undefined && changes['windowSize']) {
      this.currentPage = Math.ceil((this.firstItem - 1 + this.itemsPerPage) / this.itemsPerPage);

      if (this.reload) {
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        this.realWindowSize = this.totalPages > this.windowSize ? this.windowSize : this.totalPages;
        this.pageNumbers = Array(this.totalPages).fill(0).map((x, i) => i + 1);
        this.pageNumbersToShow = this.pageNumbers.slice(this.currentPage - 1, this.currentPage - 1 + this.windowSize);
      } else {
        if (!this.pageNumbersToShow.includes(this.currentPage)) {
          if (this.currentPage > this.pageNumbersToShow[this.pageNumbersToShow.length - 1]) {
            this.pageNumbersToShow = this.pageNumbers.slice(this.currentPage - this.windowSize, this.currentPage);
          } else {
            this.pageNumbersToShow = this.pageNumbers.slice(this.currentPage - 1, this.currentPage - 1 + this.windowSize);
          }
        }
      }

      this.dotsEnd = this.pageNumbers[this.pageNumbers.length - 1] > this.pageNumbersToShow[this.pageNumbersToShow.length - 1];
      this.dotsStart = this.pageNumbers[0] < this.pageNumbersToShow[0];
      this.enablePrevious = this.currentPage > 1;
      this.enableNext = this.currentPage < this.totalPages;
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.requestedPageEvent.emit(this.currentPage);
  }

  goToPreviousPage() {
    if (this.currentPage > 1) this.currentPage--;
    this.requestedPageEvent.emit(this.currentPage);
  }

  goToNextPage() {

    if (this.currentPage < this.totalPages)
      this.currentPage++;
    this.requestedPageEvent.emit(this.currentPage);
  }
}

