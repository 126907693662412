<div class="body-faq-main-frame">
  <div class="body-faq-title-frame">
      <div class="body-faq-title"><h3>TRAVEL NURSE
        <span class="body-faq-title-italic">FAQ</span> </h3>
      </div>
    <div class="line-green"></div>
  </div>
  <app-faq-list

  ></app-faq-list>
</div>

