import {Component} from '@angular/core';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {PortalVerificationService} from '../shared/services/portal-verification.service';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';

@Component({
  selector: 'app-portal-account-pending-verification',
  standalone: true,
  imports: [],
  templateUrl: './portal-account-pending-verification.component.html',
  styleUrl: './portal-account-pending-verification.component.css'
})
export class PortalAccountPendingVerificationComponent {

  constructor(
    private portalVerificationService: PortalVerificationService,
    private portalUserService: PortalUserService,
    private snackBar: MultiLineSnackBarService,
  ) {
  }

  resendVerificationEmail(): void {
    this.portalVerificationService
      .resendVerificationEmail()
      .subscribe(result => {
        if (!result.isSuccess) {
          this.snackBar.displayMessages(result.errorList);
          return;
        }
        this.snackBar.displayMessages(['Verification email sent. Please allow a few minutes for it to reach your inbox. Remember to check your spam folder if it does not appear.']);
      });
  }

  checkAccountVerified(): void {
    this.portalVerificationService
      .checkAccountVerified()
      .subscribe(result => {
        if (!result.isSuccess) {
          this.snackBar.displayMessages(result.errorList);
          return;
        }
        if (result.value === true) {
          this.portalUserService.setUserStatus('active');
          this.portalUserService.selectedTab.set('Home');
        } else {
          this.snackBar.displayMessages(['Your account is not yet verified.']);
        }
      });
  }
}
