import {Component} from '@angular/core';
import {PortalUserService} from '../shared/services/portal-user.service';
import {PortalSectionCompletenessComponent} from './portal-section-completness/portal-section-completeness.component';
import {PortalHomeJobApplicationsComponent} from './job-applications/portal-home-job-applications.component';
import {RecruiterContactComponent} from './recruiter-contact/recruiter-contact.component';

@Component({
  selector: 'app-portal-user-home',
  standalone: true,
  imports: [
    PortalSectionCompletenessComponent,
    PortalHomeJobApplicationsComponent,
    RecruiterContactComponent,
  ],
  templateUrl: './portal-user-home.component.html',
  styleUrl: './portal-user-home.component.css'
})
export class PortalUserHomeComponent {
  activeUser = this.userService.activeUser;
  personalInfoCompleteness = this.userService.profileCompleteness;

  constructor(
    private userService: PortalUserService,
  ) {
  }

  onPersonalInfoClick(label: string) {
    if (typeof label !== 'string') {
      return;
    }

    this.userService.selectedTab.set(label);
  }

}
