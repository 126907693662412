import { Component } from '@angular/core';
import {ConnectOnlineFormComponent} from "./connect-online-form/connect-online-form.component";
import {ContactInfoComponent} from "./contact-info/contact-info.component";

@Component({
  selector: 'app-connect-online',
  standalone: true,
  imports: [
    ConnectOnlineFormComponent,
    ContactInfoComponent
  ],
  templateUrl: './connect-online.component.html',
  styleUrl: './connect-online.component.css'
})
export class ConnectOnlineComponent {


}
