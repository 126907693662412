import { Component } from '@angular/core';
import {
  LicensureTableComponent
} from "../../../benefits/licensure-guide/body-licensure/licensure-table/licensure-table.component";
import {PolicyListComponent} from "./policy-list/policy-list.component";
import {FaqListComponent} from '../../faq/body-faq/faq-list/faq-list.component';

@Component({
  selector: 'app-body-policies',
  standalone: true,
  imports: [
    FaqListComponent,
    LicensureTableComponent,
    PolicyListComponent
  ],
  templateUrl: './body-policies.component.html',
  styleUrl: './body-policies.component.css'
})
export class BodyPoliciesComponent {

}
