<div class="flex-column">
  <mat-card class="dialog-card">

    <form [formGroup]="referenceForm" style="margin: 10px; display: flex; flex-direction: column; gap: 10px;">

      <div class="form-row">


        <div style="margin-top: 10px; flex: 1; width:100%">
          <div class="form-array-title">
            @if(referenceFormArray.controls.length === 0) {
              <div class="portal-section-empty"><p>No references added, you can add a reference by hitting
                the Add Reference button in the right.</p></div>
            }
            <div class="flex-expand"></div>
            <button class="mat-icon-button-sm" mat-icon-button (click)="addReference()"
                    matTooltip="Add Reference">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ng-container *ngTemplateOutlet="referenceList"></ng-container>
        </div>
      </div>


      <ng-template #referenceList>
        <!--        <div class="form-row">-->
        <div formArrayName="references" class="form-array-margin flex-column">
          @for (item of referenceFormArray.controls; track item.value) {
            <div [formGroupName]="$index" class="form-array">
              <button [disabled]="disablePage" mat-icon-button (click)="removeReferenceItem($index)"
                      class="remove-button">
                <mat-icon>close</mat-icon>
              </button>
              <div class="form-array-wrap">
                <mat-form-field>
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName">
                  @if (referenceFormArray.at($index).get('firstName')?.hasError('required')) {
                    <mat-error>First Name is required</mat-error>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName">
                  @if (referenceFormArray.at($index).get('lastName')?.hasError('required')) {
                    <mat-error>Last Name is required</mat-error>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Phone</mat-label>
                  <input matInput formControlName="phone">
                  @if (referenceFormArray.at($index).get('phone')?.hasError('required')) {
                    <mat-error>Phone is required</mat-error>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Reference Position</mat-label>
                  <input matInput formControlName="referencePosition">
                </mat-form-field>
              </div>
            </div>
          }
        </div>
        <!--        </div>-->
      </ng-template>
    </form>
  </mat-card>
</div>

