import { Component } from '@angular/core';
import {RapidAppFormComponent} from "./rapid-app-form/rapid-app-form.component";

@Component({
  selector: 'app-body-rapid-app',
  standalone: true,
  imports: [
    RapidAppFormComponent
  ],
  templateUrl: './body-rapid-app.component.html',
  styleUrl: './body-rapid-app.component.css'
})
export class BodyRapidAppComponent {

}
