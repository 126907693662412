import {Component, OnInit} from '@angular/core';
import {HeaderComponent} from '../shared/components/header/header.component';
import {FooterComponent} from "../shared/components/footer/footer/footer.component";
import {PtbLayoutRapidAppComponent} from "./ptb-layout-rapid-app/ptb-layout-rapid-app.component";
import {BodyRapidAppComponent} from "./body-rapid-app/body-rapid-app.component";
import {SeoUtilsService} from '../shared/services/seo-utils.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-rapid-app',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    PtbLayoutRapidAppComponent,
    BodyRapidAppComponent
  ],
  templateUrl: './rapid-app.component.html',
  styleUrl: './rapid-app.component.css'
})
export class RapidAppComponent implements OnInit {
  constructor(
    private seoUtilsService: SeoUtilsService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'Apply Now for Extraordinary Opportunities for Exceptional Nurses - Nightingale',
      'Nightingale is the travel nursing agency for nurses who know their worth and want an agency that looks out for their best interests.',
      'apply-to-nightingale'
    );
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }
}
