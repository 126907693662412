import {Component, Inject, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatCard, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {NgTemplateOutlet} from "@angular/common";
import {PortalUserService} from "../../../shared/services/portal-user.service";
import {ProfileService} from "../../../portal-user-profile/services/profile.service";
import {
  MultiLineSnackBarService
} from "../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {PortalUserProfile} from "../../../shared/types";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from "@angular/material/dialog";
import {PersonalInfoEditorComponent} from "./personal-info-editor/personal-info-editor.component";

import {WorkHistoryEditorComponent} from "./work-history-editor/work-history-editor.component";
import {ProfessionalInfoEditorComponent} from "./professional-info-editor/professional-info-editor.component";
import {EducationInfoEditorComponent} from "./education-info-editor/education-info-editor.component";
import {ReferencesInfoEditorComponent} from "./references-info-editor/references-info-editor.component";
import {MatError} from "@angular/material/form-field";

@Component({
  selector: 'app-load-resume-editor-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatCard,
    NgTemplateOutlet,
    ReactiveFormsModule,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatCardHeader,
    MatCardTitle,
    PersonalInfoEditorComponent,
    WorkHistoryEditorComponent,
    ProfessionalInfoEditorComponent,
    EducationInfoEditorComponent,
    ReferencesInfoEditorComponent,
    MatError,
    MatDialogContent
  ],
  templateUrl: './load-resume-editor.component.html',
  styleUrl: './load-resume-editor.component.css'
})
export class LoadResumeEditorComponent {
  @ViewChild('personalInfoPage', {static: false}) personalInfoEditor: PersonalInfoEditorComponent;
  @ViewChild('professionalInfoPage', {static: false}) professionalInfoEditor: ProfessionalInfoEditorComponent;
  @ViewChild('workHistoryPage', {static: false}) workInfoEditor: WorkHistoryEditorComponent;
  @ViewChild('educationInfoPage', {static: false}) educationInfoEditor: EducationInfoEditorComponent;
  @ViewChild('referenceInfoPage', {static: false}) referenceInfoEditor: ReferencesInfoEditorComponent;

  disablePage = false;
  formPages = ['Personal Info', 'Professional Info', 'Work History', 'Education',  'References',
  ];
  activePage: number = 0;
  isSaving: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PortalUserProfile,
    private profileService: ProfileService,
    private multiLineSnackBar: MultiLineSnackBarService,
    private dialogRef: MatDialogRef<LoadResumeEditorComponent>,
    private portalUserService: PortalUserService
  ) {

  }


  previousForm() {
    if (this.activePage > 0)
      this.activePage--;
  }

  nextForm() {
    let _component = null;
    switch (this.formPages[this.activePage]) {
      case 'Professional Info':
        _component = this.professionalInfoEditor;
        break;
      case 'Personal Info':
        _component = this.personalInfoEditor;
        break;
      case 'Work History':
        _component = this.workInfoEditor;
        break;
      case 'Education':
        _component = this.educationInfoEditor;
        break;
      case 'References':
        _component = this.referenceInfoEditor;
        break;
    }

    _component?.save().subscribe(result => {
      if (result) {
        if (this.activePage < this.formPages.length - 1)
          this.activePage++;
       else if (this.activePage == this.formPages.length - 1) {
          this.isSaving = true;
          this.saveResume();
        }
      }
    });
  }


  saveResume()
  {
    const resumeProfile = this.profileService.getResumeData();
    this.profileService.saveResumeProfile(resumeProfile).subscribe(result => {
      if (result.isSuccess) {
        this.multiLineSnackBar.displayMessages(['Profile saved successfully.']);

        this.portalUserService.setUserResumeInfo(result.value!);
        this.profileService.clearResumeData();
        this.dialogRef.close();
      } else {
        this.multiLineSnackBar.displayMessages(result.errorList);
        this.isSaving = false;
      }
    });
  }

  cancelForm() {
    this.profileService.clearResumeData();
    this.dialogRef.close();
  }

}
