import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Licensure} from "../../../shared/model/types";
import licence from "../../../../assets/data/licensing.json";



@Injectable({
  providedIn: 'root'
})
export class LicensureMockDataService {


  getLicensure(): Observable<Licensure[]> {
    return of(licence);
  }


  getString(item:Licensure): string {
    return item.state + '&' + item.phone + '&' + item.nursys + '&' + item.compact + '&' + item.nursys + '&' + item.info.join('/');
  }
}
