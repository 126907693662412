import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CurrencyPipe, NgClass, NgOptimizedImage} from '@angular/common';
import {CarouselScroll, CarouselType} from '../../../home/shared/model/types';
import {JobOrder} from '../../model/types';
import {JobOrderService} from '../../services/job-order.service';
import {PortalUserService} from '../../../portal/shared/services/portal-user.service';
import {PortalLoginDialogComponent} from '../../../portal/portal-login-dialog/portal-login-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {delay, of, switchMap, tap} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-job',
  standalone: true,
  imports: [
    NgClass,
    NgOptimizedImage,
    CurrencyPipe
  ],
  templateUrl: './job.component.html',
  styleUrl: './job.component.css'
})
export class JobComponent {
  @Input() jobOrder: JobOrder;
  @Input() faded: boolean;
  @Input() carousel: CarouselType = CarouselType.None;
  @Output() onScroll = new EventEmitter<CarouselScroll>();

  protected readonly CarouselType = CarouselType;


  constructor(
    private portalUserService: PortalUserService,
    private jobOrderService: JobOrderService,
    private matDialog: MatDialog,
    private router: Router,
  ) {
  }


  applyToJobOrder(jobOrder: JobOrder): void {
    let candidateId = this.portalUserService.activeUser()?.candidateId;

    const candidateId$ = !!candidateId
      ? of(candidateId)
      : this.matDialog.open(PortalLoginDialogComponent, {
        disableClose: true,
        width: '300px',
        data: 'LOGIN_REQUIRED',
      })
        .afterClosed();

    candidateId$
        .pipe(
          delay(200),
          switchMap(id => {
            candidateId = candidateId === undefined ? id?.userProfile.candidateId : candidateId;

            return this.jobOrderService.applyToJobOrder(candidateId, jobOrder);
          })
        )
        .subscribe(result => {
          jobOrder.applied = result;
        });
  }

  scroll(): void {
    const carouselScroll = this.carousel === CarouselType.Left ? CarouselScroll.Left : CarouselScroll.Right;
    this.onScroll.emit(carouselScroll);
  }

  navigateToJobDetails(): void {
    this.router.navigate(['/job-details'], {
      state: {
        jobOrder: this.jobOrder,
      }
    }).then();
  }
}
