import { Component } from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatCard, MatCardActions} from '@angular/material/card';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatDivider} from '@angular/material/divider';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatSelect} from '@angular/material/select';
import {CatalogsService} from '../../../shared/services/catalogs.service';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {ProfileService} from '../services/profile.service';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {ProfileCertification, ProfileProfessionalInfo, ProfileStateLicense} from '../../shared/types';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {MatRipple} from '@angular/material/core';
import {MatTab, MatTabGroup, MatTabHeader, MatTabLabel} from '@angular/material/tabs';
import {ChartingEmrSoftware} from '../../../shared/model/enumTypes';

@Component({
  selector: 'app-portal-user-professional-info',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatCard,
    MatCardActions,
    MatCheckbox,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDivider,
    MatError,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatProgressSpinner,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    CdkTextareaAutosize,
    NgClass,
    MatRipple,
    NgTemplateOutlet,
    MatTabGroup,
    MatTab,
    MatTabHeader,
    MatTabLabel
  ],
  templateUrl: './portal-user-professional-info.component.html',
  styleUrl: './portal-user-professional-info.component.css'
})
export class PortalUserProfessionalInfoComponent {
  states = this.catalogService.getStates();
  specialties = this.catalogService.getSpecialties();
  certifications = this.catalogService.getCertifications();
  chartingEmrOptions = ChartingEmrSoftware.chartingEmrOptions.sort((a, b) => a.label.localeCompare(b.label));
  user = this.userService.activeUser()?.professionalInfo;

  disablePage = false;

  licensesFormArray = this.formBuilder.array([]) as FormArray;
  certificationsFormArray = this.formBuilder.array([]) as FormArray;
  profileForm = this.formBuilder.group({
    hadLicenseSuspended: [this.user?.hadLicenseSuspended],
    suspensionExplanation: [{value: this.user?.suspensionExplanation, disabled: !this.user?.hadLicenseSuspended}],
    wasConvictedOfCrime: [this.user?.wasConvictedOfCrime],
    convictionExplanation: [{value: this.user?.convictionExplanation, disabled: !this.user?.wasConvictedOfCrime}],
    legalToWork: [this.user?.legalToWork],
    chartingEmrExperience: [this.user?.chartingEmrExperience],
    specialty: [this.user?.specialty],
    secondarySpecialties: [this.user?.secondarySpecialties],
    hasCompactLicense: [this.user?.hasCompactLicense],
    statesLicensed: this.licensesFormArray,
    certifications: this.certificationsFormArray,
  });

  constructor(
    private catalogService: CatalogsService,
    private userService: PortalUserService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private multiLineSnackBar: MultiLineSnackBarService,
  ) {
    this.user?.statesLicensed?.forEach(license => {
      this.addLicense(license);
    });
    this.user?.certifications?.forEach(certification => {
      this.addCertification(certification);
    });
  }

  toggleFormFieldRequired(value: any, control: AbstractControl): void {
    if (value) {
      control.setValidators(Validators.required);
      control.enable();
    } else {
      control.clearValidators();
      control.reset();
      control.disable();
    }
    control.updateValueAndValidity();
    this.profileForm.updateValueAndValidity();
  }

  saveProfile(): void {
    // validate fields
    this.profileForm.markAllAsTouched();
    if (this.profileForm.invalid) {
      this.multiLineSnackBar.displayMessages(['Please fix all errors before saving.']);
      return;
    }
    // send rapid app
    this.disablePage = true;
    this.profileForm.disable();

    const professionalInfo = this.profileForm.value as ProfileProfessionalInfo;
    this.profileService.saveProfessionalInfo(professionalInfo)
      .subscribe(result => {
        if (result.isSuccess) {
          this.userService.setProfessionalInfo(professionalInfo);
          this.multiLineSnackBar.displayMessages(['Profile update successful.']);
        } else {
          this.multiLineSnackBar.displayMessages(result.errorList);
        }
        this.disablePage = false;
        this.profileForm.enable();
      });
  }

  addLicense(license: ProfileStateLicense | null = null): void {
    const licenseForm = this.formBuilder.group({
      id: [license?.id],
      state: [license?.state ?? '', [Validators.required]],
      expirationDate: [license?.expirationDate],
    });

    this.licensesFormArray.insert(0, licenseForm, {emitEvent: false});
  }

  removeLicense(index: number): void {
    this.licensesFormArray.removeAt(index);
  }

  addCertification(certification: ProfileCertification | null = null): void {
    const certificationForm = this.formBuilder.group({
      id: [certification?.id],
      name: [certification?.name ?? '', [Validators.required]],
      expirationDate: [certification?.expirationDate],
    });

    this.certificationsFormArray.insert(0, certificationForm, {emitEvent: false});
  }

  removeCertification(index: number): void {
    this.certificationsFormArray.removeAt(index);
  }

  addActive(selectedIndex: number | null): void {
    switch (selectedIndex) {
      case 0:
        this.addLicense();
        break;
      case 1:
        this.addCertification();
        break;
    }
  }
}
