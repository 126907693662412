import { Component } from '@angular/core';
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {MatCard, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatDivider} from "@angular/material/divider";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {NgTemplateOutlet} from "@angular/common";
import {AbstractControl, FormArray, FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {ChartingEmrSoftware} from "../../../../../shared/model/enumTypes";
import {CatalogsService} from "../../../../../shared/services/catalogs.service";
import {PortalUserService} from "../../../../shared/services/portal-user.service";
import {ProfileService} from "../../../../portal-user-profile/services/profile.service";
import {
  MultiLineSnackBarService
} from "../../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {ProfileCertification, ProfilePersonalInfo, ProfileProfessionalInfo} from "../../../../shared/types";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-professional-info-editor',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    MatCard,
    MatCheckbox,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDivider,
    MatError,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    MatTab,
    MatTabGroup,
    NgTemplateOutlet,
    ReactiveFormsModule,
    MatCardHeader,
    MatCardTitle
  ],
  templateUrl: './professional-info-editor.component.html',
  styleUrl: './professional-info-editor.component.css'
})
export class ProfessionalInfoEditorComponent {
  states = this.catalogService.getStates();
  specialties = this.catalogService.getSpecialties();
  certifications = this.catalogService.getCertifications();
  professionalInfo = this.profileService.getResumeData()?.professionalInfo;

  user = this.userService.activeUser()?.professionalInfo;

  disablePage = false;

  certificationsFormArray = this.formBuilder.array([]) as FormArray;
  profileForm = this.formBuilder.group({
    specialty: [this.professionalInfo?.specialty],
    secondarySpecialties: [this.professionalInfo?.secondarySpecialties],
    hasCompactLicense: [this.professionalInfo?.hasCompactLicense],
    certifications: this.certificationsFormArray,
  });

  constructor(
    private catalogService: CatalogsService,
    private userService: PortalUserService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private multiLineSnackBar: MultiLineSnackBarService,
  ) {

    this.professionalInfo?.certifications?.forEach(certification => {
      this.addCertification(certification);
    });
  }


  save():Observable<boolean>{
    this.profileForm.markAllAsTouched();
    if (this.profileForm.invalid) {
      this.multiLineSnackBar.displayMessages(['Please correct the errors and try again.']);
      return of(false);
    }
    this.disablePage = true;
    const professionalInfo = this.profileForm.value as ProfileProfessionalInfo;
    const resumeData = this.profileService.getResumeData();
    resumeData.professionalInfo.specialty = professionalInfo.specialty;
    resumeData.professionalInfo.secondarySpecialties = professionalInfo.secondarySpecialties;
    resumeData.professionalInfo.hasCompactLicense = professionalInfo.hasCompactLicense;
    resumeData.professionalInfo.certifications = professionalInfo.certifications;


    this.profileService.saveResumeData(resumeData);
    return of(true);

  }

  addCertification(certification: ProfileCertification | null = null): void {
    const certificationForm = this.formBuilder.group({
      id: [certification?.id],
      name: [certification?.name ?? '', [Validators.required]],
      expirationDate: [certification?.expirationDate],
    });

    this.certificationsFormArray.insert(0, certificationForm, {emitEvent: false});
  }

  removeCertification(index: number): void {
    this.certificationsFormArray.removeAt(index);
  }

}
