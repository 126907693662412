import {Component} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {RapidApp, SelectOption} from "../../../shared/model/types";
import {SelectChipComponent} from "../../../shared/components/select-chip/select-chip.component";
import {combineLatest, map, Observable, tap} from "rxjs";
import {PhoneValidator} from "../../../shared/validators/PhoneValidator";
import {SelectSingleComponent} from "../../../shared/components/select-single/select-single.component";
import {Router} from "@angular/router";
import {ContactService} from '../../../shared/services/contact.service';

@Component({
  selector: 'app-rapid-app-form',
  standalone: true,
  imports: [
    NgClass,
    MatInput,
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    SelectChipComponent,
    SelectSingleComponent,
  ],
  templateUrl: './rapid-app-form.component.html',
  styleUrl: './rapid-app-form.component.css',
})
export class RapidAppFormComponent {
  specialties: SelectOption[];
  states: SelectOption[];

  specialties$: Observable<SelectOption[]> = this.contactService.getSpecialties().pipe(
    map(specialties => specialties.sort((a, b) => a.label.localeCompare(b.label))),
    tap((specialties: SelectOption[]) => {
      this.specialties = specialties;
    })
  );

  states$ = this.contactService.getStates().pipe(
    map(states => states.sort((a, b) => a.label.localeCompare(b.label))), // Add this line to sort the states

    tap((states: SelectOption[]) => {
      this.states = states;
    })
  );

  combined$ = combineLatest([this.specialties$, this.states$]).pipe(
    map(([result1, result2]) => {
      // Combine or process the results in whatever way you need
      return result1 && result2;
    })
  );

  applyForm = new FormGroup({
    firstName: new FormControl<string>('', [Validators.required]),
    lastName: new FormControl<string>('', [Validators.required]),
    phone: new FormControl<string>('', [Validators.required, PhoneValidator]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    specialty: new FormControl<string>('', [Validators.required]),
    secondarySpecialties: new FormControl<string[]>([]),
    statesLicensed: new FormControl<string[]>([], [Validators.required]),
    hasCompactLicense: new FormControl<boolean>(false)
  });

  constructor(
    private contactService: ContactService,
    private router: Router,
  ) {
  }

  onFocusOutEvent(e: any) {
    if(e.target.value.length ===10) {
      const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
    // this.applyForm.controls['phone'].setValue(e.target.value);
  }

  saveApplication() {
    this.applyForm.markAllAsTouched();

    if (!this.applyForm.valid) {
      return;
    }

    const applyData: RapidApp =
      ({
        firstName: this.applyForm.controls['firstName'].value as string,
        lastName: this.applyForm.controls['lastName'].value as string,
        phone: this.applyForm.controls['phone'].value as string,
        email: this.applyForm.controls['email'].value as string,
        specialty: this.applyForm.controls['specialty'].value as string,
        secondarySpecialties : this.applyForm.controls['secondarySpecialties'].value?.join(',')||'',
        statesLicensed: this.applyForm.controls['statesLicensed'].value?.join(',')||'',
        hasCompactLicense: this.applyForm.controls['hasCompactLicense'].value as boolean,
      });

    this.contactService.submitRapidApp(applyData).subscribe(result => {
      if (result) {
        this.router.navigate(['/thank-you'], {
          state: {
            source: 'rapid-app'
          }
        }).then();
      }
    });
  }

  getClass(controlName: string) {
    // @ts-ignore
    return this.applyForm.controls[controlName].touched && !this.applyForm.controls[controlName].valid ? 'error-label' : 'no-error-label';
  }
}
