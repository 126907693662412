import {Component} from '@angular/core';
import {PtbLayoutLicensureComponent} from './ptb-layout-licensure/ptb-layout-licensure.component';
import {BodyLicensureComponent} from './body-licensure/body-licensure.component';
import {CallToActionComponent} from '../../shared/components/call-to-action/call-to-action.component';
import {FooterComponent} from '../../shared/components/footer/footer/footer.component';
import {ActivatedRoute} from '@angular/router';
import {SeoUtilsService} from '../../shared/services/seo-utils.service';

@Component({
  selector: 'app-licensure-guide',
  standalone: true,
  imports: [
    PtbLayoutLicensureComponent,
    BodyLicensureComponent,
    CallToActionComponent,
    FooterComponent
  ],
  templateUrl: './licensure-guide.component.html',
  styleUrl: './licensure-guide.component.css'
})
export class LicensureGuideComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private seoUtilsService: SeoUtilsService
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'Travel Nurse Licensure Assistance',
      'Our Nightingale license specialists are able to assist you in obtaining a Nursing License in States where you don\'t have one yet, allowing you to travel even more.',
      'travel-nurse-licensure'
    );
  }
}
