<div class="main-job-search-container">
  <app-ptb-layout-job-search></app-ptb-layout-job-search>

  <div class="body-main-container">
    <div class="browse-jobs-container">
      <app-browse-job
        [selectedSpecialties]="jobSearchParams.specialties"
        [selectedStates]="jobSearchParams.states"
        (selectionChanged)="selectionChanged($event)"
      ></app-browse-job>
    </div>

    <div class="jobs-list" *ngIf="jobOrders$ | async as jobOrders">
      <app-job-list
        [jobOrders]="jobOrders"
        (sortChanged)="sortChanged($event)"
      ></app-job-list>
    </div>
  </div>

  <app-apply-today></app-apply-today>
  <app-footer></app-footer>
</div>

