import {Injectable} from '@angular/core';
import {catchError, Observable, of} from "rxjs";
import {Assignment, CandidatePortalReceivedTimesheetFile} from "../types";
import {createFailedResult, Result} from "../../../shared/model/result";
import { HttpClient } from "@angular/common/http";
import {PortalUserFileFull} from "../../portal-user-profile/portal-user-files/shared/types";
import {FileContent} from "../components/file-viewer-dialog/types/types";

@Injectable({
  providedIn: 'root'
})
export class PortalUserAssignmentService {

  constructor(private httpClient: HttpClient) {
  }

  getCandidateAssignments(): Observable<Result<Assignment[]>> {

    return this.httpClient.get<Result<Assignment[]>>(`assignment/assignment-list`);

  }

  getCandidatePortalReceivedTimesheetFiles(assignmentPayrollId: number): Observable<Result<CandidatePortalReceivedTimesheetFile[]>> {

    return this.httpClient.get<Result<CandidatePortalReceivedTimesheetFile[]>>(`assignment/received-timesheet-files/${assignmentPayrollId}`);

  }

  getAssignmentWeekEnds(startDate: Date, endDate: Date): Date[] {
    let dateStart = new Date(startDate);
    let dateEnd = new Date(endDate);
    const weekends: Date[] = [];

    dateStart.setDate(dateStart.getDate() + (6-dateStart.getDay()));

    while (dateStart <= dateEnd) {
      // getDay() returns 0 for Sunday and 6 for Saturday
      if (dateStart.getDay() === 6) {
        weekends.push(new Date(dateStart));
      }
      // Move to the next Sat
      dateStart.setDate(dateStart.getDate() +7);
    }

    return weekends;
  }
}
