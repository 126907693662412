import {Component, Input} from '@angular/core';
import {JobOrder} from "../../../shared/model/types";

@Component({
  selector: 'app-job-benefits',
  standalone: true,
  imports: [],
  templateUrl: './job-benefits.component.html',
  styleUrl: './job-benefits.component.css'
})
export class JobBenefitsComponent {
  @Input() jobOrder: JobOrder;

  constructor() {
  }

  apply(): void {
  }
}
