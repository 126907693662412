import { Component } from '@angular/core';
import {MatCard} from "@angular/material/card";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgTemplateOutlet} from "@angular/common";
import {FormArray, FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {CatalogsService} from "../../../../../shared/services/catalogs.service";
import {ProfileService} from "../../../../portal-user-profile/services/profile.service";
import {
  MultiLineSnackBarService
} from "../../../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {Reference} from "../../../../shared/types";
import {Observable, of} from "rxjs";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-references-info-editor',
  standalone: true,
    imports: [
        MatCard,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatError,
        MatFormField,
        MatIcon,
        MatIconButton,
        MatInput,
        MatLabel,
        MatOption,
        MatSelect,
        MatSuffix,
        NgTemplateOutlet,
        ReactiveFormsModule,
        MatTooltip
    ],
  templateUrl: './references-info-editor.component.html',
  styleUrl: './references-info-editor.component.css'
})
export class ReferencesInfoEditorComponent {

  references = this.profileService.getResumeData()?.references;

  disablePage = false;

  referenceFormArray = this.formBuilder.array([]) as FormArray;
  referenceForm = this.formBuilder.group({

    references: this.referenceFormArray});

  constructor(
    private catalogService: CatalogsService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private multiLineSnackBar: MultiLineSnackBarService,
  ) {

    this.references?.forEach(rfe => {
      this.addReference(rfe);
    });
  }


  addReference(reference: Reference | null = null): void {
    const referenceItem = this.formBuilder.group({
      firstName: [reference?.firstName ?? '', [Validators.required]],
      lastName: [reference?.lastName ?? '', [Validators.required]],
      phone: [reference?.phone ?? '', [Validators.required]],
      email: [reference?.email ?? '', [Validators.email]],
      referencePosition: [reference?.referencePosition ?? ''],
    });

    this.referenceFormArray.push( referenceItem, {emitEvent: false});
  }

  removeReferenceItem(index: number): void {
    this.referenceFormArray.removeAt(index);
  }

  save():Observable<boolean>{
    this.referenceForm.markAllAsTouched();

    if (this.referenceForm.invalid) {
      this.multiLineSnackBar.displayMessages(['Please correct the errors and try again.']);
      return of(false);
    }
    this.disablePage = true;
    const resumeData = this.profileService.getResumeData();
    resumeData.references = this.referenceForm?.value?.references;
    this.profileService.saveResumeData(resumeData);
    return of(true);

  }
}
