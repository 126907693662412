import { Component } from '@angular/core';
import {FrameBenefitsComponent} from "../../../shared/components/frame-benefits/frame-benefits.component";
import {FaqListComponent} from "./faq-list/faq-list.component";
import {WingsComponent} from '../../../benefits/benefits/body-benefit/wings/wings.component';

@Component({
  selector: 'app-body-faq',
  standalone: true,
  imports: [
    FrameBenefitsComponent,
    WingsComponent,
    FaqListComponent
  ],
  templateUrl: './body-faq.component.html',
  styleUrl: './body-faq.component.css'
})
export class BodyFaqComponent {

}
