import {Component, computed, Input, OnInit, ViewChild} from '@angular/core';
import {MatButton, MatIconButton} from '@angular/material/button';
import {PortalUserService} from '../shared/services/portal-user.service';
import {NgClass} from '@angular/common';
import {PortalUserHomeComponent} from '../portal-user-home/portal-user-home.component';
import {PortalUserProfileComponent} from '../portal-user-profile/portal-user-profile.component';
import {MatIcon} from '@angular/material/icon';
import {MatRipple} from '@angular/material/core';
import {Router} from '@angular/router';
import {
  PortalUserPersonalInfoComponent
} from '../portal-user-profile/portal-user-personal-info/portal-user-personal-info.component';
import {
  PortalUserProfessionalInfoComponent
} from '../portal-user-profile/portal-user-professional-info/portal-user-professional-info.component';
import {
  PortalUserWorkHistoryComponent
} from '../portal-user-profile/portal-user-work-history/portal-user-work-history.component';
import {
  PortalUserEducationComponent
} from '../portal-user-profile/portal-user-education/portal-user-education.component';
import {
  PortalUserReferencesComponent
} from '../portal-user-profile/portal-user-references/portal-user-references.component';
import {
  PortalUserSkillsChecklistsComponent
} from '../portal-user-profile/portal-user-skills-checklists/portal-user-skills-checklists.component';
import {MatDrawer, MatDrawerContainer} from '@angular/material/sidenav';
import {PortalUserFilesComponent} from '../portal-user-profile/portal-user-files/portal-user-files.component';
import {PortalUserReferralComponent} from '../portal-user-referral/portal-user-referral.component';
import {PortalUserSettingsComponent} from "../portal-user-settings/portal-user-settings.component";
import {
  PortalAccountPendingVerificationComponent
} from '../account-verification/portal-account-pending-verification/portal-account-pending-verification.component';
import {PortalUserAssignmentComponent} from "../portal-user-assignment/portal-user-assignments.component";


@Component({
  selector: 'app-portal-user-container',
  standalone: true,
  imports: [
    MatButton,
    NgClass,
    PortalUserHomeComponent,
    PortalUserProfileComponent,
    MatIcon,
    MatRipple,
    PortalUserPersonalInfoComponent,
    PortalUserProfessionalInfoComponent,
    PortalUserWorkHistoryComponent,
    PortalUserEducationComponent,
    PortalUserReferencesComponent,
    PortalUserSkillsChecklistsComponent,
    MatIconButton,
    MatDrawerContainer,
    MatDrawer,
    PortalUserFilesComponent,
    PortalUserReferralComponent,
    PortalUserSettingsComponent,
    PortalUserAssignmentComponent,
    PortalAccountPendingVerificationComponent,
  ],
  templateUrl: './portal-user-container.component.html',
  styleUrl: './portal-user-container.component.css'
})
export class PortalUserContainerComponent implements OnInit {
  @Input('initialTab') initialTab = '';
  @Input('showTransition') showTransition = false;

  @ViewChild('mainDiv', {static: true}) mainDiv: any;

  activeTabOptions: TabOption[] = [
    {label: 'Home', icon: 'home', isSelectable: true},
    {
      label: 'Profile', icon: 'account_circle', isSelectable: false, subTabs: [
        {label: 'Personal Info', icon: '', isSelectable: true,},
        {label: 'Professional Info', icon: '', isSelectable: true,},
        {label: 'Work History', icon: '', isSelectable: true,},
        {label: 'Education', icon: '', isSelectable: true,},
        {label: 'References', icon: '', isSelectable: true,},
        {label: 'Skills Checklists', icon: '', isSelectable: true,},
        {label: 'My Files', icon: '', isSelectable: true,},
      ]
    },
    {label: 'Assignments', icon: 'assignment', isSelectable: true},
    {label: 'Refer a Friend', icon: 'group_add', isSelectable: true},

    {
      label: 'Account Settings', icon: 'settings', isSelectable: true },
  ];
  pendingTabOptions: TabOption[] = [
    {label: 'Account Verification', icon: 'verified_user', isSelectable: true, subTabs: []}
  ];

  selectedTab = this.portalUserService.selectedTab;
  userSignal = this.portalUserService.activeUser;
  pageTitle = computed(() => {
    const userName = `${this.userSignal()?.personalInfo.firstName} ${this.userSignal()?.personalInfo.lastName}`;
    return this.selectedTab() === 'Home' ? userName : this.selectedTab();
  });

  constructor(
    private portalUserService: PortalUserService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.showTransition) {
      this.mainDiv.nativeElement.classList.add('expand-open');
    }

    if (!!this.initialTab) {
      this.selectedTab.set(this.initialTab);
    } else {
      this.selectedTab.set('Home');
    }

    if (this.userSignal()?.userStatus?.toLowerCase() === 'pending') {
      this.selectedTab.set('Account Verification');
    }
  }

  selectTab(tab: TabOption, drawer?: MatDrawer): void {
    if (tab.isSelectable) {
      this.selectedTab.set(tab.label);
    }

    if (!!drawer) {
      drawer.toggle().then();
    }
  }

  logout(): void {
    this.mainDiv.nativeElement.classList.add('shrink-close');
    setTimeout(() => this.portalUserService.clearUserData(), 225);
  }

  navigateHome(): void {
    this.router.navigate(['']).then();
  }
}

export class TabOption {
  label: string;
  icon: string;
  isSelectable: boolean = true;
  subTabs?: TabOption[] = [];
}
