import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-refer-rn-now',
  standalone: true,
  imports: [],
  templateUrl: './refer-rn-now.component.html',
  styleUrl: './refer-rn-now.component.css'
})
export class ReferRnNowComponent {
  constructor(
    private router: Router,
  ) {

  }

  navigateToPortal(): void {
    this.router.navigate(['/portal'], {
      state: {
        initialTab: 'Refer a Friend',
      }
    }).then();
  }
}
