import { Component } from '@angular/core';
import {FrameBenefitsComponent} from "../../../shared/components/frame-benefits/frame-benefits.component";
import {WingsComponent} from "./wings/wings.component";

@Component({
  selector: 'app-body-benefit',
  standalone: true,
  imports: [
    FrameBenefitsComponent,
    WingsComponent
  ],
  templateUrl: './body-benefit.component.html',
  styleUrl: './body-benefit.component.css'
})
export class BodyBenefitComponent {

}
