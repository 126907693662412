import { Component } from '@angular/core';
import {HeaderComponent} from "../../shared/components/header/header.component";
import {MatIcon} from "@angular/material/icon";
import {MatChipRemove} from "@angular/material/chips";

@Component({
  selector: 'app-ptb-layout-rapid-app',
  standalone: true,
  imports: [
    HeaderComponent,
    MatIcon,
    MatChipRemove
  ],
  templateUrl: './ptb-layout-rapid-app.component.html',
  styleUrl: './ptb-layout-rapid-app.component.css'
})
export class PtbLayoutRapidAppComponent {

}
