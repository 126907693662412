<div class="hero-container-section">

  <div class="hero">
    <app-header></app-header>

    <div class="home-container">

      <div class="headline">

        <div class="headline-group">
          <h1>
            <div class="headline-1"> we put</div>
            <div class="headline-2">nurses first</div>
          </h1>
        </div>

        <div class="headline-3">
          Put your passion for providing care into action with Nightingale. Appealing travel nursing opportunities
          for the very best nurses are a click away.
        </div>

      </div>
      <div class="desktop-tablet">
        <app-browse-jobs></app-browse-jobs>
      </div>

    </div>
    <div class="mobile">
      <app-browse-jobs></app-browse-jobs>
    </div>
  </div>

</div>

