import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthYear',
  standalone: true
})
export class MonthYearPipe implements PipeTransform {
  private readonly months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  transform(value: Date | string | null): string {
    if (!value) return '';
    const date = new Date(value);
    const month = this.months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  }
}
