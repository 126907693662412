<app-job-detail-header [state]="jobOrder.state" [city]="jobOrder.city"></app-job-detail-header>

<app-quick-details [jobOrder]="jobOrder"></app-quick-details>

<app-job-benefits [jobOrder]="jobOrder"></app-job-benefits>

<app-apply-today></app-apply-today>

<app-footer></app-footer>


