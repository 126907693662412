import {Injectable} from '@angular/core';
import {delay, Observable, of} from 'rxjs';
import {JobOrder, NurseComment} from "../../../shared/model/types";
import nurseComments from "../../../../assets/data/testimonial.json";


@Injectable({
  providedIn: 'root'
})
export class NurseCommentsMockDataService {
  getNurseComments(): Observable<NurseComment[]> {
    return of(nurseComments);
  }
}
