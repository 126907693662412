import {CredentialType} from "../portal-user-profile/portal-user-files/shared/types";

export interface LoggedInUser {
  userProfile: PortalUserProfile;
  token: PortalToken;
}

export interface PortalUserProfile {
  id: number;
  userId: number;
  userStatus: string;
  candidateId: number;
  userEmail: string;
  personalInfo: ProfilePersonalInfo;
  professionalInfo: ProfileProfessionalInfo;
  workHistory: WorkHistory[];
  education: Education[];
  references: Reference[];
  skillsChecklists: SkillsChecklist[];
  files: PortalUserFileMin[];
}

export interface ProfilePersonalInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: string;
  ssn: string;
  emails: ProfileEmail[];
  phones: ProfilePhone[];
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  emergencyContactName: string;
  emergencyContactPhone: string;
  emergencyContactEmail: string;
  emergencyContactRelationship: string;
  emergencyContactAddress: string;
  hasLatexSensitivity: boolean;
  hasLegalRightToWork: boolean;
  portalUserSource: string;
  referredByName: string;
  referredByPhone: string;
  referredByEmail: string;
  portalUserSourceDescription: string;
  referredToSmId:number|null;
}

export interface ProfileEmail {
  id?: number;
  email: string;
  isPrimary: boolean;
}

export interface ProfilePhone {
  id?: number;
  phoneType: string;
  countryCode: string;
  phoneNumber: string;
  isPrimary: boolean;
}

export interface ProfileProfessionalInfo {
  hadLicenseSuspended?: boolean;
  suspensionExplanation?: string;
  wasConvictedOfCrime?: boolean;
  convictionExplanation?: string;
  legalToWork?: boolean;
  chartingEmrExperience?: string[];
  specialty?: string;
  secondarySpecialties?: string[];
  hasCompactLicense?: boolean;
  statesLicensed?: ProfileStateLicense[];
  certifications?: ProfileCertification[];
}

export interface ProfileStateLicense {
  id: number;
  state: string;
  expirationDate: Date;
}

export interface ProfileCertification {
  id: number;
  name: string;
  expirationDate: Date;
}

export interface WorkHistory {
  id?: number;
  hospitalName: string;
  city: string;
  state: string;
  specialty: string;
  fromDate: Date;
  toDate: Date;
  chartingExperience: string;
  salary: string;
  responsibilities: string;
  numberOfBedsInUnit: number;
  numberOfBedsInHospital: number;
  patientRatio: string;
  wasTravelAssignment: boolean;
  travelCompany: string;
  wasTeachingHospital: boolean;
  traumaHospitalLevel: string;
  hasChargeExperience: boolean;
  supervisor: string;
  supervisorPhone: string;
  reasonForLeaving: string;
}

export interface Education {
  id?: number;
  schoolName: string;
  city: string;
  state: string;
  graduationDate: Date;
  degree: string;
}

export interface Reference {
  id?: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  myPosition: string;
  referencePosition: string;
  hospital: string;
  startDate: Date;
  endDate: Date;
}

export interface ChecklistTemplate {
  specialty: string;
  categories: ChecklistCategory[];
}

export interface ChecklistCategory {
  categoryName: string;
  skills: ChecklistSkill[];
}

export interface ChecklistSkill {
  skillName: string;
  skillLevel: number;
}

export interface SkillsChecklist {
  id?: number;
  createdDttm?: Date;
  checklistTemplate: ChecklistTemplate;
}

export interface PortalUserFileMin {
  id: number;
  requirementClass: string;
}

export interface PortalToken {
  token: string;
  tokenExpirationDttm: Date;
  refreshToken: string;
  refreshTokenExpirationDttm: Date;
}

export interface PortalRegistrationData {
  email: string;
  phone: string;
  password: string;
  firstName: string;
  lastName: string;
  specialty: string;
}

export interface CommunicationConsent {
  receiveMarketingEmails: boolean;
  receiveOperationalTextMessages: boolean;
  receiveMarketingTextMessages: boolean;

}

export class PortalUserSourceType {
  public static WebSearch = {
    value: 'webSearch',
    label: 'Web Search'
  };
  public static SocialMedia = {
    value: 'socialMedia',
    label: 'Social Media'
  };
  public static EmailNewsletter = {
    value: 'emailNewsletter',
    label: 'Email Newsletter'
  };
  public static Referral = {
    value: 'referral',
    label: 'Referral'
  };
  public static Other = {
    value: 'other',
    label: 'Other'
  };
  public static portalUserSourceTypeOptions = [
    PortalUserSourceType.WebSearch,
    PortalUserSourceType.SocialMedia,
    PortalUserSourceType.EmailNewsletter,
    PortalUserSourceType.Referral,
    PortalUserSourceType.Other
  ];

  public static getLabel(value: string): string {
    switch (value.toLowerCase()) {
      case PortalUserSourceType.WebSearch.value.toLowerCase():
        return PortalUserSourceType.WebSearch.label;
      case PortalUserSourceType.SocialMedia.value.toLowerCase():
        return PortalUserSourceType.SocialMedia.label;
      case PortalUserSourceType.EmailNewsletter.value.toLowerCase():
        return PortalUserSourceType.EmailNewsletter.label;
      case PortalUserSourceType.Referral.value.toLowerCase():
        return PortalUserSourceType.Referral.label;
      case PortalUserSourceType.Other.value.toLowerCase():
        return PortalUserSourceType.Other.label;
      default:
        return value;
    }
  }
}

export interface Assignment {
  assignmentPayrollId: number
  status: string;
  specialty: string;
  startDate: Date;
  actualEndDate: Date;
  facilityId: number;
  facility: string;
  state: string;
  city: string;
  candidateId: number;
  candidate: string;
  deficitHours: number;
  workedHours: number;
  eaFileId: number;
  legacyEaFileName: string;
  legacyEaFilePath: string;
  credentialerFirstName: string;
  credentialerLastName: string;
  credentialerPhone: string;
  credentialerEmail: string;
}

export enum AssignmentStatus {
  Working = 'Working',
  Locked = 'Locked',
  PendingExtension = 'PendingExtension',
  PendingNew = 'PendingNew',
  EndedNormally = 'EndedNormally',
  EarlyTermination = 'EarlyTermination'
}


export interface CandidatePortalReceivedTimesheet {
  candidateId: number;
  assignmentPayrollId: number
  weekEnding?: Date;
  fileName: string;
  fileExtension: string;
  fileContent: string;


}

// export class CredentialRequirement {
//   id: number;
//   calcId: number;
//   requirementClass: string;
//   existingEsignTemplateId: number;
//   //nLineEsignTemplate: DocuSignDocument;
//   name: string;
//   createdDttm: Date;
//   requiredOnLock: boolean;
//   isArchived: boolean;
//   externalPartyName: string;
//   externalPartyUrl: string;
//   externalPartyAddress: string;
//   doNotSend: boolean;
//   isFullFilled: boolean;
//   canExpire: boolean;
//   receivedFiles: RequirementFile[] = [];
// }
//
// export class RequirementFile {
//   id: number;
//   credentialRequirementId: number;
//   fileId: number;
//   filename: string;
//   receivedDttm: Date;
//   expirationDate: Date;
//   isAccepted: boolean;
//   note: string;
// }
//
// export class RequirementTemplate {
//   id: number;
//   name: string;
//   requirementClass: string;
//   esignTemplateId: number;
//   rnCore = false;
//   techCore = false;
//   isGeneric = false;
//   isRequiredOnLock = false;
//   canExpire = false;
// }


export class CandidatePortalReceivedTimesheetFile {
  id: number;
  candidateId: number;
  assignmentPayrollId: number
  weekEnding: Date
  createdDttm: Date
  filePath: string
  fileName: string
}

export interface CandidatePortalReceivedTimesheetFilesByWeekEnding {
  weekEnding: string;
  files: CandidatePortalReceivedTimesheetFile[];
}

export interface FileInfo {
  fileName: string;
  fileExtension: string;
  base64FileContents: string;
  lastModifiedDate: Date;

}

