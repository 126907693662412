import {Injectable} from '@angular/core';
import {State} from '../model/types';
import specialties from '../../../assets/data/specialty.json';
import states from '../../../assets/data/state.json';
import certifications from '../../../assets/data/certification.json';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  getSpecialties(): string[] {
    return specialties;
  }

  getStates(): State[] {
    return states;
  }

  getCertifications(): string[] {
    return certifications;
  }
}
