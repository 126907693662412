import {Component, computed, Signal} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatCard, MatCardActions} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {MatDialog} from '@angular/material/dialog';
import {ProfileService} from '../services/profile.service';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogResult, ConfirmationDialogStyle} from '../../../shared/components/confirmation-dialog/types';
import {SkillsChecklistDialogComponent} from './skills-checklist-dialog/skills-checklist-dialog.component';
import {ChecklistTemplate, SkillsChecklist} from '../../shared/types';

@Component({
  selector: 'app-portal-user-skills-checklists',
  standalone: true,
  imports: [
    DatePipe,
    MatButton,
    MatCard,
    MatCardActions,
    MatIcon,
    MatIconButton,
    MatProgressSpinner
  ],
  templateUrl: './portal-user-skills-checklists.component.html',
  styleUrl: './portal-user-skills-checklists.component.css'
})
export class PortalUserSkillsChecklistsComponent {
  skillsChecklists: Signal<SkillsChecklist[]> = computed(() => this.sortChecklists(this.userService.activeUser()!.skillsChecklists ?? []));

  disablePage = false;

  constructor(
    private userService: PortalUserService,
    private matDialog: MatDialog,
    private profileService: ProfileService,
    private snackBar: MultiLineSnackBarService,
  ) {
  }

  removeChecklist(checklistId?: number): void {
    if (!checklistId) {
      this.snackBar.displayMessages(['An error occurred, please reload the page and try again.']);
      return;
    }
    this.matDialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      data: {
        title: 'Delete Checklist',
        message: 'Are you sure you want to delete this record? This action cannot be undone.',
        confirmationDialogStyle: ConfirmationDialogStyle.twoButtonDialog,
        positiveBtnText: 'Delete',
        negativeBtnText: 'Cancel',
      }
    }).afterClosed().subscribe(result => {
      if (result === ConfirmationDialogResult.positive) {
        this.disablePage = true;
        this.profileService.deleteSkillsChecklist(checklistId).subscribe(result => {
          if (result.isSuccess){
            const checklists = this.userService.activeUser()?.skillsChecklists ?? [];
            if (checklists.find(e => e.id === checklistId)) {
              checklists.splice(checklists.findIndex(e => e.id === checklistId), 1);
            }
            this.userService.setSkillsChecklists(checklists);
          } else {
            this.snackBar.displayMessages(result.errorList);
          }
          this.disablePage = false;
        });
      }
    });
  }

  editChecklist(checklist: ChecklistTemplate): void {
    this.matDialog.open(SkillsChecklistDialogComponent, {
      autoFocus: false,
      panelClass: ['full-screen-mobile-dialog', 'full-screen-tablet-dialog'],
      data: checklist,
      disableClose: true,
    });
  }

  addChecklist(): void {
    this.matDialog.open(SkillsChecklistDialogComponent, {
      autoFocus: false,
      panelClass: ['full-screen-mobile-dialog', 'full-screen-tablet-dialog'],
      data: null,
      disableClose: true,
    });
  }

  private sortChecklists(skillsChecklists: SkillsChecklist[]): SkillsChecklist[] {
    return skillsChecklists
      .sort((a, b) => (b.createdDttm?.getTime() ?? 0) - (a.createdDttm?.getTime() ?? 0));
  }
}
