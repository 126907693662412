<div class="portal-background flex-column" #mainDiv>

  <mat-drawer-container>
    <mat-drawer #menuDrawer style="width: 40vw; min-width: 200px;">
      <div class="portal-header mobile-logo-header">
        <div class="header-logo" (click)="navigateHome()"></div>
      </div>

      <div class="settings-list">
        @for (tabOption of activeTabOptions; track tabOption.label) {
          <div class="setting-option unselectable"
               matRipple
               [matRippleDisabled]="!tabOption.isSelectable"
               [ngClass]="[tabOption.label === selectedTab() ? 'setting-option-selected' : '', tabOption.isSelectable ? 'setting-option-selectable' : '']"
               (click)="selectTab(tabOption, menuDrawer)">
            <mat-icon>{{ tabOption.icon }}</mat-icon>
            <span class="setting-label">{{ tabOption.label }}</span>
          </div>

          @for (subTab of tabOption.subTabs; track subTab.label) {
            <div class="sub-option"
                 matRipple
                 [ngClass]="subTab.label === selectedTab() ? 'sub-option-selected' : ''"
                 (click)="selectTab(subTab, menuDrawer)">
              <span class="setting-label">{{ subTab.label }}</span>
            </div>
          }
        }

        <div class="flex-expand"></div>

        <div class="setting-option setting-option-selectable" matRipple (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span class="setting-label">Logout</span>
        </div>
      </div>
    </mat-drawer>

    <div class="portal-background flex-column" style="height: 100dvh;">
      <div class="flex-row portal-header">
        <div class="header-logo non-drawer-menu" (click)="navigateHome()"></div>
        <button mat-icon-button class="settings-menu-button" (click)="menuDrawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <div class="flex-expand" style="display: flex; align-items: center; justify-content: center; font-size: 24px;">
          <span>{{ pageTitle() }}</span>
        </div>
      </div>

      <div class="flex-row flex-expand">
        <div class="settings-list non-drawer-menu">
          @if (userSignal()?.userStatus?.toLowerCase() === 'active') {
            @for (tabOption of activeTabOptions; track tabOption.label) {
              <div class="setting-option unselectable"
                   matRipple
                   [matRippleDisabled]="!tabOption.isSelectable"
                   [ngClass]="[tabOption.label === selectedTab() ? 'setting-option-selected' : '', tabOption.isSelectable ? 'setting-option-selectable' : '']"
                   (click)="selectTab(tabOption)">
                <mat-icon>{{ tabOption.icon }}</mat-icon>
                <span class="setting-label">{{ tabOption.label }}</span>
              </div>
              @for (subTab of tabOption.subTabs; track subTab.label) {
                <div class="sub-option"
                     matRipple
                     [ngClass]="subTab.label === selectedTab() ? 'sub-option-selected' : ''"
                     (click)="selectTab(subTab)">
                  <span class="setting-label">{{ subTab.label }}</span>
                </div>
              }
            }
          } @else {
            @for (tabOption of pendingTabOptions; track tabOption.label) {
              <div class="setting-option unselectable"
                   matRipple
                   [matRippleDisabled]="!tabOption.isSelectable"
                   [ngClass]="[tabOption.label === selectedTab() ? 'setting-option-selected' : '', tabOption.isSelectable ? 'setting-option-selectable' : '']"
                   (click)="selectTab(tabOption)">
                <mat-icon>{{ tabOption.icon }}</mat-icon>
                <span class="setting-label">{{ tabOption.label }}</span>
              </div>
              @for (subTab of tabOption.subTabs; track subTab.label) {
                <div class="sub-option"
                     matRipple
                     [ngClass]="subTab.label === selectedTab() ? 'sub-option-selected' : ''"
                     (click)="selectTab(subTab)">
                  <span class="setting-label">{{ subTab.label }}</span>
                </div>
              }
            }
          }
          <div class="flex-expand"></div>
          <div class="setting-option setting-option-selectable" matRipple (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span class="setting-label">Logout</span>
          </div>
        </div>
        <div class="portal-component-container">
          @switch (selectedTab()) {
            @case ('Home') {
              <app-portal-user-home></app-portal-user-home>
            }
            @case ('Personal Info') {
              <app-portal-user-personal-info></app-portal-user-personal-info>
            }
            @case ('Professional Info') {
              <app-portal-user-professional-info></app-portal-user-professional-info>
            }
            @case ('Work History') {
              <app-portal-user-work-history></app-portal-user-work-history>
            }
            @case ('Education') {
              <app-portal-user-education></app-portal-user-education>
            }
            @case ('References') {
              <app-portal-user-references></app-portal-user-references>
            }
            @case ('Skills Checklists') {
              <app-portal-user-skills-checklists></app-portal-user-skills-checklists>
            }
            @case ('My Files') {
              <app-portal-user-files></app-portal-user-files>
            }
            @case ('Refer a Friend') {
              <app-portal-user-referral></app-portal-user-referral>
            }
            @case ('Assignments') {
              <app-portal-user-assignment></app-portal-user-assignment>
            }
            @case ('Account Settings') {
              <app-portal-user-settings></app-portal-user-settings>
            }
            @case ('Account Verification') {
              <app-portal-account-pending-verification></app-portal-account-pending-verification>
            }
            @default {
              <app-portal-user-home></app-portal-user-home>
            }
          }
        </div>
      </div>
    </div>

  </mat-drawer-container>

</div>
