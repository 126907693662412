import {Component, OnInit} from '@angular/core';
import {PortalUserFileService} from './shared/portal-user-file.service';
import {AsyncPipe, DatePipe, NgClass} from '@angular/common';
import {
  BehaviorSubject,
  combineLatestWith,
  flatMap,
  forkJoin, map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatButton, MatIconButton} from '@angular/material/button';
import {FileType, PortalUserFileList} from './shared/types';
import {Result} from '../../../shared/model/result';
import {MatDialog, MatDialogContent} from '@angular/material/dialog';
import {PortalUserFileDialogComponent} from './portal-user-file-dialog/portal-user-file-dialog.component';
import {MatCard} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogResult, ConfirmationDialogStyle} from '../../../shared/components/confirmation-dialog/types';
import {CredentialRequirementClassPipe} from '../../../shared/model/enumTypes';
import {FileViewerDialogComponent} from '../../shared/components/file-viewer-dialog/file-viewer-dialog.component';
import {FileViewData} from '../../shared/components/file-viewer-dialog/types/types';
import {PortalUserService} from '../../shared/services/portal-user.service';

@Component({
  selector: 'app-portal-user-files',
  standalone: true,
  imports: [
    AsyncPipe,
    MatProgressSpinner,
    MatButton,
    MatDialogContent,
    NgClass,
    MatCard,
    MatIcon,
    MatIconButton,
    DatePipe,
    CredentialRequirementClassPipe,
  ],
  templateUrl: './portal-user-files.component.html',
  styleUrl: './portal-user-files.component.css'
})
export class PortalUserFilesComponent implements OnInit {
  isLoading = true;
  disablePage = false;
  isDraggingFile = false;
  fileTypes: FileType[] = [];

  fileTypes$ = this.fileService.getFileTypes();
  private fileSubject = new BehaviorSubject<boolean>(true);

  loadFileResults$: Observable<Result<PortalUserFileList[]>> = this.fileSubject
    .asObservable()
    .pipe(
      switchMap(() => forkJoin([this.fileService.getFiles(), this.fileTypes$])),
      tap(([filesResult, fileTypesResult]) => {
        if (!filesResult.isSuccess || !fileTypesResult.isSuccess) {
          this.snackBar.displayMessages([...filesResult.errorList, ...fileTypesResult.errorList]);
          this.disablePage = true;
        } else {
          this.fileTypes = fileTypesResult.value!;
          this.fileTypes$ = of(fileTypesResult);
        }
        this.isLoading = false;
      }),
      switchMap(([filesResult, _]) => of(filesResult)),
    );


  constructor(
    private fileService: PortalUserFileService,
    private snackBar: MultiLineSnackBarService,
    private matDialog: MatDialog,
    private portalUserService: PortalUserService,
  ) {
  }

  ngOnInit(): void {
  }

  onDragLeave(event: DragEvent) {
    // Check if the relatedTarget is a child of the drop zone
    if ((event.currentTarget! as Node).contains(event.relatedTarget as Node)) {
      // If it is, do not fire the dragleave event
      return;
    }

    this.isDraggingFile = false;
  }

  viewFile(fileId: number): void {
    const fileData$: Observable<FileViewData> = this.fileService.getFile(fileId)
      .pipe(
        map((fileResult) => {
          return {
            fileName: fileResult.value!.fileName,
            fileExtension: fileResult.value!.fileExtension,
            base64FileContents: fileResult.value!.base64FileContents
          };
        })
      );
    this.matDialog.open(FileViewerDialogComponent, {
      panelClass: ['full-screen-mobile-dialog', 'full-screen-tablet-dialog'],
      data: {
        fileData$: fileData$
      }
    });
  }

  dropFile(event: DragEvent): void {
    if (this.isLoading) {
      return;
    }

    event.preventDefault();
    this.isDraggingFile = false;

    if (event.dataTransfer?.files.length !== 1) {
      this.snackBar.displayMessages(['Please drop only one file at a time.']);
      return;
    }

    const file = event.dataTransfer?.files[0];
    if (!!file) {
      if (file.size > 20971520) {
        this.snackBar.displayMessages(['File size must be less than 20MB.']);
        return;
      }
      this.addFile(file);
    }
  }

  addFile(uploadedFile?: File): void {
    this.matDialog.open(PortalUserFileDialogComponent, {
      panelClass: ['full-screen-mobile-dialog'],
      data: {
        fileTypes: this.fileTypes,
        uploadedFile: uploadedFile
      }
    }).afterClosed().subscribe((result) => {
      if (!!result) {
        this.fileSubject.next(true);
      }
    });
  }

  deleteFile(fileId: number): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete File',
        message: 'Are you sure you want to delete this file? This action cannot be undone.',
        confirmationDialogStyle: ConfirmationDialogStyle.twoButtonDialog,
        positiveBtnText: 'Delete',
        negativeBtnText: 'Cancel',
      }
    }).afterClosed().subscribe((result) => {
      if (result === ConfirmationDialogResult.positive) {
        this.fileService.deleteFile(fileId).subscribe((deleteResult) => {
          if (deleteResult.isSuccess) {
            this.portalUserService.tryRemoveFile(fileId);
            this.snackBar.displayMessages(['File deleted successfully.']);
            this.fileSubject.next(true);
          } else {
            this.snackBar.displayMessages(deleteResult.errorList);
          }
        });
      }
    });
  }
}
