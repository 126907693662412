<div class="page-container">
  <div class="header-background">
    <app-header></app-header>
  </div>

  <div class="body-background">
    <div class="page-body">
      <div class="content-container">
        <div class="verify-content-title">
          {{ title }}
        </div>
        @if (isLoading) {
          <div class="loading-container">
            <mat-spinner mode="indeterminate"></mat-spinner>
            <span class="flex-expand"></span>
            <span>Attempting to verify and activate account, please wait...</span>
          </div>
        }
        @if (isInvalidLink) {
          <div class="invalid-container">
            <div>Uh oh, it looks like this link is not a valid verification link.</div>
            <div>If you need a new link, please <a href="/portal">click here</a> to login and have click on the option to have a new email sent.</div>
          </div>
        }
        @if (!isInvalidLink && !isLoading) {
          <div class="verified-container">
            <h4>Thank you for verifying your account!</h4>
            <div><a href="/portal">Click here</a> to login and start using your account</div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
