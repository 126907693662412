<div class="portal-container">

  <div class="header-background">
    <app-header></app-header>
  </div>

  @if (tokenValidation$ | async; as token) {
    @if (token.isSuccess) {
      <div class="login-register-container">

        <div style="color: black; font-family: 'Manrope Bold', sans-serif">
          <h5>Reset Password</h5>
        </div>

        <form class="login-register-form" [formGroup]="newPasswordForm">
          <mat-form-field class="form-field">
            <mat-label>New Password</mat-label>
            <input matInput type="password" formControlName="password">
            @if (newPasswordForm.controls.password.hasError('required')) {
              <mat-error>Password is required.</mat-error>
            }
            @if (newPasswordForm.hasError('fieldsDoNotMatch')) {
              <mat-error>Passwords do not match.</mat-error>
            }
          </mat-form-field>

          <mat-form-field class="form-field">
            <mat-label>Confirm New Password</mat-label>
            <input matInput type="password" formControlName="confirmPassword">
            @if (newPasswordForm.controls.confirmPassword.hasError('required')) {
              <mat-error>Password confirmation is required.</mat-error>
            }
            @if (newPasswordForm.hasError('fieldsDoNotMatch')) {
              <mat-error>Passwords do not match.</mat-error>
            }
          </mat-form-field>


        </form>

        <div class="login-register-button-row">
          <button class="login-register-button" mat-raised-button color="primary" (click)="submit()"
                  [disabled]="isLoading">
            Submit
          </button>
        </div>

      </div>
    } @else {
      <div class="login-register-container">
        <div style="color: red;font-family: 'Manrope Medium', sans-serif;  font-size: 12px;text-align: center">
          <h5> {{ token.errorList }}</h5>
        </div>
      </div>
    }
  }
</div>
