import {Component} from '@angular/core';
import {JobDetailHeaderComponent} from "./job-detail-header/job-detail-header.component";
import {JobOrder} from "../../shared/model/types";
import {Router} from "@angular/router";
import {ApplyTodayComponent} from "../../shared/components/apply-today/apply-today.component";
import {QuickDetailsComponent} from "./quick-details/quick-details.component";
import {JobBenefitsComponent} from "./job-benefits/job-benefits.component";
import {FooterComponent} from "../../shared/components/footer/footer/footer.component";

@Component({
  selector: 'app-job-details',
  standalone: true,
  imports: [
    JobDetailHeaderComponent,
    ApplyTodayComponent,
    QuickDetailsComponent,
    JobBenefitsComponent,
    FooterComponent
  ],
  templateUrl: './job-details.component.html',
  styleUrl: './job-details.component.css'
})
export class JobDetailsComponent {
  jobOrder: JobOrder;

  constructor(
    private router: Router,
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras.state) {
      this.jobOrder = navigation.extras.state["jobOrder"]
    }
  }

}
