<div class="faq-item">
  <div>
    <div class="number">02</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>How does the clinical travel process work?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>The process begins with an interaction with one of our trained and experienced Staffing
          Managers (SM). In that first interaction, candidates are preliminarily screened and vetted by
          the SM as to both interests and plans, as well as how they match up to the myriad
          opportunities that are available.</p>
        <br>
        <p>
          If both parties are satisfied to move to the next step, the candidate completes an employment
          application and a detailed checklist of their experience and comfort within their area of
          specialization.</p>
        <br>
        <p>
          Simultaneously, the SM is matching the candidate to both open positions, and those that are
          highly likely to open in the near term. It is at this time that the candidate’s background,
          education, experience, and desires <i>regarding a potential job are matched in detail</i>. The SM will
          discuss and offer assorted opportunities for consideration by the candidate and move on to
          the next stage, which is the <a href="https://www.nightingalenurses.net/benefits" class="faq-link">development of an economic package</a>.
        </p>
        <br>
        <p>
          Once the parties agree on all details, the SM electronically submits the candidate’s profile
          information to the end-user client facility for their consideration.  <i>Most clients will then want to
          have a cell phone interaction with the candidate to further explore the opportunity and to
          reconfirm the match.</i>
        </p>
        <br>
        <p>
          If all goes well, the client will propose an offer of employment, confirming all details and
          electronic agreements are prepared for the review and execution of the parties. An expected
          start date is set, and on-boarding begins.</p>

      </div>
    }

  </div>

</div>

