import {Component} from '@angular/core';
import {BodyBenefitComponent} from "./body-benefit/body-benefit.component";
import {LegendsComponent} from "./legends/legends.component";
import {FooterComponent} from "../../shared/components/footer/footer/footer.component";
import {TestimonialsComponent} from "../../shared/components/testimonials/testimonials.component";
import {PtbLayoutBenefitComponent} from "./ptb-layout-benefit/ptb-layout-benefit.component";
import {ActivatedRoute} from '@angular/router';
import {SeoUtilsService} from '../../shared/services/seo-utils.service';


@Component({
  selector: 'app-benefits',
  standalone: true,
  imports: [

    FooterComponent,
    BodyBenefitComponent,
    LegendsComponent,
    TestimonialsComponent,
    PtbLayoutBenefitComponent
  ],
  templateUrl: './benefits.component.html',
  styleUrl: './benefits.component.css'
})
export class BenefitsComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private seoUtilsService: SeoUtilsService,
  ) {
    this.seoUtilsService.setTitleAndMeta(
      this.activatedRoute,
      'Benefits of Working With Us - Nightingale',
       'We\'re not just a job listing service. We partner with travel nurses like you to get you the very best possible benefits from your temporary assignments.',
      'travel-nurse-benefits'
    );
  }

}
