<div class="ptb-layout-license">
  <app-header style="width: 100%"></app-header>
  <div class="ptb-license-main-frame">
    <div class="ptb-license-explanation-frame">
      <div class="ptb-license-title-frame">
        <h2>
          <div class="ptb-license-title-italic">Travel Nurse
            <span class="ptb-license-title">Licensing</span>
          </div>
        </h2>
      </div>
      <div class="ptb-license-title-explanation">Where applicable our license specialists are able to assist you in
        obtaining a Nursing <br>
        License in States where you currently do not hold one.
      </div>
    </div>
    <div class="line-green"></div>
  </div>
</div>

