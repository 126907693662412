import {Component} from '@angular/core';
import {BenefitReasonsComponent} from "../benefit-reasons/benefit-reasons.component";
import {AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {BenefitsMockDataService} from "../../../benefits/benefits/services/benefits-mock-data-service";
import {tap} from 'rxjs';


@Component({
  selector: 'app-frame-benefits',
  standalone: true,
  imports: [
    BenefitReasonsComponent,
    NgForOf,
    NgOptimizedImage,
    NgClass,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './frame-benefits.component.html',
  styleUrl: './frame-benefits.component.css'
})
export class FrameBenefitsComponent {

  constructor(
    private benefitsService: BenefitsMockDataService
  ) {
  }

  benefits$ = this.benefitsService.getBenefits()
    .pipe(
      tap(benefits => console.log(benefits))
    );

  getColor(itemOrder: number, deviceType: string): string {
    if (deviceType === 'desktop') {
      if (itemOrder % 2 === 1)
        return 'green';
    } else if (deviceType === 'tablet') {
      if (itemOrder % 4 === 0 || itemOrder % 4 === 1)
        return 'green';
    } else if (deviceType === 'mobile') {
      if (itemOrder % 2 === 1)
        return 'green';
    }

    return 'blue';
  }
}
