<div class="jobs-list">
  @for (jobOrder of visibleOrders; track jobOrder; let index = $index) {
    <div class="flex-r">
    <app-job
      [jobOrder]="jobOrder"
      [faded]="index === 3"
      [carousel]="index === 0 && startIndex > 0? CarouselType.Left : index === 3 && startIndex + 4 < jobOrders.length? CarouselType.Right : CarouselType.None"
      (onScroll)="scroll($event)"
    ></app-job>
      </div>
  }
</div>
