import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoUtilsService {

  private readonly url: string;

  constructor(
    private title: Title,
    private meta: Meta
  ) {
    this.url = environment
                 .siteUrl
                 .replace(/\/+$/, '');
  }

  setTitleAndMeta(
    activatedRoute: ActivatedRoute | null | undefined,
    title: string,
    description: string,
    ogImagePath: string
  ) {
    const pageUrl = activatedRoute?.snapshot?.url?.toString()?.replace(',', '/') ?? '';

    this.title.setTitle(title);

    this.meta.addTag({
      name: 'description',
      content: description
    });

    this.meta.addTag({
      property: 'og:title',
      content: title
    });

    this.meta.addTag({
      property: 'og:description',
      content: description
    });

    this.meta.addTag({
      property: 'og:url',
      content: `${this.url}/${pageUrl}`
    });

    this.meta.addTag({
      property: 'og:site_name',
      content: 'Nightingale'
    });

    this.meta.addTag({
      property: 'og:type',
      content: 'website'
    });

    this.meta.addTag({
      property: 'og:image',
      content: `${this.url}/assets/og/images/${ogImagePath}.webp`
    });
  }
}
