<div class="faq-item">
  <div>
    <div class="number">04</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>What elements are considered by the parties during the matching process?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>Nightingale is a market maker of professional clinical labor. Since the national market is always in a
        state of disequilibrium (i.e., supply and demand never match perfectly), Nightingale intervention
        serves to make the job market work for both the client and the candidate.
        </p>
        <br>
        <p>
        The clinician is typically concerned with the location, duration, duties, shift, patient ratios, scheduling,
          <a href="https://www.nightingalenurses.net/housing" class="faq-link">lodging</a>, meals, and salary. Some clinicians will request that their schedule is “blocked” together, so
        they get several days off in a row per work week. With many jobs available, we can <i>find one that works
        with the clinician’s scheduling needs</i>. </p>
        <br>
        <p>The client’s staffing concerns typically involve licensure, certification, education, employment history,
        background, and references. Nightingale acts as the ultimate intermediary, for both the client and
        candidate, ensuring that both parties’ needs and concerns are met. Our goal in providing service is
        that everyone is satisfied.</p>
      </div>
    }

  </div>

</div>

