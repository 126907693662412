[
  {
    "label": "ANTE",
    "value": "ANTE"
  },
  {
    "label": "BIOMEDTECH",
    "value": "BIOMEDTECH"
  },
  {
    "label": "BMET",
    "value": "BMET"
  },
  {
    "label": "CASE MGR",
    "value": "CASE MGR"
  },
  {
    "label": "CATH LAB",
    "value": "CATH LAB"
  },
  {
    "label": "CATH LAB TECH",
    "value": "CATH LAB TECH"
  },
  {
    "label": "CCU",
    "value": "CCU"
  },
  {
    "label": "CDS",
    "value": "CDS"
  },
  {
    "label": "CNA",
    "value": "CNA"
  },
  {
    "label": "COTA",
    "value": "COTA"
  },
  {
    "label": "CRNA",
    "value": "CRNA"
  },
  {
    "label": "CST",
    "value": "CST"
  },
  {
    "label": "CT",
    "value": "CT"
  },
  {
    "label": "CVICU",
    "value": "CVICU"
  },
  {
    "label": "CVOR",
    "value": "CVOR"
  },
  {
    "label": "CVORT",
    "value": "CVORT"
  },
  {
    "label": "DIALYSIS",
    "value": "DIALYSIS"
  },
  {
    "label": "DIALYSIS TECH",
    "value": "DIALYSIS TECH"
  },
  {
    "label": "DIETICIAN",
    "value": "DIETICIAN"
  },
  {
    "label": "DOSIMETRIST",
    "value": "DOSIMETRIST"
  },
  {
    "label": "ECHO TECH",
    "value": "ECHO TECH"
  },
  {
    "label": "EKG TECH",
    "value": "EKG TECH"
  },
  {
    "label": "EMR SPECIALIST",
    "value": "EMR SPECIALIST"
  },
  {
    "label": "ENDO",
    "value": "ENDO"
  },
  {
    "label": "EP LAB",
    "value": "EP LAB"
  },
  {
    "label": "ER",
    "value": "ER"
  },
  {
    "label": "ER HOLD",
    "value": "ER HOLD"
  },
  {
    "label": "ER PEDS",
    "value": "ER PEDS"
  },
  {
    "label": "HISTO TECH",
    "value": "HISTO TECH"
  },
  {
    "label": "HOME HEALTH",
    "value": "HOME HEALTH"
  },
  {
    "label": "HOSPICE",
    "value": "HOSPICE"
  },
  {
    "label": "ICU",
    "value": "ICU"
  },
  {
    "label": "ICU FLOAT",
    "value": "ICU FLOAT"
  },
  {
    "label": "IMCU",
    "value": "IMCU"
  },
  {
    "label": "IR",
    "value": "IR"
  },
  {
    "label": "IR TECH",
    "value": "IR TECH"
  },
  {
    "label": "L&D",
    "value": "L&D"
  },
  {
    "label": "LAB TECH",
    "value": "LAB TECH"
  },
  {
    "label": "LPN",
    "value": "LPN"
  },
  {
    "label": "LPN DIALYSIS",
    "value": "LPN DIALYSIS"
  },
  {
    "label": "LPN MS",
    "value": "LPN MS"
  },
  {
    "label": "LTAC",
    "value": "LTAC"
  },
  {
    "label": "LTC",
    "value": "LTC"
  },
  {
    "label": "MAMMO TECH",
    "value": "MAMMO TECH"
  },
  {
    "label": "MB",
    "value": "MB"
  },
  {
    "label": "MEDICAL LAB TECH",
    "value": "MEDICAL LAB TECH"
  },
  {
    "label": "MEDICAL TECHNOLOGIST",
    "value": "MEDICAL TECHNOLOGIST"
  },
  {
    "label": "MICU",
    "value": "MICU"
  },
  {
    "label": "MRI",
    "value": "MRI"
  },
  {
    "label": "MS",
    "value": "MS"
  },
  {
    "label": "MS FLOAT",
    "value": "MS FLOAT"
  },
  {
    "label": "MS TELE",
    "value": "MS TELE"
  },
  {
    "label": "MS TELE FLOAT",
    "value": "MS TELE FLOAT"
  },
  {
    "label": "NEURO",
    "value": "NEURO"
  },
  {
    "label": "NEURO ICU",
    "value": "NEURO ICU"
  },
  {
    "label": "NEURO MS",
    "value": "NEURO MS"
  },
  {
    "label": "NEURO OR",
    "value": "NEURO OR"
  },
  {
    "label": "NEURO PCU",
    "value": "NEURO PCU"
  },
  {
    "label": "NEURO TELE",
    "value": "NEURO TELE"
  },
  {
    "label": "NICU",
    "value": "NICU"
  },
  {
    "label": "NUC MED TECH",
    "value": "NUC MED TECH"
  },
  {
    "label": "NURSE EDUCATOR",
    "value": "NURSE EDUCATOR"
  },
  {
    "label": "NURSE PRACT",
    "value": "NURSE PRACT"
  },
  {
    "label": "OCC HEALTH",
    "value": "OCC HEALTH"
  },
  {
    "label": "ONCOLOGY",
    "value": "ONCOLOGY"
  },
  {
    "label": "OR",
    "value": "OR"
  },
  {
    "label": "OR MGR",
    "value": "OR MGR"
  },
  {
    "label": "OR PEDS",
    "value": "OR PEDS"
  },
  {
    "label": "ORT",
    "value": "ORT"
  },
  {
    "label": "OT",
    "value": "OT"
  },
  {
    "label": "PACU",
    "value": "PACU"
  },
  {
    "label": "PATIENT OBSERVER",
    "value": "PATIENT OBSERVER"
  },
  {
    "label": "PCU",
    "value": "PCU"
  },
  {
    "label": "PEDS",
    "value": "PEDS"
  },
  {
    "label": "PERFUSIONIST",
    "value": "PERFUSIONIST"
  },
  {
    "label": "PHARMACIST",
    "value": "PHARMACIST"
  },
  {
    "label": "PHLEBOTOMIST",
    "value": "PHLEBOTOMIST"
  },
  {
    "label": "PICU",
    "value": "PICU"
  },
  {
    "label": "PSYCH",
    "value": "PSYCH"
  },
  {
    "label": "PSYCH TECH",
    "value": "PSYCH TECH"
  },
  {
    "label": "PT",
    "value": "PT"
  },
  {
    "label": "PTA",
    "value": "PTA"
  },
  {
    "label": "RAD",
    "value": "RAD"
  },
  {
    "label": "RAD TECH",
    "value": "RAD TECH"
  },
  {
    "label": "RADIOLOGY",
    "value": "RADIOLOGY"
  },
  {
    "label": "REHAB",
    "value": "REHAB"
  },
  {
    "label": "Respiratory Therapist",
    "value": "Respiratory Therapist"
  },
  {
    "label": "RNFA",
    "value": "RNFA"
  },
  {
    "label": "RT Tech",
    "value": "RT Tech"
  },
  {
    "label": "SICU",
    "value": "SICU"
  },
  {
    "label": "SOCIAL WORKER",
    "value": "SOCIAL WORKER"
  },
  {
    "label": "SPEC PROC TECH",
    "value": "SPEC PROC TECH"
  },
  {
    "label": "SPEECH PATHOLOGIST",
    "value": "SPEECH PATHOLOGIST"
  },
  {
    "label": "STEPDOWN",
    "value": "STEPDOWN"
  },
  {
    "label": "STERILE TECH",
    "value": "STERILE TECH"
  },
  {
    "label": "SUPERVISOR",
    "value": "SUPERVISOR"
  },
  {
    "label": "TELE",
    "value": "TELE"
  },
  {
    "label": "ULTRASOUND TECH",
    "value": "ULTRASOUND TECH"
  },
  {
    "label": "VACCINATOR",
    "value": "VACCINATOR"
  },
  {
    "label": "WOUND CARE",
    "value": "WOUND CARE"
  },
  {
    "label": "X-RAY TECH",
    "value": "X-RAY TECH"
  },
  {
    "label": "PEDS ONCOLOGY",
    "value": "PEDS ONCOLOGY"
  },
  {
    "label": "PEDS STEPDOWN",
    "value": "PEDS STEPDOWN"
  },
  {
    "label": "PCVICU",
    "value": "PCVICU"
  },
  {
    "label": "PEDS REHAB",
    "value": "PEDS REHAB"
  },
  {
    "label": "ENDO TECH",
    "value": "ENDO TECH"
  },
  {
    "label": "CSTFA",
    "value": "CSTFA"
  },
  {
    "label": "Conscious Sedation",
    "value": "Conscious Sedation"
  },
  {
    "label": "Cytotechnologist",
    "value": "Cytotechnologist"
  },
  {
    "label": "Pharmacy Tech",
    "value": "Pharmacy Tech"
  },
  {
    "label": "Physicist",
    "value": "Physicist"
  },
  {
    "label": "MLT",
    "value": "MLT"
  },
  {
    "label": "EEG",
    "value": "EEG"
  },
  {
    "label": "EP Tech",
    "value": "EP Tech"
  },
  {
    "label": "PCT",
    "value": "PCT"
  },
  {
    "label": "Pathology Assistant",
    "value": "Pathology Assistant"
  }
]
