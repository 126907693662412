import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgClass, NgOptimizedImage} from "@angular/common";
import { HousingContent} from '../../../shared/model/types';
import {SafeHtmlPipe} from "../../../shared/pipes/safe-html-pipe.pipe";
import {Router} from "@angular/router";

@Component({
  selector: 'app-housing-card',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgClass,
    SafeHtmlPipe
  ],
  templateUrl: './housing-card.component.html',
  styleUrl: './housing-card.component.css'
})
export class HousingCardComponent implements OnInit {
  @Input() housingContent: HousingContent = new HousingContent('', '','', '', '');


  ngOnInit(): void {
  }
}
