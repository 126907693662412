import {Component, Inject} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {PortalUserService} from "../shared/services/portal-user.service";
import {
  MultiLineSnackBarService
} from "../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service";
import {Result} from "../../shared/model/result";
import {LoggedInUser} from "../shared/types";

@Component({
  selector: 'app-portal-password-reset-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatDialogClose
  ],
  templateUrl: './portal-password-reset-dialog.component.html',
  styleUrl: './portal-password-reset-dialog.component.css'
})
export class PortalPasswordResetDialogComponent {

  loginForm = this.formBuilder.nonNullable.group({
    email: ['', {
      validators: [
        Validators.required,
        Validators.email,
      ]
    }]
  });
  isLoading = false;

  constructor(private formBuilder: FormBuilder,
              private portalUserService: PortalUserService,
              private matDialogRef: MatDialogRef<PortalPasswordResetDialogComponent>,
              private matDialog: MatDialog,
              private multiLineSnackBar: MultiLineSnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginForm.get('email')?.setValue(data.email);
  }


  sendPasswordResetLink() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    }

    const email = this.loginForm.get('email')?.value ?? '';
    this.loginForm.disable();
    this.isLoading = true;
    this.portalUserService.sendPasswordResetLink(email).subscribe((result) => {
      if (result.isSuccess) {
        this.multiLineSnackBar.displayMessages(['If your email address exists in our system, you will receive a password recovery link at your email address in a few minutes.']);
        this.matDialogRef.close();
      } else {
        this.multiLineSnackBar.displayMessages(result.errorList);
      }
      this.isLoading = false;
      this.loginForm.enable();
    });
  }
}
