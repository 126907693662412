import {Pipe, PipeTransform} from '@angular/core';

export class EnumTypeValue {
  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }

  value: string;
  label: string;
}

export class ChartingEmrSoftware {
  public static CareCast = new EnumTypeValue('CareCast', 'CareCast');
  public static ProMed = new EnumTypeValue('ProMed', 'ProMed');
  public static Ibex = new EnumTypeValue('Ibex', 'Ibex');
  public static EMR = new EnumTypeValue('EMR', 'EMR');
  public static Siemens = new EnumTypeValue('Siemens', 'Siemens');
  public static Meditech = new EnumTypeValue('Meditech', 'Meditech');
  public static Allscripts = new EnumTypeValue('Allscripts', 'Allscripts');
  public static Athena = new EnumTypeValue('Athena', 'Athena');
  public static Epic = new EnumTypeValue('Epic', 'Epic');
  public static Cerner = new EnumTypeValue('Cerner', 'Cerner');
  public static McKesson = new EnumTypeValue('McKesson', 'McKesson');
  public static SunriseClinicalManager = new EnumTypeValue('SunriseClinicalManager', 'Sunrise Clinical Manager');
  public static Sorian = new EnumTypeValue('Sorian', 'Sorian');
  public static Oasis = new EnumTypeValue('Oasis', 'Oasis');
  public static VetPro = new EnumTypeValue('VetPro', 'VetPro');

  public static chartingEmrOptions = [
    ChartingEmrSoftware.CareCast,
    ChartingEmrSoftware.ProMed,
    ChartingEmrSoftware.Ibex,
    ChartingEmrSoftware.EMR,
    ChartingEmrSoftware.Siemens,
    ChartingEmrSoftware.Meditech,
    ChartingEmrSoftware.Allscripts,
    ChartingEmrSoftware.Athena,
    ChartingEmrSoftware.Epic,
    ChartingEmrSoftware.Cerner,
    ChartingEmrSoftware.McKesson,
    ChartingEmrSoftware.SunriseClinicalManager,
    ChartingEmrSoftware.Sorian,
    ChartingEmrSoftware.Oasis,
    ChartingEmrSoftware.VetPro
  ];

  public static getLabel(value: string): string {

    if (!value) {
      return '';
    }

    switch (value.toLowerCase()) {
      case ChartingEmrSoftware.CareCast.value.toLowerCase():
        return ChartingEmrSoftware.CareCast.label;
      case ChartingEmrSoftware.ProMed.value.toLowerCase():
        return ChartingEmrSoftware.ProMed.label;
      case ChartingEmrSoftware.Ibex.value.toLowerCase():
        return ChartingEmrSoftware.Ibex.label;
      case ChartingEmrSoftware.EMR.value.toLowerCase():
        return ChartingEmrSoftware.EMR.label;
      case ChartingEmrSoftware.Siemens.value.toLowerCase():
        return ChartingEmrSoftware.Siemens.label;
      case ChartingEmrSoftware.Meditech.value.toLowerCase():
        return ChartingEmrSoftware.Meditech.label;
      case ChartingEmrSoftware.Allscripts.value.toLowerCase():
        return ChartingEmrSoftware.Allscripts.label;
      case ChartingEmrSoftware.Athena.value.toLowerCase():
        return ChartingEmrSoftware.Athena.label;
      case ChartingEmrSoftware.Epic.value.toLowerCase():
        return ChartingEmrSoftware.Epic.label;
      case ChartingEmrSoftware.Cerner.value.toLowerCase():
        return ChartingEmrSoftware.Cerner.label;
      case ChartingEmrSoftware.McKesson.value.toLowerCase():
        return ChartingEmrSoftware.McKesson.label;
      case ChartingEmrSoftware.SunriseClinicalManager.value.toLowerCase():
        return ChartingEmrSoftware.SunriseClinicalManager.label;
      case ChartingEmrSoftware.Sorian.value.toLowerCase():
        return ChartingEmrSoftware.Sorian.label;
      case ChartingEmrSoftware.Oasis.value.toLowerCase():
        return ChartingEmrSoftware.Oasis.label;
      case ChartingEmrSoftware.VetPro.value.toLowerCase():
        return ChartingEmrSoftware.VetPro.label;
      default:
        return value;
    }
  }
}

@Pipe({name: 'credentialRequirementClass', standalone: true, pure: true})
export class CredentialRequirementClassPipe implements PipeTransform {
  transform(value: string): string {
    return CredentialRequirementClass.getLabel(value);
  }
}
export class CredentialRequirementClass {
  public static CandidateIdentification = new EnumTypeValue('CandidateIdentification','Identification');
  public static CandidateCertification = new EnumTypeValue('CandidateCertification','Certification');
  public static CandidateLicense = new EnumTypeValue('CandidateLicense','License');
  public static CandidateOther = new EnumTypeValue('CandidateOther','Other');

  public static credentialRequirementClassOptions = [
    CredentialRequirementClass.CandidateIdentification,
    CredentialRequirementClass.CandidateCertification,
    CredentialRequirementClass.CandidateLicense,
    CredentialRequirementClass.CandidateOther
  ];

  public static getLabel(value: string): string {
    if (!value) {
      return '';
    }

    switch (value.toLowerCase()) {
      case CredentialRequirementClass.CandidateIdentification.value.toLowerCase():
        return CredentialRequirementClass.CandidateIdentification.label;
      case CredentialRequirementClass.CandidateCertification.value.toLowerCase():
        return CredentialRequirementClass.CandidateCertification.label;
      case CredentialRequirementClass.CandidateLicense.value.toLowerCase():
        return CredentialRequirementClass.CandidateLicense.label;
      case CredentialRequirementClass.CandidateOther.value.toLowerCase():
        return CredentialRequirementClass.CandidateOther.label;
      default:
        return value;
    }
  }
}
