<div class="job-detail-header-container">

  <app-header></app-header>

  <div class="job-detail-header  flex-column">

    <h2 class="manrope-extralight">
      <span> {{ city }},</span>
      <span> {{ state }}</span>
    </h2>

    <div class="line-green">
    </div>

    <div class="job-detail-description">
      <p> It's easy to enjoy Travel Nursing with Nightingale. Not only do you receive the best benefits and highest pay
        rates in the industry, but you also have access to many of the best nursing assignments nationwide and dedicated
        Nightingale Recruiters there to help you every step of the way, wherever you go!</p>
    </div>
  </div>


</div>
