import {Component, Inject} from '@angular/core';
import {NgClass, NgOptimizedImage} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-header-dialog',
  standalone: true,
  imports: [
    NgClass,
    NgOptimizedImage
  ],
  templateUrl: './mobile-menu-dialog.component.html',
  styleUrl: './mobile-menu-dialog.component.css'
})
export class MobileMenuDialogComponent {
  isBenefitsDropdownOpen = false;
  isResourcesDropdownOpen = false;
  isAboutDropdownOpen = false;

  constructor(
    private dialogRef: MatDialogRef<MobileMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public deviceType: 'tablet' | 'phone',
  ) {
  }

  toggleBenefitsDropdown(): void {
    this.isBenefitsDropdownOpen = !this.isBenefitsDropdownOpen;
    this.isResourcesDropdownOpen = false;
    this.isAboutDropdownOpen = false;
  }

  toggleResourcesDropdown(): void {
    this.isBenefitsDropdownOpen = false;
    this.isResourcesDropdownOpen = !this.isResourcesDropdownOpen;
    this.isAboutDropdownOpen = false;
  }

  toggleAboutDropdown(): void {
    this.isBenefitsDropdownOpen = false;
    this.isResourcesDropdownOpen = false;
    this.isAboutDropdownOpen = !this.isAboutDropdownOpen;
  }

  close(): void {
    this.dialogRef.close();
  }
}
