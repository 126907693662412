import {Component, computed, effect, input, output} from '@angular/core';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {CatalogsService} from "../../shared/services/catalogs.service";
import {SelectOption} from '../../shared/model/types';
import {SelectChipComponent} from "../../shared/components/select-chip/select-chip.component";

@Component({
  selector: 'app-browse-job',
  standalone: true,
  imports: [
    FormsModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    SelectChipComponent,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './browse-job.component.html',
  styleUrl: './browse-job.component.css'
})
export class BrowseJobComponent {
  selectedSpecialties = input<string>('');
  selectedStates = input<string>('');
  selectionChanged = output<{ specialties: string[], states: string[] }>();

  selectedSpecialtyList = computed(() => this.splitStringToList(this.selectedSpecialties()));
  selectedStateList = computed(() => this.splitStringToList(this.selectedStates()));

  specialties: SelectOption[];
  states: SelectOption[];

  searchForm = this.formBuilder.nonNullable.group({
    specialty: new FormControl<string[]>([]),
    state: new FormControl<string[]>([]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private catalogsService: CatalogsService,
  ) {
    effect(() => {
      this.searchForm.controls['specialty'].setValue(this.selectedSpecialtyList());
    });

    effect(() => {
      this.searchForm.controls['state'].setValue(this.selectedStateList());
    });

    this.specialties = this.catalogsService.getSpecialties().map(specialty => new SelectOption(specialty, specialty));
    this.states = this.catalogsService.getStates().map(state => new SelectOption(state.name, state.code));
  }

  private splitStringToList(input: string): string[] {
    return !!input ? input.split(',') : [];
  }

  searchJobs(): void {
    this.selectionChanged.emit({
      specialties: this.searchForm.controls['specialty'].value ?? [],
      states: this.searchForm.controls['state'].value ?? []
    });
  }
}
