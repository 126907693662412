<div class="table-row-f">
  <div class="button-next button-next-text unselectable"
       [ngClass]="!enablePrevious ? 'disable-div' : ''"
       (click)="goToPreviousPage()">
    Previous
  </div>

  <div *ngIf="dotsStart" class="page-number unselectable">...</div>

  <div class="pages-frame">
    @for (item of pageNumbersToShow; track item; let i = $index) {
      <div class="page-number unselectable"
           [ngClass]="item==currentPage ? 'selected' : 'not-selected'"
           (click)="goToPage(item)">
        {{ item }}
      </div>
    }
  </div>

  <div *ngIf="dotsEnd" class="page-number unselectable">...</div>

  <div class="button-next button-next-text unselectable" [ngClass]="!enableNext ? 'disable-div' : ''"
       (click)="goToNextPage()">Next
  </div>
</div>

