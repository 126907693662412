import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NurseCommentComponent} from "../nurse-comment/nurse-comment.component";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {NurseCommentsMockDataService} from "../../../../benefits/benefits/services/nurse-comments-mock-data-service";
import {map, Observable} from "rxjs";
import {Blog, NurseComment} from "../../../model/types";

@Component({
  selector: 'app-nurse-comment-frame',
  standalone: true,
  imports: [
    NurseCommentComponent,
    NgForOf,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './nurse-comment-frame.component.html',
  styleUrl: './nurse-comment-frame.component.css'
})
export class NurseCommentFrameComponent implements OnInit{
  constructor(

    private nurseCommentsService:NurseCommentsMockDataService
  ) {
  }
  nurseComments$=this.nurseCommentsService.getNurseComments();
  nurseCommentsToShow$: Observable<NurseComment[]>;
  index= 0;
  total=0;
  @ViewChild('scrollableContent', {read: ElementRef}) public scrollableContent: ElementRef<any>;

  ngOnInit() {
    this.nurseCommentsToShow$ = this.nurseComments$;
    this.moveToPage();
  }

  moveToPage() {

    this.nurseCommentsToShow$ = this.nurseComments$.pipe(map(comments => {
        this.total = comments.length;
          return comments.slice(this.index, comments.length);
      }));
  }

  moveLeft() {
    if(this.index>0){
      this.index--;
      this.moveToPage();
    }

  }

  moveRight() {
    if(this.index+4<this.total){
      this.index++;
      this.moveToPage();
    }
  }
}
