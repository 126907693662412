<div class="job-order unselectable" [ngClass]="faded ? 'content-fade' : ''">
  <div class="specialty">
    <div class="specialty-chip">{{ jobOrder.specialty }}</div>
    <div class="weekly-pay">Weekly Pay: {{ jobOrder.weeklyPay | currency : 'USD': 'symbol' : '1.0-0' }}</div>
  </div>

  <div class="details">
    <div class="location">
      <img class="icon-location" ngSrc="../../../../assets/svg/map-marker-at.svg" alt="" height="1" width="1">
      <div class="location-text"><p>{{ jobOrder.city }}, {{ jobOrder.state }}</p></div>
    </div>

    <div class="description">
      <h4> {{ jobOrder.specialty }} in {{ jobOrder.city }} <br>
        {{ jobOrder.duration }} weeks / {{ jobOrder.shift }}</h4>
    </div>
  </div>

  <div class="action">
    @if (jobOrder.applied) {
      <div class="action-item" style="cursor: auto">
        <div class="action-decorator"></div>
        <div class="action-text button-text">APPLIED</div>
        <img class="action-icon" ngSrc="../../../../assets/svg/chevron-right.svg" alt="" height="18" width="18">
      </div>
    } @else {
      <div class="action-item apply-button" (click)="applyToJobOrder(jobOrder)">
        <div class="action-decorator"></div>
        <div class="action-text button-text">APPLY NOW</div>
        <img class="action-icon" ngSrc="../../../../assets/svg/chevron-right.svg" alt="" height="18" width="18">
      </div>
    }

    <div class="details-button unselectable" (click)="navigateToJobDetails()">Details</div>
  </div>
</div>

<div class="desktop">
@if (carousel !== CarouselType.None) {
  <div
    class="carousel-button unselectable"
    [ngClass]="{'carousel-button-left': carousel === CarouselType.Left, 'carousel-button-right': carousel === CarouselType.Right}"
    (click)="scroll()"
  >
    @if (carousel === CarouselType.Left) {
      <img ngSrc="../../../../assets/svg/carousel-arrow-filled-left.svg" alt="" height="62" width="62">
    } @else {
      <img ngSrc="../../../../assets/svg/carousel-arrow-filled-right.svg" alt="" height="62" width="62">
    }
  </div>
}
</div>
