<div style="width: 100%; height: 100%; display: flex; flex-direction: column; color: black;"
     (drop)="dropFile($event)"
     (dragover)="$event.preventDefault()"
     (dragenter)="isDraggingFile = true"
     (dragleave)="onDragLeave($event)"
>
  <div class="drag-overlay" [ngClass]="isDraggingFile ? 'full-screen-drag-over' : ''">
    <span [style.display]="isDraggingFile ? 'flex' : 'none'" style="color: white;">Drop file to upload!</span>
  </div>
  @if (isLoading) {
    <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
      <mat-spinner color="primary"></mat-spinner>
    </div>
  }
  @if (loadFileResults$ | async; as filesResult) {
    @if (filesResult.isSuccess) {
      <div class="flex-column portal-profile-section-container">
        @if( filesResult.value?.length===0) {
          <div class="portal-section-empty paragraf-big">No files added, you can add a file by hitting
            the Add File button in the bottom right.</div>
        }
        @for (file of filesResult.value; track file.id) {
          <mat-card class="portal-profile-section-card">
            <div class="file-row">
              <div class="file-row-action-buttons">
                <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage"
                        (click)="deleteFile(file.id)">
                  <mat-icon>delete</mat-icon>
                </button>
                <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage"
                        (click)="viewFile(file.id)">
                  <mat-icon>open_in_new</mat-icon>
                </button>
              </div>
              <div class="file-field">
                <div class="file-field-label">Uploaded On</div>
                <div>{{file.createdDttm | date: 'MM/dd/yyyy'}}</div>
              </div>
              <div class="file-field">
                <div class="file-field-label">File Category</div>
                <div>{{file.requirementClass | credentialRequirementClass}}</div>
              </div>
              <div class="file-field">
                <div class="file-field-label">File Type</div>
                <div>{{file.credentialTypeName}}</div>
              </div>
              <div class="file-field">
                <div class="file-field-label">File Name</div>
                <div>{{file.fileName}}</div>
              </div>
              <div class="file-field">
                <div class="file-field-label">Expiration Date</div>
                <div>{{file.expirationDate | date: 'MM/dd/yyyy'}}</div>
              </div>
            </div>
          </mat-card>
        }
      </div>

      <div class="portal-profile-section-bottom-action-bar files-bottom-action-bar">
        <div class="flex-expand drag-area"
             [ngClass]="isDraggingFile ? 'drag-over' : ''">
          {{ isDraggingFile ? 'Drop file to upload!' : 'Drag a file on to the page to upload it!' }}
        </div>
        <div>
          {{filesResult.value?.length ?? 0}}/50
        </div>
        <button [disabled]="disablePage" mat-raised-button color="primary" (click)="addFile()">
          @if (disablePage) {
            <mat-spinner diameter="25"></mat-spinner>
          } @else {
            Add File
          }
        </button>
      </div>
    } @else {
      <div style="color: black;">An error occurred while loading your files, please try again.</div>
    }
  }
</div>
