<div class="travel-mission-steps">

  <div class="travel-mission-step">
    <img ngSrc="./assets/img/icon_ally.webp" alt="" width="1" height="1" class="travel-mission-img">
    <div class="travel-mission-text">
      <h4 class="travel-mission-text1">The Travel Nurses’ Ally</h4>
      <p class="travel-mission-text2">Our mission is connecting the very best nurses with the travel assignments of
        their
        dreams. Nightingale is selective about the nurses we choose to work with. That discernment has earned us a
        reputation for delivering outstanding nursing services. It also means that our travel nurses earn more than
        the average and enjoy more generous benefits packages.</p>
    </div>
  </div>

  <div class="travel-mission-step">
    <img ngSrc="./assets/img/icon_partner.webp" alt="" width="1" height="1" class="travel-mission-img">
    <div class="travel-mission-text">
      <h4 class="travel-mission-text1">Partnership and Trust</h4>
      <p class="travel-mission-text2">We are careful in choosing the nurses we work with and once you’re part of the
        Nightingale team, we consider you family. You can rely on us to do more than just provide you with great
        travel nursing assignments, excellent pay, and competitive benefits. With Nightingale, you have a partner in
        building your nursing career. We want to see you thrive and achieve your professional dreams. We’ll do
        whatever we can to help you reach for the stars.
      </p>
    </div>
  </div>

  <div class="travel-mission-step">
    <img ngSrc="./assets/img/icon_care.webp" alt="" width="1" height="1" class="travel-mission-img">
    <div class="travel-mission-text">
      <h4 class="travel-mission-text1">We See You</h4>
      <div>
        <p class="travel-mission-text2">You may not realize it, but as an RN with real-world hospital experience,
          you are
          an invaluable member of society. The lives you’ve helped save, the patients you’ve cared for, and the
          hospitals where you’ve done your best work are all part of your legacy.</p>

        <p class="travel-mission-text2" style="margin-top: 30px;"> We never forget that you are the reason that
          Nightingale is the first name in travel nursing. Your
          dedication to your profession. Your compassion and honesty. Your warm heart and sharp mind. Nurses are
          nothing less than superheroes and we’re proud to help you shine.</p>
      </div>
    </div>
  </div>
</div>
