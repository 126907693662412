import {Component, Input} from '@angular/core';
import {NgClass} from "@angular/common";
import {PhonePipe} from "../../../../../shared/pipes/phone.pipe";



@Component({
  selector: 'app-licensure-table-item',
  standalone: true,
  imports: [
    PhonePipe,
    NgClass,

  ],
  templateUrl: './licensure-table-item.component.html',
  styleUrl: './licensure-table-item.component.css'
})
export class LicensureTableItemComponent {
  @Input() state: string;
  @Input() phone: string;
  @Input() walkThrough: boolean;
  @Input() compact: boolean;
  @Input() nursys: boolean;
  @Input() info: string[];

  // pipe: PhonePipe = new PhonePipe();

  convertWalkThrough(): string {
    return this.walkThrough ? 'Walk Through' : '';
  }
  convertNursys(): string {
    return this.nursys ? 'Nursys' : '';
  }

  convertCompact(): string {
    return this.compact ? 'Compact' : '';
  }

  // convertPhone(): string {
  //   return this.pipe.transform(this.phone);
  //
  // }


}
