<div class="body-tip">
  <div class="tip-explanation-frame">
    <div class="tip-explanation">
      <div class="flex-row-center">
        <h3>
          <span class="tip-explanation-title">TRAVEL NURSE&nbsp;</span>
          <span class="tip-explanation-title-italic">TIPS</span></h3>
      </div>
      <div class="tip-explanation-text">Get ready to get the most out of your journey with these simple tips.</div>
    </div>
    <div class="line-green"></div>
  </div>
  <app-tip-frame></app-tip-frame>

</div>
