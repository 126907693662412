<div class="faq-item">
  <div>
    <div class="number">10</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>Am I eligible for heath insurance in connection with my employment?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>Yes. Nightingale provides a free minimal essential coverage plan to all clinical
          employees within a week from the start of employment. Employees may “buy-up” dependent
          coverage of this plan on a pre-tax basis. Nightingale also provides group minimum value
          plan coverage as defined under the Affordable Care Act (ACA) subject to the Look Back Safe
          Harbor as defined under US Department of Treasury rules adopted alongside the ACA.</p>


      </div>
    }

  </div>

</div>

