import {Component, computed, input} from '@angular/core';
import {NgClass, NgOptimizedImage, NgStyle} from "@angular/common";
import {ActivatedRoute, RouterLink, RouterLinkActive} from '@angular/router';

@Component({
  selector: 'app-benefit-reasons',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgClass,
    RouterLink,
    RouterLinkActive,
    NgStyle
  ],
  templateUrl: './benefit-reasons.component.html',
  styleUrl: './benefit-reasons.component.css'
})
export class BenefitReasonsComponent {
  transparentImagePath = input<string>();
  imageIcon = input<string>();
  title = input<string>();
  text = input<string>();
  color = input<string>();
  url = input<string>();

  redirectUrl = computed<string | undefined | null>(() => {
    return !!this.url() ? this.url() : null;
  });

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
  }

}
