import { Component } from '@angular/core';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {PortalUserReferralService} from './shared/services/portal-user-referral.service';
import {PortalUserReferral} from './shared/types';
import {
  MultiLineSnackBarService
} from '../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {MatCard} from '@angular/material/card';

@Component({
  selector: 'app-portal-user-referral',
  standalone: true,
  imports: [
    MatError,
    MatLabel,
    MatInput,
    ReactiveFormsModule,
    MatFormField,
    MatButton,
    MatCard
  ],
  templateUrl: './portal-user-referral.component.html',
  styleUrl: './portal-user-referral.component.css'
})
export class PortalUserReferralComponent {
  disableSubmit = false;

  referralForm = new FormGroup({
    firstName: new FormControl<string>('', [Validators.required]),
    lastName: new FormControl<string>('', [Validators.required]),
    phone: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    specialty: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private portalUserReferralService: PortalUserReferralService,
    private snackBar: MultiLineSnackBarService,
  ) { }

  submitReferral(): void {
    this.referralForm.markAllAsTouched();
    if (!this.referralForm.valid) {
      return;
    }

    this.referralForm.disable();
    this.disableSubmit = true;
    this.portalUserReferralService.submitReferral(this.referralForm.value as PortalUserReferral)
      .subscribe((result) => {
        if (result.isSuccess) {
          this.snackBar.displayMessages(['Referral submitted successfully.']);
          this.referralForm.reset();
        } else {
          this.snackBar.displayMessages(result.errorList);
        }
        this.referralForm.enable();
        this.disableSubmit = false;
      })
  }
}
