<div class="tip-frame-layout" *ngIf="tips$ | async as tips">

  @for(item of tips; track item){
    <div class="tip-item-width">
    <app-tip-item
      [imageIcon]="item.imageIcon"
      [title]="item.title"
      [text]= "item.text"
    ></app-tip-item>
    </div>
  }

</div>

