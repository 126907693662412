<div class="mobile-dialog-header">
  {{ !!data ? 'Edit' : 'Add' }} Checklist
</div>

<mat-dialog-content class="dialog-content-container" [formGroup]="specialtyForm">
  @if (templatesResult$ | async; as templatesResult) {
    @if (templatesResult.isSuccess) {
      <div class="flex-column checklist-component-body">
        <div class="flex-row-center" style="width: 100%; justify-content: flex-end;">
          <mat-icon style="cursor: pointer;" (click)="displayHelpBottomSheet()">help</mat-icon>
        </div>

        <div class="checklist-container">

          <!-- category list -->
          <div class="flex-column hide-mobile" style="flex: 1;">
            <mat-form-field class="specialty-selector">
              <mat-label>Specialty</mat-label>
              <mat-select formControlName="specialty">
                @for (template of templatesResult.value; track template!.specialty) {
                  <mat-option [value]="template!.specialty"
                              (click)="specialtyChanged(template!)">{{ template!.specialty }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            @if (!!chosenSpecialty) {
              <ng-container *ngTemplateOutlet="categoriesCard"></ng-container>
            } @else {
              <h4>Select a specialty to begin filling out a checklist!</h4>
            }
          </div>

          <!-- category skills list -->
          <div class="flex-column hide-mobile" style="flex: 1;">
            @if (!!chosenCategory) {
              <div class="category-title">
                <span>{{ chosenCategory.categoryName }}</span>
              </div>
              <ng-template *ngTemplateOutlet="skillsCard"></ng-template>
            }
          </div>

          <div class="show-mobile flex-column">
            <mat-form-field class="specialty-selector">
              <mat-label>Specialty</mat-label>
              <mat-select formControlName="specialty">
                @for (template of templatesResult.value; track template!.specialty) {
                  <mat-option [value]="template!.specialty"
                              (click)="specialtyChanged(template!)">{{ template!.specialty }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>

            @if (!!chosenSpecialty) {
              <mat-tab-group [mat-stretch-tabs]="false" [(selectedIndex)]="activeTabIndex">
                <mat-tab label="Categories">
                  <ng-container *ngTemplateOutlet="categoriesCard"></ng-container>
                </mat-tab>
                <mat-tab [label]="chosenCategory?.categoryName ?? 'Skills'" [disabled]="!chosenCategory">
                  <ng-container *ngTemplateOutlet="skillsCard"></ng-container>
                </mat-tab>
              </mat-tab-group>
            }
          </div>
        </div>
      </div>

      <ng-template #categoriesCard>
        <mat-card class="categories-card">
          <mat-card-content class="checklist-card-content">
            @for (category of chosenSpecialty?.categories ?? []; track category.categoryName) {
              <div matRipple
                   [ngClass]="chosenCategory?.categoryName === category?.categoryName ? 'category-selected' : ''"
                   (click)="categoryChanged($index, category)"
                   class="flex-row category-row">
                <button mat-icon-button class="select-category-button" [id]="'display-skills-' + $index">
                  <mat-icon>open_in_new</mat-icon>
                </button>
                <span>{{ category.categoryName }}</span>
                <span class="flex-expand"></span>
                @if(hasCompletedAllSkills(category)) {
                  <mat-icon color="primary">check_circle</mat-icon>
                } @else {
                  <mat-icon color="warn">error</mat-icon>
                }
              </div>
            }
          </mat-card-content>
        </mat-card>
      </ng-template>

      <ng-template #skillsCard>
        <mat-card class="categories-card">
          <mat-card-content class="checklist-card-content skills-list-card-content">
            <div class="label-text skills-key-header">
              <div>1 - Least Experience</div>
              <span class="flex-expand"></span>
              <div>4 - Most Experience</div>
            </div>
            <div class="skills-list-container">
              @for (skill of chosenCategory?.skills ?? []; track skill.skillName) {
                <mat-label class="label-text"
                           [ngClass]="">
                  {{ skill.skillName }}
                </mat-label>
                <mat-button-toggle-group style="margin-bottom: 10px; min-height: 48px;"
                                         [disabled]="disablePage"
                                         [value]="skill.skillLevel"
                                         (change)="skill.skillLevel = $event.value">
                  <mat-button-toggle style="flex: 1;" [value]="0">N/A</mat-button-toggle>
                  <mat-button-toggle style="flex: 1;" [value]="1">1</mat-button-toggle>
                  <mat-button-toggle style="flex: 1;" [value]="2">2</mat-button-toggle>
                  <mat-button-toggle style="flex: 1;" [value]="3">3</mat-button-toggle>
                  <mat-button-toggle style="flex: 1;" [value]="4">4</mat-button-toggle>
                </mat-button-toggle-group>
              }
            </div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    } @else {
      <h4>An error occurred while loading checklist options. Please try again.</h4>
    }
  } @else {
    <div style="display: flex; justify-content: center; align-items: center; height: 100%; width: 100%;">
      <mat-spinner></mat-spinner>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [disabled]="disableButtons" mat-raised-button (click)="close()" color="warn">Close</button>

  <button [disabled]="disableButtons || !chosenSpecialty"
          mat-raised-button color="primary"
          (click)="saveSkillsChecklist()">
    @if (disableButtons) {
      <mat-spinner diameter="25"></mat-spinner>
    } @else {
      Save
    }
  </button>
  <button [disabled]="disableButtons || !chosenSpecialty || categoryIndex === 0"
          mat-button color="primary"
          (click)="previousSection()">
    Previous
  </button>
  <button [disabled]="disableButtons || !chosenSpecialty || categoryIndex === chosenSpecialty!.categories.length - 1"
          mat-button color="primary"
          (click)="nextSection()">
    Next
  </button>
</mat-dialog-actions>



<ng-template #helpTemplate>
  <div class="flex-row-center" style="justify-content: center;">
    <p>Skills Checklist Instructions</p>
  </div>
  <ol style="padding-right: 40px;">
    <li>Choose a specialty from the dropdown list. (If you are editing an existing checklist, you cannot change the specialty).</li>
    <li>Click on a category to view the skills within that category.</li>
    <li>Rate your experience level for each skill. 1 being the least experienced and 4 being the most experienced.</li>
    <li>Click "Next" at the bottom right to move to the next category.</li>
    <li>Once you've completed all skills in all categories, click "Save".</li>
  </ol>
  <p>Please note: You will not be able to save your checklist until you've rated every skill.</p>
  <div class="flex-row-center" style="justify-content: flex-end;">
    <button mat-button (click)="dismissHelpSheet()">Close</button>
  </div>
</ng-template>
