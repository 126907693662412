<div class="faq-item">
  <div>
    <div class="number">05</div>
  </div>
  <div class="faq-item-qa">
    <div class="faq-item-qa-question-frame">
      <div class="faq-item-qa-question"><h4>Does Nightingale pay for temporary relocation?</h4></div>
      <img ngSrc="{{expandAnswer ? './assets/svg/xmark.svg' : './assets/svg/plusmark.svg'}}" alt=""
           class="expand-button" width="1" height="1" (click)="toggleAnswer()">
    </div>
    @if (expandAnswer) {
      <div class="faq-item-qa-answer">
        <p>Nightingale provides service in mostly all states, with access and reimbursement for
          re-licensure in the majority. If the job seeker lives in a state that is party to the Nursing Compact
          (currently forty of the fifty states), he/she can take advantage of multi-state privileges and freely
          work within those states by the uncomplicated process of obtaining a Compact License. Nightingale
          can guide and assist the candidate in obtaining a Compact License.</p>


      </div>
    }

  </div>

</div>

