<div>
  <mat-card class="mat-card-style">
    <div><p>Change Password</p></div>
    <form (ngSubmit)="changePassword()" [formGroup]="changePasswordForm"
          class="change-password-form mat-icon-button-sm">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Current Password</mat-label>
        <input [type]="showCurrentPassword ? 'text' : 'password'" formControlName="currPass" matInput>
        <button matSuffix (click)="showCurrentPassword = !showCurrentPassword" mat-icon-button type="button">
          <mat-icon>{{ showCurrentPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        @if (changePasswordForm.controls['currPass'].hasError('required')) {
          <mat-error>Field is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>New Password</mat-label>
        <input [errorStateMatcher]="formMatcher"
               [type]="showNewPassword ? 'text' : 'password'"
               formControlName="newPass"
               matInput>
        <button (click)="showNewPassword = !showNewPassword" mat-icon-button matSuffix type="button">
          <mat-icon>{{ showNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        @if (changePasswordForm.controls['newPass'].hasError('required')) {
          <mat-error>Field is required</mat-error>
        }
        @if (changePasswordForm.controls['newPass'].hasError('minlength')) {
          <mat-error>Password must be at least 6 characters long</mat-error>
        }
        @if (changePasswordForm.controls['newPass'].hasError('maxlength')) {
          <mat-error>Password must be no more than 20 characters long</mat-error>
        }
        @if (changePasswordForm.hasError('fieldsMatch')) {
          <mat-error>New password cannot be the same as the current password</mat-error>
        }
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Confirm New Password</mat-label>
        <input [errorStateMatcher]="formMatcher"
               [type]="showConfirmPassword ? 'text' : 'password'"
               formControlName="confirmPass"
               matInput>
        <button (click)="showConfirmPassword = !showConfirmPassword" mat-icon-button matSuffix type="button">
          <mat-icon>{{ showConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        @if (changePasswordForm.controls['confirmPass'].hasError('required')) {
          <mat-error>Field is required</mat-error>
        }
        @if (changePasswordForm.controls['confirmPass'].hasError('minlength')) {
          <mat-error>Password must be at least 6 characters long</mat-error>
        }
        @if (changePasswordForm.controls['confirmPass'].hasError('maxlength')) {
          <mat-error>Password must be no more than 20 characters long</mat-error>
        }
        @if (changePasswordForm.hasError('fieldsDoNotMatch')) {
          <mat-error>New password and Confirm password must match</mat-error>
        }
      </mat-form-field>

      <!--suppress HtmlDeprecatedAttribute -->
      <mat-dialog-actions align="end">
        <button [disabled]="!changePasswordForm.valid" color="primary" mat-raised-button type="submit"
                #updateButton>
          Update
        </button>
      </mat-dialog-actions>
    </form>
  </mat-card>
</div>
