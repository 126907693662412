<div class="mobile-dialog-header">
  {{ !!data ? 'Edit' : 'Add' }} Reference
</div>
<mat-dialog-content>
  <form [formGroup]="referenceForm">
    <div class="form-row">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName">
        @if (referenceForm.controls.firstName.hasError('required')) {
          <mat-error>First Name is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName">
        @if (referenceForm.controls.lastName.hasError('required')) {
          <mat-error>Last Name is required</mat-error>
        }
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone">
        @if (referenceForm.controls.phone.hasError('required')) {
          <mat-error>Phone is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-label>My Position</mat-label>
        <input matInput formControlName="myPosition">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Reference Position</mat-label>
        <input matInput formControlName="referencePosition">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Hospital</mat-label>
        <input matInput formControlName="hospital">
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput formControlName="startDate" [matDatepicker]="employedFromDatePicker">
        <mat-datepicker-toggle matSuffix [for]="employedFromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #employedFromDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput formControlName="endDate" [matDatepicker]="employedToDatePicker">
        <mat-datepicker-toggle matSuffix [for]="employedToDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #employedToDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [disabled]="disablePage" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="saveReference()">Save</button>
</mat-dialog-actions>
