<div class="ptb-layout-job-search">
  <app-header style="width: 100%"></app-header>
  <div class="ptb-job-search-main-frame">
    <div class="ptb-job-search-explanation-frame">
      <div class="ptb-job-search-title-frame">
        <h2>
          <div class="ptb-job-search-title-italic">Explore
            <span class="ptb-job-search-title">Our World</span>
          </div>
        </h2>
      </div>
      <div class="ptb-job-search-title-explanation">Top pay, benefits, and unprecedented adventure await. Discover the
         <br>
        Travel Nursing lifestyle with Nightingale.
      </div>
    </div>
    <div class="line-green"></div>
  </div>
</div>


