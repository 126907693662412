<div class="mobile-dialog-header">
  {{!!data ? 'Edit' : 'Add'}} Work History
</div>
<mat-dialog-content>
  <div [formGroup]="workHistoryForm" class="form-array" style="flex-direction: column;">
    <div class="form-row">
      <mat-form-field>
        <mat-label>Hospital Name</mat-label>
        <input matInput formControlName="hospitalName">
        @if (workHistoryForm.controls.hospitalName.hasError('required')) {
          <mat-error>Hospital Name is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput formControlName="city">
      </mat-form-field>
      <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select formControlName="state">
          @for (state of states; track state.code) {
            <mat-option [value]="state.code">{{ state.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Specialty</mat-label>
        <input matInput formControlName="specialty">
      </mat-form-field>
    </div>

    <div class="form-row">

      <div class="custom-date-picker">
        <app-custom-date-month formControlName="fromDate" [placeHolder]="'Employed From'"
                               [required]="false"></app-custom-date-month>

      </div>
      <div class="custom-date-picker">
        <app-custom-date-month formControlName="toDate" [placeHolder]="'Employed To'" [required]="false"></app-custom-date-month>
      </div>


      <mat-form-field>
        <mat-label>Charting/EMR</mat-label>
        <input matInput formControlName="chartingExperience">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Salary</mat-label>
        <input matInput formControlName="salary">
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>Responsibilities</mat-label>
      <textarea matInput formControlName="responsibilities"></textarea>
    </mat-form-field>

    <div class="form-row">
      <mat-form-field>
        <mat-label>Number of Beds in Unit</mat-label>
        <input matInput formControlName="numberOfBedsInUnit" type="number">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Number of Beds in Hospital</mat-label>
        <input matInput formControlName="numberOfBedsInHospital" type="number">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Average Patient Ratio</mat-label>
        <input matInput formControlName="patientRatio">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Was this a Travel Assignment?</mat-label>
        <mat-select formControlName="wasTravelAssignment"
                    (valueChange)="toggleFormFieldRequired($event, workHistoryForm.controls.travelCompany)">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>If yes, which agency?</mat-label>
        <input matInput formControlName="travelCompany">
        @if (workHistoryForm.controls.travelCompany.hasError('required')) {
          <mat-error>Travel Agency is required</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field>
        <mat-label>Was this a Teaching Hospital?</mat-label>
        <mat-select formControlName="wasTeachingHospital">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Was this a Trauma Hospital?</mat-label>
        <mat-select formControlName="traumaHospitalLevel">
          <mat-option value="No">No</mat-option>
          <mat-option value="Level 1">Level 1</mat-option>
          <mat-option value="Level 2">Level 2</mat-option>
          <mat-option value="Level 3">Level 3</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Do you have charge experience?</mat-label>
        <mat-select formControlName="hasChargeExperience">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Supervisor</mat-label>
        <input matInput formControlName="supervisor">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Supervisor Phone</mat-label>
        <input matInput formControlName="supervisorPhone">
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>Reason for Leaving</mat-label>
      <textarea matInput formControlName="reasonForLeaving"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [disabled]="disablePage" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="saveWorkHistory()">Save</button>
</mat-dialog-actions>
