import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';
import {ConfirmationDialogResult, ConfirmationDialogStyle} from './types';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton
  ],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.css'
})
export class ConfirmationDialogComponent implements OnInit {

  twoButtonDialog = ConfirmationDialogStyle.twoButtonDialog;
  threeButtonDialog = ConfirmationDialogStyle.threeButtonDialog;
  positiveResult = ConfirmationDialogResult.positive;
  negativeResult = ConfirmationDialogResult.negative;
  neutralResult = ConfirmationDialogResult.neutral;

  title = 'Confirm';
  message = 'Are you sure?';
  confirmationDialogStyle = ConfirmationDialogStyle.twoButtonDialog;
  positiveBtnText = 'Yes';
  negativeBtnText = 'No';
  neutralBtnText = 'Cancel';

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                title: string,
                message: string,
                confirmationDialogStyle: ConfirmationDialogStyle,
                positiveBtnText: string,
                negativeBtnText: string,
                neutralBtnText: string
              },
              private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
  }

  ngOnInit(): void {
    if (!!this.data?.title) {
      this.title = this.data.title;
    }
    if (!!this.data?.message) {
      this.message = this.data.message;
    }
    if (!!this.data?.confirmationDialogStyle) {
      this.confirmationDialogStyle = this.data.confirmationDialogStyle;
    }
    if (!!this.data?.positiveBtnText) {
      this.positiveBtnText = this.data.positiveBtnText;
    }
    if (!!this.data?.negativeBtnText) {
      this.negativeBtnText = this.data.negativeBtnText;
    }
    if (!!this.data?.neutralBtnText) {
      this.neutralBtnText = this.data.neutralBtnText;
    }
  }

  exitDialog(result: ConfirmationDialogResult): void {
    this.dialogRef.close(result);
  }
}
