<div class="related-post-frame" *ngIf="relatedBlogs.length!==0">
  <div class="title-frame">
    <div class="related-post-title">RELATED <span class="related-post-title-italic">POSTS</span></div>
    <div class="border-line"></div>

  </div>
  <div class="related-items-list">
  @for (item of relatedBlogs; track item; let index = $index) {

      <div class="flex-n">
        <app-related-post-item
          [blog]=item>
        </app-related-post-item>
      </div>

  }
  </div>


</div>
