<div>
  <mat-card class="mat-card-style" >
    <div><p>Communication Consent</p></div>
    <form (ngSubmit)="submitCommunicationConsent()" [formGroup]="communicationConsentForm" class="communication-consent-form mat-icon-button-sm">
      <div class="form-row">
        <mat-checkbox formControlName="receiveMarketingEmails" color="primary">Receive marketing emails.</mat-checkbox>
      </div>
      <div class="form-row">
        <mat-checkbox formControlName="receiveOperationalTextMessages" color="primary">Receive operational text messages.</mat-checkbox>
      </div>
      <div class="form-row">
        <mat-checkbox formControlName="receiveMarketingTextMessages" color="primary">Receive marketing text messages.</mat-checkbox>
      </div>

      <!--suppress HtmlDeprecatedAttribute -->
      <mat-dialog-actions align="end">
        <button  color="primary" mat-raised-button type="submit"

        >
          Update
        </button>
      </mat-dialog-actions>
    </form>
  </mat-card>


</div>

