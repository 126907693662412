<div class="connect-online-form-container">
  <div style="text-align: left">
    <h3>
      <span class="header manrope-light">CONNECT&nbsp;</span>
      <span class="header-italic wulkandisplay-semibolditalic">ONLINE</span>
    </h3>
  </div>
  <div class="header-bottom paragraf-big manrope-regular">
    Most responses are within a few hours.
  </div>

  <form [formGroup]="connectOnlineForm" class="form">

    <div class="form-layout name">

      <div class="firstName ">
        <mat-label [ngClass]="getClass('firstName')">First Name<span style="color:red">*</span></mat-label>
        <input matInput formControlName="firstName" class="no-error-border">
      </div>

      <div class="lastName">
        <mat-label [ngClass]="getClass('lastName')">Last Name<span style="color:red">*</span></mat-label>
        <input matInput formControlName="lastName" class="no-error-border">
      </div>
    </div>

    <div class="form-layout contact">

      <div class="phone">
        <mat-label [ngClass]="getClass('phone')">Phone<span style="color:red">*</span></mat-label>
        <input matInput formControlName="phone" placeholder="(___) ___-____"
               class="no-error-border" (focusout)="onFocusOutEvent($event)">
      </div>

      <div class="email">
        <mat-label [ngClass]="getClass('email')">Email<span style="color:red">*</span></mat-label>
        <input matInput formControlName="email" type="email" class="no-error-border">
      </div>

    </div>

    <div class="form-layout inquiry">

      <div class="inquiry">
        <mat-label [ngClass]="getClass('inquiry')">Your Inquiry<span style="color:red">*</span></mat-label>
        <textarea matInput formControlName="inquiry" cols="30" rows="4"
                  class="no-error-border"></textarea>
      </div>
    </div>

    <div class="form-layout">

      <div class="send-me-outline" (click)="submitInquiry()">
        <span class="send-me-btn button-text">SEND</span>
      </div>

    </div>

  </form>

</div>

