<div class="flex-column portal-profile-section-container">
  @if(workHistory().length===0) {
    <div class="portal-section-empty paragraf-big">No work history added, you can add a work history by hitting
      the Add Work History button in the bottom right.</div>
  }

  @for (historyRecord of workHistory(); track historyRecord.id) {
    <mat-card class="portal-profile-section-card">
      <div class="work-history-row">
        <div class="work-history-row-action-buttons">
          <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage"
                  (click)="removeWorkHistory(historyRecord.id)">
            <mat-icon>delete</mat-icon>
          </button>
          <button class="mat-icon-button-sm" mat-icon-button [disabled]="disablePage"
                  (click)="editWorkHistory(historyRecord)">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
        <div class="history-field">
          <div class="history-field-label">Hospital Name</div>
          <div>{{ historyRecord.hospitalName }}</div>
        </div>
        <div class="history-field">
          <div class="history-field-label">Specialty</div>
          <div>{{ historyRecord.specialty }}</div>
        </div>
        <div class="history-field">
          <div class="history-field-label">Start - End</div>
          <div>{{ historyRecord.fromDate | monthYear }} - {{ historyRecord.toDate |monthYear }}</div>
        </div>
        <div class="history-field">
          <div class="history-field-label">City</div>
          <div>{{ historyRecord.city }}</div>
        </div>
        <div class="history-field">
          <div class="history-field-label">State</div>
          <div>{{ historyRecord.state }}</div>
        </div>
      </div>
    </mat-card>
  }
</div>

<div class="portal-profile-section-bottom-action-bar">
  <button [disabled]="disablePage" mat-raised-button color="primary" (click)="addWorkHistory()">
    @if (disablePage) {
      <mat-spinner diameter="25"></mat-spinner>
    } @else {
      Add Work History
    }
  </button>
</div>
