<div class="jobs-container">
  <div class="jobs-header">
    <div class="jobs-header-title1"> <h2>
      NEW
      <span class="jobs-header-title2"> TRAVEL NURSING </span>
      JOBS </h2>
    </div>

    <div class="line-green"></div>


    <div class="jobs-header-subtext">Competitive pay, benefits, and exciting assignments across the U.S.<br>
      Find out why more travel nurses work with Nightingale.
    </div>


  </div>

  <div class="jobs-list-top-spacer">

  <app-jobs-list [jobOrders$]="jobOrders$"></app-jobs-list>
  </div>
  <div class="job-list-footer">
  <a href="/job-search" class="jobs-button btn-hover">
    <div class="button-text">VIEW&nbsp;MORE</div>
    <img ngSrc="./assets/svg/button-left-arrow.svg" alt="" height="18" width="18">
  </a>
  </div>
</div>
