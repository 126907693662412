import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PortalVerificationService} from '../shared/services/portal-verification.service';
import {
  MultiLineSnackBarService
} from '../../../shared/components/multi-line-snack-bar/service/multi-line-snack-bar.service';
import {PortalUserService} from '../../shared/services/portal-user.service';
import {HeaderComponent} from '../../../shared/components/header/header.component';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {MatTab, MatTabGroup} from '@angular/material/tabs';

@Component({
  selector: 'app-verify-account',
  standalone: true,
  imports: [
    HeaderComponent,
    MatProgressSpinner,
    FormsModule,
    MatButton,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatTab,
    MatTabGroup,
    ReactiveFormsModule
  ],
  templateUrl: './verify-account.component.html',
  styleUrl: './verify-account.component.css'
})
export class VerifyAccountComponent implements OnInit {
  isLoading = true;
  isInvalidLink = false;
  title = 'Verifying Account'

  activeUser = this.portalUserService.activeUser;

  constructor(
    private route: ActivatedRoute,
    private portalVerificationService: PortalVerificationService,
    private snackBar: MultiLineSnackBarService,
    private portalUserService: PortalUserService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe(params => {
        const meta = params.get('meta') ?? '';
        if (!meta) {
          this.router.navigate(['/']).then();
          return;
        }
        this.portalVerificationService.verifyAccount(meta)
          .subscribe(result => {
            if (!result.isSuccess) {
              this.snackBar.displayMessages(result.errorList);
              this.isLoading = false;
              this.isInvalidLink = true;
              this.title = 'Invalid Link';
              return;
            }
            this.snackBar.displayMessages(['Account verified successfully.']);
            this.portalUserService.setUserStatus('active');
            this.portalUserService.selectedTab.set('Home');
            this.title = 'Account Verified';
            this.isLoading = false;
          });
      });
  }
}
