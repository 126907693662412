import { Component } from '@angular/core';
import {TipItemComponent} from "./tip-item/tip-item.component";
import {FaqMockDataService} from "../../services/faq-mock-data-service";
import {AsyncPipe, NgIf} from "@angular/common";
import {FaqItemComponent} from "../../body-faq/faq-list/faq-item/faq-item.component";

@Component({
  selector: 'app-tip-frame',
  standalone: true,
  imports: [
    TipItemComponent,
    AsyncPipe,
    FaqItemComponent,
    NgIf
  ],
  templateUrl: './tip-frame.component.html',
  styleUrl: './tip-frame.component.css'
})
export class TipFrameComponent {
  constructor(

    private faqService:FaqMockDataService
  ) {
  }
  tips$=this.faqService.getTips();

}
