import { Component } from '@angular/core';
import {CompanyContactInfo} from "../../../../shared/model/types";
import {ContactDetailsService} from "../../../../shared/services/contact-details.service";

@Component({
  selector: 'app-contact-info',
  standalone: true,
  imports: [],
  templateUrl: './contact-info.component.html',
  styleUrl: './contact-info.component.css'
})
export class ContactInfoComponent {

  companyContactInfo: CompanyContactInfo ;

  constructor(contactDetailsService: ContactDetailsService) {
    contactDetailsService.getContactInfo().subscribe(contactInfo => {
      this.companyContactInfo = contactInfo;
    });
  }
}
