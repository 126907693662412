<!--<div class="flex-column" style="color:black">-->

  <mat-card-header>
    <mat-card-title style="color:black" >{{ formPages[activePage] }}</mat-card-title>
  </mat-card-header>
  <mat-dialog-content class="flex-column">

    @if (formPages[activePage] === 'Personal Info') {
      <app-personal-info-editor #personalInfoPage></app-personal-info-editor>
    }
    @if (formPages[activePage] === 'Professional Info') {
      <app-professional-info-editor #professionalInfoPage></app-professional-info-editor>
    }
    @if (formPages[activePage] === 'Work History') {
      <app-work-history-editor #workHistoryPage></app-work-history-editor>
    }
    @if (formPages[activePage] === 'Education') {
      <app-education-info-editor #educationInfoPage></app-education-info-editor>
    }
    @if (formPages[activePage] === 'References') {
      <app-references-info-editor #referenceInfoPage></app-references-info-editor>
    }

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    @if (activePage > 0) {
    <button mat-raised-button color="primary" (click)="previousForm()"
            [disabled]="isSaving">
      Previous
    </button>
    }
    @if (activePage < formPages.length-1) {
    <button mat-raised-button color="primary" (click)="nextForm()"
            [disabled]="isSaving"> Next
    </button>
    }
    @if (activePage == formPages.length-1)
    {<button mat-raised-button color="primary" (click)="nextForm()"
            [disabled]="isSaving"> Save All
    </button>
    }
    <button mat-raised-button color="warn" mat-dialog-close (click)="cancelForm()">Cancel</button>
  </mat-dialog-actions>

<!--</div>-->
