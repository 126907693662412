<div class="desktop">
  <div class="connect-online flex-row">
    <app-contact-info style="min-width: 37%;"></app-contact-info>

    <div class="right-card-section">
      <app-connect-online-form></app-connect-online-form>
    </div>

  </div>

</div>


<div class="tablet-mobile">
  <div class="right-card-section">
    <app-connect-online-form></app-connect-online-form>
  </div>

  <app-contact-info></app-contact-info>

</div>


