<mat-dialog-content>
  <div style="color: black; margin-bottom: 20px; font-family: 'Manrope Bold', sans-serif">Please enter your email to reset password</div>

  <form class="login-register-form" [formGroup]="loginForm">
    <mat-form-field class="form-field">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email">

      @if (loginForm.controls.email.hasError('required')) {
        <mat-error>Email is required.</mat-error>
      }

      @if (loginForm.controls.email.hasError('email')) {
        <mat-error>Invalid email.</mat-error>
      }
    </mat-form-field>

  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button [disabled]="isLoading" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button [disabled]="isLoading" mat-raised-button color="primary" (click)="sendPasswordResetLink()">Reset Password</button>
</mat-dialog-actions>
