<div class="portal-profile-section-container referral-container">
  <mat-card style="padding: 10px;" class="portal-profile-section-card">
    <p>Fill out the following information about your friend and click Submit to refer them to Nightingale!</p>
    <form [formGroup]="referralForm" class="flex-column">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName">
        @if (referralForm.get('firstName')?.hasError('required')) {
          <mat-error>First Name is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName">
        @if (referralForm.get('lastName')?.hasError('required')) {
          <mat-error>Last Name is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone Number</mat-label>
        <input matInput formControlName="phone">
        @if (referralForm.get('phone')?.hasError('required')) {
          <mat-error>Phone Number is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
        @if (referralForm.get('email')?.hasError('required')) {
          <mat-error>Email is required</mat-error>
        } @else if (referralForm.get('email')?.hasError('email')) {
          <mat-error>Email is invalid</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>Specialty</mat-label>
        <input matInput formControlName="specialty">
        @if (referralForm.get('specialty')?.hasError('required')) {
          <mat-error>Specialty is required</mat-error>
        }
      </mat-form-field>
    </form>
    <div class="flex-row hide-mobile" style="align-items: center; justify-content: end; width: 100%;">
      <button [disabled]="disableSubmit" mat-raised-button color="primary" (click)="submitReferral()">Submit</button>
    </div>
  </mat-card>
</div>

<div class="portal-profile-section-bottom-action-bar show-mobile">
  <button [disabled]="disableSubmit" mat-raised-button color="primary" (click)="submitReferral()">Submit</button>
</div>
