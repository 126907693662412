<div [ngClass]="isSelectionListShown?'select-chip':'select-chip-unselected'" #div [id]="divId">

  <div class="grid-row-select">
    <div class="scroll-container">

      <div class="grid-row " (click)="showSelectList()">
        @for (item of selectedOptions; track item; let index = $index) {
          @if (isSelectionListShown) {
            <div class="chip-item">
              {{ item.label }}
              <img ngSrc="assets/svg/close-chip.svg" alt="" height="14" width="14" (click)="removeItem(item,$event)">
            </div>
          } @else {
            @if (index === 0) {
              @if (selectedOptions.length > 1) {
                <div class="chip-item">
                  {{ item.label| shorten: 16 }}
                  <img ngSrc="assets/svg/close-chip.svg" alt="" height="14" width="14" (click)="removeItem(item,$event)">
                </div>
                <div class="additions">
                  (+ {{ selectedOptions.length - 1 }} )
                </div>
              } @else {
                <div class="chip-item">
                  {{ item.label| shorten: 19 }}
                  <img ngSrc="assets/svg/close-chip.svg" alt="" height="14" width="14" (click)="removeItem(item,$event)">
                </div>
              }
            }
          }

        }
        @if (selectedOptions.length === 0 && !isSelectionListShown) {
          <div class="placeholder">
            {{ placeholder }}
          </div>
        }
      </div>


    </div>
    <div class="grid-row-button" (click)="showSelectList()">
      <img ngSrc="assets/svg/select-open.svg" alt="" height="23" width="23">
    </div>
  </div>
  @if (isSelectionListShown) {
    <div class="selection-list">
      @for (item of visibleOptions; track item; let index = $index) {
        <div class="select-item" (click)="addItem(item)">
          {{ item.label }}
        </div>
      }
    </div>
  }

</div>
