<mat-card>
  <div class="container unselectable">
    <div class="flex-row-header">
    <div class="portal-home-section-title">
      Your profile is {{ totalPercentage() < 1 ? 'incomplete' : 'complete' }}!
    </div>

      <button  (click)="addResumeFile()" mat-raised-button color="primary" class="button-layout">
      Load Resume

    </button>
    </div>
    <div class="progress-container">
      <div class="progress-spinner-container">
        <mat-progress-spinner
          class="spinner-desktop"
          [mode]="mode"
          [value]="totalPercentage() * 100"
          [diameter]="150"
        ></mat-progress-spinner>

        <mat-progress-spinner
          class="spinner-tablet"
          [mode]="mode"
          [value]="totalPercentage() * 100"
          [diameter]="120"
        ></mat-progress-spinner>

        <mat-progress-spinner
          class="spinner-phone"
          [mode]="mode"
          [value]="totalPercentage() * 100"
          [diameter]="80"
        ></mat-progress-spinner>

      </div>
      <div class="progress-text">{{ totalPercentage() | percent }}</div>

      <div class="progress-details">
        @for (sectionCompleteness of completeness(); track sectionCompleteness; let index = $index) {
          <div class="progress-detail" (click)="onClick(sectionCompleteness.section)">
            <div class="dot" [ngStyle]="{'background-color': sectionCompleteness.percentageColor}"></div>
            <div class="portal-home-item-title">
              {{ sectionCompleteness.section }}
            </div>
            <div class="portal-home-item-title">{{ sectionCompleteness.getCompletionPercentage() | percent }}</div>
          </div>
        }
      </div>
    </div>
  </div>

  <div class="end-spacer"></div>
</mat-card>
