<div class="header-container">
  <app-header></app-header>

  <div class="header flex-column">
    <h2>
      <div>Thank You!</div>
    </h2>
    <div class="line-green">
    </div>
  </div>
</div>

<div class="submitted-body">
  @if (source === 'contact') {
    <div class="submitted-body-text">
      Thank you for contacting us.
    </div>
  } @else {
    <div class="submitted-body-text">
      Thank you for submitting your application.
    </div>
  }

  <div class="submitted-body-text">
    We will get back to you as soon as possible.
  </div>
</div>

<app-footer></app-footer>
