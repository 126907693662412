import {Component, QueryList, ViewChildren} from '@angular/core';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {Router} from '@angular/router';
import {HeaderComponent} from "../../../shared/components/header/header.component";


@Component({
  selector: 'app-housing-header',
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage,
    HeaderComponent
  ],
  templateUrl: './housing-header.component.html',
  styleUrl: './housing-header.component.css',

})
export class HousingHeaderComponent {

  constructor(
    private router: Router,
  ) {
  }


}
