import {Injectable} from '@angular/core';
import {catchError, Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {createFailedResult, Result} from "../../../shared/model/result";
import {FileContent} from "../components/file-viewer-dialog/types/types";
import {PortalUserFileFull} from "../../portal-user-profile/portal-user-files/shared/types";
import {CandidatePortalReceivedTimesheet} from "../types";


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpClient: HttpClient) {
  }


  // getLegacyFileContent(path: string, filename: string): Observable<Result<FileContent>> {
  //   const body = {
  //     filename,
  //     path
  //   };
  //   return this.httpClient.post<Result<FileContent>>(`assignment/file/content-legacy`, body);
  // }

  getFile(fileId: number): Observable<Result<FileContent>> {
    return this.httpClient.get<Result<FileContent>>(`assignment/file/${fileId}`)
      .pipe(
        catchError(e => of(createFailedResult<FileContent>(['An error occurred, please try again.'])))
      );
  }

  addFile(newFile: CandidatePortalReceivedTimesheet): Observable<Result> {
    return this.httpClient.post<Result>('assignment/file', newFile)
      .pipe(
        catchError(e => {
          return of(createFailedResult(['An error occurred, please ensure your file is less than 20MB and try again.']));
        })
      );
  }

  base64ToArrayBuffer(base64: string): any {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const resultBytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      resultBytes[i] = binaryString.charCodeAt(i);
    }
    return resultBytes.buffer;
  }

  getReceivedtimesheetFile(fileId: number): Observable<Result<FileContent>> {
    return this.httpClient.get<Result<FileContent>>(`assignment/file/receivedtimesheet/${fileId}`)
      .pipe(
        catchError(e => of(createFailedResult<FileContent>(['An error occurred, please try again.'])))
      );
  }

}
