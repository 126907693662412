<div class="expect-more-container">

  <div  class="expect-more-content" >

    <div  class="expect-more-header" >
      <h3 style="text-align: center">
        <span class="manrope-extralight"> EXPECT MORE FROM </span>
        <div class="manrope-extralight"> YOUR
          <span class="wulkandisplay-mediumitalic"> TRAVEL NURSING </span>
          <span class="manrope-extralight"> AGENCY </span>
        </div>
      </h3>

      <div class="line-white">
      </div>
    </div>

    <div class="expect-more-content-text">
      <p>
        As an experienced, Registered Nurse, you are in demand. So demand more for yourself. Join the best team in
        travel nursing. Nightingale nurses earn well above the travel nursing average rates and they enjoy generous
        benefits. Over 50% of our nurses have been with us for at least 5 years. 25% have been here for at least 10
        years and 10% have been with us since day one.
      </p>

      <p style="margin-top: 30px;">
        What kind of travel nursing agency earns that sort of loyalty? A truly exceptional one, that knows what their
        nurses are worth and delivers the best travel nursing salaries in the industry. Ultra-competitive earnings are
        key, but we have even more than that to offer you. Our sterling reputation for providing high-quality nurses
        means we have more opportunities at the best hospitals in the most desirable parts of the country.
      </p>
    </div>

  </div>

</div>
