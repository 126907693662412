import {Component, computed, input, output, signal} from '@angular/core';
import {MatProgressSpinner, ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {NgStyle, PercentPipe} from '@angular/common';
import {PortalSectionCompleteness} from '../../../shared/model/types';
import {ReturnStatement} from '@angular/compiler';
import {MatCard} from '@angular/material/card';
import {MatButton} from "@angular/material/button";
import {
  WorkHistoryDialogComponent
} from "../../portal-user-profile/portal-user-work-history/work-history-dialog/work-history-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {LoadResumeDialogComponent} from "./load-resume-dialog/load-resume-dialog.component";
import {LoadResumeEditorComponent} from "./load-resume-editor-dialog/load-resume-editor.component";

@Component({
  selector: 'app-portal-section-completeness',
  standalone: true,
  imports: [
    MatProgressSpinner,
    PercentPipe,
    NgStyle,
    MatCard,
    MatButton
  ],
  templateUrl: './portal-section-completeness.component.html',
  styleUrl: './portal-section-completeness.component.css'
})
export class PortalSectionCompletenessComponent {
  mode: ProgressSpinnerMode = 'determinate';

  label = input.required<string>();
  completeness = input.required<PortalSectionCompleteness[]>();

  totalPercentage = computed(() => {
    const completed = this.completeness()
      .map(i => i.completed)
      .reduce((acc, item) => acc + item, 0);
    const total = this.completeness()
      .map(i => i.total)
      .reduce((acc, item) => acc + item, 0);

    return completed / total;
  });

  percentageColor = computed(() => `hsl( 100, 100%, 50%)`);

  click = output<string>();

  onClick(label: string) {
    this.click.emit(label);
  }

  constructor(private matDialog: MatDialog) {

  }

  addResumeFile(): void {
    this.matDialog.open(LoadResumeDialogComponent, {
      autoFocus: false,
      panelClass: ['mobile-popup'],
      disableClose: true,
      data: null,
    }).afterClosed().subscribe(result => {
      if (result) {
        // Assuming LoadResumeEditorComponent is correctly imported
        this.matDialog.open(LoadResumeEditorComponent, {
          data: result.value, // Pass the result as data to LoadResumeEditorComponent
          autoFocus: false,
          disableClose: true,
          panelClass: ['full-screen-mobile-dialog', 'full-screen-tablet-dialog']
        });
      }
    });
  }

}
